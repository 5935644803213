import React from "react";
import ChartKpis from "./components/chart/chart.component";
import {
  ChartsContainer,
  Container,
  Content,
  Lengend,
  LengendContainer,
  LengendText,
  LengendWrapper,
  Title,
} from "./kpis.styles";
import { IKpisAnalyzesProps } from "./kpis.types";

const KpisAnalyzes: React.FC<IKpisAnalyzesProps> = ({
  columnOne,
  columnTwo,
  columnThree,
  columnOnePerc,
  columnTwoPerc,
  columnThreePerc,
  title,
  margin,
}) => {

  return (
    <Container margin={margin}>
      {title && <Title>{title}</Title>}
      <Content>
        <ChartsContainer>
          {columnOne && columnOne.length > 0 &&
            <ChartKpis data={columnOne} isPerc={columnOnePerc} />
          }
          {columnTwo && columnTwo.length > 0 &&
            <ChartKpis data={columnTwo} isPerc={columnTwoPerc} />
          }
          {columnThree && columnThree.length > 0 &&
            <ChartKpis data={columnThree} isPerc={columnThreePerc} />
          }
        </ChartsContainer>
        <LengendContainer>
          <LengendWrapper>
            <Lengend color="#4A2C6E" />
            <LengendText>Média do cluster</LengendText>
          </LengendWrapper>
          <LengendWrapper>
            <Lengend color="#FF4141" />
            <LengendText>Performance do influenciador</LengendText>
          </LengendWrapper>
        </LengendContainer>
      </Content>
    </Container>
  );
};
export default React.memo(KpisAnalyzes);
