import React from 'react';
import { IConcludedInfluencer } from './concludedInfluencer.types';

import {
  Container,
  Row,
  Label,
  SucceedBox,
  SucceedLabel,
  Icon
} from './concludedInfluencer.styles';
import ButtonSecondary from '../../../../global/components/buttons/secondary/buttonSecondary.component';

import instagramConcluded from '../../../../assets/icons/instagram_metrics_concluded.svg';
import twitterConcluded from '../../../../assets/icons/twitter_metrics_concluded.svg';
import youtubeConcluded from '../../../../assets/icons/youtube_metrics_concluded.svg';
import tiktokConcluded from '../../../../assets/icons/tiktok_metrics_concluded.svg';

const ConcludedInfluencer: React.FC<IConcludedInfluencer> = (props) => {

  const {
    item,
    onClickEdit,
  } = props;


  return (
    <Container>
      <Row>
        <Label>{item.name}</Label>
        <SucceedBox>
          <SucceedLabel>Métricas enviadas</SucceedLabel>
        </SucceedBox>
      </Row>


      <Row>

        {item.instagram.isActive &&
          <Icon
            src={instagramConcluded}
            alt='metrics-concluded-icon' />
        }
        {item.twitter.isActive &&
          <Icon
            src={twitterConcluded}
            alt='metrics-concluded-icon' />
        }
        {item.youtube.isActive &&
          <Icon
            src={youtubeConcluded}
            alt='metrics-concluded-icon' />
        }
        {item.tiktok.isActive &&
          <Icon
            src={tiktokConcluded}
            alt='metrics-concluded-icon' />
        }

        <ButtonSecondary
          label='Editar métricas do influenciador'
          isDisabled={false}
          loading={false}
          onClick={() => onClickEdit(item)}
          customStyle={{
            'width': '235px;',
            'height': '56px;',
            'margin-left': '40px;'
          }}
          customLabelStyle={{
            "font-style": "normal;",
            "font-weight": "400;",
            "font-size": "12px;",
            "line-height": "14px;"
          }}
        />
      </Row>

    </Container>
  )

}

export default ConcludedInfluencer;