import React from 'react';
import { TailSpin } from 'react-loader-spinner';
import { Container } from './loading.styles';

interface ILoadingComponentProps {
  label: string;
}

const Loading: React.FC<ILoadingComponentProps> = ({ label }) => {
  return (
    <Container>
      <TailSpin
        height="55"
        width="100"
        color="#623CEA"
        ariaLabel="tail-spin-loading"
        radius="1"
        wrapperStyle={{}}
        wrapperClass=""
        visible={true}
      />
      <span style={{ color: 'black' }}>Carregando {label}...</span>
    </Container>
  )
}

export default Loading;