import bg from '../../../assets/backgrounds/bg.jpg';
import logo from '../../../assets/logo/mudah_logo.svg';
import logoColored from '../../../assets/logo/mudah_logo_colored.svg';
import faqIcon from '../../../assets/icons/faq.svg';
import Input from '../../../global/components/input/input.component';
import ButtonPrimary from '../../../global/components/buttons/primary/buttonPrimary.component';
import { IAccessReportProps } from './accessReport.types';

import {
  Container,
  LSWrapper,
  LogoColored,
  Title,
  Subtitle,
  FooterLabel,
  RSWrapper,
  ButtonFAQ,
  ButtonFAQLabel,
  FAQLogo,
  RSLogo,
  RSBackground,
  Overlay
} from './accessReport.styles';
import CustomModal from '../../../global/components/modal/modal.components';

const AccessReportLayout: React.FC<IAccessReportProps> = (props) => {

  const {
    loading,
    password,
    setPassword,
    handleAccessReport
  } = props;

  return (
    <Container>
      <CustomModal />
      <LSWrapper>

        <LogoColored src={logoColored} alt='mudah-logo-colored' />

        <Title>
          Bem-vindo ao mudah performance analytics
        </Title>

        <Subtitle>
          Digite a senha de acesso que você recebeu para acessar o
          relatório de performance da sua campanha
        </Subtitle>

        <Input
          label='Senha de acesso'
          type='password'
          value={password}
          onChange={setPassword}
          customLabelStyle={{
            'color': '#fff;'
          }}
          borderLineColor='#FF4141'
        />

        <ButtonPrimary
          label='Acessar'
          onClick={handleAccessReport}
          isDisabled={password.length > 0 ? false : true}
          loading={loading.status}
          customStyle={{
            'background-color': '#FF4141'
          }}
        />

        <FooterLabel>
          © Mudah 2023. Todos os direitos reservados
        </FooterLabel>
      </LSWrapper>

      <RSWrapper>
        <RSBackground
          style={{
            flex: 1,
            backgroundImage: `url(${bg})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
          }}>
          <ButtonFAQ>
            <FAQLogo src={faqIcon} alt='faq-icon' />
            <ButtonFAQLabel>Duvidas ?<span> Fale conosco</span></ButtonFAQLabel>
          </ButtonFAQ>
          <RSLogo src={logo} alt='mudah-logo' />
          <Overlay />
        </RSBackground>
      </RSWrapper>
    </Container>
  )
}

export default AccessReportLayout;