import React from 'react';
import PrivatePage from '../../global/templates/private/privatePage.component';
import {
  Container,
  Title,
  ButtonAddCampaing,
  IconBg,
  Icon,
  LabelButtonAddCampaign,
  ContainerListCampaigns,
  HeaderTitleBox,
  Row
} from './campaigns.styles';
import iconAddCampaign from '../../assets/icons/icon-quiz.svg';
import Footer from '../../global/components/footer/footer.components';
import CampaignCard from './components/campaignCard/campaignCard.component';
import FilterCampaign from './components/filterCampaign/filterCampaign.component';
import { ICampaignLayoutProps } from './campaigns.types';
import Loading from '../../global/components/loading/loading.component';

const CampaignsLayout: React.FC<ICampaignLayoutProps> = ({ localState, handlers }) => {
  const {
    showFilter,
    loading,
    campaigns
  } = localState;
  const {
    toggleFilter,
    handleNavigationCreateCampaign,
    handleCompletedCampaign
  } = handlers;

  return (
    <PrivatePage>
      <Container>

        <HeaderTitleBox>
          <Title>Reports de campanhas</Title>
          <Row>
            {/* <FilterCampaign
              label='Filtrar'
              placeholder='Selecione'
              labelExpanded='Filtros'
              isOpen={showFilter}
              onClick={toggleFilter}
            />

            <FilterCampaign
              label='Ordenar por'
              placeholder='Data (Mais recente)'
              labelExpanded='Ordenação'
              isOpen={false}
              onClick={() => null}
            /> */}
          </Row>
        </HeaderTitleBox>

        {loading.status ?
          <Loading label='campanhas' />
          :
          <ContainerListCampaigns>
            <ButtonAddCampaing onClick={handleNavigationCreateCampaign}>
              <IconBg>
                <Icon src={iconAddCampaign} alt="Icon adicionar campanha" />
              </IconBg>
              <LabelButtonAddCampaign>
                Criar uma <br />
                nova campanha
              </LabelButtonAddCampaign>
            </ButtonAddCampaing>

            {campaigns.map((item: any, index: number) =>
              <CampaignCard
                item={item}
                key={index}
                onClick={() => handleCompletedCampaign(item)}
              />)
            }
          </ContainerListCampaigns>
        }


      </Container>
      <Footer />
    </PrivatePage>
  )
}

export default CampaignsLayout;