import styled from 'styled-components'

export const Container = styled.div`
  margin-top: 68px;
  padding: 0px 21px 0px 21px;
`

export const TopicContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

export const Topic = styled.h1`
  margin-left: 32px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  color: #23314b;
`

export const WhiteWrapper = styled.div`
  margin-top: 15px;
  background: #ffffff;
  border-radius: 16px;
  margin-bottom: 40px;
  /* padding: 36px 32px; */
`

export const ContentWrapper = styled.div`
  border-radius: 16px;
  padding: 36px 32px;
  @media(max-width: 420px) {
    padding: 26px 22px;
  }
`

export const InstagramTopContainer = styled.div`
  width: 100%;
  height: 177px;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #263238 100%),
    rgba(38, 50, 56, 0.04);
  border-radius: 16px 16px 0px 0px;
`

export const InstagramWrapper = styled.div`
  height: 100%;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #263238 100%),rgba(38, 50, 56, 0.04);
  border-radius: 16px 16px 0px 0px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 62px;
  @media(max-width: 420px) {
    justify-content: center;
    padding-left: 0px;
  }
`

export const Icon = styled.img`
  height: 38px;
  width: 38px;
  margin-right: 20px;
  margin-bottom: 7px;
  @media(max-width: 420px) {
    margin-right: 10px;
    height: 30px;
    width: 30px;
  }
`

export const InstagramTitle = styled.h1`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  color: #ffffff;
  @media(max-width: 420px) {
    font-size: 18px;
  }
`

export const Title = styled.h1`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  color: #23314b;
  @media(max-width: 420px) {
    justify-content: center;
    padding-left: 0px;
  }
`

export const Description = styled.p`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #809fb8;
  margin-top: 6px;
  white-space: pre-line;
`
