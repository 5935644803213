import React, { useState } from 'react';

import { IInputProps } from './input.types';
import { Container, Label, Input, Icon } from './input.styles';

const InputComponent: React.FC<IInputProps> = (props) => {

  const {
    label,
    value,
    type,
    icon,
    customIconStyle,
    customLabelStyle,
    customInputStyle,
    borderLineColor,
    disabled,
    maxLength,
    onChange,
    iconClick
  } = props;

  const [isFocused, setIsFocused] = useState(false);


  return (
    <Container>
      <Label customStyle={customLabelStyle}>{label}</Label>
      <Input
        disabled={disabled}
        isFocused={isFocused}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        type={type ? type : 'text'}
        name='name'
        value={value}
        className='input'
        onChange={(e) => onChange(e.target.value)}
        maxLength={maxLength}
        customStyle={customInputStyle}
        borderLineColor={borderLineColor}
      />
      {icon &&
        <Icon onClick={iconClick ? iconClick : () => { }} src={icon} alt='input-icon' customIconStyle={customIconStyle} style={{ cursor: iconClick ? 'pointer' : 'default' }} />
      }
    </Container>
  )

}

export default InputComponent;