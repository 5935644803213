import styled from 'styled-components'

export const Container = styled.div<{ themeType: 'light' | 'dark' }>`
  display: flex;
  width: 100%;
  background-color: ${(props) =>
    props.themeType === 'light' ? '#A9B8CD' : '#37474E'};
  align-items: center;
  justify-content: center;
  height: 138px;
`

export const Logo = styled.img`
  width: 120px;
  height: 24px;
`

export const FooterDivisor = styled.div<{ themeType: 'light' | 'dark' }>`
  border: 1px solid
    ${(props) => (props.themeType === 'light' ? '#92A1B6' : '#5C7179')};
  height: 23px;
  margin: 0 25px;
`

export const FooterLabel = styled.span<{ themeType: 'light' | 'dark' }>`
  font-family: 'Poppins';
  font-weight: 400;
  font-size: 14px;
  color: ${(props) => (props.themeType === 'light' ? '#92A1B6' : '#5C7179')};
`
