import styled from 'styled-components'

export const TableContainer = styled.div<{ scroll?: boolean }>`
  background: #ffffff;
  border: 1px solid #d8d8e8;
  min-height: 156px;
  margin-top: 14px;
  width: 100%;

  ${props => !props.scroll ? 'border-radius: 16px;' : `
  border-top-right-radius: 16px;
  border-top-left-radius: 16px;
  overflow-x: scroll;

  ::-webkit-scrollbar {
  height: 10px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px grey; 
  border-radius: 10px;
}
 
::-webkit-scrollbar-thumb {
  background: #d8d8e8; 
  border-radius: 10px;
}
  `}

  @media(max-width: 420px) {
    display:inline-flex;
    flex-direction: row;
  }
`

export const TableHeader = styled.div<{ scroll?: boolean }>`
  display: grid;
  border-bottom: 1px solid #d8d8e8;
  justify-content: center;
  align-items: center;
  padding: 27px 30px;
  ${props => !props.scroll ? '' : 'width: fit-content;'}
  grid-template-columns: repeat(7, 1fr);
  @media(max-width: 420px) {
    grid-template-columns: repeat(1, 1fr);
    border-bottom: 0px;
    border-right: 1px solid #d8d8e8;
  }
`

export const HeaderCell = styled.p<{ width: string }>`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  color: #23314b;
  justify-self: center;
  width: ${(props) => props.width};
`

export const TableRow = styled.div<{ numberRow: number, scroll?: boolean, lastItem?: boolean }>`
  display: grid;
  padding: 27px 30px;
  align-items: center;
  justify-content: center;
  background: ${(props) => props.numberRow % 2 === 0 ? 'transparent' : '#F8F8F8'};
  ${props => !props.scroll ? '' : 'width: fit-content;'}
  ${props => props.lastItem && !props.scroll ? 'border-bottom-right-radius:16px; border-bottom-left-radius:16px;' : ''}
  grid-template-columns: repeat(7, 1fr);

  @media(max-width: 420px) {
    grid-template-columns: repeat(1, 1fr);
  }
`

export const RowCell = styled.p<{ width: string }>`
  width: ${(props) => props.width};
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #23314b;
  text-align: center;
  justify-self: center;
`
