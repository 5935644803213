import styled from 'styled-components'

export const Container = styled.div`
  margin-top: 68px;
  padding: 0px 21px 0px 21px;
`

export const Topic = styled.h1`
  margin-left: 32px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  color: #23314b;
`

export const WhiteWrapper = styled.div`
  margin-top: 15px;
  background: #ffffff;
  border-radius: 16px;
  margin-bottom: 40px;
  padding: 36px 32px;
`

export const Title = styled.p`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  color: #23314b;
`

export const Description = styled.p`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  margin-top: 20px;
  color: #23314b;
`

export const Label = styled.p`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  color: #23314b;
  margin-top: 14px;
`

export const Value = styled.p`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #23314b;
  margin-top: 2px;
`
