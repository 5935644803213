import { createSlice } from "@reduxjs/toolkit";
import {
  ICampaignReducerProps,
  IInfluencerProps,
  IInstagramPostsMetricsProps,
  IInstagramStoriesMetricsProps,
  IQualitativeMetricsProps,
  ITiktokMetricsProps,
  ITwitterMetricsProps,
  IYoutubeMetricsProps,
} from "./campaigns.types";

const initialState = {
  //auxVars
  stepNumber: 1,
  isComplete: false,
  brandsList: [],
  changedLandscape: false,
  //stepOne
  id: "",
  title: "",
  landscape: "",
  brand: "",
  initialDate: "",
  endDate: "",
  recap: "",
  //stepOne
  //stepTwo
  influencers: [] as IInfluencerProps[],
  influencerData: {
    name: "",
    cluster: "",
    profilePhoto: null,
    instagram: {
      isActive: false,
      idInstagram: "",
      numberOfPosts: "",
      storiesSigned: "",
      storiesDelivered: "",
      metricsPosts: [] as IInstagramPostsMetricsProps[],
      metricsStories: [] as IInstagramStoriesMetricsProps[],
      followers: "",
    },
    twitter: {
      isActive: false,
      idTwitter: "",
      numberOfTweets: "",
      metrics: [] as ITwitterMetricsProps[],
      followers: "",
    },
    youtube: {
      isActive: false,
      numberOfVideos: "",
      metrics: [] as IYoutubeMetricsProps[],
      followers: "",
    },
    tiktok: {
      isActive: false,
      idTiktok: "",
      numberOfPosts: "",
      metrics: [] as ITiktokMetricsProps[],
      followers: "",
    },
  } as IInfluencerProps,
  //stepTwo
  //stepThree
  instagramQualitative: {
    overviewCampaign: "",
    overviewFeed: "",
    overviewStories: "",
    qualitativeMetrics: [] as IQualitativeMetricsProps[],
  },
  twitterQualitative: {
    overviewCampaign: "",
    overviewTweets: "",
    qualitativeMetrics: [] as IQualitativeMetricsProps[],
  },
  youtubeQualitative: {
    overviewCampaign: "",
    overviewVideos: "",
    qualitativeMetrics: [] as IQualitativeMetricsProps[],
  },
  tiktokQualitative: {
    overviewCampaign: "",
    overviewPosts: "",
    qualitativeMetrics: [] as IQualitativeMetricsProps[],
  },
  //stepThree
  reportLink: "",
  reportPassword: "",
} as ICampaignReducerProps;

export const campaignsSlice = createSlice({
  name: "campaigns",
  initialState,
  reducers: {
    setCampaignsInfo: (state: any, action) => {
      const properties = action.payload;
      const newState = properties;
      const values = Object.keys(properties) as Array<keyof typeof properties>;
      for (const key of values) {
        state[key] = newState[key];
      }
    },
  },
});

export const { setCampaignsInfo } = campaignsSlice.actions;
export default campaignsSlice.reducer;
