import React from "react";
import { TailSpin } from "react-loader-spinner";
import { IButtonOutlineProps } from "./buttonSecondary.types";
import {
  ButtonContent,
  Container,
  Icon,
  Label,
  LoadingBox,
} from "./buttonSecondary.styles";

const ButtonSecondary: React.FC<IButtonOutlineProps> = (props) => {
  const {
    label,
    isDisabled,
    loading,
    onClick,
    customStyle,
    customLabelStyle,
    icon,
  } = props;

  return (
    <Container
      onClick={onClick}
      disabled={isDisabled || loading}
      isDisabled={isDisabled || loading}
      customStyle={customStyle}
    >
      {loading ? (
        <LoadingBox>
          <TailSpin
            height="35"
            width="80"
            color="#fff"
            ariaLabel="tail-spin-loading"
            radius="1"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
          />
        </LoadingBox>
      ) : (
        <ButtonContent>
          {icon && <Icon src={icon} />}
          <Label
            customLabelStyle={customLabelStyle}
            isDisabled={isDisabled || loading}
          >
            {label}
          </Label>
        </ButtonContent>
      )}
    </Container>
  );
};

export default ButtonSecondary;
