import React from 'react';
import {
  Title,
  InstagramDetails,
  LineDivider,
  Row,
  Icon,
  SpacedRow
} from './index.styles';
import tiktokLogo from '../../../../assets/icons/tiktok_metrics.svg';
import { IPreviewMetricsTiktokProps } from './index.types';
import ButtonSecondary from '../../../../global/components/buttons/secondary/buttonSecondary.component';
import PreviewResultsByInfluencerTiktok from 'global/components/report/tiktok/components/previewResultsByInfluencer/index/index.component';


const TikTokPreviewMetrics: React.FC<IPreviewMetricsTiktokProps> = (props) => {
  const {
    toggleModalVisible,
    influencers,
    tiktokQualitative
  } = props;

  return (
    <InstagramDetails>
      <Row>
        <Icon
          src={tiktokLogo}
          alt='icon-metrics-twitter'
        />
        <Title>Prévia métricas TikTok</Title>
      </Row>

      <PreviewResultsByInfluencerTiktok
        influencers={influencers}
        tiktokQualitative={tiktokQualitative}
      />

      <LineDivider />

      <SpacedRow>
        <div></div>
        <ButtonSecondary
          label='Fechar'
          isDisabled={false}
          loading={false}
          onClick={toggleModalVisible}
          customStyle={{
            'margin-top': '25px;',
            'width': '388px;',
            'height': '56px;',
            'align-self': 'flex-end;'
          }}
        />
      </SpacedRow>
    </InstagramDetails>

  )
}

export default TikTokPreviewMetrics;