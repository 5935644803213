import styled from 'styled-components'

export const Container = styled.div`
  margin-top: 68px;
  padding: 0px 21px 0px 21px;
`

export const TopicContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

export const Topic = styled.h1`
  margin-left: 32px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  color: #23314b;
`

export const WhiteWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 15px;
  background: #ffffff;
  border-radius: 16px;
  margin-bottom: 40px;
  padding: 36px 32px;
`

export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`

export const Icon = styled.img`
  height: 30.58px;
  width: 30.58px;
`

export const Title = styled.h1`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  color: #23314b;
  margin-left: 20px;
`

export const ContainerList = styled.div<{ borderBottom?: boolean }>`
  max-width: 100%;
  display: grid;
  margin-top: 51px;
  gap: 25px;
  row-gap: 50px;
  padding-bottom: 62px;
  border-bottom: ${(props) => props.borderBottom && `1.5px solid #ebeff2`};
  margin-bottom: ${(props) => props.borderBottom && '41px'};

  @media (min-width: 380px) {
    grid-template-columns: repeat(1, 1fr);
  }

  @media (min-width: 600px) {
    grid-template-columns: repeat(4, 1fr);
  }
`

export const InfluencerWrapper = styled.div`
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  width: 195px;
`

export const InfluencerPhoto = styled.img`
  height: 77px;
  width: 77px;
  min-height: 77px;
  min-width: 77px;
  border-radius: 100px;
  background: #404040;
  object-fit: cover;
`
export const InfluencerInfo = styled.div`
  display: inline-flex;
  flex-direction: column;
  margin-left: 13px;
`

export const InfluencerName = styled.span`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  color: #23314b;
`
export const InfluencerPosition = styled.span`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 100%;
  color: #809fb8;
  margin-top: 8px;
`
export const InfluencerFollowers = styled.span`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 100%;
  color: #623cea;
  margin-top: 8px;
`
