import moment from "moment";
import lodash from "lodash";

export function validateEmail(email: string) {
  const regex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regex.test(String(email).toLowerCase());
}

export function makeId(length: number) {
  let result = `${Date.now()}`;
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  let counter = 13;
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }
  return result;
}

export function makePassword(length: number) {
  let result = `Mudah@`;
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  let counter = 6;
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }
  return result;
}

export function maskDate(date: string) {
  try {
    date = date.replace(/\D/g, '').slice(0, 10)
    date = date.replace(/(\d{2})(\d)/, '$1/$2')
    date = date.replace(/(\d{2})(\d)/, '$1/$2')
    return date
  } catch (error) { }
}

export const maskNumbers = (number: string) => {
  try {
    if (number === undefined || number === null || number === '') return number;
    number = number.replace(/\D/g, '');
    number = number.replace(/(\d+)(\d{3})/, '$1.$2');
    number = number.replace(/(\d+)(\d{3})/, '$1.$2');
    number = number.replace(/(\d+)(\d{3})/, '$1.$2');
    number = number.replace(/(\d+)(\d{3})/, '$1.$2');
    number = number.replace(/(\d+)(\d{3})/, '$1.$2');
    number = number.replace(/(\d+)(\d{3})/, '$1.$2');
    number = number.replace(/(\d+)(\d{3})/, '$1.$2');
    return number
  } catch (error) {
    return '';
  }
}

export const maskFollowers = (number: string) => {
  try {
    if (number === undefined || number === null || number === '') return number;

    let result = 0;

    number = number.replace(/\D/g, '');

    if (number.length < 4)
      return number;

    if (number.length === 5) {
      result = parseInt(number) / 1000
      return `${Number.isInteger(result) ? result : checkDecimals(result)}k de `
    }

    if (number.length === 6) {
      return `${number.substring(0, 3)}k de `
    }

    if (number.length > 6) {
      result = parseInt(number) / 1000000
      return `${Number.isInteger(result) ? result : checkDecimals(result)} MM de`
    }

    return number
  } catch (error) {
    console.log('Error ->', error)
  }
}

const checkDecimals = (number: number) => {
  try {
    let result = parseFloat(`0.${number.toString().split('.')[1]}`);
    if (result >= 0.1)
      return `${number.toString().split('.')[0]}.${number.toString().split('.')[1].substring(0, 1)}`
    else
      return `${number.toString().split('.')[0]}`

  } catch (error) {

  }
}

export function onlyNumbers(str: string) {
  try {
    str = str.replace(/\D/g, '')
    return str;
  } catch (error) {
    return '';
  }
}

export function maskTime(str: string) {
  try {
    if (str === undefined || str === null || str === '') return str;
    str = str.replace(/\D/g, '');
    str = str.replace(/(\d+)(\d{2})/, '$1:$2');
    str = str.replace(/(\d+)(\d{2})/, '$1:$2');
    return str
  } catch (error) {
    return '';
  }
}


export function convertDate(str: string) {
  try {
    let date = str.split("/");
    let addDay = moment(`${date[2]}-${date[1]}-${date[0]}`).add(1, "day").format("YYYY-MM-DD");
    return addDay;
  } catch (error) {
    return '';
  }
}

export function validateIndividualRangeRateReach(metric: Array<any>, followers: string) {
  try {
    if (metric.length === 0) return 0;
    return Math.max(...metric) / parseInt(followers) * 100;
  } catch (error) {
    return 0;
  }
}

export function validateMathMax(metric: Array<any>) {
  try {
    if (metric.length === 0) return 0;

    return Math.max(...metric);
  } catch (error) {
    return 0;
  }
}

export function validateRangeRateEngagementInstagram(allIterations: Array<any>, allIndividualReaches: Array<any>) {
  try {
    if (allIndividualReaches.length === 0 || lodash.sum(allIterations) === 0) return 0;

    return (lodash.sum(allIterations) / Math.max(...allIndividualReaches)) * 100;
  } catch (error) {
    return 0;
  }
}

export function validateRangeRateViewsInstagram(allViews: Array<any>, followers: string) {
  try {
    if (allViews.length === 0) return 0;

    return (lodash.sum(allViews) / parseInt(followers)) * 100;
  } catch (error) {
    return 0;
  }
}

export function validateSingleMetricToArray(metric: any) {
  try {
    if (metric) {
      return [metric]
    } else {
      return [];
    }
  } catch (error) {
    return [];
  }
}

export function validateRangeRateEngagementTwitter(allEngagements: Array<any>, followers: string) {
  try {
    if (allEngagements.length === 0) return 0;

    return (lodash.sum(allEngagements) / parseInt(followers)) * 100;
  } catch (error) {
    return 0;
  }
}


export function validateRangeRateViewsTiktok(allViews: Array<any>, followers: string) {
  try {
    if (allViews.length === 0) return 0;

    return (lodash.sum(allViews) / parseInt(followers)) * 100;
  } catch (error) {
    return 0;
  }
}

export function validateRangeRateEngagementTiktok(allIterations: Array<any>, allIndividualReaches: Array<any>) {
  try {
    if (allIndividualReaches.length === 0 || lodash.sum(allIterations) === 0) return 0;

    return (lodash.sum(allIterations) / Math.max(...allIndividualReaches)) * 100;
  } catch (error) {
    return 0;
  }
}

export function validateIndividualRangeRateReachTiktok(metric: Array<any>, followers: string) {
  try {
    if (metric.length === 0) return 0;
    return Math.max(...metric) / parseInt(followers) * 100;
  } catch (error) {
    return 0;
  }
}

export function validateAvgMetricTiktok(metric: Array<any>, allPosts: Array<any>) {
  try {
    if (metric.length === 0 || allPosts.length === 0) return 0;

    return lodash.sum(metric) / allPosts.length;
  } catch (error) {
    return 0;
  }
}

export function validateRangeRateEngagementYoutube(allIterations: Array<any>, allIndividualReaches: Array<any>) {
  try {
    if (allIndividualReaches.length === 0 || lodash.sum(allIterations) === 0) return 0;

    return (lodash.sum(allIterations) / Math.max(...allIndividualReaches)) * 100;
  } catch (error) {
    return 0;
  }
}

export function validateAvgMetricYoutube(metric: Array<any>, allMetrics: Array<any>) {
  try {
    if (metric.length === 0 || allMetrics.length === 0) return 0;

    return lodash.sum(metric) / allMetrics.length;
  } catch (error) {
    return 0;
  }
}

export function validateIndividualRangeRateReachYoutube(metric: Array<any>, followers: string) {
  try {
    if (metric.length === 0) return 0;
    return Math.max(...metric) / parseInt(followers) * 100;
  } catch (error) {
    return 0;
  }
}

export function validateRangeRateViewsYoutube(allViews: Array<any>, followers: string) {
  try {
    if (allViews.length === 0) return 0;

    return (lodash.sum(allViews) / parseInt(followers)) * 100;
  } catch (error) {
    return 0;
  }
}

export function convertToDouble(value: string) {
  try {
    if (value === undefined || value === null || value === '') return 0;

    return parseFloat(value.replace(',', '.'));
  } catch (error) {
    return 0;
  }
}

export function convertToSeconds(value: string) {
  try {
    if (value === undefined || value === null || value === '') return 0;

    let newValue = value.split(':');
    let seconds = 0;

    if (newValue.length === 3) {
      seconds = seconds + parseInt(newValue[0]) * 60 * 60;
      seconds = seconds + parseInt(newValue[1]) * 60;
      seconds = seconds + parseInt(newValue[2]);
    }

    if (newValue.length === 2) {
      seconds = seconds + parseInt(newValue[0]) * 60;
      seconds = seconds + parseInt(newValue[1]);
    }

    if (newValue.length === 1) {
      seconds = seconds + parseInt(newValue[0]);
    }

    return seconds;
  } catch (error) {
    return 0;
  }
}


export function convertToTime(value: number) {
  try {
    const h = Math.floor(value / 3600);
    const m = Math.floor((value % 3600) / 60);
    const s = Math.round(value % 60);

    return [
      h,
      m > 9 ? m : (h ? '0' + m : m || '0'),
      s > 9 ? s : '0' + s
    ].filter(Boolean).join(':');

  } catch (error) {
    return 0;
  }
}


