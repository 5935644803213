import styled from "styled-components";

export const InfluencerWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`;

export const InfluencerPhoto = styled.img`
  height: 42px;
  width: 42px;
  min-height: 42px;
  min-width: 42px;
  border-radius: 21px;
  background: #404040;
  object-fit: cover;
`;
export const InfluencerInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 13px;
`;

export const InfluencerName = styled.span`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  text-align: left;
  color: #23314b;
`;
export const InfluencerPosition = styled.span`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  margin-top: 4px;
  line-height: 100%;
  text-align: left;
  color: #809fb8;
`;
export const InfluencerFollowers = styled.span`
  font-family: "Poppins";
  font-style: normal;
  text-align: left;
  font-weight: 400;
  font-size: 12px;
  margin-top: 4px;
  line-height: 100%;
  color: #809fb8;
`;
