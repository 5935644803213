import React from "react";
import {
  Container,
  ContainerGrid,
  GridItem,
  PersonContainer,
  PersonInfo,
  PersonName,
  PersonPhoto,
  PersonPosition,
  PersonRate,
  Subtitle,
  Title,
  TopicContainer,
  TopicText,
} from "./index.styles";
import { ITop3YoutubeProps } from "./index.types";
import { maskNumbers } from "utils/validations";

const Top3Youtube: React.FC<ITop3YoutubeProps> = ({ newArrInfluencers }) => {

  return (
    <Container>
      <Title>Top 3 Youtube</Title>
      <Subtitle>
        Os melhores influenciadores em views e interações no Youtube
      </Subtitle>
      <ContainerGrid>
        {[
          {
            label: "Views",
            influencers: newArrInfluencers.newArrViews
          },
          {
            label: "Retenção",
            influencers: newArrInfluencers.newArrRetention
          },
          {
            label: "Taxa de engajamento",
            influencers: newArrInfluencers.newArrEngagement
          }
        ].map((item, index) => {
          return (
            <GridItem key={index}>
              <TopicContainer width={item.label === 'Taxa de engajamento' ? "200px" : '133px'}>
                <TopicText>{item.label}</TopicText>
              </TopicContainer>
              {item.influencers.map((influencer, index) => (
                <PersonContainer key={index} border={index + 1 !== item.influencers.length}>
                  <PersonPhoto src={influencer.photo} />
                  <PersonInfo>
                    <PersonName>{influencer.name.toUpperCase()}</PersonName>
                    <PersonPosition>{influencer.cluster}</PersonPosition>
                    <PersonRate>
                      {item.label === 'Taxa de engajamento' ? influencer.value : maskNumbers(influencer.value.toString())}
                      {item.label === 'Taxa de engajamento' ? '%' : ''}{" "}
                      {item.label === 'Retenção' ? 'Retenções' : item.label}
                    </PersonRate>
                  </PersonInfo>
                </PersonContainer>
              ))}
            </GridItem>
          );
        })}
      </ContainerGrid>
    </Container>
  );
};
export default Top3Youtube;
