import { IModalReducerProps } from './modal.types';
import { createSlice } from '@reduxjs/toolkit';

const initialState: IModalReducerProps = {
  isVisible: false,
  title: '',
  description: '',
  icon: '',
  confirmation: false,
  labelBtn: '',
  labelBtnLeft: '',
  labelBtnRight: '',
  onClickBtnLeft: undefined,
  onClickBtnRight: undefined,
  onClick: undefined
} as IModalReducerProps;

const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    setProperties: (state: any, action) => {
      // console.log('Action modal ->', action);
      // console.log('state modal ->', state);

      const properties = action.payload;

      const newState = properties;

      const values = Object.keys(properties) as Array<keyof typeof properties>;

      for (const key of values) {
        state[key] = newState[key];
      }
    }
  }
});

export const { setProperties } = modalSlice.actions;
export default modalSlice.reducer;
