const niches = [
  'Consultoria',
  'Comércio',
  'Marketing & Publicidade',
  'Serviços',
  'Tecnologia',
  'Turismo',
  'Varejo'
];

export default niches;