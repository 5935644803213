import styled from 'styled-components';

export const Container = styled.div`
display: flex;
flex-direction: column;
justify-content: center;
width: auto;
min-height: 93px;
background: #fff;
border: 1px solid #D8D8E8;
border-radius: 12px;
margin:20px 0;
padding:30px;
`

export const ContentHeader = styled.div`
display: inline-flex;
flex-direction: row;
align-items: center;
`

export const ProfilePhoto = styled.img`
height: 49px;
width: 49px;
margin-right: 19px;
border-radius: 30px;
object-fit: cover;
`

export const InfluencerName = styled.span`
font-family: 'Poppins';
font-style: normal;
font-weight: 600;
font-size: 14px;
line-height: 21px;
color: #23314B;

>span {
  color: #A9B8CD;
}
`

export const PanelContent = styled.div`
display: flex;
flex:1;
flex-direction: row;
`
export const PanelBody = styled.div`
display: flex;
flex-direction: column;
justify-content: flex-start;
width: 100%;
`
export const Divider = styled.div`
width: 100px;
`