import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FetchAllBrands } from '../../store/brands/brands.actions';
import { setBrandsInfo } from '../../store/brands/brands.reducer';
import { IBrandsProps } from '../../store/brands/brands.types';
import { useAppDispatch } from '../../store/storeHook';
import { ILoadingProps } from '../../utils/globalTypes';

import BrandsLayout from './brands.layout';

const BrandsController: React.FC = () => {
  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  const [brands, setBrands] = useState([]);
  const [loading, setLoading] = useState({ status: true, type: '' });

  const toggleLoading = (status: ILoadingProps) => setLoading(status);

  const handleNavigateBrandRegister = () => {
    navigate('/marcas/registrar');
  }

  const handleDetails = (brand: IBrandsProps) => {
    dispatch(setBrandsInfo({ selectedBrand: brand }));
    navigate('/marcas/detalhes');
  }

  useEffect(() => {
    FetchAllBrands(setBrands, toggleLoading)
  }, [])

  return <BrandsLayout
    localState={{
      brands,
      loading
    }}
    handlers={{
      handleNavigateBrandRegister,
      handleDetails
    }} />
}

export default BrandsController;