import styled from 'styled-components'

export const Container = styled.div`
  margin-top: 52px;
`

export const Title = styled.p`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  color: #23314b;
`
export const Description = styled.p`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 18px;
  margin-top: 24px;
  color: #809fb8;
  white-space: pre-line;
`
