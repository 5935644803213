import React from 'react';
import {
  Container,
  Image,
  InfluencerContainer,
  InfluencerDetail,
  InfluencerInfo,
  InfluencerLabel,
  PersonContainer,
  PersonInfo,
  PersonName,
  PersonPhoto,
  PersonPosition,
  ResultDetailItem,
  Subtitle,
  SubTopic,
  Title,
  Topic,
} from "./index.styles";
import { IResultDetailsByInfluencerProps, ITwitterResultsByInfluencerPostsProps } from "./index.types";
import lodash from 'lodash';
import KpisComponent from "global/components/kpis/kpis.component";
import TwitterResultsByInfluencerPosts from '../postsByInfluencer/postsByInfluencer.component';
import { maskNumbers, validateRangeRateEngagementTwitter } from 'utils/validations';



const PreviewResultsByInfluencerTwitter: React.FC<
  IResultDetailsByInfluencerProps
> = ({ influencers, twitterQualitative }) => {

  const handleFeedData = (influencerName: string) => {
    let newArr: ITwitterResultsByInfluencerPostsProps[] = []

    influencers.filter((item) => item.twitter.isActive)
      .map((influencer) => {
        if (influencer.name === influencerName) {

          influencer.twitter.metrics.map((metric) => {

            let rateEngagement = validateRangeRateEngagementTwitter(metric.engagements ? [metric.engagements] : [], influencer.twitter.followers)
            newArr.push({
              'Total de impressões': maskNumbers(metric.impressions || '0'),
              'Engajamento total': maskNumbers(metric.engagements || '0'),
              'Expansão de detalhes': maskNumbers(metric.expansionDetails || '0'),
              'Taxa de engaj.': `${Number.isInteger(rateEngagement) ? rateEngagement : rateEngagement.toFixed(2)}%`,
              'Favoritos/\ncurtidas': maskNumbers(metric.likes || '0'),
              'Retweets': maskNumbers(metric.retweets || '0'),
              'Cliques em #': maskNumbers(metric.clicksOnHashtag || '0'),
              'Cliques no link': maskNumbers(metric.clicksOnLinks || '0'),
              'Visualizações': maskNumbers(metric.views || '0'),
              'Respostas': maskNumbers(metric.replies || '0'),
            })

          })

        }
      })

    return newArr;
  }

  const handleFeedDataKpis = (influencerName: string, influencerCluster: string) => {
    let allEngagementsCluster: number[] = [];
    let allRangeRateEngagementsCluster: number[] = [];
    let engagementsIndividual: number[] = [];
    let rangeRateEngamenteIndividual: number = 0;


    let allImpressionsCluster: number[] = [];
    let impressionsIndividual: number[] = [];

    influencers.filter((item) => item.twitter.isActive)
      .map((influencer) => {

        influencer.twitter.metrics.map((metric) => {

          if (influencer.cluster === influencerCluster) {
            //todos os engajamentos do cluster
            allEngagementsCluster.push(parseInt(metric.engagements || '0'));

            //todas as impressoes do cluster
            allImpressionsCluster.push(parseInt(metric.impressions || '0'));
          }


          if (influencer.name === influencerName) {
            engagementsIndividual.push(parseInt(metric.engagements || '0'));
            impressionsIndividual.push(parseInt(metric.impressions || '0'));
          }

        })

        if (influencer.name === influencerName) {
          rangeRateEngamenteIndividual = validateRangeRateEngagementTwitter(engagementsIndividual, influencer.twitter.followers);
        }

        if (influencer.cluster === influencerCluster) {

          allRangeRateEngagementsCluster.push(validateRangeRateEngagementTwitter(allEngagementsCluster, influencer.twitter.followers))
        }

      })

    let avgRangeRateEngagementCluster = lodash.sum(allRangeRateEngagementsCluster) / allRangeRateEngagementsCluster.length;

    let avgImpressionsCluster = lodash.sum(allImpressionsCluster) / allImpressionsCluster.length;
    let avgImpressionsIndividual = lodash.sum(impressionsIndividual) / impressionsIndividual.length;

    return {
      engage: [
        {
          name: 'Média do cluster',
          label: 'Taxa de engajamento',
          value: Number.isInteger(avgRangeRateEngagementCluster) ? avgRangeRateEngagementCluster : parseFloat(avgRangeRateEngagementCluster.toFixed(2))
        },
        {
          name: 'Performance influenciador',
          label: 'Taxa de engajamento',
          value: Number.isInteger(rangeRateEngamenteIndividual) ? rangeRateEngamenteIndividual : parseFloat(rangeRateEngamenteIndividual.toFixed(2))
        },
      ],
      impressions: [
        {
          name: 'Média do cluster',
          label: 'Impressões',
          value: Number.isInteger(avgImpressionsCluster) ? avgImpressionsCluster : parseFloat(avgImpressionsCluster.toFixed(2))
        },
        {
          name: 'Performance influenciador',
          label: 'Impressões',
          value: Number.isInteger(avgImpressionsIndividual) ? avgImpressionsIndividual : parseFloat(avgImpressionsIndividual.toFixed(2))
        },
      ]
    }
  }



  return (
    <Container>
      <Title>Detalhamento de Resultado</Title>
      <Subtitle>Influenciador a influenciador</Subtitle>
      {twitterQualitative.qualitativeMetrics.map((item, index) => (
        <ResultDetailItem key={index}>
          <InfluencerContainer>
            <InfluencerInfo>
              <PersonContainer>
                <PersonPhoto src={item.influencerPhoto} />
                <PersonInfo>
                  <PersonName>{item.influencerName.toUpperCase()}</PersonName>
                  <PersonPosition>{item.influencerCluster}</PersonPosition>
                </PersonInfo>
              </PersonContainer>
            </InfluencerInfo>
          </InfluencerContainer>
          <Topic>Twitter</Topic>
          <TwitterResultsByInfluencerPosts scroll data={handleFeedData(item.influencerName)} />
          <Topic>KPI's Twitter</Topic>
          <SubTopic>Cluster: {item.influencerCluster}</SubTopic>

          <KpisComponent
            columnOne={handleFeedDataKpis(item.influencerName, item.influencerCluster).engage}
            columnOnePerc={true}
            columnTwo={handleFeedDataKpis(item.influencerName, item.influencerCluster).impressions}
          />

        </ResultDetailItem>
      ))}
    </Container>
  );
};

export default React.memo(PreviewResultsByInfluencerTwitter);
