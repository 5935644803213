import React, { useMemo } from "react";
import Top3Youtube from "../top3youtube/index.component";
import { Container, Description, Title } from "./index.styles";
import { IYoutubeVideosProps } from "./index.types";
import PostsByInfluencer from "../postsByInfluencer/postsByInfluencer.component";
import lodash from 'lodash';
import { IIndividualYoutubeProps } from "../../../youtube.types";
import YoutubeGeneralFeedInfo from "../tableGeneralFeedInfo/index.component";
import { convertToDouble, convertToSeconds, convertToTime, maskNumbers, validateAvgMetricYoutube, validateIndividualRangeRateReachYoutube, validateMathMax, validateRangeRateEngagementYoutube, validateRangeRateViewsYoutube } from "utils/validations";

interface INewArrProps {
  name: string;
  photo: string;
  cluster: string;
  value: number;
}

const YoutubeVideos: React.FC<IYoutubeVideosProps> = ({ influencers, youtubeQualitative }) => {

  const generalInfo = useMemo(() => {

    let allVideos: number[] = [];
    let allReaches: number[] = [];
    let allImpressions: number[] = [];
    let allRangeRateEngagement: number[] = [];
    let allAvgWatchedTime: number[] = [];
    let allRetention: number[] = [];
    let allPercentageWatchedDuringAd: number[] = [];


    influencers.filter((item) => item.youtube.isActive)
      .map((influencer) => {
        let allReachesIndividual: number[] = [];
        let allIterationsIndividual: number[] = [];

        influencer.youtube.metrics.map((metric) => {
          allReaches.push(parseInt(metric.reach || '0'));
          allReachesIndividual.push(parseInt(metric.reach || '0'));
          allImpressions.push(parseInt(metric.impressions || '0'));


          allIterationsIndividual.push(parseInt(metric.likes || '0'));
          allIterationsIndividual.push(parseInt(metric.comments || '0'));

          allAvgWatchedTime.push(convertToSeconds(metric.watchedTimeAvg || '0'));

          allRetention.push(convertToDouble(metric.retention || '0'));
          allPercentageWatchedDuringAd.push(convertToDouble(metric.percentageView || '0'));

        })


        allVideos.push(parseInt(influencer.youtube.numberOfVideos || '0'));
        allRangeRateEngagement.push(validateRangeRateEngagementYoutube(allIterationsIndividual, allReachesIndividual));
      })

    let rangeRateEngagement = lodash.sum(allRangeRateEngagement) / allRangeRateEngagement.length;
    let avgWatchedTime = validateAvgMetricYoutube(allAvgWatchedTime, allAvgWatchedTime);
    let avgRetention = validateAvgMetricYoutube(allRetention, allRetention);
    let avgWatchedDuringAd = validateAvgMetricYoutube(allPercentageWatchedDuringAd, allPercentageWatchedDuringAd);

    return [{
      'Videos publicados': maskNumbers(lodash.sum(allVideos).toString()),
      'Alcance máximo': `${maskNumbers(validateMathMax(allReaches).toString())}`,
      'Impressões totais': `${maskNumbers(lodash.sum(allImpressions).toString())}`,
      'Taxa de engajamento': `${Number.isInteger(rangeRateEngagement) ? rangeRateEngagement : rangeRateEngagement.toFixed(2)}%`,
      'Tempo médio assistido': `${convertToTime(avgWatchedTime)}`,
      'Retenção': `${Number.isInteger(avgRetention) ? avgRetention : avgRetention.toFixed(2).toString()}%`,
      '% vis. durante o anúncio': `${Number.isInteger(avgWatchedDuringAd) ? avgWatchedDuringAd : avgWatchedDuringAd.toFixed(2).toString()}%`,
    }]
    //eslint-disable-next-line
  }, [influencers]);

  const individualInfo = useMemo(() => {
    let allIndividual: IIndividualYoutubeProps[] = [];

    influencers.filter((item) => item.youtube.isActive)
      .map((influencer) => {
        let allReaches: number[] = [];
        let allImpressions: number[] = [];
        let allViews: number[] = [];
        let allLikes: number[] = [];
        let allComments: number[] = [];
        let allAvgWatchedTime: number[] = [];
        let allRetention: number[] = [];
        let allPercentageWatchedDuringAd: number[] = [];


        influencer.youtube.metrics.map((metric => {

          allReaches.push(parseInt(metric.reach || '0'));

          allImpressions.push(parseInt(metric.impressions || '0'));

          allViews.push(parseInt(metric.views || '0'));

          allLikes.push(parseInt(metric.likes || '0'));
          allComments.push(parseInt(metric.comments || '0'));

          allAvgWatchedTime.push(convertToSeconds(metric.watchedTimeAvg || '0'));
          allRetention.push(convertToDouble(metric.retention || '0'));
          allPercentageWatchedDuringAd.push(convertToDouble(metric.percentageView || '0'));
        }))

        let rangeRateReach = validateIndividualRangeRateReachYoutube(allReaches, influencer.youtube.followers);
        let rangeEngagement = validateRangeRateEngagementYoutube([...allLikes, ...allComments], allReaches);
        let rangeViews = validateRangeRateViewsYoutube(allViews, influencer.youtube.followers);
        let avgWatchedTime = validateAvgMetricYoutube(allAvgWatchedTime, allAvgWatchedTime);
        let retention = validateAvgMetricYoutube(allRetention, allRetention);
        let percentageWatchedDuringAd = validateAvgMetricYoutube(allPercentageWatchedDuringAd, allPercentageWatchedDuringAd);

        allIndividual.push({
          'Influenciador': influencer,
          'Alcance máximo': maskNumbers(validateMathMax(allReaches).toString()),
          'Taxa de alcance': `${Number.isInteger(rangeRateReach) ? rangeRateReach : rangeRateReach.toFixed(2)}%`,
          'Impressões': maskNumbers(lodash.sum(allImpressions).toString()),
          'Curtidas': maskNumbers(lodash.sum(allLikes).toString()),
          'Comentários': maskNumbers(lodash.sum(allComments).toString()),
          'Interações totais': maskNumbers(lodash.sum([...allLikes, ...allComments]).toString()),
          'Taxa de engaj.': `${Number.isInteger(rangeEngagement) ? rangeEngagement : rangeEngagement.toFixed(2)}%`,
          'Views': maskNumbers(lodash.sum(allViews).toString()),
          'Taxa de views': `${Number.isInteger(rangeViews) ? rangeViews : rangeViews.toFixed(2)}%`,
          'Tempo médio assistido': `${convertToTime(avgWatchedTime)}`,
          'Retenção': `${Number.isInteger(retention) ? retention : retention.toFixed(2)}%`,
          '% vis. durante o anúncio': `${Number.isInteger(percentageWatchedDuringAd) ? percentageWatchedDuringAd : percentageWatchedDuringAd.toFixed(2)}%`,
        })

      })

    return allIndividual;

    //eslint-disable-next-line
  }, [influencers])

  const newArrInfluencers = useMemo(() => {
    //novo array engajamento
    let newArrEngagement: INewArrProps[] = [];

    //novo array views
    let newArrViews: INewArrProps[] = [];

    //novo array views
    let newArrRetention: INewArrProps[] = [];

    influencers.filter((item) => item.youtube.isActive)
      .map((influencer) => {
        //alcances de cada influencer
        let allReach: number[] = [];

        //todas as interacoes
        let allIterations: number[] = [];

        //todas as views
        let allViews: number[] = [];

        //retenção
        let allRetention: number[] = [];

        influencer.youtube.metrics.map((metric) => {
          allReach.push(parseInt(metric.reach || '0'));

          allIterations.push(parseInt(metric.likes || '0'));
          allIterations.push(parseInt(metric.comments || '0'));

          allViews.push(parseInt(metric.views || '0'));

          allRetention.push(parseInt(metric.retention || '0'));
        })

        let rangeRateEngagement: number = validateRangeRateEngagementYoutube(allIterations, allReach);

        newArrViews.push({
          name: influencer.name,
          photo: influencer.profilePhoto,
          cluster: influencer.cluster,
          value: lodash.sum(allViews)
        })

        newArrEngagement.push({
          name: influencer.name,
          photo: influencer.profilePhoto,
          cluster: influencer.cluster,
          value: Number.isInteger(rangeRateEngagement) ? rangeRateEngagement : parseFloat(rangeRateEngagement.toFixed(2))
        })

        newArrRetention.push({
          name: influencer.name,
          photo: influencer.profilePhoto,
          cluster: influencer.cluster,
          value: lodash.sum(allRetention)
        })
      })


    return {
      newArrRetention: lodash.orderBy(newArrRetention, ['value'], ['desc']).slice(0, 3),
      newArrEngagement: lodash.orderBy(newArrEngagement, ['value'], ['desc']).slice(0, 3),
      newArrViews: lodash.orderBy(newArrViews, ['value'], ['desc']).slice(0, 3),
    }
  }, [influencers])

  return (
    <Container>
      <YoutubeGeneralFeedInfo rows={generalInfo} />

      <Title>Panorama geral - Videos</Title>
      <PostsByInfluencer data={individualInfo} />
      <Description>
        {youtubeQualitative.overviewVideos}
      </Description>
      <Top3Youtube newArrInfluencers={newArrInfluencers} />
    </Container>
  );
};
export default YoutubeVideos;
