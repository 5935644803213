import React from 'react';
import {
  Container,
  BackgroundImage,
  BackgroundShadow,
  Content,
  Title,
  Subtitle
} from './campaignCard.styles';

import { ICampaignCardProps } from './campaignCard.types';

const CampaignCard: React.FC<ICampaignCardProps> = ({ item, isDraft = false, onClick }) => {
  const {
    title,
    initialDate,
    endDate,
    landscape,
    isComplete,
  } = item;

  // console.log('campaign', isComplete, item);


  if (!isComplete || isDraft)
    return <div></div>

  return (
    <Container onClick={() => onClick(item)}>
      <BackgroundImage src={landscape} alt='campaign-img' />
      <BackgroundShadow />
      <Content>
        <Title>{title}</Title>
        <Subtitle>{initialDate} a {endDate}</Subtitle>
      </Content>
    </Container>
  )
}

export default CampaignCard;