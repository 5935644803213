import React from 'react';
import {
  Title,
  InstagramDetails,
  LineDivider,
  Row,
  Icon,
  SpacedRow
} from './index.styles';
import twitterLogo from '../../../../assets/icons/twitter_metrics.svg';
import { IPreviewMetricsTwitterProps } from './index.types';
import ButtonSecondary from '../../../../global/components/buttons/secondary/buttonSecondary.component';
import PreviewResultsByInfluencerTwitter from 'global/components/report/twitter/components/previewResultsByInfluencer/index/index.component';


const TwitterPreviewMetrics: React.FC<IPreviewMetricsTwitterProps> = (props) => {
  const {
    toggleModalVisible,
    influencers,
    twitterQualitative
  } = props;

  return (
    <InstagramDetails>
      <Row>
        <Icon
          src={twitterLogo}
          alt='icon-metrics-twitter'
        />
        <Title>Prévia métricas Twitter</Title>
      </Row>

      <PreviewResultsByInfluencerTwitter
        influencers={influencers}
        twitterQualitative={twitterQualitative}
      />

      <LineDivider />

      <SpacedRow>
        <div></div>
        <ButtonSecondary
          label='Fechar'
          isDisabled={false}
          loading={false}
          onClick={toggleModalVisible}
          customStyle={{
            'margin-top': '25px;',
            'width': '388px;',
            'height': '56px;',
            'align-self': 'flex-end;'
          }}
        />
      </SpacedRow>
    </InstagramDetails>

  )
}

export default TwitterPreviewMetrics;