import styled from 'styled-components';

export const TwitterDetails = styled.div`
display: flex;
flex-direction: column;
padding-bottom: 20px;
`

export const PanelContent = styled.div`
display: flex;
flex:1;
flex-direction: row;
`
export const PanelBody = styled.div`
display: flex;
flex-direction: column;
justify-content: flex-start;
width: 100%;
`
export const Divider = styled.div`
width: 30px;
`