import styled from 'styled-components';

interface IButtonPrimaryProps {
  isDisabled: boolean;
  customStyle?: ICustomStyle
}

interface ICustomStyle {
  [key: string]: string;
}

export const Container = styled.button<IButtonPrimaryProps>`
  margin-top: 7%;
  opacity: ${props => props.isDisabled ? 0.5 : 1};
  background-color: #623CEA;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 56px;
  border-radius: 6px;
  ${props => props.customStyle}
`

export const LoadingBox = styled.div`
`


interface ILabelProps {
  customLabelStyle?: ICustomStyle
}

export const Label = styled.span<ILabelProps>`
  height: 14px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #fff;
  align-self: center;
  ${props => props.customLabelStyle}
`