import styled from 'styled-components'

export const Container = styled.div`
  margin-top: 68px;
  padding: 0px 21px 0px 21px;
`

export const TopicContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

export const Topic = styled.h1`
  margin-left: 32px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  color: #23314b;
`

export const WhiteWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 15px;
  background: #ffffff;
  border-radius: 16px;
  margin-bottom: 40px;
  padding: 36px 32px;
`

export const Subtitle = styled.h1`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  color: #23314b;
`

export const Description = styled.p`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  margin-top: 6px;
  color: #809fb8;
`

export const ContainerList = styled.div<{ borderBottom?: boolean }>`
  max-width: 100%;
  display: grid;
  margin-top: 51px;
  gap: 25px;
  row-gap: 56px;
  padding-bottom: 62px;
  border-bottom: ${(props) => props.borderBottom && `1.5px solid #ebeff2`};
  margin-bottom: ${(props) => props.borderBottom && '41px'};

  @media (min-width: 380px) {
    grid-template-columns: repeat(1, 1fr);
  }

  @media (min-width: 600px) {
    grid-template-columns: repeat(4, 1fr);
  }
`

export const InfluencerWrapper = styled.div`
  box-sizing: border-box;
  padding: 14px 41px;
  background: #ffffff;
  border: 1px solid #d8d8e8;
  border-radius: 16px;
`

export const InfluencerProfile = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`
export const InfluencerPhoto = styled.img`
  width: 59px;
  height: 59px;
  min-height: 59px;
  min-width: 59px;
  border-radius: 29.5px;
  object-fit: cover;
`
export const InfluencerName = styled.p`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  color: #23314b;
  margin-left: 22px;
`

export const InfluencerDescription = styled.p`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  margin-top: 14px;
  color: #809fb8;
`
export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`

export const Icon = styled.img`
  height: 30.58px;
  width: 30.58px;
`

export const Title = styled.h1`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  color: #23314b;
  margin-left: 20px;
`