import styled from 'styled-components';

export const Container = styled.div`
  margin-top: 19px;
  display: flex;
  flex-direction: column;
`

export const Label = styled.label`
  height: 14px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  color: #4E4B59;
`

export const Dropzone = styled.div`
display: flex;
flex-direction: row;
justify-content: space-between;
align-items: center;
border-bottom: 1px solid #EBEFF2;
height: 56px;
top: 26px;
margin-top: 15px;
`
export const Row = styled.div`
display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
`

export const UploadLabel = styled.label`
font-family: 'Poppins';
font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 14px;
color: #4E4B59;
margin-left: 10px;
`

export const ButtonUploadBox = styled.label`
  align-items: center;
  justify-content: center;
  width: 134px;
  height: 32px;
  border-radius: 6px;
  border: solid 1px #623CEA;
  font-family: 'Nexa';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #623CEA;
  display: flex;
  cursor: pointer;
`
export const ButtonUpload = styled.input`
  width: 134px;
  height: 32px;
  display: none;
`