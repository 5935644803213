import React from 'react';
import BreadcrumbItem from '../breadcrumbItem/breadcrumbItem.component';

import {
  Container
} from './breadcrumb.styles';
import { IBreadcrumbProps } from './breadcrumb.types';

const Breadcrumb: React.FC<IBreadcrumbProps> = ({ stepNumber }) => {

  return (
    <Container>

      <BreadcrumbItem
        step={1}
        label='Identificação da campanha'
        active={stepNumber >= 1}
      />
      <BreadcrumbItem
        step={2}
        label='Influenciadores'
        active={stepNumber >= 2}
      />
      <BreadcrumbItem
        step={3}
        label='Dados da campanha'
        active={stepNumber >= 3}
      />
      <BreadcrumbItem
        step={4}
        label='Revisão'
        active={stepNumber >= 4}
      />
      <BreadcrumbItem
        step={5}
        label='Compartilhar report'
        active={stepNumber >= 5}
      />

    </Container>
  )
}
export default Breadcrumb;