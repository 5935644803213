import React from "react";
import {
  Container,
  ContainerList,
  Description,
  InfluencerWrapper,
  Subtitle,
  Topic,
  WhiteWrapper,
  InfluencerProfile,
  InfluencerName,
  InfluencerPhoto,
  InfluencerDescription,
  TopicContent,
  TitleWrapper,
  Icon,
  Title,

} from "./scope.styles";
import { IScopeReportProps } from "./scope.types";
import EditIcon from "../../../../assets/icons/edit.svg";
import ButtonSecondary from "../../buttons/secondary/buttonSecondary.component";
import { IInfluencerProps } from "store/campaigns/campaigns.types";
import Instagram from "../../../../assets/icons/instagram.svg";
import Tiktok from "../../../../assets/icons/tiktok.svg";
import Youtube from "../../../../assets/icons/youtube_metrics.svg";
import Twitter from "../../../../assets/icons/twitter_metrics.svg";
import { IconsMediaSocialProps } from "../influencers/influencers.types";

const ScopeReport: React.FC<IScopeReportProps> = (props) => {

  const {
    editable,
    handleGoBack,
    influencers
  } = props;

  const icons: IconsMediaSocialProps = {
    Instagram: Instagram,
    Twitter: Twitter,
    Youtube: Youtube,
    TikTok: Tiktok,
  };

  const socialMedia = [
    {
      media: "Instagram",
      influencers: influencers.filter((item) => item.instagram.isActive)
    },
    {
      media: "Twitter",
      influencers: influencers.filter((item) => item.twitter.isActive)
    },
    {
      media: "Youtube",
      influencers: influencers.filter((item) => item.youtube.isActive)
    },
    {
      media: "TikTok",
      influencers: influencers.filter((item) => item.tiktok.isActive)
    }
  ];

  interface IMediaType {
    [key: string]: 'instagram' | 'twitter' | 'youtube' | 'tiktok'
  }

  const mediaType: IMediaType = {
    Instagram: 'instagram',
    Twitter: 'twitter',
    Youtube: 'youtube',
    TikTok: 'tiktok'
  }

  return (
    <Container id="#scope">
      <TopicContent>
        <Topic>3. Escopo</Topic>
        {editable && (
          <ButtonSecondary
            label="Editar Informações"
            isDisabled={false}
            loading={false}
            onClick={() => handleGoBack(3)}
            icon={EditIcon}
            customStyle={{ width: "180px", height: "32px" }}
            customLabelStyle={{
              fontSize: "13px",
              lineHeight: "14px",
              fontWeight: "400",
              fontFamily: "Montserrat",
            }}
          />
        )}
      </TopicContent>
      <WhiteWrapper>
        <Subtitle>Panorama de entregas</Subtitle>
        <Description>
          Tabela que sinaliza o escopo contratado e o que já foi entregue por
          cada um dos influenciadores até o momento.
        </Description>
        {/* {influencers.map((influencer, index) => {
            return (
              <InfluencerWrapper key={index}>
                <InfluencerProfile>
                  <InfluencerPhoto src={influencer.profilePhoto} />
                  <InfluencerName>{influencer.name.toUpperCase()}</InfluencerName>
                </InfluencerProfile>
                <InfluencerDescription>
                  {influencer.signedScope}
                </InfluencerDescription>
              </InfluencerWrapper>
            );
          })} */}

        {socialMedia.map((item, index) => {
          if (!item.influencers.length)
            return <div key={index}></div>

          return (
            <div key={index}>
              <TitleWrapper>
                <Icon src={icons[item.media]} />
                <Title>{item.media}</Title>
              </TitleWrapper>
              <ContainerList borderBottom={index + 1 !== socialMedia.length}>
                {item.influencers.map((influencer: IInfluencerProps, index) => {
                  return (
                    <InfluencerWrapper key={index}>
                      <InfluencerProfile>
                        <InfluencerPhoto src={influencer.profilePhoto} />
                        <InfluencerName>{influencer.name.toUpperCase()}</InfluencerName>
                      </InfluencerProfile>
                      <InfluencerDescription>
                        {influencer.signedScope}
                      </InfluencerDescription>
                    </InfluencerWrapper>
                  );
                })}
              </ContainerList>
            </div>
          );
        })}
      </WhiteWrapper>
    </Container>
  );
};
export default ScopeReport;
