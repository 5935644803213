import React from "react";
import ButtonPrimary from "../../../../global/components/buttons/primary/buttonPrimary.component";
import ButtonSecondary from "../../../../global/components/buttons/secondary/buttonSecondary.component";
import Footer from "../../../../global/components/footer/footer.components";

import PrivatePage from "../../../../global/templates/private/privatePage.component";
import Breadcrumb from "../../components/breadcrumb/breadcrumb.component";
import CampaignRegisterFive from "../stepFive/campaignRegisterFive.layout";
import CampaignRegisterFour from "../stepFour/campaignRegisterFour.layout";
import CampaignRegisterOne from "../stepOne/campaignRegisterOne.layout";
import CampaignRegisterThree from "../stepThree/campaignRegisterThree.layout";
import CampaignRegisterTwo from "../stepTwo/campaignRegisterTwo.layout";

import {
  Container,
  Title,
  ButtonsFooterBox,
} from "./campaignRegisterIndex.styles";
import { ICampaignRegisterLayoutProps } from "./campaignRegisterIndex.types";

const CampaignRegisterLayout: React.FC<ICampaignRegisterLayoutProps> = ({ localState, handlers }) => {
  const {
    modal,
    stepNumber,
    title,
    landscape,
    brand,
    initialDate,
    endDate,
    recap,
    brandsList,
    loadingBrands,
    loading,
    buttonDisabled,
    influencers,
    influencerData,
    instagramQualitative,
    twitterQualitative,
    youtubeQualitative,
    tiktokQualitative,
    reportLink,
    reportPassword
  } = localState;

  const {
    setModal,
    handleNextStep,
    handlePreviousStep,
    toggleModalVisible,
    handleSetStates,
    handleSaveInfluencer,
    handleEditInfluencerCompleted,
    handleShowModal,
    setButtonDisabled,
    handleBackEdit,
    handleGoToDownloadPDF
  } = handlers;

  // console.log('influencerData ->', influencerData);

  return (
    <PrivatePage>
      <Container id="#initDoc">
        <Title>Nova campanha</Title>

        <Breadcrumb stepNumber={stepNumber} />

        {stepNumber === 1 &&
          <CampaignRegisterOne
            title={title}
            landscape={landscape}
            brand={brand}
            brandsList={brandsList}
            initialDate={initialDate}
            endDate={endDate}
            recap={recap}
            loadingBrands={loadingBrands}
            setTitle={(title) => handleSetStates({ title })}
            setLandscape={(landscape) => handleSetStates({ landscape, changedLandscape: true })}
            setBrand={(brand) => handleSetStates({ brand })}
            setInitialDate={(initialDate) => handleSetStates({ initialDate })}
            setEndDate={(endDate) => handleSetStates({ endDate })}
            setRecap={(recap) => handleSetStates({ recap })}
          />}

        {stepNumber === 2 &&
          <CampaignRegisterTwo
            modal={modal}
            toggleModalVisible={toggleModalVisible}
            influencers={influencers}
            influencerData={influencerData}
            setModal={setModal}
            handleSetStates={handleSetStates}
            handleSaveInfluencer={handleSaveInfluencer}
            handleEditInfluencerCompleted={handleEditInfluencerCompleted}
          />
        }

        {stepNumber === 3 &&
          <CampaignRegisterThree
            influencers={influencers}
            instagramQualitative={instagramQualitative}
            twitterQualitative={twitterQualitative}
            youtubeQualitative={youtubeQualitative}
            tiktokQualitative={tiktokQualitative}
            handleSetStates={handleSetStates}
            toggleModalVisible={() => toggleModalVisible('instagramPosts')}
            handleShowModal={handleShowModal}
            enableHandleNext={() => setButtonDisabled(false)}
          />
        }

        {stepNumber === 4 &&
          <CampaignRegisterFour
            localState={localState}
          />
        }

        {stepNumber === 5 &&
          <CampaignRegisterFive
            reportLink={reportLink}
            reportPassword={reportPassword}
            handleGoToDownloadPDF={handleGoToDownloadPDF}
          />
        }


        <ButtonsFooterBox showBackButton={stepNumber !== 1}>

          {stepNumber !== 1 && (
            <ButtonSecondary
              label={stepNumber === 5 ? "Voltar e editar" : "Passo anterior"}
              isDisabled={false}
              loading={false}
              onClick={handlePreviousStep}
              customStyle={{
                "margin-top": "25px;",
                width: "335px;",
                height: "56px;",
              }}
            />
          )}

          {stepNumber !== 5 && (
            <ButtonPrimary
              label={stepNumber === 4 ? "Finalizar relatório" : "Próximo passo"}
              isDisabled={buttonDisabled}
              loading={loading.status}
              onClick={handleNextStep}
              customStyle={{
                "margin-top": "25px;",
                width: "335px;",
                height: "56px;",
              }}
            />
          )}
        </ButtonsFooterBox>
      </Container>
      <Footer />
    </PrivatePage>
  );
};

export default CampaignRegisterLayout;
