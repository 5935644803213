import styled from 'styled-components';

interface IContainerProps {
  customStyle?: any;
}
export const Container = styled.div<IContainerProps>`
  display: flex;
  flex-direction: column;
  position: relative;
  ${props => props.customStyle}
`

export const Label = styled.label`
  height: 14px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  color: #4E4B59;
`

interface IInputProps {
  isFocused: boolean;
}

export const Input = styled.textarea<IInputProps>`
  height: 100px;
  top: 26px;
  border: 1.5px solid ${props => props.isFocused ? '#623CEA' : '#D8D8E8'};
  border-radius: 6px;
  margin-top: 12px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  color: #4E4B59;
  padding:17px;
  background-color: #ffffff;
`