import React, { useState } from 'react';
import UploadPhotoCampaign from '../../components/uploadPhotoCampaign/uploadPhotoCampaign.component';
import Input from '../../../../global/components/input/input.component';
import CustomDropdown from '../../../../global/components/dropdown/dropdown.component';
import calendarIcon from '../../../../assets/icons/calendar.svg';
import Textarea from '../../../../global/components/textarea/textarea.component';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';

import {
  Panel,
  PanelTitle,
  PanelContent,
  PanelBody,
  Divider,
  CalendarBox
} from './campaignRegisterOne.styles';
import { ICampaignRegisterOneProps } from './campaignRegisterOne.types';
import { convertDate, maskDate } from '../../../../utils/validations';
import moment from 'moment';

const CampaignRegisterOne: React.FC<ICampaignRegisterOneProps> = (props) => {

  const [showInitialCalendar, setShowInitialCalendar] = useState(false);
  const [showEndCalendar, setShowEndCalendar] = useState(false);

  const {
    title,
    landscape,
    brand,
    initialDate,
    endDate,
    recap,
    brandsList,
    loadingBrands,
    setTitle,
    setLandscape,
    setBrand,
    setInitialDate,
    setEndDate,
    setRecap,
  } = props;
  return (
    <>
      <Panel>
        <PanelTitle>Dados principais</PanelTitle>

        <PanelContent>
          <PanelBody>
            <Input
              label='Titulo da campanha'
              value={title}
              onChange={setTitle}
            />
          </PanelBody>
          <Divider />
          <PanelBody>
            <UploadPhotoCampaign
              value={landscape?.name ? landscape?.name : landscape?.length > 0 ? 'Já existe uma imagem de capa' : ''}
              onChange={setLandscape}
            />
          </PanelBody>
        </PanelContent>

        <PanelContent>
          <PanelBody>
            <CustomDropdown
              label='Marca'
              listItem={brandsList?.length ? brandsList.map((item) => item.brandName) : []}
              value={loadingBrands.status ? 'Carregando marcas...' : brand}
              onChange={setBrand}
            />
          </PanelBody>
        </PanelContent>

        <PanelContent>
          <PanelBody>
            <Input
              label='Data de início'
              value={maskDate(initialDate) || ''}
              onChange={() => { }}
              icon={calendarIcon}
              iconClick={() => setShowInitialCalendar(true)}
              maxLength={10}
            />
            {showInitialCalendar &&
              <CalendarBox>
                <Calendar onChange={(value: Date) => {
                  setInitialDate(moment(value).format("DD/MM/YYYY"));
                  setTimeout(() => {
                    setShowInitialCalendar(false);
                  }, 200);
                }}
                  value={initialDate.length === 10 ? new Date(convertDate(initialDate)) : new Date()}
                />
              </CalendarBox>
            }
          </PanelBody>
          <Divider />
          <PanelBody>
            <Input
              label='Data de término'
              value={maskDate(endDate) || ''}
              onChange={() => { }}
              icon={calendarIcon}
              iconClick={() => setShowEndCalendar(true)}
              maxLength={10}
            />
            {showEndCalendar &&
              <CalendarBox>
                <Calendar onChange={(value: Date) => {
                  setEndDate(moment(value).format("DD/MM/YYYY"));
                  setTimeout(() => {
                    setShowEndCalendar(false);
                  }, 200);
                }}
                  value={endDate.length === 10 ? new Date(convertDate(endDate)) : new Date()}
                />
              </CalendarBox>
            }
          </PanelBody>

        </PanelContent>

      </Panel>

      <Panel>
        <Textarea
          label='Recap (Uma breve descrição da campanha)'
          value={recap}
          onChange={setRecap}
        />
      </Panel>
    </>
  )
}

export default React.memo(CampaignRegisterOne);