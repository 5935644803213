import React, { useMemo } from "react";
import {
  Container,
  ContainerGrid,
  GridItem,
  PersonContainer,
  PersonInfo,
  PersonName,
  PersonPhoto,
  PersonPosition,
  PersonRate,
  Subtitle,
  Title,
  TopicContainer,
  TopicText,
} from "./feedInstagram.styles";
import { IInstagramTopThreeFeedProps } from "./feedInstagram.types";
import lodash from 'lodash';
import { validateRangeRateEngagementInstagram, validateRangeRateViewsInstagram } from "utils/validations";
import { validateIndividualRangeRateReach } from "utils/validations";

interface INewArrProps {
  name: string;
  photo: string;
  cluster: string;
  value: number;
}

const InstagramTopThreeFeed: React.FC<IInstagramTopThreeFeedProps> = ({ influencers }) => {


  const newArrInfluencers = useMemo(() => {
    //novo array alcance
    let newArrReach: INewArrProps[] = [];

    //novo array engajamento
    let newArrEngagement: INewArrProps[] = [];

    //novo array views
    let newArrViews: INewArrProps[] = [];

    influencers.filter((item) => item.instagram.isActive)
      .map((influencer) => {
        //alcances de cada influencer
        let allReach: number[] = [];

        //todas as interacoes
        let allIterations: number[] = [];

        //todas as views
        let allViews: number[] = [];

        influencer.instagram.metricsPosts.map((metric) => {
          allReach.push(parseInt(metric.reach || '0'));

          allIterations.push(parseInt(metric.likes || '0'));
          allIterations.push(parseInt(metric.comments || '0'));
          allIterations.push(parseInt(metric.saved || '0'));
          allIterations.push(parseInt(metric.forwards || '0'));

          allViews.push(parseInt(metric.views || '0'));
        })

        let totalViews = validateRangeRateViewsInstagram(allViews, influencer.instagram.followers);
        let totalEngagement = validateRangeRateEngagementInstagram(allIterations, allReach);
        let totalReach = validateIndividualRangeRateReach(allReach, influencer.instagram.followers);

        newArrViews.push({
          name: influencer.name,
          photo: influencer.profilePhoto,
          cluster: influencer.cluster,
          value: Number.isInteger(totalViews) ? totalViews : parseFloat(totalViews.toFixed(2))
        })

        newArrEngagement.push({
          name: influencer.name,
          photo: influencer.profilePhoto,
          cluster: influencer.cluster,
          value: Number.isInteger(totalEngagement) ? totalEngagement : parseFloat(totalEngagement.toFixed(2))
        })

        newArrReach.push({
          name: influencer.name,
          photo: influencer.profilePhoto,
          cluster: influencer.cluster,
          value: Number.isInteger(totalReach) ? totalReach : parseFloat(totalReach.toFixed(2))
        })
      })


    return {
      newArrReach: lodash.orderBy(newArrReach, ['value'], ['desc']).slice(0, 3),
      newArrEngagement: lodash.orderBy(newArrEngagement, ['value'], ['desc']).slice(0, 3),
      newArrViews: lodash.orderBy(newArrViews, ['value'], ['desc']).slice(0, 3),
    }
  }, [influencers])

  return (
    <Container>
      <Title>Top 3 Feed</Title>
      <Subtitle>
        Os melhores influenciadores em % de alcance e % de engajamento em feed.
      </Subtitle>
      <ContainerGrid>
        {[{
          label: "Alcance",
          influencers: newArrInfluencers.newArrReach
        },
        {
          label: "Engajamento",
          influencers: newArrInfluencers.newArrEngagement
        },
        {
          label: "Views",
          influencers: newArrInfluencers.newArrViews
        }].map((item, index) => {
          return (
            <GridItem key={index}>
              <TopicContainer>
                <TopicText>{item.label}</TopicText>
              </TopicContainer>
              {item.influencers.map((influencer: INewArrProps, index) => (
                <PersonContainer border={index + 1 === item.influencers.length} key={index}>
                  <PersonPhoto src={influencer.photo} />
                  <PersonInfo>
                    <PersonName>{influencer.name.toUpperCase()}</PersonName>
                    <PersonPosition>{influencer.cluster}</PersonPosition>
                    <PersonRate>{`${influencer.value}% de ${item.label}`}</PersonRate>
                  </PersonInfo>
                </PersonContainer>
              ))}
            </GridItem>
          );
        })}
      </ContainerGrid>
    </Container>
  );
};
export default InstagramTopThreeFeed;
