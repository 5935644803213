import React, { useMemo } from "react";
import TopStoriesInstagram from "../topStories/storiesInstagram.component";
import { Container, Description, Title } from "./stories.styles";
import { IStoriesInstagramProps } from "./stories.types";
import StoriesByInfluencer from "../storiesByInfluencer/storiesByInfluencer.component";
import lodash from 'lodash';
import { maskNumbers, validateIndividualRangeRateReach, validateMathMax, validateRangeRateEngagementInstagram } from "../../../../../../../utils/validations";
import { IStoryByInfluencerProps } from "../storiesByInfluencer/storiesByInfluencer.types";
import InstagramGeneralStoryInfo from "../tableGeneralStoryInfo/index.component";


const StoriesInstagram: React.FC<IStoriesInstagramProps> = (props) => {
  const {
    influencers,
    instagramQualitative
  } = props;

  const storyMetricsGeneral = useMemo(() => {
    //alcanceMaximo da campanha
    let maxReachCampaign: number[] = [];

    //taxa de alcance individual
    let rangeRateIndividual: number[] = [];

    //todas as impressoes
    let allImpressions: number[] = [];

    //cliques em links
    let allLinkClicks: number[] = [];

    //story por influenciador
    let storyByInfluencer: IStoryByInfluencerProps[] = [];

    //stories contratados
    let allSigned: number[] = [];

    //stories entregues
    let allDelivered: number[] = [];

    influencers.filter((item) => item.instagram.isActive)
      .map((influencer) => {
        //todos os alcances individuais
        let allReachs: number[] = [];

        //todas as impressoes individuais
        let allImpressionsIndividual: number[] = [];

        //todos os cliques individuais
        let allClicksIndividual: number[] = [];

        //todos os cliques no @ da marca individual
        let allClicksOnBrand: number[] = [];

        let allReplies: number[] = [];
        let allShares: number[] = [];
        let allVisitsOnProfile: number[] = [];

        influencer.instagram.metricsStories.map((metric) => {
          //alcance geral
          maxReachCampaign.push(parseInt(metric.reach || '0'));

          //alcance individual
          allReachs.push(parseInt(metric.reach || '0'));

          //impressoes
          allImpressions.push(parseInt(metric.impressions || '0'));

          //cliques em links
          allLinkClicks.push(parseInt(metric.linkClicks || '0'));


          //todas as impressoes individuais
          allImpressionsIndividual.push(parseInt(metric.impressions || '0'));

          //todos os cliques individuais
          allClicksIndividual.push(parseInt(metric.linkClicks || '0'));

          //todos os cliques no @ da marca individual
          allClicksOnBrand.push(parseInt(metric.clickOnBrandLink || '0'));

          //todas as respostas individuais
          allReplies.push(parseInt(metric.replies || '0'))

          //todas os compartilhamentos individuais
          allShares.push(parseInt(metric.shares || '0'))

          //todas as visitas ao perfil individuais
          allVisitsOnProfile.push(parseInt(metric.visitsOnProfile || '0'))

        })

        rangeRateIndividual.push(validateIndividualRangeRateReach(allReachs, influencer.instagram.followers));
        allSigned.push(parseFloat(influencer.instagram.storiesSigned));
        allDelivered.push(parseFloat(influencer.instagram.storiesDelivered));

        let rangeRateReach = validateIndividualRangeRateReach(allReachs, influencer.instagram.followers);
        let allIterations = lodash.sum([...allClicksIndividual, ...allClicksOnBrand, ...allReplies, ...allShares, ...allVisitsOnProfile]);
        let rangeRateEngagement = validateRangeRateEngagementInstagram([...allClicksIndividual, ...allClicksOnBrand, ...allReplies, ...allShares, ...allVisitsOnProfile], allReachs);


        storyByInfluencer.push({
          'Influenciador': influencer,
          'Contratado': maskNumbers(influencer.instagram.storiesSigned || '0'),
          'Entregue': maskNumbers(influencer.instagram.storiesDelivered || '0'),
          "Alcance máximo": maskNumbers(validateMathMax(allReachs).toString()),
          "Taxa de alcance": `${Number.isInteger(rangeRateReach) ? rangeRateReach : rangeRateReach.toFixed(2)}%`,
          "Impressões totais": maskNumbers(lodash.sum(allImpressionsIndividual).toString()),
          "Cliques no @ da marca": maskNumbers(lodash.sum(allClicksOnBrand).toString()),
          "Cliques no link": maskNumbers(lodash.sum(allClicksIndividual).toString()),
          "Interações totais": maskNumbers(allIterations.toString()),
          "Taxa de engajamento": `${Number.isInteger(rangeRateEngagement) ? rangeRateEngagement : rangeRateEngagement.toFixed(2)}%`,
        })

      })

    let rangeGeneral = lodash.sum(rangeRateIndividual) / rangeRateIndividual.length;

    return {
      generalInfo: [
        {
          "Bonificações": maskNumbers((lodash.sum(allDelivered) - lodash.sum(allSigned)).toString()),
          "Alcance máximo": maskNumbers(Math.max(...maxReachCampaign).toString()),
          "Taxa de alcance": `${Number.isInteger(rangeGeneral) ? rangeGeneral : rangeGeneral.toFixed(2)}%`,
          "Impressões totais": maskNumbers(lodash.sum(allImpressions).toString()),
          "Cliques em link": maskNumbers(lodash.sum(allLinkClicks).toString()),
        },
      ],
      storyByInfluencer
    }

  }, [influencers])

  return (
    <Container>
      <Title>Stories</Title>
      <InstagramGeneralStoryInfo rows={storyMetricsGeneral.generalInfo} />
      <StoriesByInfluencer data={storyMetricsGeneral.storyByInfluencer} />
      <Description>{instagramQualitative.overviewStories}</Description>
      <TopStoriesInstagram influencers={influencers} />
    </Container>
  );
};
export default React.memo(StoriesInstagram);
