import React from "react";
import {
  CompanyLogo,
  Container,
  Content,
  TitleWrapper,
  PerformanceText,
  Title,
  LogoInfo,
  BgImage
} from "./headerReport.styles";
import { IHeaderReportProps } from "./headerReport.types";

const HeaderReport: React.FC<IHeaderReportProps> = ({ data }) => {
  const {
    landscape,
    title,
    brandThumb
  } = data;
  return (
    <Container>
      <BgImage
        style={{ backgroundImage: `url(${landscape})` }}
      />
      <Content>
        <LogoInfo>
          <CompanyLogo src={brandThumb} />
          <TitleWrapper>
            <PerformanceText>Performance analytics</PerformanceText>
            <Title>Campanha: {title}</Title>
          </TitleWrapper>
        </LogoInfo>
      </Content>
    </Container>
  );
};
export default HeaderReport;
