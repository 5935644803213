import styled from "styled-components";

export const Container = styled.div`
  margin-top: 41px;
`;

export const Title = styled.h1`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  color: #23314b;
`;

export const Subtitle = styled.p`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  margin-top: 3px;
  color: #809fb8;
`;

export const ResultDetailItem = styled.div`
  background: #ffffff;
  border: 1px solid #d8d8e8;
  border-radius: 16px;
  padding: 20px 15px;
  margin-top: 21px;
`;

export const InfluencerContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
`;

export const Image = styled.img`
  border-radius: 16px;
  height: 410.31px;
  width: 261px;
  object-fit: cover;
  @media(max-width:420px){
    display:none;
  }
`;

export const InfluencerInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media(max-width:420px){
   margin-left: 10px;
  }
`;

export const InfluencerLabel = styled.h1`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 21px;
  color: #23314b;
  margin-top: 24px;
`;

export const InfluencerDetail = styled.h1`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #809fb8;
  margin-top: 8px;
`;

export const PersonContainer = styled.div<{ border?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: ${(props) => props.border && "1px solid #d8d8e8"};
  padding-bottom: 23px;
`;

export const PersonPhoto = styled.img`
  width: 71px;
  height: 71px;
  border-radius: 35.5px;
  object-fit: cover;
`;

export const PersonInfo = styled.div`
  margin-left: 20px;
`;

export const PersonName = styled.div`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  color: #23314b;
`;

export const PersonPosition = styled.div`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 100%;
  color: #23314b;
`;

export const Topic = styled.p`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 21px;
  color: #23314b;
  margin-top: 40px;
`;

export const SubTopic = styled.p`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #809fb8;
`;
