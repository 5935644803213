import styled from 'styled-components';


export const Container = styled.button`
  margin-top: 7%;
  display: inline-flex;
  align-items: center;
  background: none;
`

export const Checkbox = styled.div`
  width: 24px;
  height: 24px;
  border: 1.5px solid #8C8A97;
  border-radius: 6px;
  margin-right: 5px;
  justify-content: center;
  align-items: center;
  display: inline-flex;
  `

export const CheckboxFilling = styled.div`
  width: 20px;
  height: 20px;
  background: #623CEA;
  border-radius: 6px;
  align-self: center;
  `

export const Label = styled.span`
    height: 14px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 14px;
    color: #7A7786;
  `