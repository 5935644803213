import React, { useMemo } from "react";
import {
  Container,
  ContainerGrid,
  GridItem,
  PersonContainer,
  PersonInfo,
  PersonMedia,
  PersonName,
  PersonPhoto,
  PersonPosition,
  PersonRate,
  Subtitle,
  Title,
  TopicContainer,
  TopicText,
} from "./storiesInstagram.styles";
import { IStoriesInstagramProps } from "./storiesInstagram.types";
import lodash from 'lodash';
import { maskNumbers, validateIndividualRangeRateReach } from "../../../../../../../utils/validations";


interface ITopStoryMetrics {
  name: string;
  photo: string;
  cluster: string;
  value: any;
}

const TopStoriesInstagram: React.FC<IStoriesInstagramProps> = ({ influencers }) => {

  const storyTopsMetrics = useMemo(() => {

    let reachMetric: ITopStoryMetrics[] = [];

    let linkClicskMetric: ITopStoryMetrics[] = [];

    influencers.filter((item) => item.instagram.isActive)
      .map((influencer) => {

        //todos os alcances individual
        let allReachs: number[] = [];

        //todos os cliques individual
        let allClicks: number[] = [];

        influencer.instagram.metricsStories.map((metric) => {
          //todos os alcances individual
          allReachs.push(parseInt(metric.reach || '0'));

          //todos os cliques individual
          allClicks.push(parseInt(metric.linkClicks || '0'));
        })


        let totalReachIndividual = validateIndividualRangeRateReach(allReachs, influencer.instagram.followers);

        linkClicskMetric.push({
          name: influencer.name,
          photo: influencer.profilePhoto,
          cluster: influencer.cluster,
          value: maskNumbers(lodash.sum(allClicks).toString())
        })

        reachMetric.push({
          name: influencer.name,
          photo: influencer.profilePhoto,
          cluster: influencer.cluster,
          value: Number.isInteger(totalReachIndividual) ? totalReachIndividual : parseFloat(totalReachIndividual.toFixed(2))
        })

      })



    return {
      linkClicskMetric,
      reachMetric
    }

  }, [influencers])
  return (
    <Container>
      <Title>Top 3 Stories</Title>
      <Subtitle>
        Os melhores influenciadores em % de alcance e cliques em stories.
      </Subtitle>
      <ContainerGrid>
        {[{
          label: "Alcance",
          influencer: lodash.orderBy(storyTopsMetrics.reachMetric, ['value'], ['desc']).slice(0, 3)
        },
        {
          label: "Cliques em link",
          influencer: lodash.orderBy(storyTopsMetrics.linkClicskMetric, ['value'], ['desc']).slice(0, 3)
        }].map((item, index) => {
          return (
            <GridItem key={index}>
              <TopicContainer>
                <TopicText>{item.label}</TopicText>
              </TopicContainer>
              {item.influencer.map((influencer, index) => (
                <PersonContainer key={index} border={index + 1 !== item.influencer.length}>
                  <PersonPhoto src={influencer.photo} />
                  <PersonInfo>
                    <PersonName>{influencer.name.toUpperCase()}</PersonName>
                    <PersonPosition>{influencer.cluster}</PersonPosition>
                    <PersonRate>{item.label === "Alcance" ? `${influencer.value}% de Alcance`
                      : `${influencer.value} cliques`}</PersonRate>
                    {/* <PersonMedia>Média do Cluster: 13%</PersonMedia> */}
                  </PersonInfo>
                </PersonContainer>
              ))}
            </GridItem>
          );
        })}
      </ContainerGrid>
    </Container>
  );
};
export default TopStoriesInstagram;
