import React from 'react';
import {
  Title,
  Container,
  PanelContent,
  PanelBody,
  Divider,
  LineDivider,
} from './instagramPostItems.styles';
import InputComponent from '../../../../global/components/input/input.component';
import { IInstagramPostsMetricsProps } from '../../../../store/campaigns/campaigns.types';
import { maskNumbers, onlyNumbers } from 'utils/validations';

interface IInstagramPostItemsProps {
  index: number;
  item: IInstagramPostsMetricsProps;
  handleSetMetrics: (i: number, p: string, v: React.SetStateAction<string>) => void;
}

const InstagramPostItems: React.FC<IInstagramPostItemsProps> = ({ index, item, handleSetMetrics }) => {

  const {
    postLink,
    reach,
    impressions,
    likes,
    comments,
    saved,
    forwards,
    views,
  } = item;
  return (
    <Container>
      <Title>Post {index + 1}</Title>

      <PanelContent>
        <PanelBody>
          <InputComponent
            label='Link do post'
            value={postLink}
            onChange={(postLink) => handleSetMetrics(index, 'postLink', postLink)}
          />
        </PanelBody>
        <Divider />
        <PanelBody>
          <InputComponent
            label='Alcance'
            value={maskNumbers(reach)}
            onChange={(reach) => handleSetMetrics(index, 'reach', onlyNumbers(reach.toString()))}
          />
        </PanelBody>

        <Divider />
        <PanelBody>
          <InputComponent
            label='Impressões'
            value={maskNumbers(impressions)}
            onChange={(impressions) => handleSetMetrics(index, 'impressions', onlyNumbers(impressions.toString()))}
          />
        </PanelBody>

        <Divider />
        <PanelBody>
          <InputComponent
            label='Curtidas'
            value={maskNumbers(likes)}
            onChange={(likes) => handleSetMetrics(index, 'likes', onlyNumbers(likes.toString()))}
          />
        </PanelBody>
      </PanelContent>

      <PanelContent>
        <PanelBody>
          <InputComponent
            label='Comentários'
            value={maskNumbers(comments)}
            onChange={(comments) => handleSetMetrics(index, 'comments', onlyNumbers(comments.toString()))}
          />
        </PanelBody>
        <Divider />
        <PanelBody>
          <InputComponent
            label='Salvos'
            value={maskNumbers(saved)}
            onChange={(saved) => handleSetMetrics(index, 'saved', onlyNumbers(saved.toString()))}
          />
        </PanelBody>

        <Divider />
        <PanelBody>
          <InputComponent
            label='Encaminhamentos'
            value={maskNumbers(forwards)}
            onChange={(forwards) => handleSetMetrics(index, 'forwards', onlyNumbers(forwards.toString()))}
          />
        </PanelBody>

        <Divider />
        <PanelBody>
          <InputComponent
            label='Views / Reproduções'
            value={maskNumbers(views)}
            onChange={(views) => handleSetMetrics(index, 'views', onlyNumbers(views.toString()))}
          />
        </PanelBody>
      </PanelContent>

      <LineDivider />
    </Container>

  )
}

export default InstagramPostItems;