import styled from 'styled-components';

export const Container = styled.div`
  margin-top: 24px;
  display: inline-flex;
  flex-direction: column;
  position: relative;
`

interface ILabelProps {
  customStyle: any;
}
export const Label = styled.label<ILabelProps>`
  height: 14px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  color: #4E4B59;
  ${props => props.customStyle}
`

interface IInputProps {
  isFocused: boolean;
  customStyle: any;
  borderLineColor?: string;
}

export const Input = styled.input<IInputProps>`
  height: 56px;
  top: 26px;
  border: 1.5px solid ${props => props.isFocused ? props.borderLineColor ? props.borderLineColor : '#623CEA' : '#D8D8E8'};
  border-radius: 6px;
  margin-top: 12px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  color: ${props => props.disabled ? '#ccc' : '#4E4B59'};
  padding-right: 17px;
  padding-left: 17px;
  background-color: ${props => props.disabled ? '#eee' : '#ffffff'};
  ${props => props.customStyle}
`

interface IIconProps {
  customIconStyle: any;
}
export const Icon = styled.img<IIconProps>`
position: absolute;
top:45px;
right:25px;
align-self: center;
${props => props.customIconStyle}
`