import styled from 'styled-components';

export const Container = styled.div`
padding:25px;
overflow-y: hidden;
scroll-behavior: smooth;
`

export const Title = styled.h1`
font-family: 'Poppins';
font-weight: 600;
font-size: 24px;
color: ${props => props.theme.colors.dark};
`

interface IButtonsFooterBoxProps {
  showBackButton: boolean;
}
export const ButtonsFooterBox = styled.div<IButtonsFooterBoxProps>`
display: flex;
flex-direction: row;
justify-content: ${props => props.showBackButton ? 'space-between' : 'flex-end'};
align-items: center;
`