import React, { useMemo } from "react";
import CustomTable from "../../../../../../../global/components/table/table.component";
import Top3TikTok from "../top3Tiktok/top3Tiktok.component";
import { Container, Description, Title } from "./index.styles";
import { IPostsTikTokProps } from "./index.types";
import PostsByInfluencer from "../postsByInfluencer/postsByInfluencer.component";
import lodash from 'lodash';
import { IIndividualTiktokProps } from "../../../tiktok.types";
import { convertToDouble, maskNumbers, validateAvgMetricTiktok, validateIndividualRangeRateReachTiktok, validateMathMax, validateRangeRateEngagementTiktok, validateRangeRateViewsInstagram, validateRangeRateViewsTiktok } from "utils/validations";
import TiktokGeneralPostsInfo from "../tableGeneralPostsInfo/index.component";

interface INewArrProps {
  name: string;
  photo: string;
  cluster: string;
  value: number;
}

const PostsTikTok: React.FC<IPostsTikTokProps> = ({ influencers, tiktokQualitative }) => {

  const generalInfo = useMemo(() => {

    let allViews: number[] = [];
    let allRateViews: number[] = [];
    let allReaches: number[] = [];
    let allIterations: number[] = [];
    let allEngagementRate: number[] = [];

    influencers.filter((item) => item.tiktok.isActive)
      .map((influencer) => {
        let allViewsIndividual: number[] = [];
        let allReachesIndividual: number[] = [];
        let allIterationsIndividual: number[] = [];

        influencer.tiktok.metrics.map((metric) => {
          allReaches.push(parseInt(metric.reach || '0'));
          allReachesIndividual.push(parseInt(metric.reach || '0'));

          allViews.push(parseInt(metric.views || '0'));
          allViewsIndividual.push(parseInt(metric.views || '0'));

          allIterations.push(parseInt(metric.likes || '0'));
          allIterations.push(parseInt(metric.comments || '0'));
          allIterations.push(parseInt(metric.forwards || '0'));
          allIterations.push(parseInt(metric.saved || '0'));

          allIterationsIndividual.push(parseInt(metric.likes || '0'));
          allIterationsIndividual.push(parseInt(metric.comments || '0'));
          allIterationsIndividual.push(parseInt(metric.forwards || '0'));
          allIterationsIndividual.push(parseInt(metric.saved || '0'));
        })

        allRateViews.push(validateRangeRateViewsTiktok(allViewsIndividual, influencer.tiktok.followers));
        allEngagementRate.push(validateRangeRateEngagementTiktok(allIterationsIndividual, allReachesIndividual));
      })

    let rangeRateViews = lodash.sum(allRateViews) / allRateViews.length;
    let rangeRateEngagement = lodash.sum(allEngagementRate) / allEngagementRate.length;

    return [{
      'Nº de influenciadores': influencers.filter((item) => item.tiktok.isActive).length,
      'Alcance Máximo': `${maskNumbers(validateMathMax(allReaches).toString())}`,
      'Views totais': `${maskNumbers(lodash.sum(allViews).toString())}`,
      'Taxa de Views': `${Number.isInteger(rangeRateViews) ? rangeRateViews : rangeRateViews.toFixed(2)}%`,
      'Interações totais': `${maskNumbers(lodash.sum(allIterations).toString())}`,
      'Taxa de engajamento': `${Number.isInteger(rangeRateEngagement) ? rangeRateEngagement : rangeRateEngagement.toFixed(2)}%`,
    }]
    //eslint-disable-next-line
  }, [influencers]);

  const individualInfo = useMemo(() => {
    let allIndividual: IIndividualTiktokProps[] = [];

    influencers.filter((item) => item.tiktok.isActive)
      .map((influencer) => {
        let allReaches: number[] = [];
        let allViews: number[] = [];
        let allIterations: number[] = [];
        let allRetentions: number[] = [];

        influencer.tiktok.metrics.map((metric => {

          allReaches.push(parseInt(metric.reach || '0'));

          allRetentions.push(convertToDouble(metric.retention || '0'));

          allViews.push(parseInt(metric.views || '0'));

          allIterations.push(parseInt(metric.likes || '0'));
          allIterations.push(parseInt(metric.comments || '0'));
          allIterations.push(parseInt(metric.forwards || '0'));
          allIterations.push(parseInt(metric.saved || '0'));
        }))

        let rangeRateReach = validateIndividualRangeRateReachTiktok(allReaches, influencer.tiktok.followers);
        let iterationsAvg = validateAvgMetricTiktok(allIterations, allReaches);
        let rangeEngagement = validateRangeRateEngagementTiktok(allIterations, allReaches);
        let rangeViews = validateRangeRateViewsTiktok(allViews, influencer.tiktok.followers);
        let avgRetention = validateAvgMetricTiktok(allRetentions, allRetentions);

        allIndividual.push({
          'Influenciador': influencer,
          'Posts': `${allReaches.length}`,
          'Alcance máximo': `${maskNumbers(validateMathMax(allReaches).toString())}`,
          'Taxa de alcance': `${Number.isInteger(rangeRateReach) ? rangeRateReach : rangeRateReach.toFixed(2)}%`,
          'Interações totais': `${maskNumbers(lodash.sum(allIterations).toString())}`,
          'Média de\ninterações': `${maskNumbers(iterationsAvg.toFixed(0))}`,
          'Taxa de\nengaj.': `${Number.isInteger(rangeEngagement) ? rangeEngagement : rangeEngagement.toFixed(2)}%`,
          'Views': `${maskNumbers(lodash.sum(allViews).toString())}`,
          'Taxa de views': `${Number.isInteger(rangeViews) ? rangeViews : rangeViews.toFixed(2)}%`,
          'Média de\nretenção': `${Number.isInteger(avgRetention) ? avgRetention : avgRetention.toFixed(2)}%`,
        })

      })

    return allIndividual;

    //eslint-disable-next-line
  }, [influencers])

  const newArrInfluencers = useMemo(() => {
    //novo array alcance
    let newArrReach: INewArrProps[] = [];

    //novo array engajamento
    let newArrEngagement: INewArrProps[] = [];

    //novo array views
    let newArrViews: INewArrProps[] = [];

    influencers.filter((item) => item.tiktok.isActive)
      .map((influencer) => {
        //alcances de cada influencer
        let allReach: number[] = [];

        //todas as interacoes
        let allIterations: number[] = [];

        //todas as views
        let allViews: number[] = [];

        influencer.tiktok.metrics.map((metric) => {
          allReach.push(parseInt(metric.reach));

          allIterations.push(parseInt(metric.likes || '0'));
          allIterations.push(parseInt(metric.comments || '0'));
          allIterations.push(parseInt(metric.forwards || '0'));
          allIterations.push(parseInt(metric.saved || '0'));

          allViews.push(parseInt(metric.views || '0'));
        })

        let rangeRateViews = validateRangeRateViewsTiktok(allViews, influencer.tiktok.followers);
        let rangeRateEngagement = validateRangeRateEngagementTiktok(allIterations, allReach);
        let rangeRateReach = validateIndividualRangeRateReachTiktok(allReach, influencer.tiktok.followers);

        newArrViews.push({
          name: influencer.name,
          photo: influencer.profilePhoto,
          cluster: influencer.cluster,
          value: Number.isInteger(rangeRateViews) ? Number(rangeRateViews) : Number(rangeRateViews.toFixed(2))
        })

        newArrEngagement.push({
          name: influencer.name,
          photo: influencer.profilePhoto,
          cluster: influencer.cluster,
          value: Number.isInteger(rangeRateEngagement) ? Number(rangeRateEngagement) : Number(rangeRateEngagement.toFixed(2))
        })

        newArrReach.push({
          name: influencer.name,
          photo: influencer.profilePhoto,
          cluster: influencer.cluster,
          value: Number.isInteger(rangeRateReach) ? Number(rangeRateReach) : Number(rangeRateReach.toFixed(2))
        })
      })


    return {
      newArrReach: lodash.orderBy(newArrReach, ['value'], ['desc']).slice(0, 3),
      newArrEngagement: lodash.orderBy(newArrEngagement, ['value'], ['desc']).slice(0, 3),
      newArrViews: lodash.orderBy(newArrViews, ['value'], ['desc']).slice(0, 3),
    }
  }, [influencers])

  return (
    <Container>
      <TiktokGeneralPostsInfo rows={generalInfo} />
      <Title>Panorama geral - TikToks</Title>
      <PostsByInfluencer data={individualInfo} />
      <Description>
        {tiktokQualitative.overviewPosts}
      </Description>
      <Top3TikTok newArrInfluencers={newArrInfluencers} />
    </Container>
  );
};
export default PostsTikTok;
