import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../store/storeHook';

import BrandsDetailsLayout from './brandsDetails.layout';
import { ILoadingProps } from '../../../utils/globalTypes';
import { FetchCampaignsBelongsToBrand } from 'store/campaigns/campaigns.actions';
import { setCampaignsInfo } from 'store/campaigns/campaigns.reducer';

const BrandsDetailsController: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { selectedBrand } = useAppSelector(state => state.brandsReducer);

  const [campaigns, setCampaigns] = useState([]);
  const [loading, setLoading] = useState({ status: true, type: '' });

  const toggleLoading = (status: ILoadingProps) => setLoading(status);

  const handleEdit = () => {
    navigate('/marcas/editar')
  }

  const handleNavigationCreateCampaign = () => {
    dispatch(setCampaignsInfo({
      stepNumber: 1,
      title: '',
      landscape: '',
      isComplete: false,
      brandsList: [],
      changedLandscape: false,
      id: '',
      brand: '',
      initialDate: '',
      endDate: '',
      recap: '',
      influencers: [],
      influencerData: {
        name: '',
        cluster: '',
        profilePhoto: '',
        instagram: {
          isActive: false,
          idInstagram: '',
          numberOfPosts: '',
          storiesSigned: '',
          storiesDelivered: '',
          metricsPosts: [],
          metricsStories: [],
          followers: ''
        },
        twitter: {
          isActive: false,
          idTwitter: '',
          numberOfTweets: '',
          metrics: [],
          followers: ''
        },
        youtube: {
          isActive: false,
          numberOfVideos: '',
          metrics: [],
          followers: ''
        },
        tiktok: {
          isActive: false,
          idTiktok: '',
          numberOfPosts: '',
          metrics: [],
          followers: ''
        },
      },
      instagramQualitative: {
        overviewCampaign: '',
        overviewFeed: '',
        overviewStories: '',
        qualitativeMetrics: []
      },
      twitterQualitative: {
        overviewCampaign: '',
        overviewTweets: '',
        qualitativeMetrics: []
      },
      youtubeQualitative: {
        overviewCampaign: '',
        overviewVideos: '',
        qualitativeMetrics: []
      },
      tiktokQualitative: {
        overviewCampaign: '',
        overviewPosts: '',
        qualitativeMetrics: []
      },
      reportLink: '',
      reportPassword: ''
    }))
    navigate('/campanhas/criar')
  }

  const handleRedirectToCampaign = (campaign: any) => {
    // console.log("campaign ->", campaign);

    dispatch(setCampaignsInfo({
      ...campaign
    }))

    if (campaign.isComplete) {
      setTimeout(() => {
        navigate('/campanhas/finalizada');
      }, 200);
    } else {
      setTimeout(() => {
        navigate('/campanhas/criar');
      }, 200);
    }
  }


  useEffect(() => {
    // console.log('selectedBrand', selectedBrand);

    FetchCampaignsBelongsToBrand(selectedBrand.brandName, setCampaigns, toggleLoading)
  }, []);

  return <BrandsDetailsLayout
    localState={{
      selectedBrand,
      loading,
      campaigns
    }}
    handlers={{
      handleEdit,
      handleNavigationCreateCampaign,
      handleRedirectToCampaign
    }}
  />
}

export default BrandsDetailsController;