import React from "react";
import InfluencerInstagram from "./components/influencer/influencer.component";
import {
  HeaderCell,
  RowCell,
  TableContainer,
  TableHeader,
  TableRow,
} from "./storiesByInfluencer.styles";

import { IStoriesByInfluencerProps } from "./storiesByInfluencer.types";

const StoriesByInfluencer: React.FC<IStoriesByInfluencerProps> = ({
  data,
}) => {
  const headers = !data.length ? [] : Object.keys(data[0]);
  const collumnsWidth = ["140px"]
  return (
    <TableContainer scroll>
      <TableHeader scroll collumns={headers.length}>
        {headers.map((item, index) => {
          return (
            <HeaderCell
              width={(collumnsWidth && collumnsWidth[index]) || "auto"}
              key={index}
            >
              {item}
            </HeaderCell>
          );
        })}
      </TableHeader>
      {data.map((item, index) => {
        return (
          <TableRow scroll key={index} collumns={headers.length} numberRow={index} lastItem={index + 1 === data.length}>
            <RowCell width={"140px"} justifySelf="flex-start">
              <InfluencerInstagram influencer={item.Influenciador} />
            </RowCell>
            <RowCell width={"auto"}>{item['Contratado']}</RowCell>
            <RowCell width={"auto"}>{item['Entregue']}</RowCell>
            <RowCell width={"auto"}>{item['Alcance máximo']}</RowCell>
            <RowCell width={"auto"}>{item["Taxa de alcance"]}</RowCell>
            <RowCell width={"auto"}>{item["Impressões totais"]}</RowCell>
            <RowCell width={"auto"}>{item["Cliques no @ da marca"]}</RowCell>
            <RowCell width={"auto"}>{item["Cliques no link"]}</RowCell>
            <RowCell width={"auto"}>{item["Interações totais"]}</RowCell>
            <RowCell width={"auto"}>{item["Taxa de engajamento"]}</RowCell>
          </TableRow>
        );
      })}
    </TableContainer>
  );
};
export default StoriesByInfluencer;
