import React from 'react';
import PrivatePage from '../../../global/templates/private/privatePage.component';
import {
  Container,
} from './campaignsCompleted.styles';
import Footer from '../../../global/components/footer/footer.components';
import { ICampaignsCompletedLayoutProps } from './campaignsCompleted.types';
import Loading from '../../../global/components/loading/loading.component';
import HeaderReport from '../../report/components/headerReport/headerReport.component';
import CampaignRegisterFive from '../register/stepFive/campaignRegisterFive.layout';
import CampaignRegisterFourLayout from '../register/stepFour/campaignRegisterFour.layout';

const CampaignsCompletedLayout: React.FC<ICampaignsCompletedLayoutProps> = ({ localState, handlers }) => {
  const {
    reportLink,
    reportPassword,
    landscape,
    title,
    brandThumb
  } = localState;

  const { handleBackEdit, handleGoToDownloadPDF } = handlers;

  return (
    <PrivatePage headerOff={true}>
      <HeaderReport
        data={{
          landscape,
          title,
          brandThumb
        }}
      />

      <Container>
        <CampaignRegisterFive
          isComplete={true}
          reportLink={reportLink}
          reportPassword={reportPassword}
          handleBackEdit={handleBackEdit}
          handleGoToDownloadPDF={handleGoToDownloadPDF}
        />

        <CampaignRegisterFourLayout
          isComplete={true}
          localState={localState}
        />
      </Container>
      <Footer />
    </PrivatePage>
  )
}

export default CampaignsCompletedLayout;