import styled from 'styled-components';

export const Container = styled.div`
  margin-top: 26px;
  display: flex;
  position: relative;
  flex-direction: column;
  cursor: pointer;
`

export const Label = styled.label`
  height: 14px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  color: #4E4B59;
`

export const DropdownBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 58px;
  border: 1.5px solid #D8D8E8;
  border-radius: 6px;
  margin-top: 10.5px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  color: #4E4B59;
  padding-right: 17px;
  padding-left: 17px;
  background-color: #ffffff;
`

export const DropdownPlaceholder = styled.label`
font-family: 'Poppins';
font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 22px;
color: #BBBBBB;
`

export const DropdownValue = styled.label`
font-family: 'Poppins';
font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 22px;
color: #4E4B59;
`

export const DropdownMenu = styled.ul`
position: absolute;
list-style-type: none;
padding: 0;
width: 100%;
max-height: 400px;
border-radius: 6px;
border:0.5px solid #D8D8E8;
z-index: 100;
overflow-y: scroll;
::-webkit-scrollbar {
        height: 5px;
        width: 10px;
        background-color: #fff;
      }

      ::-webkit-scrollbar-track {
        box-shadow: inset 0 0 2px grey; 
        border-radius: 10px;
      }
 
      ::-webkit-scrollbar-thumb {
        background: #d8d8e8; 
        border-radius: 10px;
      }
`

export const DropdownLine = styled.li`
  margin: 0;
  background-color: white;

  &:hover{
    background-color: lightgray;
  };

  > button {
    width: 100%;
    height: 100%;
    text-align: left;
    background: none;
    border: none;
    padding: 5px;
    margin: 0;
    font: inherit;
    cursor: pointer;

    > label{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 12px;
    color: #4E4B59;
    }
  }
`