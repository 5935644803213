import React, { useState } from 'react';

import {
  Container,
  Label,
  DropdownBox,
  DropdownPlaceholder,
  DropdownMenu,
  DropdownLine,
  DropdownValue
} from './dropdown.styles';
import { IDropdownProps } from './dropdown.types';

const CustomDropdown: React.FC<IDropdownProps> = (props) => {

  const {
    label,
    value,
    listItem,
    onChange
  } = props;
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(!open);
  };

  return (
    <Container onClick={handleOpen}>
      <Label>{label}</Label>
      <DropdownBox>
        {value && value?.length > 0 ?
          <DropdownValue>{value}</DropdownValue>
          :
          <DropdownPlaceholder>Selecione</DropdownPlaceholder>
        }
        <svg width="16" height="9" viewBox="0 0 16 9" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M7.99997 9C7.71322 9 7.4265 8.89196 7.20788 8.67633L0.328227 1.88748C-0.109409 1.45562 -0.109409 0.755441 0.328227 0.32376C0.765686 -0.10792 1.4751 -0.10792 1.91277 0.32376L7.99997 6.33091L14.0872 0.32397C14.5249 -0.10771 15.2342 -0.10771 15.6716 0.32397C16.1095 0.755651 16.1095 1.45583 15.6716 1.88768L8.79207 8.67654C8.57334 8.8922 8.28662 9 7.99997 9Z" fill="#A9B8CD" />
        </svg>
      </DropdownBox>

      {open ? (
        <DropdownMenu>
          {listItem.map((item, index) => {
            return (
              <DropdownLine key={index} onClick={() => onChange(item)}>
                <button>
                  <label>{item}</label>
                </button>
              </DropdownLine>
            )
          })}
        </DropdownMenu>
      ) : null}

    </Container>
  )

}

export default React.memo(CustomDropdown);

