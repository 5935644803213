import styled from 'styled-components';

export const Container = styled.div`
display: flex;
flex-direction: row;
justify-content: space-between;
align-items: center;
width: auto;
height: 93px;
background: #fff;
border: 1px solid #D8D8E8;
border-radius: 12px;
margin:20px 0;
padding:0 27px;
`

export const Row = styled.div`
display: flex;
flex-direction: row;
align-items: center;
`

export const Label = styled.span`
font-family: 'Poppins';
font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 12px;
color: #4E4B59;
margin-right: 20px;
`

export const SucceedBox = styled.div`
display: flex;
justify-content: center;
align-items: center;
width: 141px;
height: 22px;
background: #3BC5CB;
border-radius: 23px;
`

export const SucceedLabel = styled.span`
font-family: 'Poppins';
font-style: normal;
font-weight: 500;
font-size: 12px;
line-height: 12px;
color: #FFFFFF;
`

export const Icon = styled.img`
display: flex;
margin: 0 10px;
`