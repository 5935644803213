import React, { useMemo } from "react";
import {
  Container,
  Input,
  Profile,
  IconBox,
  UserName,
  ButtonExit,
  ProfilePhoto,
  HeaderBg,
  Title,
  InputProfileBox,
  UserBox,
} from "./mainHeader.styles";

import profilePhoto from '../../../assets/img/user_default.png';
import { useLocation } from 'react-router-dom';
import { IMainHeaderProps } from './mainHeader.types';
import { useAppSelector } from "../../../store/storeHook";

const MainHeader: React.FC<IMainHeaderProps> = ({
  theme = "light",
  showNotification = true,
  showHeaderBg = true,
  handleSignOut
}) => {

  const { pathname } = useLocation();

  const { fullname, thumb } = useAppSelector(state => state.userReducer)

  const title = useMemo(() => {

    if (pathname.includes('/dashboard'))
      return 'Dashboard';

    if (pathname.includes('/campanhas'))
      return 'Campanhas';

    if (pathname.includes('/rascunhos'))
      return 'Rascunhos';

    if (pathname.includes('/marcas'))
      return 'Marcas';

    if (pathname.includes('/usuarios'))
      return 'Usuários';

  }, [pathname])

  return (
    <Container themeMode={theme}>
      <InputProfileBox>
        <Input themeMode={theme}>
          <svg
            width="26"
            height="25"
            viewBox="0 0 26 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10.5617 19.5135C12.7963 19.5135 14.9669 18.7977 16.7298 17.4793L23.3806 23.8651C23.8774 24.3258 24.6691 24.3126 25.1489 23.8355C25.617 23.3702 25.617 22.6325 25.1489 22.1672L18.4981 15.7815C21.9053 11.5703 21.1119 5.50445 16.7259 2.23304C12.3399 -1.03837 6.02239 -0.27657 2.61519 3.93461C-0.792008 8.1458 0.00141016 14.2116 4.3874 17.483C6.15315 18.8001 8.32573 19.5146 10.5617 19.5135ZM5.21787 4.72829C8.16923 1.8945 12.9543 1.89445 15.9058 4.72819C18.8572 7.56193 18.8572 12.1563 15.9059 14.9901C12.9545 17.8239 8.16939 17.824 5.21797 14.9902C5.21792 14.9902 5.21792 14.9902 5.21787 14.9901C2.2665 12.177 2.24911 7.59938 5.17897 4.76564C5.19192 4.75316 5.20487 4.74072 5.21787 4.72829Z"
              fill={theme === "light" ? "#4C6072" : "#F6F7FB"}
            />
          </svg>

          <span>Buscar</span>
        </Input>

        <Profile>
          {showNotification && (
            <IconBox>
              <svg
                width="24px"
                height="24px"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M18 8.4C18 6.70261 17.3679 5.07475 16.2426 3.87452C15.1174 2.67428 13.5913 2 12 2C10.4087 2 8.88258 2.67428 7.75736 3.87452C6.63214 5.07475 6 6.70261 6 8.4C6 15.8667 3 18 3 18H21C21 18 18 15.8667 18 8.4Z"
                  stroke="#4C6072"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M13.73 21C13.5542 21.3031 13.3019 21.5547 12.9982 21.7295C12.6946 21.9044 12.3504 21.9965 12 21.9965C11.6496 21.9965 11.3054 21.9044 11.0018 21.7295C10.6982 21.5547 10.4458 21.3031 10.27 21"
                  stroke="#4C6072"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </IconBox>
          )}
          <UserBox>
            <UserName themeMode={theme}>{fullname}</UserName>
            <ButtonExit themeMode={theme} onClick={handleSignOut}>
              Sair
            </ButtonExit>
          </UserBox>
          <ProfilePhoto src={thumb ? thumb : profilePhoto} alt="foto de perfil" />
        </Profile>
      </InputProfileBox>
      {showHeaderBg && (
        <HeaderBg>
          <Title>{title}</Title>
        </HeaderBg>
      )}
    </Container>
  );
};

export default React.memo(MainHeader);
