import React from "react";
import InfluencerInstagram from "./components/influencer/influencer.component";
import PercentualCollumn from "./components/percentualCollumn/percentualCollumn.component";
import {
  HeaderCell,
  RowCell,
  TableContainer,
  TableHeader,
  TableRow,
} from "./postsByInfluencer.styles";

import { IInstagramPostsByInfluencerProps } from "./postsByInfluencer.types";

const InstagramPostsByInfluencer: React.FC<IInstagramPostsByInfluencerProps> = ({
  data,
}) => {

  const headers = Object.keys(data[0]);
  const collumnsWidth = ["160px"];

  return (
    <TableContainer scroll>
      <TableHeader scroll collumns={headers.length}>
        {headers.map((item, index) => {
          return (
            <HeaderCell
              key={index}
              width={(collumnsWidth && collumnsWidth[index]) || "auto"}
            >
              {item}
            </HeaderCell>
          );
        })}
      </TableHeader>
      {data.map((item, index) => {
        return (
          <TableRow scroll key={index} collumns={headers.length} numberRow={index} lastItem={index + 1 === data.length}>
            <RowCell width={"160px"} justifySelf="flex-start">
              <InfluencerInstagram influencer={item.Influenciador} />
            </RowCell>
            <RowCell width={"auto"}>{item["Alcance máximo"]}</RowCell>
            <RowCell width={"auto"}>{item["Taxa de alcance"]}</RowCell>
            <RowCell width={"auto"}>{item["Impressões totais"]}</RowCell>
            <RowCell width={"auto"}>{item["Interações totais"]}</RowCell>
            <RowCell width={"auto"}>{item['Posts']}</RowCell>
            <RowCell width={"auto"}>{item["Média de interações"]}</RowCell>
            <RowCell width={"auto"}>{item["Taxa de engaj."]}</RowCell>
            <RowCell width={"auto"}>{item['Views']}</RowCell>
            <RowCell width={"auto"}>{item["Taxa de views"]}</RowCell>
          </TableRow>
        );
      })}
    </TableContainer>
  );
};
export default InstagramPostsByInfluencer;
