import React from 'react';
import ButtonPrimary from '../../../global/components/buttons/primary/buttonPrimary.component';
import ButtonSecondary from '../../../global/components/buttons/secondary/buttonSecondary.component';
import Footer from '../../../global/components/footer/footer.components';
import Input from '../../../global/components/input/input.component';
import PrivatePage from '../../../global/templates/private/privatePage.component';
import UploadPhotoComponent from './components/uploadPhoto/uploadPhoto.component';
import { IUserRegisterProps } from './usersEdit.types';
import {
  ButtonsFooterBox,
  Container,
  Divider,
  Panel,
  PanelBody,
  PanelContent,
  PanelTitle,
  Title,
  SwitchActiveBox
} from './usersEdit.styles';
import CustomDropdown from '../../../global/components/dropdown/dropdown.component';
import IOSSwitchComponent from '../../../global/components/iOSSwitch/iOSSwitch.component';

const UsersEditLayout: React.FC<IUserRegisterProps> = ({ localState, handlers }) => {

  const {
    fullname,
    email,
    profilePhoto,
    userHierarchy,
    userHierarchyList,
    buttonDisabled,
    loading,
    loggedUserHierarchy,
    active,
    loadingResetPass
  } = localState;

  const {
    handleBack,
    handleEdit,
    setFullname,
    setEmail,
    setUserHierarchy,
    setProfilePhoto,
    setActive,
    handleResetPassword
  } = handlers;

  return (
    <PrivatePage>
      <Container>
        <Title>Editar dados do usuário</Title>
        <Panel>
          <PanelTitle>
            Dados principais
          </PanelTitle>
          <PanelContent>
            <PanelBody>
              <Input
                label='Nome completo'
                value={fullname}
                onChange={setFullname}
              />
              <Input
                label='Email'
                value={email}
                onChange={setEmail}
                disabled={true}
              />

            </PanelBody>
            <Divider />
            <PanelBody>
              <UploadPhotoComponent
                value={profilePhoto?.name}
                onChange={setProfilePhoto}
              />
              <CustomDropdown
                label='Perfil de acesso'
                value={userHierarchy}
                listItem={userHierarchyList}
                onChange={setUserHierarchy}
              />
              <ButtonPrimary
                label='Resetar senha do usuário'
                isDisabled={userHierarchy !== 'super_admin' ? buttonDisabled : loggedUserHierarchy === 'super_admin' ? buttonDisabled : true}
                loading={loadingResetPass.status}
                onClick={handleResetPassword}
                customStyle={{
                  'margin-top': '25px;',
                  'width': '250px;',
                  'height': '45px;'
                }}
                customLabelStyle={{
                  'font-size': '14px',
                  'font-weight': '400'
                }}
              />
            </PanelBody>
          </PanelContent>
        </Panel>

        <SwitchActiveBox>
          <IOSSwitchComponent
            value={active}
            onChange={setActive}
          />
          <span>{active ? 'Usuário ativo' : 'Usuário inativo'}</span>
        </SwitchActiveBox>
        <ButtonsFooterBox>

          <ButtonSecondary
            label='Cancelar'
            isDisabled={false}
            loading={false}
            onClick={handleBack}
            customStyle={{
              'margin-top': '25px;',
              'width': '335px;',
              'height': '56px;'
            }}
          />

          <ButtonPrimary
            label='Salvar alterações'
            isDisabled={userHierarchy !== 'super_admin' ? buttonDisabled : loggedUserHierarchy === 'super_admin' ? buttonDisabled : true}
            loading={loading.status}
            onClick={handleEdit}
            customStyle={{
              'margin-top': '25px;',
              'width': '335px;',
              'height': '56px;'
            }}
          />
        </ButtonsFooterBox>
      </Container>
      <Footer theme='light' />
    </PrivatePage>
  )
}

export default UsersEditLayout;