import React from "react";
import {
  HeaderCell,
  RowCell,
  TableContainer,
  TableHeader,
  TableRow,
} from "./postsByInfluencer.styles";

import { IInstagramPostsByInfluencerProps } from "./postsByInfluencer.types";

const InstagramResultsByInfluencerFeedPosts: React.FC<IInstagramPostsByInfluencerProps> = ({
  data,
  scroll = false
}) => {

  const headers = !data.length ? [] : Object.keys(data[0]);

  if (!data.length) return null;

  return (
    <TableContainer scroll={scroll}>
      <TableHeader scroll={scroll} collumns={headers.length}>
        {headers.map((item, index) => {
          return (
            <HeaderCell
              key={index}
              width={"100px"}
            >
              {item}
            </HeaderCell>
          );
        })}
      </TableHeader>
      {data.map((item, index) => {
        return (
          <TableRow key={index} scroll={scroll} collumns={headers.length} numberRow={index} lastItem={index + 1 === data.length}>
            <RowCell width={"100px"}>{item["Alcance do post"]}</RowCell>
            <RowCell width={"100px"}>{item["Taxa de alcance"]}</RowCell>
            <RowCell width={"100px"}>{item["Impressões totais"]}</RowCell>
            <RowCell width={"100px"}>{item["Likes"]}</RowCell>
            <RowCell width={"100px"}>{item['Comentários']}</RowCell>
            <RowCell width={"100px"}>{item["Salvo"]}</RowCell>
            <RowCell width={"100px"}>{item["Encaminham."]}</RowCell>
            <RowCell width={"100px"}>{item['Interações']}</RowCell>
            <RowCell width={"100px"}>{item["Taxa de engajamento"]}</RowCell>
            <RowCell width={"100px"}>{item['Views']}</RowCell>
            <RowCell width={"100px"}>{item['Taxa de views']}</RowCell>
          </TableRow>
        );
      })}
    </TableContainer>
  );
};
export default InstagramResultsByInfluencerFeedPosts;
