import React, { SetStateAction } from 'react';
import {
  IOSSwitch,
} from './iOSSwitch.styles';

interface IIOSSwitchProps {
  value: boolean;
  onChange: React.Dispatch<SetStateAction<boolean>>;
}

const IOSSwitchComponent: React.FC<IIOSSwitchProps> = ({ value, onChange }) => {

  return (
    <IOSSwitch
      checked={value}
      onChange={(e) => {
        onChange(e.target.checked)
      }} />
  )
}

export default IOSSwitchComponent;