import React from 'react';
import {
  Title,
  InstagramDetails,
  LineDivider,
  Row,
  Icon,
  SpacedRow
} from './index.styles';

import instagramLogo from '../../../../assets/icons/instagram_metrics.svg';
import { IInstagramPostMetricsProps } from './index.types';
import ButtonSecondary from '../../../../global/components/buttons/secondary/buttonSecondary.component';
import PreviewResultsByInfluencerInstagram from 'global/components/report/instagram/components/previewResultsByInfluencer/index/resultDetailsByInfluencer.component';


const InstagramPreviewMetrics: React.FC<IInstagramPostMetricsProps> = (props) => {
  const {
    toggleModalVisible,
    influencers,
    instagramQualitative
  } = props;

  return (
    <InstagramDetails>
      <Row>
        <Icon
          src={instagramLogo}
          alt='icon-metrics-instagram'
        />
        <Title>Prévia métricas Instagram</Title>
      </Row>

      <PreviewResultsByInfluencerInstagram
        influencers={influencers}
        instagramQualitative={instagramQualitative}
      />

      <LineDivider />

      <SpacedRow>
        <div></div>
        <ButtonSecondary
          label='Fechar'
          isDisabled={false}
          loading={false}
          onClick={toggleModalVisible}
          customStyle={{
            'margin-top': '25px;',
            'width': '388px;',
            'height': '56px;',
            'align-self': 'flex-end;'
          }}
        />
      </SpacedRow>
    </InstagramDetails>

  )
}

export default InstagramPreviewMetrics;