import styled from 'styled-components';

interface ILogoImgProps {
  width: number;
  height: number;
}

interface IMenuItemLinkProps {
  isActive: boolean;
}

interface IIconBoxProps {
  isActive: boolean;
}

export const Container = styled.div`
grid-area: SM;
background-color: ${props => props.theme.colors.white};
border-right: 1px solid ${props => props.theme.colors.primary};
`

export const Header = styled.header`
height: 70px;
display: flex;
justify-content: center;
align-items: center;
`

export const LogoImg = styled.img<ILogoImgProps>`
height: ${props => props.height}px;
width: ${props => props.width}px;
`

export const MenuContainer = styled.nav`
display: flex;
flex-direction: column;
margin-top: 50px;
padding-left: 38px;
justify-content: center;
`

export const MenuItemLink = styled.a<IMenuItemLinkProps>`
color:${props => props.isActive ? '#4F2FC4' : props.theme.colors.primary2};
font-family: 'Poppins';
font-weight: 500;
font-size: 18px;
text-decoration: none;
transition: opacity .3s;
margin:23px 0;
display: flex;
align-items: center;
`
export const IconBox = styled.div<IIconBoxProps>`
background: ${props => props.isActive ? '#E5E0F6' : '#F6F7FC'};
border-radius: 10px;
height: 36px;
width:36px;
display: flex;
justify-content: center;
align-items: center;
margin-right: 5px;
position: relative;
`

export const TagBox = styled.div`
display: flex;
flex:1;
justify-content: flex-end;
`
export const Tag = styled.div`
width: 6px;
height: 30px;
background-color: #4F2FC4;
display: flex;
border-top-left-radius: 10px;
border-bottom-left-radius: 10px;
`
export const NotificationBullet = styled.div`

`