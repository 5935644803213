import React from "react";
import ButtonSecondary from "../../buttons/secondary/buttonSecondary.component";
import {
  Container,
  Topic,
  WhiteWrapper,
  PeriodWrapper,
  RecapWrapper,
  Subtitle,
  Description,
  TopicContent,
} from "./introduction.styles";
import { IIntroducationReportProps } from "./introduction.types";
import EditIcon from "../../../../assets/icons/edit.svg";

const IntroductionReport: React.FC<IIntroducationReportProps> = ({
  editable = false,
  recap,
  initialDate,
  endDate,
  handleGoBack
}) => {
  return (
    <Container id="#introduction">
      <TopicContent>
        <Topic>1. Introdução</Topic>
        {editable && (
          <ButtonSecondary
            label="Editar Informações"
            isDisabled={false}
            loading={false}
            onClick={() => handleGoBack(1)}
            icon={EditIcon}
            customStyle={{ width: "180px", height: "32px" }}
            customLabelStyle={{
              fontSize: "13px",
              lineHeight: "14px",
              fontWeight: "400",
              fontFamily: "Montserrat",
            }}
          />
        )}
      </TopicContent>
      <WhiteWrapper>
        <RecapWrapper>
          <Subtitle>Recap</Subtitle>
          <Description>
            {recap}
          </Description>
        </RecapWrapper>
        <PeriodWrapper>
          <Subtitle>Período da campanha</Subtitle>
          <Description>Início: {initialDate}</Description>
          <Description>Término: {endDate}</Description>
        </PeriodWrapper>
      </WhiteWrapper>
    </Container>
  );
};
export default IntroductionReport;
