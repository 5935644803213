/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import {
  FetchCampaignAccessReport,
  FetchCampaignBrand,
} from "../../store/campaigns/campaigns.actions";
import { setModalMessage } from "../../store/modal/modal.actions";
import { IModalReducerProps } from "../../store/modal/modal.types";
import { useAppDispatch, useAppSelector } from "../../store/storeHook";
import { ILoadingProps } from "../../utils/globalTypes";
import ReportLayout from "./report.layout";
import { TSocialSessionCondition } from "./report.types";

const ReportController = () => {
  const { pathname } = useLocation();

  const dispatch = useAppDispatch();

  const {
    title,
    landscape,
    brand,
    recap,
    initialDate,
    endDate,
    influencers,
    instagramQualitative,
    twitterQualitative,
    youtubeQualitative,
    tiktokQualitative,
  } = useAppSelector((state) => state.campaignsReducer);

  const socialMetricsExist = {
    instagramQualitative: { name: "Instagram", scrollTo: "#instagram" },
    twitterQualitative: { name: "Twitter", scrollTo: "#twitter" },
    youtubeQualitative: { name: "Youtube", scrollTo: "#youtube" },
    tiktokQualitative: { name: "TikTok", scrollTo: "#tiktok" },
  };

  const createSessionsList = (
    socialMetricsArray: Array<TSocialSessionCondition>
  ) => {
    const list = [
      { name: "Introdução", scrollTo: "#introduction" },
      { name: "Influenciadores", scrollTo: "#influencers" },
      { name: "Escopo", scrollTo: "#scope" },
    ];

    socialMetricsArray.map((item) => {
      if (item.obj.qualitativeMetrics.length > 0) {
        list.push(socialMetricsExist[item.kind]);
      }
    });

    list.push({ name: "Glossário", scrollTo: "#glossary" });

    return list;
  };

  const socialMetricsArray: Array<TSocialSessionCondition> = [
    { obj: instagramQualitative, kind: "instagramQualitative" },
    { obj: twitterQualitative, kind: "twitterQualitative" },
    { obj: youtubeQualitative, kind: "youtubeQualitative" },
    { obj: tiktokQualitative, kind: "tiktokQualitative" },
  ];

  const sections = createSessionsList(socialMetricsArray);

  //metodo(qualitative, name)
  //qualit

  const [menuActive, setMenuActive] = useState("#introduction");
  const [loading, setLoading] = useState({ status: false, type: "" });
  const [accessReport, setAccessReport] = useState(false);
  const [password, setPassword] = useState("");
  const [brandThumb, setBrandThumb] = useState(
    "https://firebasestorage.googleapis.com/v0/b/mudah-web.appspot.com/o/users%2Fuser_default.png?alt=media&token=b2bb04ff-82bb-42ae-baea-29dd9b9430c5"
  );

  const toggleLoading = (status: ILoadingProps) => setLoading(status);

  const handleScroll = (id: string) => {
    setMenuActive(id);
    // console.log("document ->", document.getElementById(id));

    document.getElementById(id)?.scrollIntoView({ behavior: "smooth" });
  };

  const handleAccessReport = () => {
    toggleLoading({ status: true, type: "fetching" });
    const campaignId = pathname.split("/")[2];
    // console.log("pathname", pathname.split("/")[2]);

    dispatch(FetchCampaignAccessReport(campaignId, password, toggleLoading));
  };

  useEffect(() => {
    if (loading.type === "report_does_not_exist")
      dispatch(
        setModalMessage({
          isVisible: true,
          title: "Ops!",
          description:
            "Link do relatório ou senha inválidos. Verifique os dados e tente novamente.",
          confirmation: false,
          icon: "warning",
        } as IModalReducerProps)
      );

    if (loading.type === "access_denied")
      dispatch(
        setModalMessage({
          title: "Ops!",
          description:
            "Houve um erro na busca do relatório. Aguarde um momento e tente novamente. Se o problema persistir entre em contato com o administrador.",
          confirmation: false,
          icon: "warning",
        } as IModalReducerProps)
      );

    if (loading.type === "access_granted") setAccessReport(true);
  }, [loading]);

  useEffect(() => {
    if (brand.length > 0) FetchCampaignBrand(brand, setBrandThumb);
  }, [brand]);

  return (
    <ReportLayout
      localState={{
        sections,
        menuActive,
        loading,
        password,
        accessReport,
        title,
        landscape,
        brandThumb,
        recap,
        initialDate,
        endDate,
        influencers,
        instagramQualitative,
        twitterQualitative,
        youtubeQualitative,
        tiktokQualitative,
      }}
      handlers={{
        handleScroll,
        setPassword,
        handleAccessReport,
      }}
    />
  );
};
export default ReportController;
