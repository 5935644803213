import { doc, getDoc, onSnapshot, query, collection, setDoc, updateDoc, where, getDocs, orderBy } from "firebase/firestore";
import db, { firebaseStorage } from "../../db/firebaseConn";
import { ILoadingProps } from "../../utils/globalTypes";
import { IInfluencerProps, IInstagramQualitativeProps, IStepOneRegisterCampaignProps, ITiktokQualitativeProps, ITwitterQualitativeProps, IYoutubeQualitativeProps } from "./campaigns.types";
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
import { makeId, makePassword } from "../../utils/validations";
import { AppThunk } from "..";
import { setCampaignsInfo } from "./campaigns.reducer";
import React, { SetStateAction } from "react";
import { log } from "console";

export const FetchAllCampaigns = (setCampaigns: (campaignsList: any) => void, callback: (status: ILoadingProps) => void) => {
  try {
    const q = query(collection(db, 'campaigns'));
    let campaigns: any = [];
    onSnapshot(q, (querySnapShot) => {

      querySnapShot.forEach((doc) => {
        campaigns.push(doc.data());
      });

      // console.log('campaigns returned from fetchAll campaigns ->', campaigns);

      setCampaigns(campaigns);
      callback({ status: false, type: 'list_campaigns_success' });
    })
  } catch (error) {
    console.log('Error on trycatch FetchAllcampaigns ->', error);

  }
}

export const FetchAllBrandsToCampaigns = (setBrands: (brands: any) => void, callback: (status: ILoadingProps) => void) => {
  try {
    const q = query(collection(db, 'brands'), orderBy('brandName'));
    let brands: any = [];
    onSnapshot(q, (querySnapShot) => {

      querySnapShot.forEach((doc) => {
        brands.push(doc.data());
      });

      // console.log('brands ->', brands);

      setBrands({ brandsList: brands });
      callback({ status: false, type: 'list_brands_success' });
    })
  } catch (error) {
    console.log('Error on trycatch FetchAllBrands ->', error);

  }
}

export const FetchCampaignRegister = (campaign: IStepOneRegisterCampaignProps, callback: (status: ILoadingProps) => void): AppThunk => {
  return (dispatch) => {
    try {
      // console.log(campaign);

      const storageRef = ref(firebaseStorage, `campaigns/${campaign.landscape?.name}`);
      const uploadTask = uploadBytesResumable(storageRef, campaign.landscape);

      uploadTask.on("state_changed",
        () => { },
        (error) => {
          console.log('Error on uploadPhoto');
          callback({ status: false, type: 'capaign_registration_failed' });
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then(async (downloadURL) => {

            const campaignId = makeId(20);

            const newCampaign = {
              ...campaign,
              id: campaignId,
              landscape: downloadURL,
              stepNumber: 1,
              isComplete: false
            }

            await setDoc(doc(db, "campaigns", campaignId), newCampaign);
            dispatch(setCampaignsInfo({ id: campaignId }));
            callback({ status: false, type: 'campaign_registered_successfully' });
          });
        }
      );

    } catch (error) {
      console.log('Error on trycatch FetchCreateCampaign ->', error);
      callback({ status: false, type: 'capaign_registration_failed' });
    }
  }
}

export const FetchCampaignUpdateStepOne = async (campaign: IStepOneRegisterCampaignProps, callback: (status: ILoadingProps) => void) => {
  try {
    // console.log("updatingCampaign ->", campaign);

    const campaignId = campaign.id || ''

    if (!campaign.changedLandscape) {
      // console.log("updatingCampaign without landscape ->", campaign);
      await updateDoc(doc(db, "campaigns", campaignId), {
        title: campaign.title,
        brand: campaign.brand,
        initialDate: campaign.initialDate,
        endDate: campaign.endDate,
        recap: campaign.recap
      });
      callback({ status: false, type: 'campaign_registered_successfully' });
    }
    else {
      const storageRef = ref(firebaseStorage, `campaigns/${campaign.landscape?.name}`);
      const uploadTask = uploadBytesResumable(storageRef, campaign.landscape);

      uploadTask.on("state_changed",
        () => { },
        (error) => {
          console.log('Error on uploadPhoto');
          callback({ status: false, type: 'capaign_registration_failed' });
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then(async (downloadURL) => {
            // console.log("updatingCampaign with landscape->", downloadURL);

            await updateDoc(doc(db, "campaigns", campaignId), {
              title: campaign.title,
              brand: campaign.brand,
              initialDate: campaign.initialDate,
              endDate: campaign.endDate,
              recap: campaign.recap,
              landscape: downloadURL
            });
            callback({ status: false, type: 'campaign_registered_successfully' });
          });
        }
      );
    }

  } catch (error) {
    console.log('Error on trycatch FetchCreateCampaign ->', error);
    callback({ status: false, type: 'capaign_registration_failed' });
  }
}

export const FetchCampaignUpdateStepTwo = (campaignId: string, influencers: IInfluencerProps[], callback: (status: ILoadingProps) => void): AppThunk => {
  return (dispatch, getState) => {
    try {
      // let campaign = getState().campaignsReducer;
      let newInfluencersArr: IInfluencerProps[] = []; //isso vai dentro de campanha. montar o array no foreach

      let requests = influencers.map(async (item) => {

        return new Promise<void>((resolve) => {
          if (item.profilePhoto?.name) {
            const storageRef = ref(firebaseStorage, `campaigns/${item.profilePhoto?.name}`);
            const uploadTask = uploadBytesResumable(storageRef, item.profilePhoto);

            uploadTask.on("state_changed",
              () => { },
              (error) => {
                console.log('Error on uploadPhoto');
                callback({ status: false, type: 'capaign_registration_failed' });
              },
              () => {
                getDownloadURL(uploadTask.snapshot.ref).then(async (downloadURL) => {
                  // console.log("updatingCampaign with photo influencer->", downloadURL);

                  //push das infos do influencer dentro do array de influencer
                  newInfluencersArr.push({
                    ...item,
                    profilePhoto: downloadURL,
                  });
                  resolve();
                });
              }
            );
          }
          else {
            // console.log('already have image ?', item.profilePhoto, item.profilePhoto.length);

            //push das infos do influencer dentro do array de influencer
            newInfluencersArr.push({
              ...item,
              profilePhoto: item.profilePhoto.length > 0 ? item.profilePhoto : 'https://firebasestorage.googleapis.com/v0/b/mudah-web.appspot.com/o/users%2Fuser_default.png?alt=media&token=b2bb04ff-82bb-42ae-baea-29dd9b9430c5'
            });
            resolve();
          }
        })

      });


      Promise.all(requests).then(async () => {
        // console.log('newInfluencersArr', newInfluencersArr);

        // let instagramQualitative = 

        await updateDoc(doc(db, "campaigns", campaignId), {
          influencers: newInfluencersArr,
          stepNumber: 2,
        });

        dispatch(setCampaignsInfo({ influencers: newInfluencersArr }))
        callback({ status: false, type: 'campaign_stepTwo_successfully' });
      })

    } catch (error) {
      console.log('Error on trycatch FetchUpdateCampaignStepTwo ->', error);
      callback({ status: false, type: 'campaign_stepTwo_failed' });
    }
  }

}


// const checkQualitativeMetrics = (qualitativeMetrics:any[], influencers:any[])=>{
//   let newQualitative = {};
//   let newQualitativeMetrics = [];

//   qualitativeMetrics.map((item)=>{


//   })

// }

export const FetchCampaignUpdateStepThree = async (
  campaignId: string,
  analyzeQualitative: {
    instagramQualitative: IInstagramQualitativeProps;
    twitterQualitative: ITwitterQualitativeProps;
    youtubeQualitative: IYoutubeQualitativeProps;
    tiktokQualitative: ITiktokQualitativeProps;
  },
  callback: (status: ILoadingProps) => void) => {
  try {

    await updateDoc(doc(db, "campaigns", campaignId), {
      ...analyzeQualitative,
      stepNumber: 4,
    });

    callback({ status: false, type: 'campaign_stepThree_successfully' });

  } catch (error) {
    console.log('Error on trycatch FetchUpdateCampaignStepThree ->', error);
    callback({ status: false, type: 'campaign_stepThree_failed' });
  }
}


export const FetchCampaignCreateReport = (campaignId: string, callback: (status: ILoadingProps) => void): AppThunk => {
  return async (dispatch) => {
    try {
      const docRef = doc(db, "reports", campaignId);
      const docSnap = await getDoc(docRef);


      if (!docSnap.exists()) {
        let reportPassword = makePassword(10);

        await updateDoc(doc(db, "campaigns", campaignId), {
          stepNumber: 5,
          isComplete: true,
          reportLink: `https://analytics-mudah.com/relatorio/${campaignId}`,
          reportPassword: reportPassword
        });

        await setDoc(doc(db, "reports", campaignId), {
          idCampaign: campaignId,
          password: reportPassword
        });

        //atualizar o redux
        dispatch(setCampaignsInfo({
          reportLink: `https://analytics-mudah.com/relatorio/${campaignId}`,
          reportPassword: reportPassword
        }));

        callback({ status: false, type: 'campaign_stepFour_successfully' });
      } else {

        await updateDoc(doc(db, "campaigns", campaignId), {
          stepNumber: 5,
          isComplete: true,
        });
        callback({ status: false, type: 'campaign_stepFour_successfully' });
      }


    } catch (error) {
      console.log('Error on trycatch FetchCampaignCreateReport ->', error);
      callback({ status: false, type: 'campaign_stepFour_failed' });
    }
  }

}

export const FetchCampaignAccessReport = (campaignId: string, password: string, callback: (status: ILoadingProps) => void): AppThunk => {
  return async (dispatch) => {
    try {
      // console.log('password ->', password);
      // console.log('campaignId ->', campaignId);

      const docRef = doc(db, "reports", campaignId);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {

        if (docSnap.data()?.password === password) {

          const campaignRef = doc(db, "campaigns", campaignId);
          const campaign = await getDoc(campaignRef);

          //atualizar o redux
          dispatch(setCampaignsInfo({
            ...campaign.data()
          }));

          callback({ status: false, type: 'access_granted' });
        } else {
          callback({ status: false, type: 'report_does_not_exist' });
        }


      } else {
        callback({ status: false, type: 'report_does_not_exist' });
      }

    } catch (error) {
      console.log('Error on trycatch FetchCampaignAccessReport ->', error);
      callback({ status: false, type: 'access_denied' });
    }
  }

}

export const FetchCampaignBrand = async (brand: string, setBrandThumb: React.Dispatch<SetStateAction<string>>) => {
  try {
    const querySnapshot = await getDocs(collection(db, "brands"));
    querySnapshot.forEach((doc) => {
      if (doc.data().brandName === brand) {
        setBrandThumb(doc.data().thumb)
      }
    });
  } catch (error) {
    console.log('Error on trycatch FetchCampaignAccessReport ->', error);
  }
}

export const FetchCampaignsBelongsToBrand = async (brandId: string, setCampaigns: (campaignsList: any) => void, callback: (status: ILoadingProps) => void) => {
  try {
    const q = query(collection(db, "campaigns"), where("brand", "==", brandId));

    const querySnapshot = await getDocs(q);
    let campaigns: any = [];

    querySnapshot.forEach((doc) => {
      campaigns.push(doc.data());
    });

    setCampaigns(campaigns);
    callback({ status: false, type: 'campaigns_loaded' });

  } catch (error) {
    console.log('Error on trycatch FetchCampaignAccessReport ->', error);
  }
}




