import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setProperties } from '../../store/modal/modal.reducer';
import { useAppSelector } from '../../store/storeHook';
import { FetchAllUsers, FetchDeleteUser } from '../../store/user/user.actions';
import { setUserInfo } from '../../store/user/user.reducer';
import { IUserReducerProps, IUserRegisterProps } from '../../store/user/user.types';
import { ILoadingProps } from '../../utils/globalTypes';

import UsersLayout from './users.layout';

const UsersController: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { userHierarchy } = useAppSelector(state => state.userReducer)

  const [users, setUsers] = useState([] as IUserReducerProps[])
  const [loading, setLoading] = useState({ status: true, type: '' });

  const toggleLoading = (status: ILoadingProps) => setLoading(status);

  const handleRegister = () => {
    if (userHierarchy === 'operador')
      return dispatch(setProperties({
        isVisible: true,
        title: 'Ops!',
        description: 'Você não tem permissão para criar usuários',
        confirmation: false,
        icon: 'user',
      }));

    navigate('/usuarios/cadastro')
  }

  const handleEdit = (user: IUserRegisterProps) => {
    if (userHierarchy === 'operador')
      return dispatch(setProperties({
        isVisible: true,
        title: 'Ops!',
        description: 'Você não tem permissão para editar usuários',
        confirmation: false,
        icon: 'user',
      }))

    dispatch(setUserInfo({ editingUser: user }))
    navigate('/usuarios/editar')
  }

  const handleDeleteUser = (user: IUserRegisterProps) => {
    dispatch(setProperties({
      isVisible: false,
      title: 'Deletar usuário',
      description: `Deseja mesmo deletar o usuário ${user.fullname} ?`,
      confirmation: true,
      icon: 'user',
    }))
    setTimeout(() => {
      toggleLoading({ status: true, type: 'deleting_user' })
      FetchDeleteUser(user, () => FetchAllUsers(setUsers, toggleLoading));
    }, 300);

  }

  const handleAskDeleteUser = (user: IUserRegisterProps) => {
    if (userHierarchy === 'operador')
      dispatch(setProperties({
        isVisible: true,
        title: 'Ops!',
        description: 'Você não tem permissão para excluir usuários',
        confirmation: false,
        icon: 'user',
      }))
    else
      dispatch(setProperties({
        isVisible: true,
        title: 'Deletar usuário',
        description: `Deseja mesmo deletar o usuário ${user.fullname} ?`,
        confirmation: true,
        icon: 'user',
        onClickBtnRight: () => handleDeleteUser(user)
      }))
  }

  useEffect(() => {
    FetchAllUsers(setUsers, toggleLoading);
  }, [])

  return <UsersLayout
    localState={{
      users,
      loading
    }}
    handlers={{
      handleRegister,
      handleEdit,
      handleAskDeleteUser
    }} />
}
export default UsersController;