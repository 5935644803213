import styled from 'styled-components';

export const Panel = styled.div`
margin-bottom: 20px;
background-color: ${props => props.theme.colors.white};
border-radius: 10px;
display: flex;
flex-direction: column;
padding:32px;
`
export const PanelTitle = styled.h1`
font-family: 'Poppins';
font-weight: 600;
font-size: 18px;
color: ${props => props.theme.colors.dark};
`
export const PanelContent = styled.div`
display: flex;
flex:1;
flex-direction: row;
`
export const PanelBody = styled.div`
display: flex;
flex-direction: column;
justify-content: flex-start;
width: 100%;
`
export const Divider = styled.div`
width: 30px;
`
export const CalendarBox = styled.div`
padding: 20px 0;
align-self: flex-end;
`