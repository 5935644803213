import React from 'react';

import {
  Container
} from './notFound.styles';
const NotFoundLayout: React.FC = () => {
  return (
    <Container>
      <h1>404</h1>
      <h2>OOPS :( A página não encontrada.</h2>
      <p>Verifique a URL digitada e tente novamente!
      </p>
    </Container>
  )
}

export default NotFoundLayout;