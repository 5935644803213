import styled from 'styled-components';


export const Container = styled.div`
display: inline-flex;
height: 100vh;
width: 100vw;
flex-direction: column;
justify-content: center;
align-items: center;
`