import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { setModalMessage } from '../../../store/modal/modal.actions';
import { useAppDispatch, useAppSelector } from '../../../store/storeHook';
import { FetchEditUser, FetchRegisterUser, FetchSendEmailResetPassword } from '../../../store/user/user.actions';
import { ILoadingProps } from '../../../utils/globalTypes';
import { validateEmail } from '../../../utils/validations';

import UsersRegisterLayout from './usersEdit.layout';

const UsersEditController: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const {
    userHierarchy: loggedUserHierarchy,
    editingUser
  } = useAppSelector(state => state.userReducer)

  const [fullname, setFullname] = useState(editingUser?.fullname || '');
  const [email, setEmail] = useState(editingUser?.email || '');
  const [userHierarchy, setUserHierarchy] = useState(editingUser?.userHierarchy || '');
  const [profilePhoto, setProfilePhoto] = useState<any>(editingUser?.thumb || '');
  const [password, setPassword] = useState(editingUser?.password || '');
  const [confirmPassword, setConfirmPassword] = useState(editingUser?.password || '');
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [loading, setLoading] = useState({ status: false, type: '' });
  const [loadingResetPass, setLoadingResetPass] = useState({ status: false, type: '' });
  const [active, setActive] = useState(editingUser?.active || false);

  const userHierarchyList = [
    'operador',
    'admin'
  ]

  const toggleLoading = (status: ILoadingProps) => setLoading(status);
  const toggleLoadingResetPass = (status: ILoadingProps) => setLoadingResetPass(status);

  const handleBack = () => {
    navigate('/usuarios');
  }

  const handleEdit = () => {
    toggleLoading({ status: true, type: 'Fetching' })
    FetchEditUser({
      id: editingUser?.id,
      fullname,
      email,
      userHierarchy,
      thumb: profilePhoto,
      password,
      active,
      lastLogin: editingUser?.lastLogin || ''
    }, toggleLoading);
  }

  const handleResetPassword = () => {
    toggleLoadingResetPass({ status: true, type: 'Fetching' })
    FetchSendEmailResetPassword(email, toggleLoadingResetPass);
  }

  const handleRedirectAfterRegistered = () => {
    dispatch(setModalMessage({
      title: 'Sucesso',
      isVisible: false,
      description: 'Usuário atualizado com sucesso.',
      icon: 'success',
      confirmation: false,
    }));
    setTimeout(() => {
      navigate('/usuarios');
    }, 500);
  }

  useEffect(() => {
    if (loading.type === 'user_updated_successfully')
      dispatch(setModalMessage({
        title: 'Sucesso',
        isVisible: true,
        description: 'Usuário atualizado com sucesso.',
        icon: 'success',
        confirmation: false,
        onClick: handleRedirectAfterRegistered
      }));

    if (loading.type === 'user_update_failed')
      dispatch(setModalMessage({
        title: 'Ops! :(',
        isVisible: true,
        description: 'Não foi possível registrar o usuário. Verifique sua conexão com a internet e tente novamente. Se o problema persistir, contate o administrador.',
        icon: 'warning',
        confirmation: false,
      }))

    //eslint-disable-next-line
  }, [loading])

  useEffect(() => {
    if (loadingResetPass.type === 'reset_sent_successfully')
      dispatch(setModalMessage({
        title: 'Sucesso',
        isVisible: true,
        description: 'Um email foi enviado ao usuário para restar a senha.',
        icon: 'success',
        confirmation: false,
      }));

    if (loadingResetPass.type === 'reset_failed')
      dispatch(setModalMessage({
        title: 'Ops! :(',
        isVisible: true,
        description: 'Não foi possível registrar o usuário. Verifique sua conexão com a internet e tente novamente. Se o problema persistir, contate o administrador.',
        icon: 'warning',
        confirmation: false,
      }))

    //eslint-disable-next-line
  }, [loadingResetPass])

  useEffect(() => {
    if (fullname.length > 5
      && validateEmail(email)
      && userHierarchy.length > 0
      && password.length > 0
      && confirmPassword.length > 0
      && password === confirmPassword
    )
      setButtonDisabled(false);
    else
      setButtonDisabled(true);
  }, [fullname, email, userHierarchy, password, confirmPassword])

  return <UsersRegisterLayout
    localState={{
      fullname,
      email,
      profilePhoto,
      userHierarchy,
      password,
      confirmPassword,
      userHierarchyList,
      buttonDisabled,
      loading,
      loggedUserHierarchy,
      active,
      loadingResetPass
    }}
    handlers={{
      handleBack,
      handleEdit,
      setFullname,
      setEmail,
      setUserHierarchy,
      setProfilePhoto,
      setPassword,
      setConfirmPassword,
      setActive,
      handleResetPassword
    }}
  />
}

export default UsersEditController;