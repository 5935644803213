import React from "react";
import InfluencerInstagram from "./components/influencer/influencer.component";
import PercentualCollumn from "./components/percentualCollumn/percentualCollumn.component";
import {
  HeaderCell,
  RowCell,
  TableContainer,
  TableHeader,
  TableRow,
} from "./postsByInfluencer.styles";

import { IYoutubeVideosByInfluencerProps } from "./postsByInfluencer.types";

const YoutubeVideosByInfluencer: React.FC<IYoutubeVideosByInfluencerProps> = ({
  data,
}) => {

  const headers = Object.keys(data[0]);
  const collumnsWidth = ["150px"];

  return (
    <TableContainer scroll>
      <TableHeader scroll collumns={headers.length}>
        {headers.map((item, index) => {
          return (
            <HeaderCell
              key={index}
              width={(collumnsWidth && collumnsWidth[index]) || "110px"}
            >
              {item}
            </HeaderCell>
          );
        })}
      </TableHeader>
      {data.map((item, index) => {
        return (
          <TableRow scroll key={index} collumns={headers.length} numberRow={index} lastItem={index + 1 === data.length}>
            <RowCell width={"150px"} justifySelf="flex-start">
              <InfluencerInstagram influencer={item.Influenciador} />
            </RowCell>
            <RowCell width={"110px"}>{item["Alcance máximo"]}</RowCell>
            <RowCell width={"110px"}>{item["Taxa de alcance"]}</RowCell>
            <RowCell width={"110px"}>{item["Impressões"]}</RowCell>
            <RowCell width={"110px"}>{item["Curtidas"]}</RowCell>
            <RowCell width={"110px"}>{item["Comentários"]}</RowCell>
            <RowCell width={"110px"}>{item["Interações totais"]}</RowCell>
            <RowCell width={"110px"}>{item["Taxa de engaj."]}</RowCell>
            <RowCell width={"110px"}>{item["Views"]}</RowCell>
            <RowCell width={"110px"}>{item["Taxa de views"]}</RowCell>
            <RowCell width={"110px"}>{item["Tempo médio assistido"]}</RowCell>
            <RowCell width={"110px"}>{item["Retenção"]}</RowCell>
            <RowCell width={"110px"}>{item["% vis. durante o anúncio"]}</RowCell>
          </TableRow>
        );
      })}
    </TableContainer>
  );
};
export default YoutubeVideosByInfluencer;
