import React from "react";
import {
  HeaderCell,
  RowCell,
  TableContainer,
  TableHeader,
  TableRow,
} from "./postsByInfluencer.styles";

import { YoutubeResultsByInfluencerProps } from "./postsByInfluencer.types";

const YoutubeResultsByInfluencer: React.FC<YoutubeResultsByInfluencerProps> = ({
  data,
  scroll = false
}) => {

  const headers = data[0] ? Object.keys(data[0]) : [];

  return (
    <TableContainer scroll={scroll}>
      <TableHeader scroll={scroll} collumns={headers.length}>
        {headers.map((item, index) => {
          return (
            <HeaderCell
              key={index}
              width={"100px"}
            >
              {item}
            </HeaderCell>
          );
        })}
      </TableHeader>
      {data.map((item, index) => {
        return (
          <TableRow key={index} scroll={scroll} collumns={headers.length} numberRow={index} lastItem={index + 1 === data.length}>
            <RowCell width={"100px"}>{item["Alcance máximo"]}</RowCell>
            <RowCell width={"100px"}>{item["Taxa de alcance"]}</RowCell>
            <RowCell width={"100px"}>{item["Impressões"]}</RowCell>
            <RowCell width={"100px"}>{item["Curtidas"]}</RowCell>
            <RowCell width={"100px"}>{item['Comentários']}</RowCell>
            <RowCell width={"100px"}>{item["Interações totais"]}</RowCell>
            <RowCell width={"100px"}>{item["Taxa de engaj."]}</RowCell>
            <RowCell width={"100px"}>{item['Views']}</RowCell>
            <RowCell width={"100px"}>{item["Taxa de views"]}</RowCell>
            <RowCell width={"100px"}>{item["Tempo médio assistido"]}</RowCell>
            <RowCell width={"100px"}>{item['Retenção']}</RowCell>
            <RowCell width={"100px"}>{item['% vis. durante o anúncio']}</RowCell>
          </TableRow>
        );
      })}
    </TableContainer>
  );
};
export default YoutubeResultsByInfluencer;
