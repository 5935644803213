import styled from 'styled-components'

interface IButtonOutlineProps {
  isDisabled: boolean
  customStyle?: any
}

export const Container = styled.button<IButtonOutlineProps>`
  align-items: center;
  justify-content: center;
  width: auto;
  height: 38px;
  border-radius: 6px;
  border: solid 1px ${(props) => (props.isDisabled ? '#B19FF0' : '#623CEA')};
  background: none;
  ${(props) => props.customStyle}
`

export const LoadingBox = styled.div`
  display: inline-flex;
`
interface ILabelButtonOutlineProps {
  isDisabled: boolean
  customLabelStyle?: any
}

export const ButtonContent = styled.div`
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`

export const Label = styled.span<ILabelButtonOutlineProps>`
  height: 14px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: ${(props) => (props.isDisabled ? '#B19FF0' : '#623CEA')};
  display: flex;
  ${(props) => props.customLabelStyle};
`

export const Icon = styled.img`
  width: 15px;
  height: 15px;
  margin-right: 13px;
`
