import React from "react";
import logo from "../../../assets/logo/mudah_logo_footer.svg";
import logo2 from "../../../assets/logo/mudah_logo_footer_2.svg";
import { Container, Logo, FooterDivisor, FooterLabel } from "./footer.styles";
import { IFooterProps } from "./footer.types";

const Footer: React.FC<IFooterProps> = ({ theme = "light" }) => {
  return (
    <Container themeType={theme}>
      <Logo src={theme === "light" ? logo : logo2} alt="mudah-logo-footer" />
      <FooterDivisor themeType={theme} />
      <FooterLabel themeType={theme}>
        © Mudah 2023. Todos os direitos reservados
      </FooterLabel>
    </Container>
  );
};

export default Footer;
