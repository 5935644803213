import React from "react";
import Footer from "../../global/components/footer/footer.components";
import CustomModal from "../../global/components/modal/modal.components";
import CampaignRegisterFourLayout from "../campaings/register/stepFour/campaignRegisterFour.layout";
import AccessReportLayout from "./accessReport/accessReport.layout";
import HeaderReport from "./components/headerReport/headerReport.component";
import SideMenuReport from "./components/sideMenu/sideMenuReport.component";
import { Container, Content } from "./report.styles";
import { IReportLayoutProps } from "./report.types";

const ReportLayout: React.FC<IReportLayoutProps> = ({
  localState,
  handlers,
}) => {
  const {
    sections,
    menuActive,
    loading,
    password,
    accessReport,
    title,
    landscape,
    brandThumb,
  } = localState;

  const { handleScroll, setPassword, handleAccessReport } = handlers;

  return (
    <>
      {!accessReport ? (
        <AccessReportLayout
          loading={loading}
          password={password}
          setPassword={setPassword}
          handleAccessReport={handleAccessReport}
        />
      ) : (
        <Container>
          <CustomModal />
          <SideMenuReport
            sections={sections}
            handleScroll={handleScroll}
            menuActive={menuActive}
          />
          <Content>
            {title.length > 0 &&
              <>
                <HeaderReport
                  data={{
                    title,
                    landscape,
                    brandThumb,
                  }}
                />
                <CampaignRegisterFourLayout
                  isComplete={true}
                  localState={localState}
                />
                <Footer theme="dark" />
              </>
            }
          </Content>
        </Container>
      )}
    </>
  );
};

export default ReportLayout;
