import React from 'react';
import Modal from 'react-bootstrap/Modal';
import { IModalContentProps } from './modalContent.types';
import './modalContentStyles.scss';

const ModalContent: React.FC<IModalContentProps> = (props) => {

  const {
    isVisible,
    children,
  } = props;

  return (
    <Modal
      size='xl'
      show={isVisible}
      style={{ backgroundColor: 'transparent' }}
    >
      {children}
    </Modal>
  )
}

export default ModalContent;