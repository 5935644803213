import {
  Container,
  Image,
  InfluencerContainer,
  InfluencerDetail,
  InfluencerInfo,
  InfluencerLabel,
  PersonContainer,
  PersonInfo,
  PersonName,
  PersonPhoto,
  PersonPosition,
  ResultDetailItem,
  Subtitle,
  SubTopic,
  Title,
  Topic,
} from "./index.styles";
import { IResultDetailsByInfluencerProps, IYoutubeResultsByInfluencerComponentProps } from "./index.types";
import lodash from 'lodash';
import YoutubeKpisAnalyzes from '../../kpis/kpis.component';
import YoutubeResultsByInfluencer from "../postsByInfluencer/postsByInfluencer.component";
import { convertToSeconds, convertToTime, maskNumbers } from "utils/validations";



const PreviewResultsByInfluencerYoutube: React.FC<
  IResultDetailsByInfluencerProps
> = ({ influencers, youtubeQualitative }) => {

  const handleFeedDataKpis = (influencerName: string, influencerCluster: string) => {

    let allRangeRateEngagements: number[] = [];
    let rangeRateEngagementIndividual: number[] = [];

    let allRangeRateViews: number[] = [];
    let rangeRateViewsIndividual: number[] = [];

    let allRetention: number[] = [];
    let allRetentionIndividual: number[] = [];

    let allAvgWatchedTime: number[] = [];
    let allAvgWatchedTimeIndividual: number[] = [];

    influencers.filter((item) => item.youtube.isActive)
      .map((influencer) => {

        influencer.youtube.metrics.map((metric) => {
          let allIterationsIndividual = lodash.sum([parseInt(metric.likes || '0'), parseInt(metric.comments || '0')])

          if (influencer.cluster === influencerCluster) {
            allRangeRateEngagements.push(allIterationsIndividual / parseInt(metric.reach || '0') * 100)
            allRangeRateViews.push(parseInt(metric.views || '0') / parseInt(influencer.youtube.followers) * 100)
            allRetention.push(parseInt(metric.retention || '0'));
            allAvgWatchedTime.push(parseInt(metric.watchedTimeAvg || '0'));
          }


          if (influencer.name === influencerName) {
            rangeRateEngagementIndividual.push(allIterationsIndividual / parseInt(metric.reach || '0') * 100)
            rangeRateViewsIndividual.push(parseInt(metric.views || '0') / parseInt(influencer.youtube.followers) * 100)
            allRetentionIndividual.push(parseInt(metric.retention || '0'));
            allAvgWatchedTimeIndividual.push(parseInt(metric.watchedTimeAvg || '0'));
          }

        })

      })

    let avgRangeRateEngagementCluster = lodash.sum(allRangeRateEngagements) / allRangeRateEngagements.length;
    let avgRangeRateEngagementIndividual = lodash.sum(rangeRateEngagementIndividual) / rangeRateEngagementIndividual.length;

    let avgRangeRateViewsCluster = lodash.sum(allRangeRateViews) / allRangeRateViews.length;
    let avgRangeRateViewsIndividual = lodash.sum(rangeRateViewsIndividual) / rangeRateViewsIndividual.length;

    let avgRetentionCluster = lodash.sum(allRetention) / allRetention.length;
    let avgRetentionIndividual = lodash.sum(allRetentionIndividual) / allRetentionIndividual.length;

    let avgWatchedTimeCluster = lodash.sum(allAvgWatchedTime) / allAvgWatchedTime.length;
    let avgWatchedTimeIndividual = lodash.sum(allAvgWatchedTimeIndividual) / allAvgWatchedTimeIndividual.length;

    return {
      engage: [
        {
          name: 'Média do cluster',
          label: 'Taxa de engajamento',
          value: Number.isInteger(avgRangeRateEngagementCluster) ? avgRangeRateEngagementCluster : parseFloat(avgRangeRateEngagementCluster.toFixed(2))
        },
        {
          name: 'Performance influenciador',
          label: 'Taxa de engajamento',
          value: Number.isInteger(avgRangeRateEngagementIndividual) ? avgRangeRateEngagementIndividual : parseFloat(avgRangeRateEngagementIndividual.toFixed(2))
        },
      ],
      views: [
        {
          name: 'Média do cluster',
          label: 'Taxa de Views',
          value: Number.isInteger(avgRangeRateViewsCluster) ? avgRangeRateViewsCluster : parseFloat(avgRangeRateViewsCluster.toFixed(2))
        },
        {
          name: 'Performance influenciador',
          label: 'Taxa de Views',
          value: Number.isInteger(avgRangeRateViewsIndividual) ? avgRangeRateViewsIndividual : parseFloat(avgRangeRateViewsIndividual.toFixed(2))
        },
      ],
      retention: [
        {
          name: 'Média do cluster',
          label: 'Retenção',
          value: Number.isInteger(avgRetentionCluster) ? avgRetentionCluster : parseFloat(avgRetentionCluster.toFixed(2))
        },
        {
          name: 'Performance influenciador',
          label: 'Retenção',
          value: Number.isInteger(avgRetentionIndividual) ? avgRetentionIndividual : parseFloat(avgRetentionIndividual.toFixed(2))
        },
      ],
      avgWatchedTime: [
        {
          name: 'Média do cluster',
          label: 'Tempo médio assistido',
          value: Number.isInteger(avgWatchedTimeCluster) ? avgWatchedTimeCluster : parseFloat(avgWatchedTimeCluster.toFixed(2))
        },
        {
          name: 'Performance influenciador',
          label: 'Tempo médio assistido',
          value: Number.isInteger(avgWatchedTimeIndividual) ? avgWatchedTimeIndividual : parseFloat(avgWatchedTimeIndividual.toFixed(2))
        },
      ]
    }
  }

  const handleFeedData = (influencerName: string) => {
    let newArr: IYoutubeResultsByInfluencerComponentProps[] = []

    influencers.filter((item) => item.youtube.isActive)
      .map((influencer) => {
        if (influencer.name === influencerName) {

          influencer.youtube.metrics.map((metric) => {

            let rangeRateReach = parseInt(metric.reach) / parseInt(influencer.youtube.followers) * 100;
            let allIterations = lodash.sum([parseInt(metric.likes), parseInt(metric.comments)]);
            let rangeRateEngagement = allIterations / parseInt(metric.reach) * 100;
            let rangeRateViews = parseInt(metric.views) / parseInt(influencer.youtube.followers) * 100;

            newArr.push({
              'Alcance máximo': maskNumbers(metric.reach),
              'Taxa de alcance': `${Number.isInteger(rangeRateReach) ? rangeRateReach : rangeRateReach.toFixed(2)}%`,
              'Impressões': maskNumbers(metric.impressions),
              'Curtidas': maskNumbers(metric.likes),
              'Comentários': maskNumbers(metric.comments),
              'Interações totais': maskNumbers(allIterations.toString()),
              'Taxa de engaj.': `${Number.isInteger(rangeRateEngagement) ? rangeRateEngagement : rangeRateEngagement.toFixed(2)}%`,
              'Views': maskNumbers(metric.views),
              'Taxa de views': `${Number.isInteger(rangeRateViews) ? rangeRateViews : rangeRateViews.toFixed(2)}%`,
              'Tempo médio assistido': `${convertToTime(convertToSeconds(metric.watchedTimeAvg))}`,
              'Retenção': `${metric.retention}%`,
              '% vis. durante o anúncio': maskNumbers(metric.percentageView),
            })
          })
        }
      })

    return newArr;
  }


  return (
    <Container>
      <Title>Detalhamento de Resultado</Title>
      <Subtitle>Influenciador a influenciador</Subtitle>
      {youtubeQualitative.qualitativeMetrics.map((item, index) => (
        <ResultDetailItem key={index}>
          <InfluencerContainer>
            <InfluencerInfo>
              <PersonContainer>
                <PersonPhoto src={item.influencerPhoto} />
                <PersonInfo>
                  <PersonName>{item.influencerName.toUpperCase()}</PersonName>
                  <PersonPosition>{item.influencerCluster}</PersonPosition>
                </PersonInfo>
              </PersonContainer>
            </InfluencerInfo>
          </InfluencerContainer>
          <Topic>Youtube</Topic>
          <YoutubeResultsByInfluencer scroll data={handleFeedData(item?.influencerName)} />
          <Topic>KPI's Youtube</Topic>
          <SubTopic>Cluster: {item.influencerCluster}</SubTopic>

          <YoutubeKpisAnalyzes
            columnOne={handleFeedDataKpis(item.influencerName, item.influencerCluster).engage}
            columnOnePerc={true}
            columnTwo={handleFeedDataKpis(item.influencerName, item.influencerCluster).views}
            columnTwoPerc={true}
            columnThree={handleFeedDataKpis(item.influencerName, item.influencerCluster).retention}
            columnFour={handleFeedDataKpis(item.influencerName, item.influencerCluster).avgWatchedTime}
          />

        </ResultDetailItem>
      ))}
    </Container>
  );
};

export default PreviewResultsByInfluencerYoutube;
