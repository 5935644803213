import React, { useEffect, useState } from 'react';
import {
  Container,
  Content,
  Title,
  Icon,
  Row,
  ExpandButton,
  ContentHeaderBox,
} from './metrics.styles';
import { IBgColor, IMetricsComponentProps } from './metrics.types';
import IOSSwitchComponent from '../../../../global/components/iOSSwitch/iOSSwitch.component';
import InstagramDetailsComponent from '../instagramDetails/instagramDetails.component';

import instagramLogo from '../../../../assets/icons/instagram_metrics.svg';
import twitterLogo from '../../../../assets/icons/twitter_metrics.svg';
import youtubeLogo from '../../../../assets/icons/youtube_metrics.svg';
import tiktokLogo from '../../../../assets/icons/tiktok_metrics.svg';
import TwitterDetailsComponent from '../twitterDetails/twitterDetails.component';
import YoutubeDetailsComponent from '../youtubeDetails/youtubeDetails.component';
import TiktokDetailsComponent from '../tiktokDetails/tiktokDetails.component';

const MetricsComponent: React.FC<IMetricsComponentProps> = (props) => {

  const {
    label,
    value,
    active,
    toggleActive,
    onChange,
    toggleModalVisible
  } = props;

  const [expanded, setExpanded] = useState(false);

  const toggleActiveLocal = () => {
    if (active)
      setExpanded(false);

    toggleActive(!active);
  }

  const toggleExpand = () => setExpanded(!expanded);

  const appearence: IBgColor = {
    Instagram: {
      bg: 'linear-gradient(90deg, #FFA201 -0.85%, #FE2A6E 53.06%, #9E33EB 97.72%);',
      icon: instagramLogo
    },
    Twitter: {
      bg: '#50ABF1;',
      icon: twitterLogo
    },
    Youtube: {
      bg: '#E81C26;',
      icon: youtubeLogo
    },
    TikTok: {
      bg: 'linear-gradient(0deg, #23314B, #23314B);',
      icon: tiktokLogo
    }
  }

  useEffect(() => {
    if (!active)
      setExpanded(false);
    else
      setExpanded(true);
  }, [active]);

  return (
    <Container
      customStyle={{
        "background": active ? appearence[label].bg : '#A9B8CD'
      }}>
      <Content>
        <ContentHeaderBox>
          <Row>
            <Icon
              src={appearence[label].icon}
              alt='icon-metrics'
            />
            <Title>{label}</Title>
          </Row>
          <Row>
            <IOSSwitchComponent value={active} onChange={toggleActiveLocal} />
            <ExpandButton onClick={toggleExpand} disabled={!active}>
              {!expanded ?
                <svg width="16" height="9" viewBox="0 0 16 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M7.99997 9C7.71322 9 7.4265 8.89196 7.20788 8.67633L0.328227 1.88748C-0.109409 1.45562 -0.109409 0.755441 0.328227 0.32376C0.765686 -0.10792 1.4751 -0.10792 1.91277 0.32376L7.99997 6.33091L14.0872 0.32397C14.5249 -0.10771 15.2342 -0.10771 15.6716 0.32397C16.1095 0.755651 16.1095 1.45583 15.6716 1.88768L8.79207 8.67654C8.57334 8.8922 8.28662 9 7.99997 9Z" fill="#A9B8CD" />
                </svg>
                :
                <svg width="16" height="9" viewBox="0 0 16 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M8.00003 6.9938e-07C8.28678 6.74311e-07 8.5735 0.108042 8.79212 0.323673L15.6718 7.11252C16.1094 7.54438 16.1094 8.24456 15.6718 8.67624C15.2343 9.10792 14.5249 9.10792 14.0872 8.67624L8.00003 2.66909L1.91279 8.67603C1.47515 9.10771 0.765811 9.10771 0.328387 8.67603C-0.109461 8.24435 -0.109461 7.54417 0.328387 7.11232L7.20793 0.323463C7.42666 0.107797 7.71338 7.24439e-07 8.00003 6.9938e-07Z" fill="#A9B8CD" />
                </svg>
              }
            </ExpandButton>
          </Row>
        </ContentHeaderBox>
        {expanded &&
          <>
            {label === 'Instagram' &&
              <InstagramDetailsComponent
                value={value}
                onChange={onChange}
                toggleModalPosts={() => toggleModalVisible('instagramPosts')}
                toggleModalStories={() => toggleModalVisible('instagramStories')}
              />
            }

            {label === 'Twitter' &&
              <TwitterDetailsComponent
                value={value}
                onChange={onChange}
                toggleModalVisible={toggleModalVisible}
              />
            }

            {label === 'Youtube' &&
              <YoutubeDetailsComponent
                value={value}
                onChange={onChange}
                toggleModalVisible={toggleModalVisible}
              />
            }

            {label === 'TikTok' &&
              <TiktokDetailsComponent
                value={value}
                onChange={onChange}
                toggleModalVisible={toggleModalVisible}
              />
            }
          </>
        }
      </Content>
    </Container>
  )
}

export default React.memo(MetricsComponent);