import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { setModalMessage } from '../../../store/modal/modal.actions';
import { useAppDispatch, useAppSelector } from '../../../store/storeHook';
import Niches from '../../../utils/mocks/niche';
import EmployeesList from '../../../utils/mocks/employees';
import AnnualBillingList from '../../../utils/mocks/annualBillingList';

import BrandsEditLayout from './brandsEdit.layout';
import { ILoadingProps } from '../../../utils/globalTypes';
import { FetchBrandsEdit } from '../../../store/brands/brands.actions';
import { makeId } from '../../../utils/validations';

const BrandsEditController: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { selectedBrand } = useAppSelector(state => state.brandsReducer);

  const [brandName, setBrandName] = useState(selectedBrand.brandName || '');
  const [niche, setNiche] = useState(selectedBrand.niche || '');
  const [employees, setEmployees] = useState(selectedBrand.employees || '');
  const [thumb, setThumb] = useState<any>(selectedBrand.thumb || []);
  const [annualBilling, setAnnualBilling] = useState(selectedBrand.annualBilling || '');
  const [loading, setLoading] = useState({ status: false, type: '' });
  const [buttonDisabled, setButtonDisabled] = useState(true);

  const toggleLoading = (status: ILoadingProps) => setLoading(status);

  const handleBack = () => {
    navigate('/marcas');
  }

  useEffect(() => {
    if (brandName.length > 1 &&
      niche.length > 2 &&
      employees.length > 2 &&
      annualBilling.length > 2)
      setButtonDisabled(false);
    else
      setButtonDisabled(true);

  }, [brandName, niche, employees, annualBilling])

  const handleRegister = () => {
    toggleLoading({ status: true, type: 'Fetching' })
    FetchBrandsEdit({
      id: selectedBrand.id,
      brandName,
      niche,
      employees,
      thumb,
      annualBilling
    }, toggleLoading)
  }

  const handleBackBrands = () => {
    dispatch(setModalMessage({
      title: 'Sucesso',
      isVisible: false,
      description: 'Marca atualizada com sucesso.',
      icon: 'success',
      confirmation: false,
    }))

    setTimeout(() => {
      navigate('/marcas');
    }, 300);
  }

  useEffect(() => {
    if (loading.type === 'brand_edited_successfully')
      dispatch(setModalMessage({
        title: 'Sucesso',
        isVisible: true,
        description: 'Marca atualizada com sucesso.',
        icon: 'success',
        confirmation: false,
        onClick: handleBackBrands
      }))

    if (loading.type === 'brand_edition_failed')
      dispatch(setModalMessage({
        title: 'Ops! :(',
        isVisible: true,
        description: 'Não foi possível atualizar os dados da marca. Verifique as informações e tente novamente.',
        icon: 'blank',
        confirmation: false,
      }))

    //eslint-disable-next-line
  }, [loading])

  return <BrandsEditLayout
    localState={{
      Niches,
      EmployeesList,
      AnnualBillingList,
      brandName,
      thumb,
      employees,
      niche,
      annualBilling,
      loading,
      buttonDisabled
    }}
    handlers={{
      setBrandName,
      setNiche,
      setEmployees,
      setThumb,
      setAnnualBilling,
      handleBack,
      handleRegister
    }}
  />
}

export default BrandsEditController;