/* eslint-disable array-callback-return */
import React, { useMemo } from "react";
import EditIcon from "../../../../assets/icons/edit.svg";
import Instagram from "../../../../assets/icons/instagram_white.svg";
import Img from "../../../../assets/img/instagramBG.png";
import ButtonSecondary from "../../buttons/secondary/buttonSecondary.component";
import PostsInstagram from "./components/posts/index/posts.component";
import ResultDetailsByInfluencer from "./components/resultDetailsByInfluencer/index/resultDetailsByInfluencer.component";
import StoriesInstagram from "./components/stories/index/stories.component";
import {
  Container,
  ContentWrapper,
  Description,
  Icon,
  InstagramTitle,
  InstagramTopContainer,
  InstagramWrapper,
  Title,
  Topic,
  TopicContent,
  WhiteWrapper,
} from "./instagram.styles";
import { IInstagramReportProps } from "./instagram.types";

const InstagramReport: React.FC<IInstagramReportProps> = (props) => {
  const { editable, influencers, instagramQualitative, position } = props;

  const isThereAnyContent = useMemo(() => {
    let isThereAStory = false;
    let isThereAPost = false;

    influencers
      .filter((item) => item.instagram.isActive)
      .map((influencer) => {
        if (influencer.instagram.metricsStories.length > 0) {
          isThereAStory = true;
        }
        if (influencer.instagram.metricsPosts.length > 0) {
          isThereAPost = true;
        }
      });
    return {
      story: isThereAStory,
      posts: isThereAPost,
    };
  }, [influencers]);

  return (
    <Container id="#instagram">
      <TopicContent>
        <Topic>{position}. Instagram</Topic>
        {editable && (
          <ButtonSecondary
            label="Editar Informações"
            isDisabled={false}
            loading={false}
            onClick={() => null}
            icon={EditIcon}
            customStyle={{ width: "180px", height: "32px" }}
            customLabelStyle={{
              fontSize: "13px",
              lineHeight: "14px",
              fontWeight: "400",
              fontFamily: "Montserrat",
            }}
          />
        )}
      </TopicContent>
      <WhiteWrapper>
        <InstagramTopContainer
          style={{
            backgroundImage: `url(${Img})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
          }}
        >
          <InstagramWrapper>
            <Icon src={Instagram} />
            <InstagramTitle>Performance no Instagram</InstagramTitle>
          </InstagramWrapper>
        </InstagramTopContainer>
        <ContentWrapper>
          <Title>Panorama geral da campanha</Title>
          <Description>{instagramQualitative.overviewCampaign}</Description>

          {isThereAnyContent.posts && (
            <PostsInstagram
              influencers={influencers}
              instagramQualitative={instagramQualitative}
            />
          )}

          {isThereAnyContent.story && (
            <StoriesInstagram
              influencers={influencers}
              instagramQualitative={instagramQualitative}
            />
          )}
          <ResultDetailsByInfluencer
            instagramQualitative={instagramQualitative}
            influencers={influencers}
            isThereAnyContent={isThereAnyContent}
          />
        </ContentWrapper>
      </WhiteWrapper>
    </Container>
  );
};
export default InstagramReport;
