import React, { useEffect, useRef, useState } from 'react';
import {
  Container,
  ButtonBack,
  WrapperLoading,
} from './campaignsDownloadPdf.styles';
import Footer from '../../../global/components/footer/footer.components';
import { ICampaignsCompletedLayoutProps } from './campaignsDownloadPdf.types';
import HeaderReport from '../../report/components/headerReport/headerReport.component';
import CampaignRegisterFourLayout from '../register/stepFour/campaignRegisterFour.layout';
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { TailSpin } from 'react-loader-spinner'

const CampaignsDownloadPdfLayout: React.FC<ICampaignsCompletedLayoutProps> = ({ localState, handlers }) => {
  const {
    landscape,
    title,
    brandThumb
  } = localState;

  const { handleBack } = handlers;

  const [test, setTest] = useState<any>(null);
  const [loading, setLoading] = useState<any>(true);

  // console.log(test);

  const componentRef: any = useRef(null);

  const A4_PAPER_DIMENSIONS = {
    width: 900,
    height: 14000,
  };

  const A4_PAPER_RATIO = A4_PAPER_DIMENSIONS.width / A4_PAPER_DIMENSIONS.height;

  interface ImageDimension {
    width: number;
    height: number;
  }

  const imageDimensionsOnA4 = (dimensions: ImageDimension) => {
    const isLandscapeImage = dimensions.width >= dimensions.height;

    // If the image is in landscape, the full width of A4 is used.
    if (isLandscapeImage) {
      return {
        width: A4_PAPER_DIMENSIONS.width,
        height:
          A4_PAPER_DIMENSIONS.width / (dimensions.width / dimensions.height),
      };
    }

    // If the image is in portrait and the full height of A4 would skew
    // the image ratio, we scale the image dimensions.
    const imageRatio = dimensions.width / dimensions.height;
    if (imageRatio > A4_PAPER_RATIO) {
      const imageScaleFactor =
        (A4_PAPER_RATIO * dimensions.height) / dimensions.width;

      const scaledImageHeight = A4_PAPER_DIMENSIONS.height * imageScaleFactor;

      return {
        height: scaledImageHeight,
        width: scaledImageHeight * imageRatio,
      };
    }

    // The full height of A4 can be used without skewing the image ratio.
    return {
      width: A4_PAPER_DIMENSIONS.height / (dimensions.height / dimensions.width),
      height: A4_PAPER_DIMENSIONS.height,
    };
  };

  const htmlToImageConvert = () => {
    html2canvas(componentRef.current, { allowTaint: true, useCORS: true })
      .then((dataUrl: any) => {
        setTest(dataUrl.toDataURL("image/jpeg", 1));
      })
      .catch((err) => {
        console.log('error on catch toJpeg', err);
      });
  };

  const generatePdfFromImages = () => {
    // Default export is A4 paper, portrait, using millimeters for units.
    const img = new jsPDF();
    const imgProps = img.getImageProperties(test)

    const imageDimensions = imageDimensionsOnA4({
      width: imgProps.width,
      height: imgProps.height,
    });

    const doc = new jsPDF({
      orientation: 'p',
      unit: 'px',
      format: [Number(imageDimensions.width.toFixed(0)), Number(imageDimensions.height.toFixed(0))],
      precision: 100,
      floatPrecision: 'smart',
      hotfixes: ['px_scaling'],
    });

    // console.log(imgProps);
    // console.log(imageDimensions);

    doc.addImage(
      test,
      'JPEG',
      0,
      0,
      imageDimensions.width,
      imageDimensions.height);

    doc.save(`campanha-${title}.pdf`);

    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }

  useEffect(() => {
    if (test !== null && loading)
      generatePdfFromImages();

  }, [test]);

  useEffect(() => {
    setTimeout(() => {
      htmlToImageConvert();
    }, 3000);
  }, [])

  return (
    <>
      {loading ?
        <WrapperLoading>
          <span>Gerando PDF...</span>
          <span>Por favor, aguarde!</span>
          {/* <TailSpin
            height="35"
            width="80"
            color="#000"
            ariaLabel="tail-spin-loading"
            radius="1"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
          /> */}
        </WrapperLoading>
        :
        <WrapperLoading>
          <ButtonBack onClick={handleBack}>Voltar</ButtonBack>
          <div style={{ height: 30 }}></div>
          <ButtonBack onClick={generatePdfFromImages}>Gerar PDF novamente</ButtonBack>
        </WrapperLoading>
      }
      <div ref={componentRef}>
        <HeaderReport
          data={{
            landscape,
            title,
            brandThumb
          }}
        />

        <Container>
          <CampaignRegisterFourLayout
            isComplete={true}
            localState={localState}
          />
        </Container>
        <Footer />
      </div>
    </>
  )
}

export default CampaignsDownloadPdfLayout;