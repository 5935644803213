import React from 'react';
import { IConcludedMetricsProps } from './concludedMetrics.types';

import {
  Container,
  Row,
  Label,
  SucceedBox,
  SucceedLabel
} from './concludedMetrics.styles';
import ButtonSecondary from '../../../../global/components/buttons/secondary/buttonSecondary.component';

const ConcludedMetrics: React.FC<IConcludedMetricsProps> = (props) => {

  const {
    label,
    amount,
    onClickEdit,
    onClickDelete
  } = props;


  return (
    <Container>
      <Row>
        <Label>{amount} {label}</Label>
        <SucceedBox>
          <SucceedLabel>Métricas enviadas</SucceedLabel>
        </SucceedBox>
      </Row>


      <Row>
        <ButtonSecondary
          label='Editar métricas'
          isDisabled={false}
          loading={false}
          onClick={onClickEdit}
          customStyle={{
            'width': '145px;',
            'height': '56px;',
            'margin-right': '20px;'
          }}
          customLabelStyle={{
            "font-style": "normal;",
            "font-weight": "400;",
            "font-size": "12px;",
            "line-height": "14px;"
          }}
        />
        <ButtonSecondary
          label='Excluir'
          isDisabled={false}
          loading={false}
          onClick={onClickDelete}
          customStyle={{
            'width': '83px;',
            'height': '56px;',
          }}
          customLabelStyle={{
            "font-style": "normal;",
            "font-weight": "400;",
            "font-size": "12px;",
            "line-height": "14px;"
          }}
        />



      </Row>

    </Container>
  )

}

export default ConcludedMetrics;