import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FetchAllCampaigns } from '../../store/campaigns/campaigns.actions';
import { setCampaignsInfo } from '../../store/campaigns/campaigns.reducer';
import { useAppDispatch } from '../../store/storeHook';
import { ILoadingProps } from '../../utils/globalTypes';

import DraftsLayout from './drafts.layout';

const DraftsController: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [campaigns, setCampaigns] = useState([])
  const [loading, setLoading] = useState({ status: true, type: 'fetching' });

  const toggleLoading = (status: ILoadingProps) => setLoading(status);

  const handleContinueCampaign = (campaign: any) => {
    // console.log("campaign to continue ->", campaign);

    dispatch(setCampaignsInfo({
      ...campaign
    }))

    setTimeout(() => {
      navigate('/campanhas/criar');
    }, 300);
  }

  useEffect(() => {
    FetchAllCampaigns(setCampaigns, toggleLoading);
  }, [])

  return <DraftsLayout
    localState={{
      campaigns,
      loading
    }}
    handlers={{
      handleContinueCampaign
    }}
  />
}
export default DraftsController;