import React, { useState } from 'react';

import { ITextareaProps } from './textarea.types';
import { Container, Label, Input } from './textarea.styles';

const Textarea: React.FC<ITextareaProps> = ({ label, value, onChange, customStyle }) => {

  const [isFocused, setIsFocused] = useState(false)

  return (
    <Container customStyle={customStyle}>
      <Label>{label}</Label>
      <Input
        isFocused={isFocused}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        name='name'
        value={value}
        className='input'
        onChange={(e) => onChange(e.target.value)}
      />
    </Container>
  )

}

export default Textarea;