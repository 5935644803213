import styled from 'styled-components';

export const Container = styled.div`
display: flex;
flex-direction: row;
height: 38px;
background: #FFFFFF;
border-radius: 15px;
justify-content: center;
align-items: center;
padding: 0px 24px;
cursor: pointer;
`
export const SimpleText = styled.span`
font-family: 'Roboto';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 16px;
letter-spacing: 0.1px;
color: #696974;
margin-right: 5px;
`

export const BoldText = styled.span`
font-family: 'Poppins';
font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 21px;
letter-spacing: 0.1px;
color: #44444F;
margin-right: 5px;
`