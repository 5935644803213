/* eslint-disable no-mixed-operators */
/* eslint-disable array-callback-return */
import React from "react";
// import AnalyzesReport from "../../../../global/components/report/analyzes/analyzes.component";
import GlossaryReport from "../../../../global/components/report/glossary/glossary.component";
import InfluencersReport from "../../../../global/components/report/influencers/influencers.component";
import InstagramReport from "../../../../global/components/report/instagram/instagram.component";
import IntroductionReport from "../../../../global/components/report/introduction/introduction.component";
import ScopeReport from "../../../../global/components/report/scope/scope.component";
import TiktokReport from "../../../../global/components/report/tiktok/tiktok.component";
import { setCampaignsInfo } from "../../../../store/campaigns/campaigns.reducer";
import { useAppDispatch } from "../../../../store/storeHook";
import TwitterReport from "../../../../global/components/report/twitter/twitter.component";
import YoutubeReport from "../../../../global/components/report/youtube/youtube.component";
import {
  ICampaignRegisterFourProps,
  IRenderBySessions,
} from "./campaignRegisterFour.types";
import { findIndex } from "lodash";

const CampaignRegisterFour: React.FC<ICampaignRegisterFourProps> = ({
  localState,
  isComplete,
}) => {
  const {
    recap,
    initialDate,
    endDate,
    influencers,
    instagramQualitative,
    twitterQualitative,
    youtubeQualitative,
    tiktokQualitative,
    sections,
  } = localState;

  const dispatch = useAppDispatch();

  const handleGoBack = (stepNumber: number) => {
    dispatch(setCampaignsInfo({ stepNumber }));
  };

  const renderFullSectionsList = [
    "Introdução",
    "Influenciadores",
    "Escopo",
    "Instagram",
    "TikTok",
    "Twitter",
    "Youtube",
    "Glossário",
  ];

  const renderBySessions: IRenderBySessions = {
    "Introdução": () => (
      <IntroductionReport
        editable={!isComplete}
        recap={recap}
        initialDate={initialDate}
        endDate={endDate}
        handleGoBack={handleGoBack}
      />
    ),
    "Influenciadores": () => (
      <InfluencersReport
        editable={!isComplete}
        influencers={influencers}
        handleGoBack={handleGoBack}
      />
    ),
    "Escopo": () => (
      <ScopeReport
        editable={!isComplete}
        handleGoBack={handleGoBack}
        influencers={influencers}
      />
    ),
    "Instagram": () => {
      if (instagramQualitative.qualitativeMetrics.length > 0) {
        return (
          <InstagramReport
            editable={!isComplete}
            influencers={influencers}
            instagramQualitative={instagramQualitative}
            position={
              (sections && findIndex(sections, { name: "Instagram" }) + 1) ||
              4
            }
          />
        );
      }
    },
    "TikTok": () => {
      if (tiktokQualitative.qualitativeMetrics.length > 0) {
        return (
          <TiktokReport
            editable={!isComplete}
            tiktokQualitative={tiktokQualitative}
            influencers={influencers}
            position={
              (sections && findIndex(sections, { name: "TikTok" }) + 1) || 5
            }
          />
        );
      }
    },
    "Twitter": () => {
      if (twitterQualitative.qualitativeMetrics.length > 0) {
        return (
          <TwitterReport
            influencers={influencers}
            twitterQualitative={twitterQualitative}
            position={
              (sections && findIndex(sections, { name: "Twitter" }) + 1) || 6
            }
          />
        );
      }
    },
    "Youtube": () => {
      if (youtubeQualitative.qualitativeMetrics.length > 0) {
        return (
          <YoutubeReport
            influencers={influencers}
            youtubeQualitative={youtubeQualitative}
            position={
              (sections && findIndex(sections, { name: "Youtube" }) + 1) || 7
            }
          />
        );
      }
    },
    "Glossário": () => (
      <GlossaryReport
        position={
          (sections && findIndex(sections, { name: "Glossário" }) + 1) || 8
        }
      />
    ),
  };

  return (
    <>
      {/* <AnalyzesReport
        editable={!isComplete}
        totalStoriesGifted={totalStoriesGifted}
        maximumReach={maximumReach}
        influencers={influencers}
        handleGoBack={handleGoBack}
      /> */}

      {sections
        ? sections.map((item) => {
          return renderBySessions[item.name]();
        })
        : renderFullSectionsList.map((item) => {
          return renderBySessions[item]();
        })}
    </>
  );
};

export default React.memo(CampaignRegisterFour);
