import React, { useMemo, useState, useEffect } from 'react';
import { ICampaignDataTwitterProps } from './campaignDataTwitter.types';

import {
  Container,
  Row,
  Label,
  SucceedBox,
  SucceedLabel,
  ExpandButton,
  Icon,
  ContentHeader,
  EmptyDivider
} from './campaignDataTwitter.styles';
import ButtonSecondary from '../../../../global/components/buttons/secondary/buttonSecondary.component';
import chartsIcon from '../../../../assets/icons/looking_charts.svg';

import twitterLogo from '../../../../assets/icons/twitter_metrics.svg';
import QualityAnalytics from '../qualityAnalytics/qualityAnalytics.component';
import InputComponent from '../../../../global/components/input/input.component';
import { IQualitativeMetricsProps } from '../../../../store/campaigns/campaigns.types';
import InputAreaComponent from 'global/components/inputArea/index.component';

const CampaignDataTwitter: React.FC<ICampaignDataTwitterProps> = (props) => {

  const {
    influencers,
    twitterQualitative,
    getData,
    handleGetInfoBack,
    handleShowGraphics
  } = props;


  const influencersLength = useMemo(() => influencers.filter((item) => item.twitter.isActive).length, [influencers])

  const [expanded, setExpanded] = useState(false);
  const [qualitativeMetrics, setQualitativeMetrics] = useState<any>([]);
  const [overviewCampaign, setOverviewCampaign] = useState(twitterQualitative.overviewCampaign || '');
  const [overviewTweets, setOverviewTweets] = useState(twitterQualitative.overviewTweets || '');

  const toggleExpand = () => {
    if (!influencersLength) return;
    setExpanded(!expanded);
  }

  const handleSetMetrics = (index: number, prop: string, value: React.SetStateAction<string>) => {
    try {
      setQualitativeMetrics(qualitativeMetrics.map((item: any, i: any) => {
        if (i === index) {
          return { ...item, [prop]: value };
        } else {
          return item;
        }
      }));
    } catch (error) {
      console.log('Error on setMetrics ->', error);

    }
  }

  const handleLoadQualitativeMetrics = () => {
    if (twitterQualitative.qualitativeMetrics.length > 0) {
      let newInfluencersArr: any = [];

      if (twitterQualitative.qualitativeMetrics.length !== influencers.filter(item => item.twitter.isActive).length) {

        influencers.filter(item => item.twitter.isActive)
          .map((influencer) => {
            if (!twitterQualitative.qualitativeMetrics.find(item => item.influencerName === influencer.name)) {
              newInfluencersArr.push({
                contentInsights: '',
                analyzeQualitative: '',
                analyzeComments: '',
                linkPubli: '',
                influencerName: influencer.name,
                influencerPhoto: influencer.profilePhoto,
                influencerCluster: influencer.cluster
              })
            }
          })
      }


      setQualitativeMetrics([...twitterQualitative.qualitativeMetrics, ...newInfluencersArr])
    } else {
      let newArr: any = [];

      influencers.map((item) => {
        if (item.twitter.isActive) {
          newArr.push({
            contentInsights: '',
            analyzeQualitative: '',
            analyzeComments: '',
            linkPubli: '',
            influencerName: item.name,
            influencerPhoto: item.profilePhoto,
            influencerCluster: item.cluster
          })
        }
      })

      setQualitativeMetrics(newArr);
    }

  }

  useEffect(() => {
    if (getData)
      handleGetInfoBack({
        twitterQualitative: {
          overviewCampaign,
          overviewTweets,
          qualitativeMetrics
        }
      });
    //eslint-disable-next-line
  }, [getData])

  useEffect(() => {
    handleLoadQualitativeMetrics();
    //eslint-disable-next-line
  }, []);

  return (
    <Container>
      <ContentHeader>
        <Row>
          <Icon
            src={twitterLogo}
            alt='icon-metrics'
          />
          <Label>Twitter</Label>
          <SucceedBox>
            <SucceedLabel>{`${influencersLength} ${influencersLength > 1 ? 'influenciadores' : 'influenciador'}`}</SucceedLabel>
          </SucceedBox>
        </Row>


        <Row>
          {influencersLength > 0 &&
            <ButtonSecondary
              label='Consultar gráficos'
              isDisabled={false}
              loading={false}
              onClick={handleShowGraphics}
              customStyle={{
                'width': '180px;',
                'height': '32px;',
                'margin-right': '40px;'
              }}
              customLabelStyle={{
                "font-style": "normal;",
                "font-weight": "400;",
                "font-size": "12px;",
                "line-height": "14px;"
              }}
              icon={chartsIcon}
            />
          }


          <ExpandButton onClick={toggleExpand}>
            {!expanded ?
              <svg width="16" height="9" viewBox="0 0 16 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M7.99997 9C7.71322 9 7.4265 8.89196 7.20788 8.67633L0.328227 1.88748C-0.109409 1.45562 -0.109409 0.755441 0.328227 0.32376C0.765686 -0.10792 1.4751 -0.10792 1.91277 0.32376L7.99997 6.33091L14.0872 0.32397C14.5249 -0.10771 15.2342 -0.10771 15.6716 0.32397C16.1095 0.755651 16.1095 1.45583 15.6716 1.88768L8.79207 8.67654C8.57334 8.8922 8.28662 9 7.99997 9Z" fill="#A9B8CD" />
              </svg>
              :
              <svg width="16" height="9" viewBox="0 0 16 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8.00003 6.9938e-07C8.28678 6.74311e-07 8.5735 0.108042 8.79212 0.323673L15.6718 7.11252C16.1094 7.54438 16.1094 8.24456 15.6718 8.67624C15.2343 9.10792 14.5249 9.10792 14.0872 8.67624L8.00003 2.66909L1.91279 8.67603C1.47515 9.10771 0.765811 9.10771 0.328387 8.67603C-0.109461 8.24435 -0.109461 7.54417 0.328387 7.11232L7.20793 0.323463C7.42666 0.107797 7.71338 7.24439e-07 8.00003 6.9938e-07Z" fill="#A9B8CD" />
              </svg>
            }
          </ExpandButton>
        </Row>

      </ContentHeader>

      {expanded &&
        <>
          <InputAreaComponent
            label='Panorama geral da campanha'
            value={overviewCampaign}
            onChange={(value) => setOverviewCampaign(value)}
          />
          <InputAreaComponent
            label='Panorama geral dos tweets'
            value={overviewTweets}
            onChange={(value) => setOverviewTweets(value)}
          />

          <EmptyDivider />
          <Label>Análise qualitativa</Label>
          <EmptyDivider />

          {qualitativeMetrics.map((item: IQualitativeMetricsProps, index: number) =>
            <QualityAnalytics
              key={index}
              index={index}
              item={item}
              handleSetMetrics={handleSetMetrics}
            />)}

        </>
      }

    </Container>
  )

}

export default React.memo(CampaignDataTwitter);