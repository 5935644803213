import React from "react";
import {
  Container,
  Topic,
  WhiteWrapper,
  Title,
  Description,
  Label,
  Value,
} from "./glossary.styles";
import { IGlossaryReportProps } from "./glossary.types";

const GlossaryReport: React.FC<IGlossaryReportProps> = ({ position }) => {
  return (
    <Container id="#glossary">
      <Topic>{position}. Glossário</Topic>
      <WhiteWrapper>
        <Title>
          Todos os índices numéricos de comparação são médias dos resultados de
          campanhas que já realizados anteriormente na Mudah.
        </Title>
        <Description>
          Alcance é o número de contas únicas alcançadas, impressões é o número
          de vezes que o conteúdo foi visto e interações é uma soma de curtidas,
          comentários, salvos e demais dados de engajamento.
        </Description>
        <Label>Taxa de Alcance</Label>
        <Value>Número de Alcance/Número de Seguidores</Value>
        <Label>Taxa de Engajamento - Feed</Label>
        <Value>Total de interações/Número de Alcance</Value>
        <Label>Taxa de Engajamento – Reels e Tiktok</Label>
        <Value>Total de interações/views</Value>
        <Label>Taxa de Views</Label>
        <Value>Número de Views/Número de Seguidores</Value>
        <Label>Alcance Máximo</Label>
        <Value>
          O maior número de alcance que o influenciador entregou entre todos os
          posts realizados
        </Value>
      </WhiteWrapper>
    </Container>
  );
};
export default GlossaryReport;
