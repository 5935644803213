import React from "react";
import {
  HeaderCell,
  RowCell,
  TableContainer,
  TableHeader,
  TableRow,
} from "./index.styles";
import { IInstagramGeneralFeedInfoProps } from "./index.types";

const TwitterTableGeneralInfo: React.FC<IInstagramGeneralFeedInfoProps> = ({
  rows,
  scroll = false
}) => {
  return (
    <TableContainer scroll={scroll}>
      <TableHeader scroll={scroll}>
        {Object.keys(rows[0]).map((item, index) => {
          return (
            <HeaderCell key={index} width={'90px'}>
              {item}
            </HeaderCell>
          );
        })}
      </TableHeader>
      {rows.map((item, index) => {
        const cells = Object.values(item);
        return (
          <TableRow key={index} numberRow={index} lastItem={index + 1 === rows.length}>
            {cells.map((c: any, index) => {
              return <RowCell key={index} width={"auto"} >{c}</RowCell>
            })}
          </TableRow>
        );
      })}
    </TableContainer>
  );
};
export default TwitterTableGeneralInfo;
