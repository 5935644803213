import styled from 'styled-components'

export const Container = styled.div`
  margin-top: 68px;
  padding: 0px 21px 0px 21px;
`

export const TopicContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`

export const Topic = styled.h1`
  margin-left: 32px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  color: #23314b;
`

export const WhiteWrapper = styled.div`
  display: inline-flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 15px;
  background: #ffffff;
  border-radius: 16px;
  margin-bottom: 40px;
  padding: 36px 32px;
  width: 100%;
  @media(max-width: 420px) {
    width: 100%;
   flex-direction: column;
  }
`

export const RecapWrapper = styled.div`
  width: 60%;
  border-right: 1.5px solid #ebeff2;
  padding-right: 32px;
  @media(max-width: 420px) {
   width: 100%;
   border:none;
  }
`

export const Subtitle = styled.p`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  color: #23314b;
  margin-bottom: 10px;
`

export const Description = styled.span`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #809fb8;
`

export const PeriodWrapper = styled.div`
  display: inline-flex;
  flex-direction: column;
  padding-left: 73px;
  width: 40%;
  @media(max-width: 420px) {
    margin-top: 20px;
    padding:0;
    width: 100%;
  }
`
