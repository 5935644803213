import React from 'react';
import {
  Container,
  Icon,
  LabelButtonAddCampaign,
} from './brandCard.styles'
import { IBrandCardProps } from './brandCard.types';

const BrandCard: React.FC<IBrandCardProps> = ({ item, handleDetails }) => {
  return (
    <Container onClick={() => handleDetails(item)}>
      <Icon src={item.thumb} alt="logo-marca" />
      <LabelButtonAddCampaign>
        {item.brandName}
      </LabelButtonAddCampaign>
    </Container>
  )
}

export default BrandCard;