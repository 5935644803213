import React, { useState } from 'react';
import {
  Title,
  InstagramDetails,
  LineDivider,
  Row,
  Icon,
  Subtitle,
  SpacedRow
} from './tiktokMetrics.styles';

import tiktokLogo from '../../../../assets/icons/tiktok_metrics.svg';

import { ITiktokMetricsComponentProps } from './tiktokMetrics.types';
import ButtonSecondary from '../../../../global/components/buttons/secondary/buttonSecondary.component';
import ButtonPrimary from '../../../../global/components/buttons/primary/buttonPrimary.component';
import TiktokMetricsItems from '../tiktokMetricsItems/tiktokMetricsItems.component';
import { ITiktokMetricsProps } from '../../../../store/campaigns/campaigns.types';


const TiktokMetrics: React.FC<ITiktokMetricsComponentProps> = (props) => {
  const {
    value,
    onChange,
    toggleModalVisible
  } = props;

  const [metrics, setMetrics] = useState<ITiktokMetricsProps[]>(
    value.tiktok.metrics.length > 0 ? [
      ...value.tiktok.metrics,
      ...new Array(Number(value.tiktok.numberOfPosts) - value.tiktok.metrics.length).fill({
        reach: '',
        likes: '',
        comments: '',
        forwards: '',
        totalReprodution: '',
        views: '',
        retention: '',
        postLink: '',
        avgWatchedTime: '',
        watchedFullVideo: '',
        saved: '',
      })
    ] :
      new Array(Number(value.tiktok.numberOfPosts)).fill({
        reach: '',
        likes: '',
        comments: '',
        forwards: '',
        totalReprodution: '',
        views: '',
        retention: '',
        postLink: '',
        avgWatchedTime: '',
        watchedFullVideo: '',
        saved: '',
      })
  )

  const handleSetMetrics = (index: number, prop: string, value: React.SetStateAction<string>) => {
    try {
      setMetrics(metrics.map((item, i) => {
        if (i === index) {
          return { ...item, [prop]: value };
        } else {
          return item;
        }
      }));
    } catch (error) {
      console.log('Error on setMetrics ->', error);

    }
  }

  const handleSaveMetrics = () => {
    onChange({
      influencerData: {
        ...value,
        tiktok: {
          ...value.tiktok,
          metrics: metrics
        }
      }
    })
    setTimeout(() => {
      toggleModalVisible()
    }, 200);
  }


  return (
    <InstagramDetails>
      <Row>
        <Icon
          src={tiktokLogo}
          alt='icon-metrics-tiktok'
        />
        <Title>Métricas TikTok</Title>
      </Row>
      <Subtitle>
        {`Preencha os resultados detalhados por post (${value.tiktok.numberOfPosts} ${parseInt(value.tiktok.numberOfPosts) > 1 ? 'posts' : 'post'})`}
      </Subtitle>

      <LineDivider />


      {metrics.map((item, index) =>
        <TiktokMetricsItems
          key={index}
          index={index}
          item={item}
          handleSetMetrics={handleSetMetrics}
        />)}
      <SpacedRow>
        <ButtonSecondary
          label='Cancelar'
          isDisabled={false}
          loading={false}
          onClick={toggleModalVisible}
          customStyle={{
            'margin-top': '25px;',
            'width': '388px;',
            'height': '56px;'
          }}
        />

        <ButtonPrimary
          label='Salvar métricas'
          isDisabled={false}
          loading={false}
          onClick={handleSaveMetrics}
          customStyle={{
            'margin-top': '25px;',
            'width': '388px;',
            'height': '56px;'
          }}
        />
      </SpacedRow>
    </InstagramDetails>

  )
}

export default TiktokMetrics;