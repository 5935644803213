import bg from '../../assets/backgrounds/bg.jpg';
import logo from '../../assets/logo/mudah_logo.svg';
import logoColored from '../../assets/logo/mudah_logo_colored.svg';
import faqIcon from '../../assets/icons/faq.svg';
import Input from '../../global/components/input/input.component';
import Checkbox from '../../global/components/checkbox/checkbox.component';
import ButtonPrimary from '../../global/components/buttons/primary/buttonPrimary.component';
import { ISigninProps } from './signIn.types';

import {
  Container,
  LSWrapper,
  LogoColored,
  Title,
  Subtitle,
  FooterLabel,
  RSWrapper,
  ButtonFAQ,
  ButtonFAQLabel,
  FAQLogo,
  RSLogo,
  RSBackground
} from './signIn.styles';
import CustomModal from '../../global/components/modal/modal.components';

const SignInLayout = ({ localState, handlers }: ISigninProps) => {

  const {
    email,
    password,
    keepSignedIn,
    buttonDisabled,
    loading
  } = localState;

  const {
    setEmail,
    setPassword,
    setKeepSignedIn,
    handleSignIn
  } = handlers;
  return (
    <Container>
      <CustomModal />
      <LSWrapper>

        <LogoColored src={logoColored} alt='mudah-logo-colored' />

        <Title>
          Bem-vindo ao <br />
          mudah performance <br />
          analytics
        </Title>

        <Subtitle>
          Preencha os campos abaixo e acesse sua conta
        </Subtitle>

        <Input
          label='Email'
          value={email}
          onChange={setEmail}
        />

        <Input
          label='Senha'
          type='password'
          value={password}
          onChange={setPassword}
        />

        <Checkbox
          label='Manter conectado'
          checked={keepSignedIn}
          onClick={setKeepSignedIn}
        />

        <ButtonPrimary
          label='Acessar'
          onClick={handleSignIn}
          isDisabled={buttonDisabled}
          loading={loading.status}
        />

        <FooterLabel>
          © Mudah 2023. Todos os direitos reservados
        </FooterLabel>
      </LSWrapper>

      <RSWrapper>
        <RSBackground
          style={{
            flex: 1,
            backgroundImage: `url(${bg})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
          }}>
          <ButtonFAQ>
            <FAQLogo src={faqIcon} alt='faq-icon' />
            <ButtonFAQLabel>Duvidas ?<span> Fale conosco</span></ButtonFAQLabel>
          </ButtonFAQ>
          <RSLogo src={logo} alt='mudah-logo' />
        </RSBackground>
      </RSWrapper>
    </Container>
  )
}

export default SignInLayout;