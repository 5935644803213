import React from 'react';
import GlobalRouter from './routes/index';
import GlobalStyles from './global/styles/globalStyles';
import { ThemeProvider } from 'styled-components';
import light from './global/styles/themes/light';
import { Provider } from 'react-redux';
import { store } from './store/index';
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';
import './index.css';

const App: React.FC = () => {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistStore(store)}>
        <ThemeProvider theme={light}>
          <GlobalStyles />
          <GlobalRouter />
        </ThemeProvider>
      </PersistGate>
    </Provider>
  );
}

export default App;
