import styled from 'styled-components';

export const Container = styled.div`
min-height:100vh;
padding:25px;
`

export const HeaderTitleBox = styled.div`
display: flex;
justify-content: space-between;
align-items: center;
`

export const Title = styled.h1`
font-family: 'Poppins';
font-weight: 600;
font-size: 24px;
color: #23314B;
`

export const Row = styled.div`
display:flex;
justify-content: center;
align-items: center;
flex-direction: row;
`

export const ContainerListBrand = styled.div`
display:flex;
flex-direction: row;
flex-wrap: wrap;
`

export const ButtonAddCampaing = styled.div`
width: 256px;
height: 141px;
background: linear-gradient(125.26deg, #8363F3 9.14%, #4E2EC3 96.81%);
border-radius: 12px;

margin: 40px 20px 0 0;
display: flex;
justify-content: center;
align-items: center;
cursor:pointer;
transition: all .3s;

&:hover{
  opacity: .9;
  transform: translateX(10px);
};
`
export const IconBox = styled.div`
display: flex;
justify-content: center;
align-items: center;
`

export const IconBg = styled.div`
width: 86px;
height: 86px;
border-radius: 50px;
background-color: hsl(234, 92%, 80%, 0.15);
display: flex;
justify-content: center;
align-items: center;
margin:17px;
`

export const Icon = styled.img`
width: 32px;
height: 32px;
margin:40px;
`

export const LabelButtonAddCampaign = styled.h5`
font-family: 'Poppins';
font-style: normal;
font-weight: 600;
font-size: 16px;
text-align: center;
color: #FFFFFF;
`