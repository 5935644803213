import { Axis, Chart, Interval, Legend } from "bizcharts";
import React from "react";
import { IChartKpisProps } from "./chart.types";

const ChartKpis: React.FC<IChartKpisProps> = ({ data, isPerc }) => {
  return (
    <Chart height={320} data={data} autoFit>
      <Interval
        adjust={[
          {
            type: "dodge",
            marginRatio: 0.2,
          },
        ]}
        size={45}
        color={[
          "name",
          (name) => (name === "Média do cluster" ? "#4A2C6E" : "#FF4141"),
        ]}
        position="label*value"
      />
      {isPerc && (
        <Axis name="value" label={{ formatter: (val) => `${val}%` }} />
      )}
      <Legend visible={false} />
    </Chart>
  );
};
export default ChartKpis;
