import React from "react";
import Tiktok from "../../../../assets/icons/tiktok_white.svg";
import Img from "../../../../assets/img/tiktokBG.png";
import PostsTikTok from "./components/posts/index/index.component";
import ResultDetailsByInfluencer from "./components/resultDetailsByInfluencer/index/index.component";
import {
  Container,
  ContentWrapper,
  Description,
  Icon,
  InstagramTitle,
  TikTokTopContainer,
  TikTokWrapper,
  Title,
  Topic,
  TopicContent,
  WhiteWrapper,
} from "./tiktok.styles";
import { ITiktokReportProps } from "./tiktok.types";
import EditIcon from "../../../../assets/icons/edit.svg";
import ButtonSecondary from "../../buttons/secondary/buttonSecondary.component";

const TiktokReport: React.FC<ITiktokReportProps> = (props) => {
  const { editable, tiktokQualitative, influencers, position } = props;

  return (
    <Container id="#tiktok">
      <TopicContent>
        <Topic>{position}. TikTok</Topic>
        {editable && (
          <ButtonSecondary
            label="Editar Informações"
            isDisabled={false}
            loading={false}
            onClick={() => null}
            icon={EditIcon}
            customStyle={{ width: "180px", height: "32px" }}
            customLabelStyle={{
              fontSize: "13px",
              lineHeight: "14px",
              fontWeight: "400",
              fontFamily: "Montserrat",
            }}
          />
        )}
      </TopicContent>
      <WhiteWrapper>
        <TikTokTopContainer
          style={{
            backgroundImage: `url(${Img})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
          }}
        >
          <TikTokWrapper>
            <Icon src={Tiktok} />
            <InstagramTitle>Performance no TikTok</InstagramTitle>
          </TikTokWrapper>
        </TikTokTopContainer>
        <ContentWrapper>
          <Title>Panorama geral da campanha</Title>
          <Description>{tiktokQualitative.overviewCampaign}</Description>

          <PostsTikTok
            influencers={influencers}
            tiktokQualitative={tiktokQualitative}
          />
          <ResultDetailsByInfluencer
            influencers={influencers}
            tiktokQualitative={tiktokQualitative}
          />
        </ContentWrapper>
      </WhiteWrapper>
    </Container>
  );
};
export default TiktokReport;
