import React, { useMemo } from "react";
import TopThreeFeed from "../topThreeFeed/feedInstagram.component";
import { Container, Description, Title } from "./posts.styles";
import { IPostsInstagramProps } from "./posts.types";
import PostsByInfluencer from "../postsByInfluencer/postsByInfluencer.component";
import lodash from 'lodash';
import { maskNumbers, validateIndividualRangeRateReach, validateMathMax, validateRangeRateEngagementInstagram, validateRangeRateViewsInstagram } from "../../../../../../../utils/validations";
import InstagramGeneralFeedInfo from "../../posts/tableGeneralFeedInfo/index.component";

const PostsInstagram: React.FC<IPostsInstagramProps> = (props) => {
  const {
    influencers,
    instagramQualitative
  } = props;

  const generalInfo = useMemo(() => {
    let allReachs: number[] = [];
    let allImpressions: number[] = [];
    let allViews: number[] = [];
    let allIterations: number[] = [];

    let alcancesIndividuaisGerais: number[] = [];

    let allEngagementRate: number[] = [];

    influencers.filter((item) => item.instagram.isActive)
      .map((influencer) => {
        //taxa de alcance individual
        let alcancesIndividual: number[] = [];
        let allIterationsIndividual: number[] = [];

        influencer.instagram.metricsPosts.map((metric) => {
          alcancesIndividual.push(parseInt(metric.reach || '0'));

          allReachs.push(parseInt(metric.reach || '0'))

          allImpressions.push(parseInt(metric.impressions || '0'));
          allViews.push(parseInt(metric.views || '0'));

          allIterations.push(parseInt(metric.likes || '0'));
          allIterations.push(parseInt(metric.comments || '0'));
          allIterations.push(parseInt(metric.saved || '0'));
          allIterations.push(parseInt(metric.forwards || '0'));

          allIterationsIndividual.push(parseInt(metric.likes || '0'));
          allIterationsIndividual.push(parseInt(metric.comments || '0'));
          allIterationsIndividual.push(parseInt(metric.saved || '0'));
          allIterationsIndividual.push(parseInt(metric.forwards || '0'));

        });

        alcancesIndividuaisGerais.push(validateIndividualRangeRateReach(alcancesIndividual, influencer.instagram.followers))
        allEngagementRate.push(validateRangeRateEngagementInstagram(allIterationsIndividual, alcancesIndividual))
      });


    let rangeRate = lodash.sum(alcancesIndividuaisGerais) / alcancesIndividuaisGerais.length;
    let engagementRate = lodash.sum(allEngagementRate) / allEngagementRate.length

    // console.log('Todos os alcances', allReachs);//check
    // console.log('Todas as taxas de alcance individuais', alcancesIndividuaisGerais);//check
    // console.log('allImpressions', allImpressions);//check
    // console.log('allViews', allViews);//check
    // console.log('allIterations', allIterations);//check
    // console.log('todas as taxas de engajamento', allIterations);//check

    return [
      {
        "Alcance máximo": maskNumbers(validateMathMax(allReachs).toString()),
        "Taxa de alcance": `${Number.isInteger(rangeRate) ? rangeRate : rangeRate.toFixed(2)}%`,
        "Impressões totais": maskNumbers(lodash.sum(allImpressions).toString()),
        "Views totais (Reels)": maskNumbers(lodash.sum(allViews).toString()),
        "Interações": maskNumbers(lodash.sum(allIterations).toString()),
        "Taxa de engajamento": `${Number.isInteger(engagementRate) ? engagementRate : engagementRate.toFixed(2)}%`,
      },
    ]
    //eslint-disable-next-line
  }, [influencers]);

  const postsByInfluencer = useMemo(() => {

    let newArrInfluencers: any = [];

    influencers.filter((item) => item.instagram.isActive)
      .map((influencer) => {
        //cada rodada disso aqui é um influencer

        //alcance maximo
        let highestReach: number[] = [];

        //impressoes totais
        let totalImpressions: number[] = [];

        //interações totais
        let allInterations: number[] = [];

        //total views
        let totalViews: number[] = [];

        influencer.instagram.metricsPosts.map((metric) => {

          //alcance maximo
          highestReach.push(parseInt(metric.reach || '0'));

          //impressoes totais
          totalImpressions.push(parseInt(metric.impressions || '0'));

          //interacoes totais
          allInterations.push(parseInt(metric.likes || '0'));
          allInterations.push(parseInt(metric.comments || '0'));
          allInterations.push(parseInt(metric.saved || '0'));
          allInterations.push(parseInt(metric.forwards || '0'));

          //total views
          totalViews.push(parseInt(metric.views || '0'));
        })

        let maxReachIndividual = validateIndividualRangeRateReach(highestReach, influencer.instagram.followers);
        let engagementIndividual = validateRangeRateEngagementInstagram(allInterations, highestReach);
        let viewsIndividual = validateRangeRateViewsInstagram(totalViews, influencer.instagram.followers);

        newArrInfluencers.push({
          Influenciador: influencer,
          "Alcance máximo": maskNumbers(validateMathMax(highestReach).toString()),
          "Taxa de alcance": `${Number.isInteger(maxReachIndividual) ? maxReachIndividual : maxReachIndividual.toFixed(2)}%`,
          "Impressões totais": maskNumbers(lodash.sum(totalImpressions).toString()),
          "Interações totais": maskNumbers(lodash.sum(allInterations).toString()),
          Posts: maskNumbers(highestReach.length.toString()),
          "Média de interações": maskNumbers(allInterations.length === 0 ? '0' : (lodash.sum(allInterations) / highestReach.length).toString()),
          "Taxa de engaj.": `${Number.isInteger(engagementIndividual) ? engagementIndividual : engagementIndividual.toFixed(2)}%`,
          Views: maskNumbers(lodash.sum(totalViews).toString()),
          "Taxa de views": `${Number.isInteger(viewsIndividual) ? viewsIndividual : viewsIndividual.toFixed(2)}%`,
        })
      })


    return newArrInfluencers;
    //eslint-disable-next-line
  }, [influencers])

  return (
    <Container>
      <Title>Posts em Feed</Title>
      <InstagramGeneralFeedInfo rows={generalInfo} />
      <PostsByInfluencer data={postsByInfluencer} />
      <Description>{instagramQualitative.overviewFeed}</Description>
      <TopThreeFeed influencers={influencers} />
    </Container>
  );
};
export default PostsInstagram;
