import {
  AnyAction,
  configureStore,
  ThunkAction,
  combineReducers,
} from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';
import logger from 'redux-logger';

import userReducer from './user/user.reducer';
import modalReducer from './modal/modal.reducer';
import brandsReducer from './brands/brands.reducer';
import campaignsReducer from './campaigns/campaigns.reducer';

const persistConfig = {
  key: 'root',
  storage
}

const reducers = combineReducers({
  userReducer,
  modalReducer,
  brandsReducer,
  campaignsReducer
});

const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: {
      ignoreActions: true
    }
  }).concat(logger)
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  AnyAction>