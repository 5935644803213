import styled from 'styled-components';

export const Container = styled.div`
min-height: 100vh;
padding:25px;
`

export const HeaderTitleBox = styled.div`
display: flex;
justify-content: space-between;
align-items: center;
`

export const Title = styled.h1`
font-family: 'Poppins';
font-weight: 600;
font-size: 24px;
color: ${props => props.theme.colors.dark};
`

export const Row = styled.div`
display:inline-flex;
justify-content: center;
align-items: center;
flex-direction: row;
`

export const ListBoxHeader = styled.div`
display: flex;
flex-direction: row;
margin-top: 45px;
`
interface IHeaderTitleProps {
  size: number;
}

export const HeaderTitle = styled.span<IHeaderTitleProps>`
display: flex;
flex:${props => props.size};
justify-content: center;
align-items: center;
font-family: 'Poppins';
font-weight: 600;
font-size: 14px;
line-height: 18px;
color: ${props => props.theme.colors.dark};
`

export const ListScroll = styled.div`
overflow-y: scroll;
`
export const ListItemBox = styled.div`
display: flex;
flex-direction: row;
background-color: ${props => props.theme.colors.white};
height: 88px;
width: auto;
border-radius: 12px;
margin-top:14px;
`

interface IItemLabelsProps {
  size: number;
  customStyle?: any;
}

export const ItemLabels = styled.span<IItemLabelsProps>`
display: flex;
flex:${props => props.size};
justify-content: center;
align-items: center;
font-family: 'Poppins';
font-weight: 400;
font-size: 14px;
line-height: 18px;
color: ${props => props.theme.colors.dark};
${props => props.customStyle}
`
export const ItemBox = styled.div<IItemLabelsProps>`
display: flex;
flex:${props => props.size};
justify-content: center;
align-items: center;
`

export const ItemButton = styled.button`
background:none;
padding:10px;
`

export const ProfilePhoto = styled.img`
height: 60px;
width: 60px;
margin-right: 20px;
margin-left: 65px;
border-radius: 37px;
object-fit: cover;
`
interface IStatusBulletProps {
  active: boolean;
}

export const StatusBullet = styled.div<IStatusBulletProps>`
height: 14px;
width: 14px;
background-color: ${props => props.active ? '#3BC5CB' : '#FF4141'};
border-radius: 7px;
margin-right: 9px;
`

export const PaginationBox = styled.div`
display:flex;
flex-direction: row;
justify-content: center;
align-items: center;
margin-top: 60px;
`

export const PaginationButton = styled.button`
background: none;
padding:10px;
margin: 0px 10px 0px 10px;
`

export const PaginationPage = styled.button`
width: 36px;
height: 36px;
background: ${props => props.theme.colors.white};
border-radius: 4px;
display: flex;
justify-content: center;
align-items: center;
font-family: 'Poppins';
font-weight: 500;
font-size: 12px;
color: ${props => props.theme.colors.darkGreen};
margin:0px 5px 0px 5px;
`

export const PaginationPageActive = styled.button`
cursor: not-allowed;
width: 36px;
height: 36px;
background: ${props => props.theme.colors.purple};
border-radius: 30px;
display: flex;
justify-content: center;
align-items: center;
font-family: 'Poppins';
font-weight: 500;
font-size: 12px;
color: ${props => props.theme.colors.white};
margin:0px 5px 0px 5px;
`