import React, { useState } from 'react';
import {
  Title,
  InstagramDetails,
  LineDivider,
  Row,
  Icon,
  Subtitle,
  SpacedRow
} from './instagramStoryMetrics.styles';

import instagramLogo from '../../../../assets/icons/instagram_metrics.svg';
import { IInstagramStoryMetricsProps } from './instagramStoryMetrics.types';
import ButtonSecondary from '../../../../global/components/buttons/secondary/buttonSecondary.component';
import ButtonPrimary from '../../../../global/components/buttons/primary/buttonPrimary.component';
import InstagramStoryItems from '../instagramStoryItems/instagramStoryItems.component';
import { IInstagramStoriesMetricsProps } from '../../../../store/campaigns/campaigns.types';


const InstagramStoryMetrics: React.FC<IInstagramStoryMetricsProps> = (props) => {
  const {
    value,
    onChange,
    toggleModalVisible
  } = props;

  const [metrics, setMetrics] = useState<IInstagramStoriesMetricsProps[]>(
    value.instagram.metricsStories.length > 0 ? [
      ...value.instagram.metricsStories,
      ...new Array(Number(value.instagram.storiesDelivered) - Number(value.instagram.metricsStories.length)).fill({
        reach: '',
        impressions: '',
        linkClicks: '',
        clickOnBrandLink: '',
        replies: '',
        shares: '',
        visitsOnProfile: ''
      })
    ] :
      new Array(Number(value.instagram.storiesDelivered)).fill({
        reach: '',
        impressions: '',
        linkClicks: '',
        clickOnBrandLink: '',
        replies: '',
        shares: '',
        visitsOnProfile: ''
      })
  )

  const handleSetMetrics = (index: number, prop: string, value: React.SetStateAction<string>) => {
    try {
      setMetrics(metrics.map((item, i) => {
        if (i === index) {
          return { ...item, [prop]: value };
        } else {
          return item;
        }
      }));
    } catch (error) {
      console.log('Error on setMetrics ->', error);

    }
  }

  const handleSaveMetrics = () => {
    onChange({
      influencerData: {
        ...value,
        instagram: {
          ...value.instagram,
          metricsStories: metrics
        }
      }
    })
    setTimeout(() => {
      toggleModalVisible()
    }, 200);
  }


  return (
    <InstagramDetails>
      <Row>
        <Icon
          src={instagramLogo}
          alt='icon-metrics-instagram'
        />
        <Title>Métricas Instagram Stories</Title>
      </Row>
      <Subtitle>
        {`Preencha os resultados detalhados por story (${value.instagram.storiesDelivered} stories)`}
      </Subtitle>

      <LineDivider />


      {metrics.map((item, index) => <InstagramStoryItems
        key={index}
        index={index}
        item={item}
        handleSetMetrics={handleSetMetrics}
      />)}
      <SpacedRow>
        <ButtonSecondary
          label='Cancelar'
          isDisabled={false}
          loading={false}
          onClick={toggleModalVisible}
          customStyle={{
            'margin-top': '25px;',
            'width': '388px;',
            'height': '56px;'
          }}
        />

        <ButtonPrimary
          label='Salvar métricas'
          isDisabled={false}
          loading={false}
          onClick={handleSaveMetrics}
          customStyle={{
            'margin-top': '25px;',
            'width': '388px;',
            'height': '56px;'
          }}
        />
      </SpacedRow>
    </InstagramDetails>

  )
}

export default InstagramStoryMetrics;