import React from 'react';
import {
  Title,
  InstagramDetails,
  PanelContent,
  PanelBody,
  Divider,
  LineDivider
} from './instagramDetails.styles';
import InputComponent from '../../../../global/components/input/input.component';
import ButtonSecondary from '../../../../global/components/buttons/secondary/buttonSecondary.component';
import ConcludedMetrics from '../concludedMetrics/concludedMetrics.component';
import { IInstagramDetailsProps } from './instagramDetails.types';
import { onlyNumbers } from 'utils/validations';


const InstagramDetailsComponent: React.FC<IInstagramDetailsProps> = (props) => {
  const {
    value,
    onChange,
    toggleModalPosts,
    toggleModalStories,
  } = props;
  return (
    <InstagramDetails>
      <Title>Feed</Title>
      <PanelContent>
        <PanelBody>
          <InputComponent
            label='@ do influenciador'
            value={value.instagram.idInstagram}
            onChange={(idInstagram) => onChange({
              influencerData: {
                ...value,
                instagram: {
                  ...value.instagram,
                  idInstagram
                }
              }
            })}
          />
        </PanelBody>
        <Divider />
        <PanelBody>
          <InputComponent
            label='Número de seguidores'
            value={value.instagram.followers}
            onChange={(followers) => onChange({
              influencerData: {
                ...value,
                instagram: {
                  ...value.instagram,
                  followers: onlyNumbers(followers.toString())
                }
              }
            })
            }
          />
        </PanelBody>
        <Divider />
        <PanelBody>
          <InputComponent
            label='Número de posts'
            value={value.instagram.numberOfPosts}
            onChange={(numberOfPosts) => onChange({
              influencerData: {
                ...value,
                instagram: {
                  ...value.instagram,
                  numberOfPosts: onlyNumbers(numberOfPosts.toString())
                }
              }
            })}
          />
        </PanelBody>
        <Divider />
        <PanelBody>
          <ButtonSecondary
            label='Inserir métricas'
            isDisabled={false}
            loading={false}
            onClick={toggleModalPosts}
            customStyle={{
              'margin-top': '52.5px;',
              'width': '296px;',
              'height': '56px;',
            }}
            customLabelStyle={{
              "font-style": "normal;",
              "font-weight": "400;",
              "font-size": "12px;",
              "line-height": "14px;"
            }}
          />
        </PanelBody>
      </PanelContent>

      {value.instagram.metricsPosts.length > 0 &&
        <ConcludedMetrics
          label='Posts'
          amount={value.instagram.numberOfPosts}
          onClickEdit={toggleModalPosts}
          onClickDelete={() => onChange({
            influencerData: {
              ...value,
              instagram: {
                ...value.instagram,
                metricsPosts: []
              }
            }
          })}
        />

      }

      <LineDivider />

      <PanelContent>
        <PanelBody>
          <InputComponent
            label='Stories contratados'
            value={value.instagram.storiesSigned}
            onChange={(storiesSigned) => onChange({
              influencerData: {
                ...value,
                instagram: {
                  ...value.instagram,
                  storiesSigned: onlyNumbers(storiesSigned.toString())
                }
              }
            })}
          />
        </PanelBody>
        <Divider />
        <PanelBody>
          <InputComponent
            label='Stories entregues'
            value={value.instagram.storiesDelivered}
            onChange={(storiesDelivered) => onChange({
              influencerData: {
                ...value,
                instagram: {
                  ...value.instagram,
                  storiesDelivered: onlyNumbers(storiesDelivered.toString())
                }
              }
            })}
          />
        </PanelBody>
        <Divider />
        <PanelBody>
          <ButtonSecondary
            label='Inserir métricas'
            isDisabled={false}
            loading={false}
            onClick={toggleModalStories}
            customStyle={{
              'margin-top': '52.5px;',
              'width': '296px;',
              'height': '56px;',
            }}
            customLabelStyle={{
              "font-style": "normal;",
              "font-weight": "400;",
              "font-size": "12px;",
              "line-height": "14px;"
            }}
          />
        </PanelBody>
      </PanelContent>
      {value.instagram.metricsStories.length > 0 &&
        <ConcludedMetrics
          label='Stories entregues'
          amount={value.instagram.storiesDelivered}
          onClickEdit={toggleModalStories}
          onClickDelete={() => onChange({
            influencerData: {
              ...value,
              instagram: {
                ...value.instagram,
                metricsStories: []
              }
            }
          })}
        />
      }
    </InstagramDetails>

  )
}

export default InstagramDetailsComponent;