import React from 'react';
import {
  YoutubeDetails,
  PanelContent,
  PanelBody,
  Divider,
} from './youtubeDetails.styles';
import InputComponent from '../../../../global/components/input/input.component';
import ButtonSecondary from '../../../../global/components/buttons/secondary/buttonSecondary.component';
import ConcludedMetrics from '../concludedMetrics/concludedMetrics.component';
import { IInfluencerProps } from '../../../../store/campaigns/campaigns.types';
import { onlyNumbers } from 'utils/validations';

interface IYoutubeDetailsProps {
  value: IInfluencerProps;
  onChange: (item: any) => void;
  toggleModalVisible: () => void;
}

const YoutubeDetailsComponent: React.FC<IYoutubeDetailsProps> = (props) => {
  const {
    value,
    onChange,
    toggleModalVisible
  } = props;
  return (
    <YoutubeDetails>
      <PanelContent>
        <PanelBody>
          <InputComponent
            label='Número de videos'
            value={value.youtube.numberOfVideos}
            onChange={(numberOfVideos) => onChange({
              influencerData: {
                ...value,
                youtube: {
                  ...value.youtube,
                  numberOfVideos
                }
              }
            })}
          />
        </PanelBody>
        <Divider />
        <PanelBody>
          <InputComponent
            label='Número de inscritos'
            value={value.youtube.followers}
            onChange={(followers) => onChange({
              influencerData: {
                ...value,
                youtube: {
                  ...value.youtube,
                  followers: onlyNumbers(followers.toString())
                }
              }
            })}
          />
        </PanelBody>
        <Divider />
        <ButtonSecondary
          label='Inserir métricas'
          isDisabled={false}
          loading={false}
          onClick={toggleModalVisible}
          customStyle={{
            'margin-top': '52.5px;',
            'width': '296px;',
            'height': '56px;',
          }}
          customLabelStyle={{
            "font-style": "normal;",
            "font-weight": "400;",
            "font-size": "12px;",
            "line-height": "14px;"
          }}
        />
      </PanelContent>
      {value.youtube.metrics.length > 0 &&
        <ConcludedMetrics
          label='Videos'
          amount={value.youtube.numberOfVideos}
          onClickEdit={toggleModalVisible}
          onClickDelete={() => onChange({
            influencerData: {
              ...value,
              youtube: {
                ...value.youtube,
                metrics: []
              }
            }
          })}
        />
      }
    </YoutubeDetails>
  )
}

export default YoutubeDetailsComponent;