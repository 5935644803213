import styled from 'styled-components'

interface ILogoImgProps {
  width: number
  height: number
}

interface IMenuItemLinkProps {
  isActive: boolean
}

interface IIconBoxProps {
  isActive: boolean
}

export const Container = styled.div`
  grid-area: SM;
  background-color: ${(props) => props.theme.colors.darkGray};
  /* position: fixed; */
  width: 259px;
  overflow-y: scroll;

  ::-webkit-scrollbar {
    width: 5px;
  }

  /* @media(max-width: 420px) {
    display: none;
  } */
`

export const Header = styled.header`
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 34px;
`

export const LogoImg = styled.img<ILogoImgProps>`
  height: ${(props) => props.height}px;
  width: ${(props) => props.width}px;
`

export const MenuContainer = styled.div`
  display: flex;
  height: 100vh;
  flex-direction: column;
  margin-top: 50px;
  padding-left: 38px;
  /* justify-content: center; */
  overflow: auto;
`

export const MenuItemLink = styled.a<IMenuItemLinkProps>`
  color: ${(props) => (props.isActive ? '#FF4141' : props.theme.colors.white)};
  font-family: 'Poppins';
  font-weight: 500;
  font-size: 18px;
  text-decoration: none;
  transition: opacity 0.3s;
  margin: 23px 0;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
`
export const IconBox = styled.div<IIconBoxProps>`
  background: ${(props) => (props.isActive ? '#FF4141' : '#37474E')};
  border-radius: 10px;
  height: 36px;
  width: 36px;
  display: inline-flex;
  justify-content: center;
  margin-right: 15px;
  > p {
    font-size: 14px;
    font-family: 'Poppins';
    color: ${(props) => props.theme.colors.white};
    font-weight: 500;
    margin-top:7px;
  }
`

export const TagBox = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
`
export const Tag = styled.div`
  width: 6px;
  height: 30px;
  background-color: #ff4141;
  display: flex;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
`
