import React from 'react';
import { TailSpin } from 'react-loader-spinner'
import { IButtonPrimaryProps } from './buttonPrimary.types';
import { Container, Label, LoadingBox } from './buttonPrimary.styles';

const ButtonPrimary: React.FC<IButtonPrimaryProps> = (props) => {

  const {
    label,
    isDisabled,
    onClick,
    loading,
    customStyle,
    customLabelStyle
  } = props;

  return (
    <Container
      customStyle={customStyle}
      onClick={onClick}
      disabled={isDisabled || loading}
      isDisabled={isDisabled || loading}>
      {loading ?
        <LoadingBox>
          <TailSpin
            height="35"
            width="80"
            color="#fff"
            ariaLabel="tail-spin-loading"
            radius="1"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
          />
        </LoadingBox>
        :
        <Label customLabelStyle={customLabelStyle}>{label}</Label>
      }
    </Container>
  )

}

export default ButtonPrimary;