import React from "react";
import {
  Container,
  Title,
  TitleWrapper,
  Topic,
  WhiteWrapper,
  Icon,
  ContainerList,
  InfluencerWrapper,
  InfluencerPhoto,
  InfluencerInfo,
  InfluencerName,
  InfluencerFollowers,
  InfluencerPosition,
  TopicContent,
} from "./influencers.styles";
import {
  IconsMediaSocialProps,
  IInfluencersReportProps,
} from "./influencers.types";
import Instagram from "../../../../assets/icons/instagram.svg";
import Tiktok from "../../../../assets/icons/tiktok.svg";
import Youtube from "../../../../assets/icons/youtube_metrics.svg";
import Twitter from "../../../../assets/icons/twitter_metrics.svg";
import ButtonSecondary from "../../buttons/secondary/buttonSecondary.component";
import EditIcon from "../../../../assets/icons/edit.svg";
import { IInfluencerProps } from "../../../../store/campaigns/campaigns.types";
import { maskFollowers } from "../../../../utils/validations";

const InfluencersReport: React.FC<IInfluencersReportProps> = ({ editable, influencers, handleGoBack }) => {

  const icons: IconsMediaSocialProps = {
    Instagram: Instagram,
    Twitter: Twitter,
    Youtube: Youtube,
    TikTok: Tiktok,
  };

  const socialMedia = [
    {
      media: "Instagram",
      influencers: influencers.filter((item) => item.instagram.isActive)
    },
    {
      media: "Twitter",
      influencers: influencers.filter((item) => item.twitter.isActive)
    },
    {
      media: "Youtube",
      influencers: influencers.filter((item) => item.youtube.isActive)
    },
    {
      media: "TikTok",
      influencers: influencers.filter((item) => item.tiktok.isActive)
    }
  ];

  interface IMediaType {
    [key: string]: 'instagram' | 'twitter' | 'youtube' | 'tiktok'
  }

  const mediaType: IMediaType = {
    Instagram: 'instagram',
    Twitter: 'twitter',
    Youtube: 'youtube',
    TikTok: 'tiktok'
  }

  return (
    <Container id="#influencers">
      <TopicContent>
        <Topic>2. Influenciadores</Topic>
        {editable && (
          <ButtonSecondary
            label="Editar Informações"
            isDisabled={false}
            loading={false}
            onClick={() => handleGoBack(2)}
            icon={EditIcon}
            customStyle={{ width: "180px", height: "32px" }}
            customLabelStyle={{
              fontSize: "13px",
              lineHeight: "14px",
              fontWeight: "400",
              fontFamily: "Montserrat",
            }}
          />
        )}
      </TopicContent>
      <WhiteWrapper>
        {socialMedia.map((item, index) => {
          if (!item.influencers.length)
            return <div key={index}></div>

          return (
            <div key={index}>
              <TitleWrapper>
                <Icon src={icons[item.media]} />
                <Title>Os influenciadores | {item.media}</Title>
              </TitleWrapper>
              <ContainerList borderBottom={index + 1 !== socialMedia.length}>
                {item.influencers.map((influencer: IInfluencerProps, index) => {
                  return (
                    <InfluencerWrapper key={index}>
                      <InfluencerPhoto src={influencer.profilePhoto} />
                      <InfluencerInfo>
                        <InfluencerName>{influencer.name.toUpperCase()}</InfluencerName>
                        <InfluencerPosition>{influencer.cluster}</InfluencerPosition>
                        <InfluencerFollowers>
                          {maskFollowers(influencer[mediaType[item.media]].followers)} {item.media !== 'Youtube' ? 'seguidores' : 'inscritos'}
                        </InfluencerFollowers>
                      </InfluencerInfo>
                    </InfluencerWrapper>
                  );
                })}
              </ContainerList>
            </div>
          );
        })}
      </WhiteWrapper>
    </Container>
  );
};
export default InfluencersReport;
