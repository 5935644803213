import styled from 'styled-components';

export const Container = styled.div`
display: grid;
grid-template-columns: 259px auto;
grid-template-rows: 270px auto;

grid-template-areas: 
'SM MC'
'SM MC';

height: 100vh;

/* @media(max-width: 600px){
  grid-template-columns: 100%;
  grid-template-rows: 70px auto;

  grid-template-areas: 
  'MH'
  'MC';
} */
`