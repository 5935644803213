import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { setModalMessage } from '../../../store/modal/modal.actions';
import { useAppDispatch } from '../../../store/storeHook';
import { FetchRegisterUser } from '../../../store/user/user.actions';
import { ILoadingProps } from '../../../utils/globalTypes';
import { validateEmail } from '../../../utils/validations';

import UsersRegisterLayout from './usersRegister.layout';

const UsersRegisterController: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [fullname, setFullname] = useState('');
  const [email, setEmail] = useState('');
  const [userHierarchy, setUserHierarchy] = useState('');
  const [profilePhoto, setProfilePhoto] = useState<any>();
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [loading, setLoading] = useState({ status: false, type: '' });

  const userHierarchyList = [
    'operador',
    'admin'
  ]

  const toggleLoading = (status: ILoadingProps) => setLoading(status);

  const handleBack = () => {
    navigate('/usuarios');
  }

  const handleRegister = () => {
    toggleLoading({ status: true, type: 'Fetching' })
    FetchRegisterUser({
      fullname,
      email,
      userHierarchy,
      thumb: profilePhoto,
      password,
      active: true,
      lastLogin: ''
    }, toggleLoading);
  }

  const handleRedirectAfterRegistered = () => {
    dispatch(setModalMessage({
      title: 'Sucesso',
      isVisible: false,
      description: 'Usuário cadastrado com sucesso.',
      icon: 'success',
      confirmation: false,
    }));
    setTimeout(() => {
      navigate('/usuarios');
    }, 500);
  }

  useEffect(() => {
    if (loading.type === 'user_registered_successfully')
      dispatch(setModalMessage({
        title: 'Sucesso',
        isVisible: true,
        description: 'Usuário cadastrado com sucesso.',
        icon: 'success',
        confirmation: false,
        onClick: handleRedirectAfterRegistered
      }));

    if (loading.type === 'user_recovered_successfully')
      dispatch(setModalMessage({
        title: 'Sucesso',
        isVisible: true,
        description: 'Usuário cadastrado com sucesso. Um email foi enviado ao usuário para redefinir a senha de acesso.',
        icon: 'success',
        confirmation: false,
        onClick: handleRedirectAfterRegistered
      }));

    if (loading.type === 'user_not_registered')
      dispatch(setModalMessage({
        title: 'Ops! :(',
        isVisible: true,
        description: 'Não foi possível registrar o usuário. Verifique os dados e tente novamente.',
        icon: 'warning',
        confirmation: false,
      }))

    //eslint-disable-next-line
  }, [loading])

  useEffect(() => {
    if (fullname.length > 5
      && validateEmail(email)
      && userHierarchy.length > 0
      && password.length > 0
      && confirmPassword.length > 0
      && password === confirmPassword
    )
      setButtonDisabled(false);
    else
      setButtonDisabled(true);
  }, [fullname, email, userHierarchy, password, confirmPassword])

  return <UsersRegisterLayout
    localState={{
      fullname,
      email,
      profilePhoto,
      userHierarchy,
      password,
      confirmPassword,
      userHierarchyList,
      buttonDisabled,
      loading
    }}
    handlers={{
      handleBack,
      handleRegister,
      setFullname,
      setEmail,
      setUserHierarchy,
      setProfilePhoto,
      setPassword,
      setConfirmPassword
    }}
  />
}

export default UsersRegisterController;