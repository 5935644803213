import React from 'react';
import {
  Title,
  Container,
  PanelContent,
  PanelBody,
  Divider,
  LineDivider,
} from './instagramStoryItems.styles';
import InputComponent from '../../../../global/components/input/input.component';
import { IInstagramStoriesMetricsProps } from '../../../../store/campaigns/campaigns.types';
import { maskNumbers, onlyNumbers } from 'utils/validations';

interface IInstagramStoryItemsProps {
  index: number;
  item: IInstagramStoriesMetricsProps;
  handleSetMetrics: (i: number, p: string, v: React.SetStateAction<string>) => void;
}

const InstagramStoryItems: React.FC<IInstagramStoryItemsProps> = ({ index, item, handleSetMetrics }) => {
  const {
    reach,
    impressions,
    linkClicks,
    clickOnBrandLink,
    replies,
    shares,
    visitsOnProfile
  } = item;
  return (
    <Container>
      <Title>Story {index + 1}</Title>

      <PanelContent>
        <PanelBody>
          <InputComponent
            label='Alcance'
            value={maskNumbers(reach)}
            onChange={(reach) => handleSetMetrics(index, 'reach', onlyNumbers(reach.toString()))}
          />
        </PanelBody>
        <Divider />
        <PanelBody>
          <InputComponent
            label='Impressões'
            value={maskNumbers(impressions)}
            onChange={(impressions) => handleSetMetrics(index, 'impressions', onlyNumbers(impressions.toString()))}
          />
        </PanelBody>

        <Divider />
        <PanelBody>
          <InputComponent
            label='Cliques em link'
            value={maskNumbers(linkClicks)}
            onChange={(linkClicks) => handleSetMetrics(index, 'linkClicks', onlyNumbers(linkClicks.toString()))}
          />
        </PanelBody>
        <Divider />
        <PanelBody>
          <InputComponent
            label='Cliq. no @ da marca'
            value={maskNumbers(clickOnBrandLink)}
            onChange={(clickOnBrandLink) => handleSetMetrics(index, 'clickOnBrandLink', onlyNumbers(clickOnBrandLink.toString()))}
          />
        </PanelBody>
      </PanelContent>
      <PanelContent>
        <PanelBody>
          <InputComponent
            label='Respostas'
            value={maskNumbers(replies)}
            onChange={(replies) => handleSetMetrics(index, 'replies', onlyNumbers(replies.toString()))}
          />
        </PanelBody>
        <Divider />
        <PanelBody>
          <InputComponent
            label='Compartilhamentos'
            value={maskNumbers(shares)}
            onChange={(shares) => handleSetMetrics(index, 'shares', onlyNumbers(shares.toString()))}
          />
        </PanelBody>

        <Divider />
        <PanelBody>
          <InputComponent
            label='Visitas ao perfil'
            value={maskNumbers(visitsOnProfile)}
            onChange={(visitsOnProfile) => handleSetMetrics(index, 'visitsOnProfile', onlyNumbers(visitsOnProfile.toString()))}
          />
        </PanelBody>
        <Divider />
        <PanelBody />
      </PanelContent>

      <LineDivider />
    </Container>

  )
}

export default InstagramStoryItems;