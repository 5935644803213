import React from 'react';
import {
  Container,
  SimpleText,
  BoldText
} from './filterBrand.styles';

const FilterBrand: React.FC = () => {
  return (
    <Container>

      <SimpleText>
        Exibir:
      </SimpleText>
      <BoldText>
        Todas as Marcas
      </BoldText>

      <svg width="11" height="7" viewBox="0 0 11 7" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M9.62668 0.333252C10.0459 0.333252 10.279 0.818218 10.0171 1.1456L5.72409 6.51188C5.52393 6.76208 5.14339 6.76208 4.94322 6.51188L0.650203 1.1456C0.388297 0.818217 0.621385 0.333252 1.04064 0.333252L9.62668 0.333252Z" fill="#92929D" />
      </svg>

    </Container>
  )
}

export default FilterBrand;