import styled from 'styled-components';

export const Container = styled.div`
width: 256px;
height: 141px;
background: #fff;
border-radius: 12px;
margin: 40px 20px 0px 0px;
display: flex;
justify-content: flex-start;
align-items: center;
cursor:pointer;
transition: all .3s;

&:hover{
  opacity: .9;
  transform: translateX(10px);
};
`

export const Icon = styled.img`
margin-left: 32px;
margin-right: 20px;
width: 86px;
height: 86px;
border-radius: 50px;
background:#fff;
border: 2px solid #D9D9DD;
object-fit: cover;
`

export const LabelButtonAddCampaign = styled.h5`
font-family: 'Poppins';
font-style: normal;
font-weight: 600;
font-size: 16px;
text-align: center;
color: #23314B;
margin-right: 32px;
`