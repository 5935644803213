import styled from 'styled-components';

export const Container = styled.div`
display: flex;
margin-right: 24px;
justify-content: center;
align-items: center;
`

interface IStepNumberProps {
  active: boolean;
}

export const StepNumberBox = styled.div<IStepNumberProps>`
width: 29px;
height: 29px;
background: ${props => props.active ? '#4F2FC4' : '#A9B8CD'};
display: flex;
justify-content: center;
align-items: center;
border-radius: 15px;
margin-right: 14px;
`

export const StepNumberLabel = styled.h1`
font-family: 'Poppins';
font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 12px;
color: '#FFFFFF';
margin-top:8px;
`

export const StepDescription = styled.span<IStepNumberProps>`
font-family: 'Poppins';
font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 12px;
color: ${props => props.active ? '#23314B' : '#A9B8CD'};
margin-right: 14px;
`