import { doc, getDoc, onSnapshot, query, collection, setDoc, updateDoc, deleteDoc, getDocs } from "firebase/firestore";
import { signInWithEmailAndPassword, signOut, createUserWithEmailAndPassword, sendPasswordResetEmail } from "firebase/auth";
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
import { ILoadingProps } from "../../utils/globalTypes";
import { AppThunk } from "..";
import db, { firebaseAuth, firebaseStorage } from "../../db/firebaseConn";
import { setUserInfo } from "./user.reducer";
import { setModalMessage } from "../modal/modal.actions";
import { IModalReducerProps } from "../modal/modal.types";
import { IUserReducerProps, IUserRegisterProps } from './user.types'
import moment from "moment";

export const FetchSignIn = (
  email: string,
  password: string,
  callback: ({ status, type }: ILoadingProps) => void): AppThunk => {
  return async (dispatch) => {
    try {

      await signInWithEmailAndPassword(firebaseAuth, email, password)
        .then(async (userCredential) => {
          const user = userCredential.user;
          const docRef = doc(db, "users", user.uid);
          const docSnap = await getDoc(docRef);


          if (docSnap.exists()) {

            if (!docSnap.data().active)
              return callback({ status: false, type: 'user_inactive' });

            await updateDoc(doc(db, 'users', user.uid), {
              lastLogin: moment(new Date()).format("DD/MM/YYYY HH:mm")
            })

            dispatch(setUserInfo({
              fullname: docSnap.data().fullname,
              email,
              thumb: docSnap.data().thumb,
              userHierarchy: docSnap.data().userHierarchy,
              active: docSnap.data().active,
              lastLogin: docSnap.data().lastLogin
            }));

            callback({ status: true, type: 'login_successfully' });

          } else {
            callback({ status: false, type: 'login_failed' });
          }
        })
        .catch((error) => {
          // const errorCode = error.code;
          // const errorMessage = error.message;
          callback({ status: false, type: 'login_failed' });
        });

    } catch (error) {
      console.log('Error on catch FetchSignIn', error);
      callback({ status: false, type: 'login_failed' });
    }
  }
}

export const FetchSignOut = (callback: () => void): AppThunk => {
  return (dispatch) => {
    try {
      signOut(firebaseAuth);
      dispatch(setUserInfo({
        fullname: '',
        email: '',
        thumb: '',
        userHierarchy: ''
      }))
      callback();
    } catch (error) {
      dispatch(setModalMessage({
        title: 'Ops! :(',
        isVisible: true,
        description: 'Não foi possível sair do sistema. Por favor, verifique sua conexão com a internet e tente novamente.',
        icon: '',
        confirmation: false,
      } as IModalReducerProps))
    }
  }
}

export const FetchAllUsers = (setUsers: (arrUsers: any) => void, toggleLoading: (status: ILoadingProps) => void) => {
  try {
    const q = query(collection(db, 'users'));
    let users: any = [];
    onSnapshot(q, (querySnapShot) => {

      querySnapShot.forEach((doc) => {
        users.push(doc.data() as IUserReducerProps);
      });

      // console.log('Users ->', users);

      setUsers(users);
      toggleLoading({ status: false, type: 'list_users_success' });
    })
  } catch (error) {
    toggleLoading({ status: false, type: 'list_users_failed' });
  }
}

export const FetchRegisterUser = async (props: IUserRegisterProps, callback: (status: ILoadingProps) => void) => {
  try {
    // console.log('Props Register -> ', props);

    const {
      fullname,
      email,
      userHierarchy,
      password,
      thumb
    } = props;

    let userExists = false;
    let userData: any = {};
    let deletedUsers = await getDocs(collection(db, "deleted-users"));

    deletedUsers.forEach((doc) => {
      // console.log('Doc ->', doc.data());

      if (doc.data().email === email) {
        // console.log('Doc ->', doc.data().email);
        userExists = true;
        userData = doc.data();
      }
    });



    if (!userExists) {
      createUserWithEmailAndPassword(firebaseAuth, email, password)
        .then(async (userCredential) => {

          // console.log('user credentials ->', userCredential);
          const user = userCredential.user;

          //fazer upload da foto
          if (thumb?.name) {
            const storageRef = ref(firebaseStorage, `files/${thumb?.name}`);
            const uploadTask = uploadBytesResumable(storageRef, thumb);

            uploadTask.on("state_changed",
              () => { },
              (error) => {
                console.log('Error on uploadPhoto');

              },
              () => {
                getDownloadURL(uploadTask.snapshot.ref).then(async (downloadURL) => {
                  //cadastrar usuario
                  const userInfo = {
                    id: user.uid,
                    fullname,
                    email,
                    userHierarchy,
                    password,
                    thumb: downloadURL,
                    active: true,
                    lastLogin: ''
                  }

                  await setDoc(doc(db, "users", user.uid), userInfo);

                  callback({ status: false, type: 'user_registered_successfully' });
                });
              }
            );
          } else {
            const userInfo = {
              id: user.uid,
              fullname,
              email,
              userHierarchy,
              password,
              thumb: 'https://firebasestorage.googleapis.com/v0/b/mudah-web.appspot.com/o/users%2Fuser_default.png?alt=media&token=b2bb04ff-82bb-42ae-baea-29dd9b9430c5',
              active: true,
              lastLogin: ''
            }

            await setDoc(doc(db, "users", user.uid), userInfo);

            callback({ status: false, type: 'user_registered_successfully' });
          }

        })
        .catch((error) => {
          console.log('Error on signUp email/password', error);
          callback({ status: false, type: 'user_not_registered' });
        })
    } else {

      await setDoc(doc(db, "users", userData.id), {
        ...userData,
        ...props,
        thumb: userData.thumb
      });
      await deleteDoc(doc(db, "deleted-users", userData.id));
      sendPasswordResetEmail(firebaseAuth, email)
        .then(() => {
          callback({ status: false, type: 'user_recovered_successfully' });
        })
    }


  } catch (error) {
    callback({ status: false, type: 'user_not_registered' });
  }
}


export const FetchEditUser = async (user: IUserRegisterProps, callback: (status: ILoadingProps) => void) => {
  try {
    const userId = user.id || ''
    if (user.thumb?.name) {
      const storageRef = ref(firebaseStorage, `files/${user.thumb?.name}${new Date()}`);
      const uploadTask = uploadBytesResumable(storageRef, user.thumb);

      uploadTask.on("state_changed",
        () => { },
        (error) => {
          console.log('Error on uploadPhoto', error);
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then(async (downloadURL) => {

            await updateDoc(doc(db, "users", userId), {
              fullname: user.fullname,
              email: user.email,
              password: user.password,
              userHierarchy: user.userHierarchy,
              thumb: downloadURL,
              active: user.active
            });

            callback({ status: false, type: 'user_updated_successfully' });
          })
            .catch((err) => {
              console.log('Error on uploader photo ->', err);
              callback({ status: false, type: 'user_update_failed' });
            })
        }
      );
    } else {

      await updateDoc(doc(db, "users", userId), {
        fullname: user.fullname,
        email: user.email,
        password: user.password,
        userHierarchy: user.userHierarchy,
        active: user.active
      });

      callback({ status: false, type: 'user_updated_successfully' });
    }

  } catch (error) {
    console.log('Error on catch Edit user ->', error);
    callback({ status: false, type: 'user_update_failed' });
  }
}

export const FetchSendEmailResetPassword = (email: string, callback: (status: ILoadingProps) => void) => {
  try {
    sendPasswordResetEmail(firebaseAuth, email)
      .then(() => {
        callback({ status: false, type: 'reset_sent_successfully' });
      })
  } catch (error) {
    console.log('Error on catch Edit user ->', error);
    callback({ status: false, type: 'reset_sent_successfully' });
  }
}

export const FetchDeleteUser = async (user: IUserRegisterProps, callback: () => void) => {
  try {
    const userId = user.id || '';

    await setDoc(doc(db, "deleted-users", userId), user);
    await deleteDoc(doc(db, "users", userId));

    callback();

  } catch (error) {
    console.log('Error on catch Delete user ->', error);
    callback();
  }
}