import React from 'react';
import ButtonPrimary from '../../../global/components/buttons/primary/buttonPrimary.component';
import ButtonSecondary from '../../../global/components/buttons/secondary/buttonSecondary.component';
import Footer from '../../../global/components/footer/footer.components';
import Input from '../../../global/components/input/input.component';
import PrivatePage from '../../../global/templates/private/privatePage.component';
import UploadPhotoComponent from './components/uploadPhoto/uploadPhoto.component';
import { IUserRegisterProps } from './usersRegister.types';
import {
  ButtonsFooterBox,
  Container,
  Divider,
  Panel,
  PanelBody,
  PanelContent,
  PanelTitle,
  Title,
} from './usersRegister.styles';
import CustomDropdown from '../../../global/components/dropdown/dropdown.component';

const UsersRegisterLayout: React.FC<IUserRegisterProps> = ({ localState, handlers }) => {
  const {
    fullname,
    email,
    profilePhoto,
    userHierarchy,
    password,
    confirmPassword,
    userHierarchyList,
    buttonDisabled,
    loading
  } = localState;

  const {
    handleBack,
    handleRegister,
    setFullname,
    setEmail,
    setUserHierarchy,
    setProfilePhoto,
    setPassword,
    setConfirmPassword
  } = handlers;

  return (
    <PrivatePage>
      <Container>
        <Title>Cadastrar usuário</Title>
        <Panel>
          <PanelTitle>
            Dados principais
          </PanelTitle>
          <PanelContent>
            <PanelBody>
              <Input
                label='Nome completo'
                value={fullname}
                onChange={setFullname}
              />
              <Input
                label='Email'
                value={email}
                onChange={setEmail}
              />
              <Input
                label='Crie uma senha'
                value={password}
                onChange={setPassword}
              />
            </PanelBody>
            <Divider />
            <PanelBody>
              <UploadPhotoComponent
                value={profilePhoto?.name}
                onChange={setProfilePhoto}
              />
              <CustomDropdown
                label='Perfil de acesso'
                value={userHierarchy}
                listItem={userHierarchyList}
                onChange={setUserHierarchy}
              />
              <Input
                label='Confirme a senha'
                value={confirmPassword}
                onChange={setConfirmPassword}
              />
            </PanelBody>
          </PanelContent>
        </Panel>
        <ButtonsFooterBox>

          <ButtonSecondary
            label='Cancelar'
            isDisabled={false}
            loading={false}
            onClick={handleBack}
            customStyle={{
              'margin-top': '25px;',
              'width': '335px;',
              'height': '56px;'
            }}
          />

          <ButtonPrimary
            label='Cadastrar'
            isDisabled={buttonDisabled}
            loading={loading.status}
            onClick={handleRegister}
            customStyle={{
              'margin-top': '25px;',
              'width': '335px;',
              'height': '56px;'
            }}
          />
        </ButtonsFooterBox>
      </Container>
      <Footer />
    </PrivatePage>
  )
}

export default UsersRegisterLayout;