import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FetchCampaignBrand } from '../../../store/campaigns/campaigns.actions';
import { useAppSelector } from '../../../store/storeHook';
import { ILoadingProps } from '../../../utils/globalTypes';

import CampaignsDownloadPDFLayout from './campaignsDownloadPdf.layout';

const CampaignsDownloadPDFController: React.FC = () => {
  const navigate = useNavigate();
  const {
    landscape,
    initialDate,
    endDate,
    recap,
    influencers,
    instagramQualitative,
    twitterQualitative,
    youtubeQualitative,
    tiktokQualitative,
    reportLink,
    reportPassword,
    brand,
    title
  } = useAppSelector(state => state.campaignsReducer);

  const [loading, setLoading] = useState({ status: false, type: 'fetching' });
  const [brandThumb, setBrandThumb] = useState('carregando...');

  const toggleLoading = (status: ILoadingProps) => setLoading(status);

  const handleBack = () => {
    navigate(`/campanhas/finalizada`);
  }

  useEffect(() => {
    if (brand.length > 0)
      FetchCampaignBrand(brand, setBrandThumb)
  }, [brand])

  return <CampaignsDownloadPDFLayout
    localState={{
      recap,
      initialDate,
      endDate,
      influencers,
      instagramQualitative,
      twitterQualitative,
      youtubeQualitative,
      tiktokQualitative,
      reportLink,
      reportPassword,
      loading,
      landscape,
      title,
      brandThumb
    }}
    handlers={{
      handleBack
    }}
  />
}
export default CampaignsDownloadPDFController;