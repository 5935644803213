import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";

import SignInController from "../pages/signin/signIn.controller";
import CampaignsController from "../pages/campaings/campaigns.controller";
import DraftsController from "../pages/drafts/drafts.controller";
import BrandsController from "../pages/brands/brands.controller";
import UsersController from "../pages/users/users.controller";
import UsersRegisterController from "../pages/users/register/usersRegister.controller";
import UsersEditController from "../pages/users/edit/usersEdit.controller";
import ReportController from "../pages/report/report.controller";
import BrandsRegisterController from '../pages/brands/register/brandsRegister.controller';
import CampaignRegisterController from "../pages/campaings/register/index/campaignRegisterIndex.controller";
import NotFoundController from "../pages/notFound/notFound.controller";
import { useAppSelector } from "../store/storeHook";
import BrandsDetailsController from "../pages/brands/details/brandsDetails.controller";
import BrandsEditController from "../pages/brands/edit/brandsEdit.controller";
import CampaignsCompletedController from "../pages/campaings/completed/campaignsCompleted.controller";
import CampaignsDownloadPDFController from "pages/campaings/downloadPDF/campaignsDownloadPdf.controller";

export const routePaths = {
  root: "/",
  login: "/login",
  campaigns: "/campanhas",
  campaignsRegister: "/campanhas/criar",
  campaignsCompleted: "/campanhas/finalizada",
  campaignsDownloadPdf: "/campanhas/download-pdf",
  drafts: "/rascunhos",
  brands: "/marcas",
  brandsRegister: '/marcas/registrar',
  brandsDetails: '/marcas/detalhes',
  brandsEdit: '/marcas/editar',
  users: "/usuarios",
  userRegister: "/usuarios/cadastro",
  usersEdit: "/usuarios/editar",
  report: "/relatorio/:id",
  notFound: "*"
};

const GlobalRouter = () => {
  const { fullname } = useAppSelector(state => state.userReducer)
  return (
    <Router>
      <Routes>

        <Route path={routePaths.root} element={<Navigate to={fullname.length > 0 ? "/campanhas" : "/login"} replace />} />
        <Route path={routePaths.login} element={fullname.length > 0 ? <Navigate to="/campanhas" replace /> : <SignInController />} />
        <Route path={routePaths.campaigns} element={fullname.length > 0 ? <CampaignsController /> : <Navigate to="/login" replace />} />
        <Route path={routePaths.campaignsRegister} element={fullname.length > 0 ? <CampaignRegisterController /> : <Navigate to="/login" replace />} />
        <Route path={routePaths.campaignsCompleted} element={fullname.length > 0 ? <CampaignsCompletedController /> : <Navigate to="/login" replace />} />
        <Route path={routePaths.campaignsDownloadPdf} element={fullname.length > 0 ? <CampaignsDownloadPDFController /> : <Navigate to="/login" replace />} />
        <Route path={routePaths.drafts} element={fullname.length > 0 ? <DraftsController /> : <Navigate to="/login" replace />} />
        <Route path={routePaths.brands} element={fullname.length > 0 ? <BrandsController /> : <Navigate to="/login" replace />} />
        <Route path={routePaths.brandsRegister} element={fullname.length > 0 ? <BrandsRegisterController /> : <Navigate to="/login" replace />} />
        <Route path={routePaths.brandsDetails} element={fullname.length > 0 ? <BrandsDetailsController /> : <Navigate to="/login" replace />} />
        <Route path={routePaths.brandsEdit} element={fullname.length > 0 ? <BrandsEditController /> : <Navigate to="/login" replace />} />
        <Route path={routePaths.users} element={fullname.length > 0 ? <UsersController /> : <Navigate to="/login" replace />} />
        <Route path={routePaths.userRegister} element={fullname.length > 0 ? <UsersRegisterController /> : <Navigate to="/login" replace />} />
        <Route path={routePaths.usersEdit} element={fullname.length > 0 ? <UsersEditController /> : <Navigate to="/login" replace />} />
        <Route path={routePaths.report} element={<ReportController />} />
        <Route path={routePaths.notFound} element={<NotFoundController />} />
      </Routes>
    </Router>
  );
};

export default GlobalRouter;
