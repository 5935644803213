import styled from 'styled-components'

export const TableContainer = styled.div<{ scroll?: boolean }>`
  background: #ffffff;
  border: 1px solid #d8d8e8;
  min-height: 156px;
  margin-top: 14px;
  ${props => !props.scroll ? 'border-radius: 16px;' : `
  border-top-right-radius: 16px;
  border-top-left-radius: 16px;
  overflow-x: scroll;

  ::-webkit-scrollbar {
  height: 10px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px grey; 
  border-radius: 10px;
}
 
::-webkit-scrollbar-thumb {
  background: #d8d8e8; 
  border-radius: 10px;
}
`}
@media(max-width: 420px) {
    border-bottom-right-radius:16px; 
    border-bottom-left-radius:16px;
  }
`

export const TableHeader = styled.div<{ collumns: number, scroll?: boolean }>`
  display: grid;
  border-bottom: 1px solid #d8d8e8;
  align-items: center;
  padding: 27px 30px;
  ${props => props.scroll ? '' : 'width: fit-content;'}
  grid-template-columns: repeat(${(props) => props.collumns}, 1fr);

  @media(max-width: 420px) {
    ${props => !props.scroll ? '' : 'width: fit-content;'}
  }
`



export const TableRow = styled.div<{ collumns: number; numberRow: number, lastItem: boolean, scroll?: boolean, }>`
  display: grid;
  padding: 27px 30px;
  background-color: ${(props) => props.numberRow % 2 === 0 ? 'transparent' : '#F8F8F8'};
  ${props => props.scroll ? '' : 'width: fit-content;'}
  ${props => props.lastItem && !props.scroll ? 'border-bottom-right-radius:16px; border-bottom-left-radius:16px;' : ''}

  grid-template-columns: repeat(${(props) => props.collumns}, 1fr);

   @media(max-width: 420px) {
    ${props => !props.scroll ? '' : 'width: fit-content;'}
    border-bottom-right-radius:16px; 
    border-bottom-left-radius:16px;
  }
`

export const HeaderCell = styled.span<{ width: string }>`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  color: #23314b;

  justify-self: center;

  width: ${(props) => props.width};
  @media(max-width: 420px) {
    width: ${(props) => props.width !== 'auto' ? props.width : '100px'};
  }
`

export const RowCell = styled.span<{
  width: string
}>`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #23314b;
  text-align: center;

  width: ${(props) => props.width};

  @media(max-width: 420px) {
    width: ${(props) => props.width !== 'auto' ? props.width : '100px'};
  }
`
