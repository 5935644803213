import React from 'react';
import PrivatePage from '../../global/templates/private/privatePage.component';
import {
  Container,
  Title,
  ButtonAddCampaing,
  IconBg,
  Icon,
  LabelButtonAddCampaign,
  ContainerListBrand,
  HeaderTitleBox,
  Row
} from './brands.styles'
import iconAddCampaign from '../../assets/icons/icon-quiz.svg';
import Footer from '../../global/components/footer/footer.components';
import BrandCard from './components/brandCard/brandCard.component';
// import ButtonFilter from '../../global/components/buttons/buttonFilter/buttonFilter.component';
import FilterBrand from './components/filterBrand/filterBrand.component';
// import ViewMode from './components/viewMode/viewMode.component';
import { IBrandsLayoutProps } from './brands.types';
import Loading from '../../global/components/loading/loading.component';

const BrandsLayout: React.FC<IBrandsLayoutProps> = ({ localState, handlers }) => {

  const {
    brands,
    loading
  } = localState;

  const {
    handleNavigateBrandRegister,
    handleDetails
  } = handlers;

  return (
    <PrivatePage>
      <Container>

        <HeaderTitleBox>
          <Title>Reports por marca</Title>
          <Row>
            <FilterBrand />
            {/* <ButtonFilter onClick={() => null} />
            <ViewMode /> */}
          </Row>
        </HeaderTitleBox>

        {loading.status ?
          <Loading label='marcas' />
          :
          <ContainerListBrand>
            <ButtonAddCampaing onClick={handleNavigateBrandRegister}>
              <IconBg>
                <Icon src={iconAddCampaign} alt="icon-cadastrar-marca" />
              </IconBg>
              <LabelButtonAddCampaign>
                Cadastrar <br /> marca
              </LabelButtonAddCampaign>
            </ButtonAddCampaing>
            {brands.map((item: any, index: number) => <BrandCard key={index} item={item} handleDetails={handleDetails} />)}
          </ContainerListBrand>
        }
      </Container>
      <Footer />
    </PrivatePage>
  )
}

export default BrandsLayout;