import React from 'react';

import { IUploadPhotoProps } from './uploadPhotoCampaign.types';
import {
  Container,
  Label,
  Dropzone,
  UploadLabel,
  Row,
  ButtonUpload,
  ButtonUploadBox
} from './uploadPhotoCampaign.styles';

const UploadPhotoComponent: React.FC<IUploadPhotoProps> = (props) => {
  const {
    value,
    isInfluencer,
    onChange
  } = props;

  return (
    <Container>
      <Label>{isInfluencer ? 'Foto do influenciador' : 'Imagem principal da campanha'}</Label>
      <Dropzone>
        <Row>
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M2.09877 0H17.9012C19.0603 0 20 0.939648 20 2.09877V17.9012C20 19.0603 19.0604 20 17.9012 20H2.09877C0.939651 20 0 19.0604 0 17.9012V2.09877C0 0.939651 0.939648 0 2.09877 0ZM2.09877 1.35802C1.68967 1.35802 1.35802 1.68967 1.35802 2.09877V14.0081L4.29918 11.0669C5.03142 10.3347 6.2186 10.3347 6.95083 11.0669L8.30807 12.4242C8.55215 12.6683 8.94787 12.6683 9.19195 12.4242L13.6742 7.94194C14.4064 7.20971 15.5936 7.20971 16.3258 7.94194L18.642 10.2581V2.09877C18.642 1.68967 18.3103 1.35802 17.9012 1.35802H2.09877ZM1.35802 17.9012V15.7759L5.18307 11.9508C5.42715 11.7067 5.82287 11.7067 6.06695 11.9508L7.42418 13.3081C8.15642 14.0403 9.3436 14.0403 10.0758 13.3081L14.5581 8.82583C14.8021 8.58175 15.1979 8.58175 15.442 8.82583L18.642 12.0259V17.9012C18.642 18.3103 18.3103 18.642 17.9012 18.642H2.09877C1.68967 18.642 1.35802 18.3103 1.35802 17.9012ZM7.5 6.25C7.5 6.94036 6.94036 7.5 6.25 7.5C5.55964 7.5 5 6.94036 5 6.25C5 5.55964 5.55964 5 6.25 5C6.94036 5 7.5 5.55964 7.5 6.25ZM8.75 6.25C8.75 7.63071 7.63071 8.75 6.25 8.75C4.86929 8.75 3.75 7.63071 3.75 6.25C3.75 4.86929 4.86929 3.75 6.25 3.75C7.63071 3.75 8.75 4.86929 8.75 6.25Z" fill="#4E4C59" />
          </svg>
          <UploadLabel>
            {value ? value : isInfluencer ? 'Upload da imagem do influenciador' : 'Upload da imagem de capa'}
          </UploadLabel>
        </Row>

        <ButtonUploadBox>
          Buscar Imagem
          <ButtonUpload
            type="file"
            accept="image/png, image/jpg, image/jpeg"
            onChange={(e) => {
              console.log('Image captured ->', e.target.files);
              const image: any = e.target.files;
              onChange(image[0]);
            }}
          />
        </ButtonUploadBox>
      </Dropzone>
    </Container>
  )

}

export default UploadPhotoComponent;