import styled from 'styled-components';

export const Title = styled.h1`
font-family: 'Poppins';
font-style: normal;
font-weight: 600;
font-size: 18px;
line-height: 27px;
color: #23314B;
`

export const InstagramDetails = styled.div`
display: flex;
flex-direction: column;
padding: 20px 0;
`

export const PanelContent = styled.div`
display: flex;
flex:1;
flex-direction: row;
`
export const PanelBody = styled.div`
display: flex;
flex-direction: column;
justify-content: flex-start;
width: 100%;
`
export const Divider = styled.div`
width: 30px;
`

export const LineDivider = styled.div`
width: 100%;
border: 1px solid #D8D8E8;
margin:50px 0 20px 0;
`