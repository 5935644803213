import React from 'react';
import {
  Title,
  Container,
  PanelContent,
  PanelBody,
  Divider,
  LineDivider,
} from './twitterMetricsItems.styles';
import InputComponent from '../../../../global/components/input/input.component';
import { ITwitterMetricsProps } from '../../../../store/campaigns/campaigns.types';
import { maskNumbers, onlyNumbers } from 'utils/validations';

interface ITwitterMetricsItemsProps {
  index: number;
  item: ITwitterMetricsProps;
  handleSetMetrics: (i: number, p: string, v: React.SetStateAction<string>) => void;
}

const TwitterMetricsItems: React.FC<ITwitterMetricsItemsProps> = ({ index, item, handleSetMetrics }) => {
  const {
    tweetLink,
    impressions,
    engagements,
    expansionDetails,
    likes,
    retweets,
    clicksOnHashtag,
    clicksOnLinks,
    views,
    replies
  } = item;

  return (
    <Container>
      <Title>Tweet {index + 1}</Title>

      <PanelContent>
        <PanelBody>
          <InputComponent
            label='Link do tweet'
            value={tweetLink}
            onChange={(tweetLink) => handleSetMetrics(index, 'tweetLink', tweetLink)}
          />
        </PanelBody>
        <Divider />
        <PanelBody>
          <InputComponent
            label='Impressões'
            value={maskNumbers(impressions)}
            onChange={(impressions) => handleSetMetrics(index, 'impressions', onlyNumbers(impressions.toString()))}
          />
        </PanelBody>

        <Divider />
        <PanelBody>
          <InputComponent
            label='Engajamentos'
            value={maskNumbers(engagements)}
            onChange={(engagements) => handleSetMetrics(index, 'engagements', onlyNumbers(engagements.toString()))}
          />
        </PanelBody>

        <Divider />
        <PanelBody>
          <InputComponent
            label='Expansão de detalhes'
            value={maskNumbers(expansionDetails)}
            onChange={(expansionDetails) => handleSetMetrics(index, 'expansionDetails', onlyNumbers(expansionDetails.toString()))}
          />
        </PanelBody>
      </PanelContent>
      <PanelContent>
        <PanelBody>
          <InputComponent
            label='Favoritos/Curtidas'
            value={maskNumbers(likes)}
            onChange={(likes) => handleSetMetrics(index, 'likes', onlyNumbers(likes.toString()))}
          />
        </PanelBody>
        <Divider />
        <PanelBody>
          <InputComponent
            label='Retweets'
            value={maskNumbers(retweets)}
            onChange={(retweets) => handleSetMetrics(index, 'retweets', onlyNumbers(retweets.toString()))}
          />
        </PanelBody>

        <Divider />
        <PanelBody>
          <InputComponent
            label='Cliques em #'
            value={maskNumbers(clicksOnHashtag)}
            onChange={(clicksOnHashtag) => handleSetMetrics(index, 'clicksOnHashtag', onlyNumbers(clicksOnHashtag.toString()))}
          />
        </PanelBody>

        <Divider />
        <PanelBody>
          <InputComponent
            label='Cliques no link'
            value={maskNumbers(clicksOnLinks)}
            onChange={(clicksOnLinks) => handleSetMetrics(index, 'clicksOnLinks', onlyNumbers(clicksOnLinks.toString()))}
          />
        </PanelBody>
      </PanelContent>
      <PanelContent>
        <PanelBody>
          <InputComponent
            label='Visualizações'
            value={maskNumbers(views)}
            onChange={(views) => handleSetMetrics(index, 'views', views)}
          />
        </PanelBody>
        <Divider />
        <PanelBody>
          <InputComponent
            label='Respostas'
            value={maskNumbers(replies)}
            onChange={(replies) => handleSetMetrics(index, 'replies', onlyNumbers(replies.toString()))}
          />
        </PanelBody>
        <Divider />
        <PanelBody />
        <Divider />
        <PanelBody />
      </PanelContent>
      <LineDivider />
    </Container>

  )
}

export default TwitterMetricsItems;