import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FetchAllBrandsToCampaigns, FetchCampaignCreateReport, FetchCampaignRegister, FetchCampaignUpdateStepOne, FetchCampaignUpdateStepThree, FetchCampaignUpdateStepTwo } from '../../../../store/campaigns/campaigns.actions';
import { setCampaignsInfo } from '../../../../store/campaigns/campaigns.reducer';
import { IInfluencerProps, IInstagramPostsMetricsProps, IInstagramStoriesMetricsProps, ITiktokMetricsProps, ITwitterMetricsProps, IYoutubeMetricsProps } from '../../../../store/campaigns/campaigns.types';
import { setModalMessage } from '../../../../store/modal/modal.actions';
import { IModalReducerProps } from '../../../../store/modal/modal.types';
import { useAppDispatch, useAppSelector } from '../../../../store/storeHook';
import { ILoadingProps } from '../../../../utils/globalTypes';
import { makeId } from '../../../../utils/validations';

import CampaignRegisterLayout from './campaignRegisterIndex.layout';
import { IModalMetricsProps, IModalType } from './campaignRegisterIndex.types';
import { TSocialSessionCondition } from 'pages/report/report.types';

const CampaignRegisterController: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const mockInfluencerData = {
    name: '',
    cluster: '',
    profilePhoto: null,
    signedScope: '',
    instagram: {
      isActive: false,
      idInstagram: '',
      numberOfPosts: '',
      storiesSigned: '',
      storiesDelivered: '',
      metricsPosts: [] as IInstagramPostsMetricsProps[],
      metricsStories: [] as IInstagramStoriesMetricsProps[],
      followers: ''
    },
    twitter: {
      isActive: false,
      idTwitter: '',
      numberOfTweets: '',
      metrics: [] as ITwitterMetricsProps[],
      followers: ''
    },
    youtube: {
      isActive: false,
      numberOfVideos: '',
      metrics: [] as IYoutubeMetricsProps[],
      followers: ''
    },
    tiktok: {
      isActive: false,
      idTiktok: '',
      numberOfPosts: '',
      metrics: [] as ITiktokMetricsProps[],
      followers: ''
    },
    reportLink: '',
    reportPassword: ''
  } as IInfluencerProps

  const {
    id,
    stepNumber,
    brandsList,
    title,
    landscape,
    brand,
    initialDate,
    endDate,
    recap,
    changedLandscape,
    influencers,
    influencerData,
    instagramQualitative,
    twitterQualitative,
    youtubeQualitative,
    tiktokQualitative,
    reportLink,
    reportPassword
  } = useAppSelector(state => state.campaignsReducer);

  const [modal, setModal] = useState<IModalMetricsProps>({
    isVisible: false,
    modalType: '' as IModalType
  })
  const [loadingBrands, setLoadingBrands] = useState({ status: true, type: 'fetching' });
  const [loading, setLoading] = useState({ status: false, type: '' });
  const [buttonDisabled, setButtonDisabled] = useState(true);

  const socialMetricsExist = {
    instagramQualitative: { name: "Instagram", scrollTo: "#instagram" },
    twitterQualitative: { name: "Twitter", scrollTo: "#twitter" },
    youtubeQualitative: { name: "Youtube", scrollTo: "#youtube" },
    tiktokQualitative: { name: "TikTok", scrollTo: "#tiktok" },
  };

  const createSessionsList = (
    socialMetricsArray: Array<TSocialSessionCondition>
  ) => {
    const list = [
      { name: "Introdução", scrollTo: "#introduction" },
      { name: "Influenciadores", scrollTo: "#influencers" },
      { name: "Escopo", scrollTo: "#scope" },
    ];

    socialMetricsArray.map((item) => {
      if (item.obj.qualitativeMetrics.length > 0) {
        list.push(socialMetricsExist[item.kind]);
      }
    });

    list.push({ name: "Glossário", scrollTo: "#glossary" });

    return list;
  };

  const socialMetricsArray: Array<TSocialSessionCondition> = [
    { obj: instagramQualitative, kind: "instagramQualitative" },
    { obj: twitterQualitative, kind: "twitterQualitative" },
    { obj: youtubeQualitative, kind: "youtubeQualitative" },
    { obj: tiktokQualitative, kind: "tiktokQualitative" },
  ];

  const sections = createSessionsList(socialMetricsArray);

  const handleBack = () => {
    navigate('/campanhas');
  }

  const handleBackEdit = () => {
    navigate('/campanhas/criar');
  }

  const handleGoToDownloadPDF = () => {
    navigate(`/campanhas/download-pdf`);
  }

  const toggleLoading = (status: ILoadingProps) => setLoading(status);
  const toggleLoadingBrands = (status: ILoadingProps) => setLoadingBrands(status);
  const toggleModalVisible = (modalType: IModalType) => {
    setModal({ isVisible: !modal.isVisible, modalType });
  }

  const handleShowModal = () => {
    dispatch(setModalMessage({
      title: 'Sucesso',
      description: 'As informações foram salvas com sucesso',
      isVisible: true,
      icon: 'success',
      confirmation: false,
    } as IModalReducerProps))
  }

  const handleSetStates = (item: any) => {
    dispatch(setCampaignsInfo({ ...item }))
  }

  const handleEditInfluencerCompleted = (influencer: IInfluencerProps) => {
    let newArr: IInfluencerProps[] = [];

    //eslint-disable-next-line
    influencers.map((item) => {
      if (item.id !== influencer?.id)
        newArr.push(item);
    });

    dispatch(setCampaignsInfo({ influencerData: influencer, influencers: newArr }))
  }

  const handleSaveInfluencer = () => {
    let newArrInfluencers = [...influencers];

    if (!influencerData.id?.length)
      newArrInfluencers.push({ ...influencerData, id: makeId(20) });
    else
      newArrInfluencers.push(influencerData);

    dispatch(setCampaignsInfo({ influencers: newArrInfluencers }));
    document.getElementById("#initDoc")?.scrollIntoView({ behavior: "smooth" });

    setTimeout(() => {
      dispatch(setCampaignsInfo({ influencerData: mockInfluencerData }))
    }, 200);
  }

  const handleCreateCampaign = () => {
    dispatch(FetchCampaignRegister({
      title,
      landscape,
      brand,
      initialDate,
      endDate,
      recap
    }, toggleLoading));
  }

  const handleUpdateCampaignStepOne = () => {
    FetchCampaignUpdateStepOne({
      id,
      title,
      landscape,
      brand,
      initialDate,
      endDate,
      recap,
      changedLandscape
    }, toggleLoading)
  }

  const handleUpdateCampaignStepTwo = () => {
    dispatch(FetchCampaignUpdateStepTwo(id, influencers, toggleLoading))
  }

  const handleUpdateCampaignStepThree = () => {
    FetchCampaignUpdateStepThree(
      id,
      {
        instagramQualitative,
        twitterQualitative,
        youtubeQualitative,
        tiktokQualitative
      }, toggleLoading)
  }

  const handleCreateReport = () => {
    dispatch(FetchCampaignCreateReport(id, toggleLoading))
  }

  const handleNextStep = () => {
    toggleLoading({ status: true, type: 'fetching' });

    if (stepNumber < 5) {

      if (stepNumber === 1) {
        if (id.length > 0)
          handleUpdateCampaignStepOne();
        else
          handleCreateCampaign();
      }

      if (stepNumber === 2) {
        handleUpdateCampaignStepTwo();
      }

      if (stepNumber === 3) {
        handleUpdateCampaignStepThree();
      }

      if (stepNumber === 4) {
        handleCreateReport();
      }

    }


  }

  const handlePreviousStep = () => {
    if (stepNumber > 0)
      handleSetStates({ stepNumber: stepNumber - 1 })
  }

  useEffect(() => {
    if (stepNumber === 1) {
      if ((title?.length && title?.length > 0) &&
        (landscape && (landscape?.length || landscape?.name.length)) &&
        (brand.length && brand.length > 0) &&
        (initialDate.length && initialDate.length > 0) &&
        (endDate.length && endDate.length > 0) &&
        (recap.length && recap.length > 0)
      )
        setButtonDisabled(false);
      else
        setButtonDisabled(true);
    }

    if (stepNumber === 2) {
      if (influencers.length > 0)
        setButtonDisabled(false);
      else
        setButtonDisabled(true);
    }

    if (stepNumber === 3) {
      setButtonDisabled(true);
    }

    if (stepNumber === 4) {
      setButtonDisabled(false);
    }

    //eslint-disable-next-line
  }, [title, landscape, brand, initialDate, endDate, influencers, stepNumber, recap])

  useEffect(() => {

    if (loading.type === 'campaign_stepFour_successfully') {
      setButtonDisabled(true);
      setTimeout(() => {
        handleSetStates({ stepNumber: stepNumber + 1, changedLandscape: false });

        setTimeout(() => {
          toggleLoading({ status: false, type: '' });
          document.getElementById("#initDoc")?.scrollIntoView({ behavior: "smooth" });
        }, 200);

      }, 200);
    }

    if (loading.type === 'campaign_stepThree_successfully') {
      setButtonDisabled(true);
      setTimeout(() => {
        handleSetStates({ stepNumber: stepNumber + 1, changedLandscape: false });

        setTimeout(() => {
          toggleLoading({ status: false, type: '' });
          document.getElementById("#initDoc")?.scrollIntoView({ behavior: "smooth" });
        }, 200);

      }, 200);
    }

    if (loading.type === 'campaign_stepTwo_successfully') {
      setButtonDisabled(true);
      setTimeout(() => {
        handleSetStates({ stepNumber: stepNumber + 1, changedLandscape: false, influencerData: mockInfluencerData });

        setTimeout(() => {
          toggleLoading({ status: false, type: '' });
          document.getElementById("#initDoc")?.scrollIntoView({ behavior: "smooth" });
        }, 200);

      }, 200);
    }

    if (loading.type === 'campaign_registered_successfully') {
      setButtonDisabled(true);

      setTimeout(() => {
        handleSetStates({ stepNumber: stepNumber + 1, changedLandscape: false });

        setTimeout(() => {
          toggleLoading({ status: false, type: '' });
          document.getElementById("#initDoc")?.scrollIntoView({ behavior: "smooth" });
        }, 200);

      }, 200);

    }

    //eslint-disable-next-line
  }, [loading])

  useEffect(() => {
    FetchAllBrandsToCampaigns(handleSetStates, toggleLoadingBrands);
    //eslint-disable-next-line
  }, [])


  return <CampaignRegisterLayout
    localState={{
      modal,
      stepNumber,
      title,
      landscape,
      brand,
      initialDate,
      endDate,
      recap,
      brandsList,
      loadingBrands,
      loading,
      buttonDisabled,
      influencers,
      influencerData,
      instagramQualitative,
      twitterQualitative,
      youtubeQualitative,
      tiktokQualitative,
      reportLink,
      reportPassword,
      sections
    }}
    handlers={{
      setModal,
      handleBack,
      handleNextStep,
      handlePreviousStep,
      toggleModalVisible,
      handleSetStates,
      handleSaveInfluencer,
      handleEditInfluencerCompleted,
      handleShowModal,
      setButtonDisabled,
      handleBackEdit,
      handleGoToDownloadPDF
    }}
  />

}

export default CampaignRegisterController;