import styled from 'styled-components';

export const Container = styled.div`
width: 256.5px;
height: 251.14px;
background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 89.76%);
border-radius: 12px;
margin: 40px 20px 0 0;
display: flex;
position: relative;
flex-direction: column;
justify-content: center;
align-items: center;
cursor:pointer;
transition: all .3s;

&:hover{
  opacity: .9;
  transform: translateX(10px);
};
`

export const BackgroundShadow = styled.div`
width: 256.5px;
height: 251.14px;
background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 89.76%);
border-radius: 12px;
display: flex;
position: absolute;
z-index: 10;
`

export const IconBg = styled.div`
width: 86px;
height: 86px;
border-radius: 50px;
background:#fff;
border: 2px solid #D9D9DD;
display: flex;
justify-content: center;
align-items: center;
margin-left: 32px;
margin-right: 28px;
`

export const Icon = styled.img`
width: 80px;
height: 80px;
object-fit: scale-down;
`

export const LabelButtonAddCampaign = styled.h5`
font-family: 'Poppins';
font-style: normal;
font-weight: 600;
font-size: 16px;
text-align: center;
color: #23314B;
margin-right: 32px;
`

export const BackgroundImage = styled.img`
border-radius: 12px;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
width: 256.5px;
height: 251.14px;
position: absolute;
object-fit: cover;
`

export const Content = styled.div`
border-radius: 12px;
display: flex;
flex-direction: column;
justify-content: flex-end;
align-items: center;
width: 256.5px;
height: 251.14px;
position: absolute;
z-index: 20;
padding-bottom: 25px;
`

export const Title = styled.h1`
width: 100px;
font-family: 'Poppins';
font-style: normal;
font-weight: 600;
font-size: 16px;
text-align: center;
color: #FFFFFF;
`

export const Subtitle = styled.h2`
width: 176px;
font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 12px;
text-align: center;
color: #FFFFFF;
margin-top: 15px;
`