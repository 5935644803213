import React from 'react';
import {
  Title,
  Container,
  PanelContent,
  PanelBody,
  Divider,
  LineDivider,
} from './youtubeMetricsItems.styles';
import InputComponent from '../../../../global/components/input/input.component';
import { IYoutubeMetricsProps } from '../../../../store/campaigns/campaigns.types';
import { maskNumbers, maskTime, onlyNumbers } from 'utils/validations';

interface IYoutubeMetricsItemsProps {
  index: number;
  item: IYoutubeMetricsProps;
  handleSetMetrics: (i: number, p: string, v: React.SetStateAction<string>) => void;
}

const YoutubeMetricsItems: React.FC<IYoutubeMetricsItemsProps> = ({ index, item, handleSetMetrics }) => {
  const {
    views,
    reach,
    impressions,
    likes,
    comments,
    watchedTimeAvg,
    retention,
    percentageView,
    videoLink
  } = item;


  return (
    <Container>
      <Title>Vídeo {index + 1}</Title>

      <PanelContent>
        <PanelBody>
          <InputComponent
            label='Visualizações'
            value={maskNumbers(views)}
            onChange={(views) => handleSetMetrics(index, 'views', onlyNumbers(views.toString()))}
          />
        </PanelBody>
        <Divider />
        <PanelBody>
          <InputComponent
            label='Alcance máximo'
            value={maskNumbers(reach)}
            onChange={(reach) => handleSetMetrics(index, 'reach', onlyNumbers(reach.toString()))}
          />
        </PanelBody>

        <Divider />
        <PanelBody>
          <InputComponent
            label='Impressões'
            value={maskNumbers(impressions)}
            onChange={(impressions) => handleSetMetrics(index, 'impressions', onlyNumbers(impressions.toString()))}
          />
        </PanelBody>
        <Divider />
        <PanelBody>
          <InputComponent
            label='Curtidas'
            value={maskNumbers(likes)}
            onChange={(likes) => handleSetMetrics(index, 'likes', onlyNumbers(likes.toString()))}
          />
        </PanelBody>
      </PanelContent>

      <PanelContent>
        <PanelBody>
          <InputComponent
            label='Comentários'
            value={maskNumbers(comments)}
            onChange={(comments) => handleSetMetrics(index, 'comments', onlyNumbers(comments.toString()))}
          />
        </PanelBody>
        <Divider />
        <PanelBody>
          <InputComponent
            label='Tempo médio assistido'
            value={watchedTimeAvg}
            onChange={(watchedTimeAvg) => handleSetMetrics(index, 'watchedTimeAvg', maskTime(watchedTimeAvg.toString()))}
          />
        </PanelBody>

        <Divider />
        <PanelBody>
          <InputComponent
            label='Retenção de público (%)'
            value={retention}
            onChange={(retention) => handleSetMetrics(index, 'retention', retention.toString())}
          />
        </PanelBody>
        <Divider />
        <PanelBody>
          <InputComponent
            label='% vis. durante o anúncio'
            value={percentageView}
            onChange={(percentageView) => handleSetMetrics(index, 'percentageView', percentageView.toString())}
          />
        </PanelBody>
      </PanelContent>
      <PanelContent>
        <PanelBody>
          <InputComponent
            label='Link do vídeo'
            value={videoLink}
            onChange={(videoLink) => handleSetMetrics(index, 'videoLink', videoLink)}
          />
        </PanelBody>
        <Divider />
        <PanelBody />

        <Divider />
        <PanelBody />
        <Divider />
        <PanelBody />
      </PanelContent>
      <LineDivider />
    </Container>

  )
}

export default YoutubeMetricsItems;