import { styled } from '@mui/material/styles';
import Switch from '@mui/material/Switch';

export const IOSSwitch = styled(Switch)(({ theme }: any) => ({
  width: 49,
  height: 24,
  padding: 0,
  display: 'flex',
  marginRight: 23,
  justifyContent: 'center',
  alignItems: 'center',
  '& .MuiSwitch-switchBase': {
    top: 2.5,
    left: 3,
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(21px)',
      color: '#fff',
      top: 2.5,
      '& + .MuiSwitch-track': {
        backgroundColor: '#4F2FC4',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#4F2FC4',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 18,
    height: 14,
    borderRadius: 7,
  },
  '& .MuiSwitch-track': {
    borderRadius: 24,
    backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));
