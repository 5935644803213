import React from 'react';
import Footer from '../../global/components/footer/footer.components';
import Loading from '../../global/components/loading/loading.component';
import PrivatePage from '../../global/templates/private/privatePage.component';
import CampaignCard from '../campaings/components/campaignCard/campaignCard.component';
import { Container, Title, ContainerListCampaigns } from './drafts.styles'
import { IDraftsLayoutProps } from './drafts.types';

const DraftsLayout: React.FC<IDraftsLayoutProps> = ({ localState, handlers }) => {

  const {
    loading,
    campaigns
  } = localState;

  const {
    handleContinueCampaign
  } = handlers;

  return (
    <PrivatePage>
      <Container>
        <Title>Reports não finalizados</Title>
        {loading.status ?
          <Loading label='campanhas' />
          :
          <ContainerListCampaigns>
            {campaigns.map((item: any, index: number) => <CampaignCard
              item={item}
              key={index}
              isDraft={true}
              onClick={handleContinueCampaign}
            />)}
          </ContainerListCampaigns>
        }
      </Container>
      <Footer />
    </PrivatePage>
  )
}

export default DraftsLayout;