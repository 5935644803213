import styled from 'styled-components';

export const Container = styled.div`
height: 100vh;
padding:25px;
`
export const Row = styled.div`
display: flex;
flex-direction: row;
`

export const RowAligned = styled.div`
display: flex;
flex-direction: row;
justify-content: flex-start;
align-items: center;
margin-bottom: 7px;
`

export const BrandLogo = styled.img`
width: 116px;
height: 116px;
border: 3px solid #263238;
border-radius: 26px;
margin-right: 20px;
object-fit: cover;
`

export const BrandDetailsBox = styled.div`

`
export const Title = styled.h1`
font-family: 'Poppins';
font-style: normal;
font-weight: 600;
font-size: 24px;
line-height: 36px;
color: #23314B;
margin-right: 25px;
`

export const Subtitle = styled.h2`
height: 12px;
font-family: 'Poppins';
font-size: 16px;
color: #A9B8CD;
font-weight: bold;
`

export const LineDivider = styled.div`
width: 100%;
border: 1px solid #D8D8E8;
margin:50px 0 20px 0;
`


export const ContainerListCampaigns = styled.div`
display:flex;
flex-direction: row;
flex-wrap: wrap;
`

export const ButtonAddCampaing = styled.div`
width: 256.5px;
height: 251.14px;
background: linear-gradient(125.26deg, #8363F3 9.14%, #4E2EC3 96.81%);
border-radius: 12px;
margin: 40px 20px 0 0;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
cursor:pointer;
transition: all .3s;

&:hover{
  opacity: .9;
  transform: translateX(10px);
};
`

export const IconBg = styled.div`
width: 86px;
height: 86px;
background-color: hsl(234, 92%, 80%, 0.15);
border-radius: 50px;
display: flex;
justify-content: center;
align-items: center;
margin:17px;
`
export const Icon = styled.img`
width: 32px;
height: 32px;
`

export const LabelButtonAddCampaign = styled.h5`
font-family: 'Poppins';
font-style: normal;
font-weight: 600;
font-size: 16px;
text-align: center;
color: #FFFFFF;
`