import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FetchAllCampaigns } from '../../store/campaigns/campaigns.actions';
import { setCampaignsInfo } from '../../store/campaigns/campaigns.reducer';
import { useAppDispatch } from '../../store/storeHook';
import { ILoadingProps } from '../../utils/globalTypes';

import CampaignsLayout from './campaigns.layout';

const CampaignsController: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [campaigns, setCampaigns] = useState([])
  const [showFilter, setShowFilter] = useState(false);
  const [loading, setLoading] = useState({ status: true, type: 'fetching' });

  const toggleFilter = () => setShowFilter(!showFilter);
  const toggleLoading = (status: ILoadingProps) => setLoading(status);

  const handleNavigationCreateCampaign = () => {
    dispatch(setCampaignsInfo({
      stepNumber: 1,
      title: '',
      landscape: '',
      isComplete: false,
      brandsList: [],
      changedLandscape: false,
      id: '',
      brand: '',
      initialDate: '',
      endDate: '',
      recap: '',
      influencers: [],
      influencerData: {
        name: '',
        cluster: '',
        profilePhoto: '',
        instagram: {
          isActive: false,
          idInstagram: '',
          numberOfPosts: '',
          storiesSigned: '',
          storiesDelivered: '',
          metricsPosts: [],
          metricsStories: [],
          followers: ''
        },
        twitter: {
          isActive: false,
          idTwitter: '',
          numberOfTweets: '',
          metrics: [],
          followers: ''
        },
        youtube: {
          isActive: false,
          numberOfVideos: '',
          metrics: [],
          followers: ''
        },
        tiktok: {
          isActive: false,
          idTiktok: '',
          numberOfPosts: '',
          metrics: [],
          followers: ''
        },
      },
      instagramQualitative: {
        overviewCampaign: '',
        overviewFeed: '',
        overviewStories: '',
        qualitativeMetrics: []
      },
      twitterQualitative: {
        overviewCampaign: '',
        overviewTweets: '',
        qualitativeMetrics: []
      },
      youtubeQualitative: {
        overviewCampaign: '',
        overviewVideos: '',
        qualitativeMetrics: []
      },
      tiktokQualitative: {
        overviewCampaign: '',
        overviewPosts: '',
        qualitativeMetrics: []
      },
      reportLink: '',
      reportPassword: ''
    }))
    navigate('/campanhas/criar')
  }

  const handleCompletedCampaign = (campaign: any) => {
    // console.log("campaign ->", campaign);

    dispatch(setCampaignsInfo({
      ...campaign
    }))

    setTimeout(() => {
      navigate('/campanhas/finalizada');
    }, 200);
  }

  useEffect(() => {
    FetchAllCampaigns(setCampaigns, toggleLoading);
  }, [])

  return <CampaignsLayout
    localState={{
      showFilter,
      campaigns,
      loading
    }}
    handlers={{
      toggleFilter,
      handleNavigationCreateCampaign,
      handleCompletedCampaign
    }}
  />
}
export default CampaignsController;