import React from "react";
import {
  InfluencerFollowers,
  InfluencerInfo,
  InfluencerName,
  InfluencerPhoto,
  InfluencerPosition,
  InfluencerWrapper,
} from "./influencer.styles";
import { IInfluencerInstagramProps } from "./influencer.types";
import { maskFollowers } from "utils/validations";

const InfluencerInstagram: React.FC<IInfluencerInstagramProps> = ({ influencer }) => {
  return (
    <InfluencerWrapper>
      <InfluencerPhoto src={influencer.profilePhoto} />
      <InfluencerInfo>
        <InfluencerName>{influencer.name.toUpperCase()}</InfluencerName>
        <InfluencerPosition>{influencer.cluster}</InfluencerPosition>
        <InfluencerFollowers>{maskFollowers(influencer.twitter.followers)} seguidores</InfluencerFollowers>
      </InfluencerInfo>
    </InfluencerWrapper>
  );
};
export default InfluencerInstagram;
