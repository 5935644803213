import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../../../store/storeHook';
import { FetchSignOut } from '../../../store/user/user.actions';
import MainContent from '../../components/mainContent/mainContent.components';
import MainHeader from '../../components/mainHeader/mainHeader.components';
import CustomModal from '../../components/modal/modal.components';
import SideMenu from "../../components/sideMenu/sideMenu.component";
import { Container } from './privatePage.styles';
import { IPrivatePageProps } from './privatePage.types';


const PrivatePage: React.FC<IPrivatePageProps> = ({ children, headerOff }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleGoBackLogin = () => {
    navigate('/login');
  }
  const handleSignOut = () => {
    dispatch(FetchSignOut(handleGoBackLogin))
  }

  return (
    <Container>
      <CustomModal />
      <SideMenu />
      <MainContent>
        <MainHeader handleSignOut={handleSignOut} showHeaderBg={!headerOff} />
        {children}
      </MainContent>
    </Container>
  )

}

export default PrivatePage;