import React from 'react';
import {
  TwitterDetails,
  PanelContent,
  PanelBody,
  Divider,
} from './twitterDetails.styles';
import InputComponent from '../../../../global/components/input/input.component';
import searchIcon from '../../../../assets/icons/search_metrics.svg';
import ButtonSecondary from '../../../../global/components/buttons/secondary/buttonSecondary.component';
import ConcludedMetrics from '../concludedMetrics/concludedMetrics.component';
import { IInfluencerProps } from '../../../../store/campaigns/campaigns.types';
import { onlyNumbers } from 'utils/validations';

interface ITwitterDetailsProps {
  value: IInfluencerProps;
  onChange: any;
  toggleModalVisible: () => void;
}

const TwitterDetailsComponent: React.FC<ITwitterDetailsProps> = (props) => {

  const {
    value,
    onChange,
    toggleModalVisible
  } = props;
  return (
    <TwitterDetails>
      <PanelContent>
        <PanelBody>
          <InputComponent
            label='@ do influenciador'
            value={value.twitter.idTwitter}
            onChange={(idTwitter) => onChange({
              influencerData: {
                ...value,
                twitter: {
                  ...value.twitter,
                  idTwitter
                }
              }
            })}
            icon={searchIcon}
          />
        </PanelBody>
        <Divider />
        <PanelBody>
          <InputComponent
            label='Número de seguidores'
            value={value.twitter.followers}
            onChange={(followers) => onChange({
              influencerData: {
                ...value,
                twitter: {
                  ...value.twitter,
                  followers: onlyNumbers(followers.toString())
                }
              }
            })}
          />
        </PanelBody>
        <Divider />
        <PanelBody>
          <InputComponent
            label='Número de tweets'
            value={value.twitter.numberOfTweets}
            onChange={(numberOfTweets) => onChange({
              influencerData: {
                ...value,
                twitter: {
                  ...value.twitter,
                  numberOfTweets: onlyNumbers(numberOfTweets.toString())
                }
              }
            })}
          />
        </PanelBody>
        <Divider />
        <PanelBody>
          <ButtonSecondary
            label='Inserir métricas'
            isDisabled={false}
            loading={false}
            onClick={toggleModalVisible}
            customStyle={{
              'margin-top': '52.5px;',
              'width': '296px;',
              'height': '56px;',
            }}
            customLabelStyle={{
              "font-style": "normal;",
              "font-weight": "400;",
              "font-size": "12px;",
              "line-height": "14px;"
            }}
          />
        </PanelBody>
      </PanelContent>

      {value.twitter.metrics.length > 0 &&
        <ConcludedMetrics
          label='Tweets'
          amount={value.twitter.numberOfTweets}
          onClickEdit={toggleModalVisible}
          onClickDelete={() => onChange({
            influencerData: {
              ...value,
              twitter: {
                ...value.twitter,
                metrics: []
              }
            }
          })}
        />
      }

    </TwitterDetails>
  )
}

export default TwitterDetailsComponent;