import {
  Container,
  Title,
  Subtitle,
  ResultDetailItem,
  InfluencerContainer,
  InfluencerInfo,
  PersonContainer,
  PersonInfo,
  PersonName,
  PersonPhoto,
  PersonPosition,
  Topic,
  SubTopic,
} from "./resultDetailsByInfluencer.styles";
import { IInstragramResultByInfluencerFeedProps, IInstragramResultByInfluencerStoryProps, IResultDetailsByInfluencerProps } from "./resultDetailsByInfluencer.types";
import KpisAnalyzes from "../../../../../../../global/components/kpis/kpis.component";
import lodash from 'lodash';
import { maskNumbers, validateIndividualRangeRateReach, validateMathMax, validateRangeRateEngagementInstagram, validateRangeRateViewsInstagram, validateSingleMetricToArray } from "../../../../../../../utils/validations";
import InstagramResultsByInfluencerFeedPosts from "../postsByInfluencer/postsByInfluencer.component";
import InstagramResultsStoriesByInfluencer from "../storiesByInfluencer/index.component";
import { useMemo } from "react";


const PreviewResultsByInfluencerInstagram: React.FC<IResultDetailsByInfluencerProps> = ({ instagramQualitative, influencers }) => {

  const isThereAnyContent = useMemo(() => {
    let isThereAStory = false;
    let isThereAPost = false;

    influencers
      .filter((item) => item.instagram.isActive)
      .map((influencer) => {
        if (influencer.instagram.metricsStories.length > 0) {
          isThereAStory = true;
        }
        if (influencer.instagram.metricsPosts.length > 0) {
          isThereAPost = true;
        }
      });
    return {
      story: isThereAStory,
      posts: isThereAPost,
    };
  }, [influencers]);

  const handleFeedData = (influencerName: string) => {
    let newArr: IInstragramResultByInfluencerFeedProps[] = []

    influencers.filter((item) => item.instagram.isActive)
      .map((influencer) => {

        if (influencer.name === influencerName) {

          influencer.instagram.metricsPosts.map((metric) => {

            let allIterations = [parseInt(metric.likes || '0'), parseInt(metric.comments || '0'), parseInt(metric.saved || '0'), parseInt(metric.forwards || '0')];

            let rangeRateReach = validateIndividualRangeRateReach(validateSingleMetricToArray(metric.reach), influencer.instagram.followers);
            let rangeRateEngagement = validateRangeRateEngagementInstagram(allIterations, validateSingleMetricToArray(metric.reach));
            let rangeRateViews = validateRangeRateViewsInstagram(validateSingleMetricToArray(metric.views), influencer.instagram.followers);

            newArr.push({
              'Alcance do post': maskNumbers(metric.reach || '0'),
              'Taxa de alcance': `${Number.isInteger(rangeRateReach) ? rangeRateReach : rangeRateReach.toFixed(2)}%`,
              'Impressões totais': maskNumbers(metric.impressions || '0'),
              'Likes': maskNumbers(metric.likes || '0'),
              'Comentários': maskNumbers(metric.comments || '0'),
              'Salvo': maskNumbers(metric.saved || '0'),
              'Encaminham.': maskNumbers(metric.forwards || '0'),
              'Interações': maskNumbers(lodash.sum(allIterations).toString()),
              'Taxa de engajamento': `${Number.isInteger(rangeRateEngagement) ? rangeRateEngagement : rangeRateEngagement.toFixed(2)}%`,
              'Views': maskNumbers(metric.views || '0'),
              'Taxa de views': `${Number.isInteger(rangeRateViews) ? rangeRateViews : rangeRateViews.toFixed(2)}%`,
            })
          })
        }
      })

    return newArr;
  }

  const handleFeedDataKpis = (influencerName: string, influencerCluster: string) => {
    let allImpressions: number[] = [];
    let impressionsIndividual: number[] = [];

    let iterationsIndividual: number[] = [];

    let allReachs: number[] = [];
    let reachsIndividual: number[] = [];

    let rangeRateIndividual: number = 0;
    let allRangeRate: number[] = [];

    let rangeRateEngagementIndividual: number = 0;
    let allRangeRateEngagement: number[] = [];

    let viewsIndividual: number[] = [];
    let allRangeRateViews: number[] = [];
    let rangeRateViewsIndividual: number = 0;

    influencers.filter((item) => item.instagram.isActive)
      .map((influencer) => {

        let allReachGeneral: number[] = [];
        let allIterations: number[] = [];
        let allViews: number[] = [];


        influencer.instagram.metricsPosts.map((metric) => {

          if (influencer.cluster === influencerCluster) {
            //todas as impressoes da campanha
            allImpressions.push(parseInt(metric.impressions || '0'));

            //todas as iteraçoes da campanha
            allIterations.push(parseInt(metric.likes || '0'));
            allIterations.push(parseInt(metric.comments || '0'));
            allIterations.push(parseInt(metric.saved || '0'));
            allIterations.push(parseInt(metric.forwards || '0'));

            //todos os alcances da campanha
            allReachs.push(parseInt(metric.reach || '0'));

            allReachGeneral.push(parseInt(metric.reach || '0'));

            allViews.push(parseInt(metric.views || '0'));
          }


          if (influencer.name === influencerName) {
            impressionsIndividual.push(parseInt(metric.impressions || '0'));

            iterationsIndividual.push(parseInt(metric.likes || '0'));
            iterationsIndividual.push(parseInt(metric.comments || '0'));
            iterationsIndividual.push(parseInt(metric.saved || '0'));
            iterationsIndividual.push(parseInt(metric.forwards || '0'));

            reachsIndividual.push(parseInt(metric.reach || '0'));

            viewsIndividual.push(parseInt(metric.views || '0'));
          }

        })

        if (influencer.cluster === influencerCluster) {
          //taxa de alcance por individuo
          allRangeRate.push(validateIndividualRangeRateReach(allReachGeneral, influencer.instagram.followers))

          //taxa de engajamento por individuo
          allRangeRateEngagement.push(validateRangeRateEngagementInstagram(allIterations, allReachGeneral))

          //taxa de views por individuo
          allRangeRateViews.push(validateRangeRateViewsInstagram(allViews, influencer.instagram.followers))
        }

        //taxa de alcance pelo influencer name
        if (influencer.name === influencerName) {
          rangeRateIndividual = validateIndividualRangeRateReach(reachsIndividual, influencer.instagram.followers);
          rangeRateEngagementIndividual = validateRangeRateEngagementInstagram(iterationsIndividual, reachsIndividual);
          rangeRateViewsIndividual = validateRangeRateViewsInstagram(viewsIndividual, influencer.instagram.followers);
        }

      })

    let avgEngageCluster = (lodash.sum(allRangeRateEngagement) / allRangeRateEngagement.length);
    let avgEngageIndividual = rangeRateEngagementIndividual;

    let avgReachCluster = lodash.sum(allRangeRate) / allRangeRate.length;
    let avgReachIndividual = rangeRateIndividual;

    let avgViewsCluster = lodash.sum(allRangeRateViews) / allRangeRateViews.length;
    let avgViewsIndividual = rangeRateViewsIndividual;

    return {
      engage: [
        {
          name: 'Média do cluster',
          label: '% engajamento',
          value: Number.isInteger(avgEngageCluster) ? avgEngageCluster : parseFloat(avgEngageCluster.toFixed(2))
        },
        {
          name: 'Performance influenciador',
          label: '% engajamento',
          value: Number.isInteger(avgEngageIndividual) ? avgEngageIndividual : parseFloat(avgEngageIndividual.toFixed(2))
        },
      ],
      reach: [
        {
          name: 'Média do cluster',
          label: '% Alcance',
          value: Number.isInteger(avgReachCluster) ? avgReachCluster : parseFloat(avgReachCluster.toFixed(2))
        },
        {
          name: 'Performance influenciador',
          label: '% Alcance',
          value: Number.isInteger(avgReachIndividual) ? avgReachIndividual : parseFloat(avgReachIndividual.toFixed(2))
        },
      ],
      views: [
        {
          name: 'Média do cluster',
          label: '% Views',
          value: Number.isInteger(avgViewsCluster) ? avgViewsCluster : parseFloat(avgViewsCluster.toFixed(2))
        },
        {
          name: 'Performance influenciador',
          label: '% Views',
          value: Number.isInteger(avgViewsIndividual) ? avgViewsIndividual : parseFloat(avgViewsIndividual.toFixed(2))
        },
      ]
    }
  }

  const handleStoryData = (influencerName: string) => {
    let newArr: IInstragramResultByInfluencerStoryProps[] = []

    influencers.filter((item) => item.instagram.isActive)
      .map((influencer) => {
        if (influencer.name === influencerName) {
          let allReachs: number[] = [];
          let allImpressions: number[] = [];
          let allIterations: number[] = [];
          let allClicksOnBrand: number[] = [];
          let allClicksOnLink: number[] = [];

          influencer.instagram.metricsStories.map((metric) => {
            allReachs.push(parseInt(metric.reach || '0'))
            allImpressions.push(parseInt(metric.impressions || '0'))

            allIterations.push(parseInt(metric.linkClicks || '0'))
            allIterations.push(parseInt(metric.clickOnBrandLink || '0'))
            allIterations.push(parseInt(metric.replies || '0'))
            allIterations.push(parseInt(metric.shares || '0'))
            allIterations.push(parseInt(metric.visitsOnProfile || '0'))

            allClicksOnBrand.push(parseInt(metric.clickOnBrandLink || '0'));
            allClicksOnLink.push(parseInt(metric.linkClicks || '0'));
          })

          let rangeReach = validateIndividualRangeRateReach(allReachs, influencer.instagram.followers);

          newArr.push({
            'Contratado': maskNumbers(influencer.instagram.storiesSigned || '0'),
            'Entregue': maskNumbers(influencer.instagram.storiesDelivered || '0'),
            'Alcance máximo': maskNumbers(validateMathMax(allReachs).toString()),
            'Taxa de alcance': `${Number.isInteger(rangeReach) ? rangeReach : rangeReach.toFixed(2)}%`,
            'Impressões totais': maskNumbers(lodash.sum(allImpressions).toString()),
            'Cliques no @ da marca': maskNumbers(lodash.sum(allClicksOnBrand).toString()),
            'Cliques no link': maskNumbers(lodash.sum(allClicksOnLink).toString()),
            'Interações totais': maskNumbers(lodash.sum(allIterations).toString()),
          })
        }
      })

    return newArr;
  }


  const handleStoryDataKpis = (influencerName: string, influencerCluster: string) => {
    let allReachsIndividualCluster: number[] = [];
    let allReachsIndividual: number[] = [];
    let allRangeRateReachs: number[] = [];
    let rangeRateIndividual: number = 0;

    let allImpressionsIndividualCluster: number[] = [];
    let allImpressionsIndividual: number[] = [];

    let allLinkClicksIndividualCluster: number[] = [];
    let allLinkClicksIndividual: number[] = [];



    influencers.filter((item) => item.instagram.isActive)
      .map((influencer) => {

        let allReachGeneral: number[] = [];


        influencer.instagram.metricsStories.map((metric) => {

          if (influencer.cluster === influencerCluster) {
            allReachsIndividualCluster.push(parseInt(metric.reach || '0'));

            //todas as impressoes do cluster
            allImpressionsIndividualCluster.push(parseInt(metric.impressions || '0'));

            //todos os link clicks do cluster
            allLinkClicksIndividualCluster.push(parseInt(metric.linkClicks || '0'));
          }


          if (influencer.name === influencerName) {
            allReachsIndividual.push(parseInt(metric.reach || '0'));
            allImpressionsIndividual.push(parseInt(metric.impressions || '0'));
            allLinkClicksIndividual.push(parseInt(metric.linkClicks || '0'));
          }

        })

        if (influencer.cluster === influencerCluster) {
          //taxas de alcance do cluster
          allRangeRateReachs.push(validateIndividualRangeRateReach(allReachGeneral, influencer.instagram.followers))
        }


        if (influencer.name === influencerName) {
          //taxa de alcance pelo influencer
          rangeRateIndividual = validateIndividualRangeRateReach(allReachsIndividual, influencer.instagram.followers);
        }

      })

    let avgReachCluster = lodash.sum(allRangeRateReachs) / allRangeRateReachs.length;

    let avgImpressionsCluster = lodash.sum(allImpressionsIndividualCluster) / allImpressionsIndividualCluster.length;
    let avgImpressionsIndividual = lodash.sum(allImpressionsIndividual) / allImpressionsIndividual.length;

    let avgLinkClicksCluster = lodash.sum(allLinkClicksIndividualCluster) / allLinkClicksIndividualCluster.length;
    let avgLinkClicksIndividual = lodash.sum(allLinkClicksIndividual) / allLinkClicksIndividual.length;

    return {
      rangeRate: [
        {
          name: 'Média do cluster',
          label: '% de alcance',
          value: Number.isInteger(avgReachCluster) ? avgReachCluster : parseFloat(avgReachCluster.toFixed(2))
        },
        {
          name: 'Performance influenciador',
          label: '% de alcance',
          value: Number.isInteger(rangeRateIndividual) ? rangeRateIndividual : parseFloat(rangeRateIndividual.toFixed(2))
        },
      ],
      impressions: [
        {
          name: 'Média do cluster',
          label: 'impressões',
          value: Number.isInteger(avgImpressionsCluster) ? avgImpressionsCluster : parseFloat(avgImpressionsCluster.toFixed(2))
        },
        {
          name: 'Performance influenciador',
          label: 'impressões',
          value: Number.isInteger(avgImpressionsIndividual) ? avgImpressionsIndividual : parseFloat(avgImpressionsIndividual.toFixed(2))
        },
      ],
      clickLinks: [
        {
          name: 'Média do cluster',
          label: 'cliques em links',
          value: Number.isInteger(avgLinkClicksCluster) ? avgLinkClicksCluster : parseFloat(avgLinkClicksCluster.toFixed(2))
        },
        {
          name: 'Performance influenciador',
          label: 'cliques em links',
          value: Number.isInteger(avgLinkClicksIndividual) ? avgLinkClicksIndividual : parseFloat(avgLinkClicksIndividual.toFixed(2))
        },
      ]
    }
  }


  return (
    <Container>
      <Title>Detalhamento de Resultado</Title>
      <Subtitle>Influenciador a influenciador</Subtitle>
      {instagramQualitative.qualitativeMetrics.map((item, index) => (
        <ResultDetailItem key={index}>
          <InfluencerContainer>
            <InfluencerInfo>
              <PersonContainer>
                <PersonPhoto src={item.influencerPhoto} />
                <PersonInfo>
                  <PersonName>{item.influencerName.toUpperCase()}</PersonName>
                  <PersonPosition>{item.influencerCluster}</PersonPosition>
                </PersonInfo>
              </PersonContainer>
            </InfluencerInfo>
          </InfluencerContainer>
          {isThereAnyContent.posts &&
            <>
              <Topic>Resultados de Feed</Topic>
              <InstagramResultsByInfluencerFeedPosts
                scroll
                data={handleFeedData(item.influencerName)}
              />
              <Topic>KPI's Feed</Topic>
              <SubTopic>Cluster: {item.influencerCluster}</SubTopic>
              <KpisAnalyzes
                columnOne={handleFeedDataKpis(item.influencerName, item.influencerCluster).reach}
                columnOnePerc={true}
                columnTwo={handleFeedDataKpis(item.influencerName, item.influencerCluster).engage}
                columnTwoPerc={true}
                columnThree={handleFeedDataKpis(item.influencerName, item.influencerCluster).views}
                columnThreePerc={true}
              />
            </>
          }

          {isThereAnyContent.story &&
            <>
              <Topic>Resultados Stories</Topic>
              <InstagramResultsStoriesByInfluencer
                scroll={true}
                data={handleStoryData(item.influencerName)}
              />
              <Topic>KPI's Stories</Topic>
              <SubTopic>Cluster: {item.influencerCluster}</SubTopic>
              <KpisAnalyzes
                columnOne={handleStoryDataKpis(item.influencerName, item.influencerCluster).rangeRate}
                columnTwo={handleStoryDataKpis(item.influencerName, item.influencerCluster).impressions}
                columnThree={handleStoryDataKpis(item.influencerName, item.influencerCluster).clickLinks}
              />
            </>}

        </ResultDetailItem>
      ))}
    </Container>
  );
};

export default PreviewResultsByInfluencerInstagram;
