import styled from 'styled-components';

interface IContainerProps {
  customStyle: any;
}
export const Container = styled.div<IContainerProps>`
display: flex;
flex-direction: column;
justify-content: flex-start;
width: auto;
min-height: 93px;
border: 1px solid #D8D8E8;
border-radius: 16px;
background: linear-gradient(90deg, #FFA201 -0.85%, #FE2A6E 53.06%, #9E33EB 97.72%);
margin-top:24px;
${props => props.customStyle}
`

export const Content = styled.div`
display: flex;
flex-direction: column;
padding: 0 33px;
width: auto;
min-height: 89px;
border-radius: 14px;
margin-top: 4px;
background: #fff;
`

export const ContentHeaderBox = styled.div`
display: flex;
width: 100%;
min-height: 89px;
flex-direction: row;
align-items: center;
justify-content:space-between;
`

export const Row = styled.div`
display: flex;
align-items: center;
justify-content:center;
`

export const Title = styled.h1`
font-family: 'Poppins';
font-style: normal;
font-weight: 600;
font-size: 18px;
line-height: 27px;
color: #23314B;
`

export const Icon = styled.img`
margin-right: 18px;
`

export const ExpandButton = styled.button`
background:none;
`