import React from 'react';
import ButtonSecondary from '../../../global/components/buttons/secondary/buttonSecondary.component';
import Footer from '../../../global/components/footer/footer.components';
import PrivatePage from '../../../global/templates/private/privatePage.component';
import { IBrandsDetailsLayoutProps } from './brandsDetails.types';
import {
  Container,
  BrandLogo,
  Row,
  BrandDetailsBox,
  Title,
  RowAligned,
  Subtitle,
  LineDivider,
  ContainerListCampaigns,
  ButtonAddCampaing,
  IconBg,
  Icon,
  LabelButtonAddCampaign
} from './brandsDetails.styles';
import CampaignCard from '../../campaings/components/campaignCard/campaignCard.component';
import iconAddCampaign from '../../../assets/icons/icon-quiz.svg';
import Loading from 'global/components/loading/loading.component';

const BrandsDetailsLayout: React.FC<IBrandsDetailsLayoutProps> = ({ localState, handlers }) => {

  const {
    selectedBrand,
    campaigns,
    loading,
  } = localState;
  const {
    handleEdit,
    handleNavigationCreateCampaign,
    handleRedirectToCampaign
  } = handlers;



  return (
    <PrivatePage>
      <Container>
        <Row>
          <BrandLogo src={selectedBrand.thumb} alt='logo-marca' />
          <BrandDetailsBox>
            <RowAligned>
              <Title>{selectedBrand.brandName}</Title>
              <ButtonSecondary
                label='Editar informações'
                isDisabled={false}
                loading={false}
                onClick={handleEdit}
                customStyle={{
                  'width': '154px;',
                  'height': '32px;'
                }}
                customLabelStyle={{
                  'font-style': 'normal;',
                  'font-weight': '400;',
                  'font-size': '12px;',
                  'line-height': '14px;'
                }}
              />
            </RowAligned>
            <Subtitle>{`Segmento de mercado: ${selectedBrand.niche}`}</Subtitle>
            <Subtitle>{`Funcionários: ${selectedBrand.employees}`}</Subtitle>
            <Subtitle>{`Faturamento anual: ${selectedBrand.annualBilling}`}</Subtitle>
          </BrandDetailsBox>
        </Row>
        <LineDivider />

        <ContainerListCampaigns>
          <ButtonAddCampaing onClick={handleNavigationCreateCampaign}>
            <IconBg>
              <Icon src={iconAddCampaign} alt="Icon adicionar campanha" />
            </IconBg>
            <LabelButtonAddCampaign>
              Criar uma <br />
              nova campanha
            </LabelButtonAddCampaign>
          </ButtonAddCampaing>

          {loading.status ?
            <Loading label='Campanhas' />
            :
            <>
              {campaigns.map((item: any, index: number) =>
                <CampaignCard
                  item={item}
                  key={index}
                  onClick={() => handleRedirectToCampaign(item)}
                />)
              }
            </>
          }

        </ContainerListCampaigns>
      </Container>
      <Footer />
    </PrivatePage>
  )
}

export default BrandsDetailsLayout;