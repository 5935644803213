import React from 'react';

import {
  Container,
  StepNumberBox,
  StepNumberLabel,
  StepDescription
} from './breadcrumbItem.styles';
import { IBreadcrumbItemProps } from './breadcrumbItem.types';

const BreadcrumbItem: React.FC<IBreadcrumbItemProps> = ({ active, label, step }) => {

  return (
    <Container>
      <StepNumberBox active={active}>
        <StepNumberLabel>
          {step}
        </StepNumberLabel>
      </StepNumberBox>
      <StepDescription active={active}>
        {label}
      </StepDescription>

      {step !== 5 &&
        <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M1.55557 10.7634L6.12546 6.19354L1.55556 1.62364" stroke="#A9B8CD" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
      }

    </Container>
  )
}
export default BreadcrumbItem;