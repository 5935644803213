import React from 'react';
import Input from '../../../../global/components/input/input.component';
import CustomDropdown from '../../../../global/components/dropdown/dropdown.component';
import Textarea from '../../../../global/components/textarea/textarea.component';
import UploadPhotoCampaign from '../../components/uploadPhotoCampaign/uploadPhotoCampaign.component';
import {
  Panel,
  PanelTitle,
  PanelSubtitle,
  PanelContent,
  PanelBody,
  Divider,
  InterPanelBorder,
  LineDivider,
  Title
} from './campaignRegisterTwo.styles';
import ButtonSecondary from '../../../../global/components/buttons/secondary/buttonSecondary.component';
import MetricsComponent from '../../components/metrics/metrics.component';
import ConcludedInfluencer from '../../components/concludedInfluencer/concludedInfluencer.component';
import ModalContent from '../../../../global/components/modalContent/modalContent.components';
import { ICampaignRegisterTwoProps } from './campaignRegisterTwo.types';
import InstagramPostMetrics from '../../components/instagramPostMetrics/instagramPostMetrics.component';
import InstagramStoryMetrics from '../../components/instagramStoryMetrics/instagramStoryMetrics.component';
import TwitterMetrics from '../../components/twitterMetrics/twitterMetrics.component';
import YoutubeMetrics from '../../components/youtubeMetrics/youtubeMetrics.component';
import TiktokMetrics from '../../components/tiktokMetrics/tiktokMetrics.component';
import { IInfluencerProps } from '../../../../store/campaigns/campaigns.types';

const CampaignRegisterTwo: React.FC<ICampaignRegisterTwoProps> = (props) => {

  const {
    influencers,
    modal,
    influencerData,
    toggleModalVisible,
    handleSetStates,
    handleSaveInfluencer,
    handleEditInfluencerCompleted
  } = props;

  return (
    <>
      <Panel>
        <PanelTitle>Setup de influenciadores</PanelTitle>
        <PanelSubtitle>Informe os influenciadores envolvidos na campanha e o escopo contratado</PanelSubtitle>

        {influencers?.map((item: IInfluencerProps, index: number) => <ConcludedInfluencer
          key={index}
          item={item}
          onClickEdit={handleEditInfluencerCompleted}
        />)}


        {influencers?.length > 0 &&
          <>
            <LineDivider />
            <Title>Novo influenciador</Title>
          </>
        }


        <InterPanelBorder>
          <PanelContent>
            <PanelBody>
              <Input
                label='Nome do influenciador'
                value={influencerData.name}
                onChange={(name) => handleSetStates({
                  influencerData: {
                    ...influencerData,
                    name
                  }
                })}
              />
            </PanelBody>
            <Divider />
            <PanelBody>
              <CustomDropdown
                label='Cluster'
                value={influencerData.cluster}
                listItem={['Micro', 'Engajador', 'Amplificador', 'Broadcaster']}
                onChange={(cluster) => handleSetStates({
                  influencerData: {
                    ...influencerData,
                    cluster
                  }
                })}
              />
            </PanelBody>
          </PanelContent>

          <PanelContent>
            <PanelBody>
              <UploadPhotoCampaign
                isInfluencer={true}
                value={influencerData.profilePhoto?.name}
                onChange={(profilePhoto: any) => handleSetStates({
                  influencerData: {
                    ...influencerData,
                    profilePhoto
                  }
                })}
              />
            </PanelBody>
          </PanelContent>

          <PanelContent>
            <PanelBody>
              <Textarea
                label='Escopo contratado'
                value={influencerData.signedScope}
                onChange={(signedScope) => handleSetStates({
                  influencerData: {
                    ...influencerData,
                    signedScope
                  }
                })}
                customStyle={{ "margin-top": "24px;" }}
              />
            </PanelBody>
          </PanelContent>

          <PanelTitle style={{ marginTop: 27 }}>Métricas</PanelTitle>

          <MetricsComponent
            label='Instagram'
            active={influencerData.instagram.isActive}
            value={influencerData}
            toggleActive={(isActive) => handleSetStates({
              influencerData: {
                ...influencerData,
                instagram: {
                  ...influencerData.instagram,
                  isActive
                }
              }
            })}
            onChange={handleSetStates}
            toggleModalVisible={toggleModalVisible}

          />
          <MetricsComponent
            label='Twitter'
            active={influencerData.twitter.isActive}
            value={influencerData}
            toggleActive={(isActive) => handleSetStates({
              influencerData: {
                ...influencerData,
                twitter: {
                  ...influencerData.twitter,
                  isActive
                }
              }
            })}
            onChange={handleSetStates}
            toggleModalVisible={() => toggleModalVisible('twitter')} />

          <MetricsComponent
            label='Youtube'
            active={influencerData.youtube.isActive}
            value={influencerData}
            toggleActive={(isActive) => handleSetStates({
              influencerData: {
                ...influencerData,
                youtube: {
                  ...influencerData.youtube,
                  isActive
                }
              }
            })}
            onChange={handleSetStates}
            toggleModalVisible={() => toggleModalVisible('youtube')} />

          <MetricsComponent
            label='TikTok'
            active={influencerData.tiktok.isActive}
            value={influencerData}
            toggleActive={(isActive) => handleSetStates({
              influencerData: {
                ...influencerData,
                tiktok: {
                  ...influencerData.tiktok,
                  isActive
                }
              }
            })}
            onChange={handleSetStates}
            toggleModalVisible={() => toggleModalVisible('tiktok')} />

        </InterPanelBorder>
        <ButtonSecondary
          label='Salvar informações do influenciador'
          isDisabled={false}
          loading={false}
          onClick={handleSaveInfluencer}
          customStyle={{
            'margin-top': '25px;',
            'width': '256px;',
            'height': '56px;',
            'align-self': 'flex-end;'
          }}
          customLabelStyle={{
            "font-style": "normal;",
            "font-weight": "400;",
            "font-size": "12px;",
            "line-height": "14px;"
          }}
        />
        <ModalContent isVisible={modal.isVisible}>
          {modal.modalType === 'instagramPosts' &&
            <InstagramPostMetrics
              value={influencerData}
              onChange={handleSetStates}
              toggleModalVisible={() => toggleModalVisible('instagramPosts')} />
          }
          {modal.modalType === 'instagramStories' &&
            <InstagramStoryMetrics
              value={influencerData}
              onChange={handleSetStates}
              toggleModalVisible={() => toggleModalVisible('instagramStories')} />
          }
          {modal.modalType === 'twitter' &&
            <TwitterMetrics
              value={influencerData}
              onChange={handleSetStates}
              toggleModalVisible={() => toggleModalVisible('twitter')} />
          }
          {modal.modalType === 'youtube' &&
            <YoutubeMetrics
              value={influencerData}
              onChange={handleSetStates}
              toggleModalVisible={() => toggleModalVisible('youtube')} />
          }
          {modal.modalType === 'tiktok' &&
            <TiktokMetrics
              value={influencerData}
              onChange={handleSetStates}
              toggleModalVisible={() => toggleModalVisible('tiktok')} />
          }
        </ModalContent>
      </Panel>
    </>
  )
}

export default CampaignRegisterTwo;