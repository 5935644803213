import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  height: 390px;
  position: relative;
`

export const BgImage = styled.div`
position: absolute;
height: 390px;
width: 100%;
background-color:white;
  background-repeat:no-repeat;
  background-size:cover;
  background-position: center center;
`


export const Content = styled.div`
  width: '100%';
  height: 390px;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.3) 0%, #263238 100%), rgba(38, 50, 56, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: relative;
  padding-left: 53px;
  padding-right: 39px;
  @media(max-width: 420px) {
    padding:0;
  }
`

export const LogoInfo = styled.div`
  width: '100%';
  display: inline-flex;
  flex-direction: row;
  align-items: flex-start;
  position: relative;
  bottom: -20px;
  @media(max-width: 420px) {
    justify-content: center;
    align-items: center;
    flex-direction: column;
    bottom: 0px;
    padding-bottom: 20px;
    
  }
`

export const CompanyLogo = styled.img`
  width: 128px;
  height: 128px;
  border-radius: 18px;
  background: linear-gradient(180deg, rgba(0, 0, 0) 0%, #000000 100%), rgba(0, 0, 0);
  position: relative;
  object-fit: contain;
`
export const TitleWrapper = styled.div`
  margin-left: 19px;
  display: inline-flex;
  flex-direction: column;
  @media(max-width: 420px) {
    margin-left: 0px;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
`

export const PerformanceText = styled.span`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  color: #ff4141;
  @media(max-width: 420px) {
   font-size: 20px;
  }
`
export const Title = styled.span`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  color: #ffffff;
  @media(max-width: 420px) {
   font-size: 30px;
  }
`

export const MudahMoreCompany = styled.p`
  width: 153px;
  height: 24px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-align: right;
  color: #ff4141;
`
