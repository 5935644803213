import React from 'react';
import {
  TiktokDetails,
  PanelContent,
  PanelBody,
  Divider,
} from './tiktokDetails.styles';
import InputComponent from '../../../../global/components/input/input.component';
import ButtonSecondary from '../../../../global/components/buttons/secondary/buttonSecondary.component';
import ConcludedMetrics from '../concludedMetrics/concludedMetrics.component';
import { IInfluencerProps } from '../../../../store/campaigns/campaigns.types';
import { onlyNumbers } from 'utils/validations';

interface ITiktokDetailsProps {
  value: IInfluencerProps;
  onChange: (item: any) => void;
  toggleModalVisible: () => void;
}

const TiktokDetailsComponent: React.FC<ITiktokDetailsProps> = (props) => {
  const {
    value,
    onChange,
    toggleModalVisible
  } = props;
  return (

    <TiktokDetails>
      <PanelContent>
        <PanelBody>
          <InputComponent
            label='@ do influenciador'
            value={value.tiktok.idTiktok}
            onChange={(idTiktok) => onChange({
              influencerData: {
                ...value,
                tiktok: {
                  ...value.tiktok,
                  idTiktok
                }
              }
            })}
          />
        </PanelBody>
        <Divider />
        <PanelBody>
          <InputComponent
            label='Número de seguidores'
            value={value.tiktok.followers}
            onChange={(followers) => onChange({
              influencerData: {
                ...value,
                tiktok: {
                  ...value.tiktok,
                  followers: onlyNumbers(followers.toString())
                }
              }
            })}
          />
        </PanelBody>
        <Divider />
        <PanelBody>
          <InputComponent
            label='Número de posts'
            value={value.tiktok.numberOfPosts}
            onChange={(numberOfPosts) => onChange({
              influencerData: {
                ...value,
                tiktok: {
                  ...value.tiktok,
                  numberOfPosts: onlyNumbers(numberOfPosts.toString())
                }
              }
            })}
          />
        </PanelBody>
        <Divider />
        <PanelBody>
          <ButtonSecondary
            label='Inserir métricas'
            isDisabled={false}
            loading={false}
            onClick={toggleModalVisible}
            customStyle={{
              'margin-top': '52.5px;',
              'width': '296px;',
              'height': '56px;',
            }}
            customLabelStyle={{
              "font-style": "normal;",
              "font-weight": "400;",
              "font-size": "12px;",
              "line-height": "14px;"
            }}
          />
        </PanelBody>
      </PanelContent>
      {value.tiktok.metrics.length > 0 &&
        <ConcludedMetrics
          label='Posts'
          amount={value.tiktok.numberOfPosts}
          onClickEdit={toggleModalVisible}
          onClickDelete={() => onChange({
            influencerData: {
              ...value,
              tiktok: {
                ...value.tiktok,
                metrics: []
              }
            }
          })}
        />
      }
    </TiktokDetails>
  )
}

export default TiktokDetailsComponent;