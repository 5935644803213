/* eslint-disable import/no-anonymous-default-export */
export default {
  title: 'light',
  colors: {
    primary: '#DCDCDC',
    primary2: '#4C6072',
    secondary: '#FFF',
    tertiary: '#F5F5F5',
    white: '#fff',
    black: '#000',
    dark: '#23314B',
    darkGreen: '#1B2126',
    gray: '#E9EAEF',
    purple: '#4F2FC4',
    success: '#03bb85',
    info: '#3b5988',
    warning: '#ff6961',
    darkGray: '#263238',
    midGray: '#37474E',
  },
  fonts: {
    popins: 'Popins',
    inter: 'Poppins',
    nexa: 'Nexa',
  },
  weight: {
    regular: 400,
    medium: 500,
    large: 600,
    bold: 700,
  },
}
