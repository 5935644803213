import { IUserReducerProps } from './user.types';
import { createSlice } from '@reduxjs/toolkit';

const initialState: IUserReducerProps = {
  id: '',
  fullname: '',
  email: '',
  password: '',
  thumb: '',
  userHierarchy: '',
  active: true,
  lastLogin: '',
  editingUser: {
    id: '',
    fullname: '',
    email: '',
    password: '',
    thumb: '',
    userHierarchy: '',
    active: true,
    lastLogin: '',
  }
} as IUserReducerProps;

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserInfo: (state: any, action) => {
      const properties = action.payload;
      const newState = properties;
      const values = Object.keys(properties) as Array<keyof typeof properties>;
      for (const key of values) {
        state[key] = newState[key];
      }
    }
  }
});

export const { setUserInfo } = userSlice.actions;
export default userSlice.reducer;
