import React from "react";
import Youtube from "../../../../assets/icons/youtube_report.svg";
import Img from "../../../../assets/img/youtube_report_bg.png";
import VideosYoutube from "./components/panorama/index/index.component";
import ResultDetailsByInfluencer from "./components/resultDetailsByInfluencer/index/index.component";
import {
  Container,
  ContentWrapper,
  Description,
  Icon,
  YoutubeTitle,
  YoutubeTopContainer,
  YoutubeWrapper,
  Title,
  Topic,
  TopicContent,
  WhiteWrapper,
} from "./youtube.styles";
import { IYoutubeReportProps } from "./youtube.types";
import EditIcon from "../../../../assets/icons/edit.svg";
import ButtonSecondary from "../../buttons/secondary/buttonSecondary.component";

const YoutubeReport: React.FC<IYoutubeReportProps> = (props) => {
  const { editable, influencers, youtubeQualitative, position } = props;

  return (
    <Container id="#youtube">
      <TopicContent>
        <Topic>{position}. Youtube</Topic>
        {editable && (
          <ButtonSecondary
            label="Editar Informações"
            isDisabled={false}
            loading={false}
            onClick={() => null}
            icon={EditIcon}
            customStyle={{ width: "180px", height: "32px" }}
            customLabelStyle={{
              fontSize: "13px",
              lineHeight: "14px",
              fontWeight: "400",
              fontFamily: "Montserrat",
            }}
          />
        )}
      </TopicContent>
      <WhiteWrapper>
        <YoutubeTopContainer
          style={{
            backgroundImage: `url(${Img})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
          }}
        >
          <YoutubeWrapper>
            <Icon src={Youtube} />
            <YoutubeTitle>Performance no Youtube</YoutubeTitle>
          </YoutubeWrapper>
        </YoutubeTopContainer>
        <ContentWrapper>
          <Title>Panorama geral da campanha</Title>
          <Description>{youtubeQualitative.overviewCampaign}</Description>

          <VideosYoutube
            influencers={influencers}
            youtubeQualitative={youtubeQualitative}
          />
          <ResultDetailsByInfluencer
            influencers={influencers}
            youtubeQualitative={youtubeQualitative}
          />
        </ContentWrapper>
      </WhiteWrapper>
    </Container>
  );
};
export default YoutubeReport;
