import React from 'react';

import { ICheckboxProps } from './checkbox.types';

import { Container, Checkbox, Label, CheckboxFilling } from './checkbox.styles'

const CheckboxComponent: React.FC<ICheckboxProps> = ({ label, checked, onClick }) => {

  return (
    <Container onClick={() => onClick(!checked)}>
      <Checkbox>
        {checked && <CheckboxFilling />}
      </Checkbox>
      <Label>{label}</Label>
    </Container>
  )

}

export default CheckboxComponent;