import React, { useMemo } from "react";
import BonusesAnalyzes from "../../../../../../global/components/bonuses/bonuses.component";
import CustomTable from "../../../../../../global/components/table/table.component";
import Top3Twitter from "./top3Twitter/top3Twitter.component";
import { Container, Description, Title } from "./panorama.styles";
import { IPostsTikTokProps } from "./panorama.types";
import PostsByInfluencer from "./postsByInfluencer/postsByInfluencer.component";
import lodash from 'lodash';
import { IIndividualTwitterProps } from "../../twitter.types";
import { maskNumbers, validateRangeRateEngagementTwitter } from "utils/validations";
import TwitterTableGeneralInfo from "../tableGeneralFeedInfo/index.component";
import TwitterPostsByInfluencer from "../postsByInfluencer/postsByInfluencer.component";

interface INewArrProps {
  name: string;
  photo: string;
  cluster: string;
  value: number;
}

const PostTwitter: React.FC<IPostsTikTokProps> = ({ influencers, twitterQualitative }) => {

  const generalInfo = useMemo(() => {

    let allImpressions: number[] = [];

    let allEngagement: number[] = [];

    let allRateEngagement: number[] = [];

    let allViews: number[] = [];

    let allFolowers: number[] = [];

    influencers.filter((item) => item.twitter.isActive)
      .map((influencer) => {
        let allEngagementIndividual: number[] = [];

        influencer.twitter.metrics.map((metric) => {

          allImpressions.push(parseInt(metric.impressions || '0'));

          allEngagement.push(parseInt(metric.engagements || '0'));
          allEngagementIndividual.push(parseInt(metric.engagements || '0'));

          allViews.push(parseInt(metric.views || '0'));
        });

        allRateEngagement.push(validateRangeRateEngagementTwitter(allEngagementIndividual, influencer.twitter.followers));
        allFolowers.push(parseInt(influencer.twitter.followers));
      })

    let rateEngagement = lodash.sum(allRateEngagement) / allRateEngagement.length;

    return [{
      'Nº de influenciadores': influencers.filter((item) => item.twitter.isActive).length,
      'Total de impressões': `${maskNumbers(lodash.sum(allImpressions).toString())}`,
      'Engajamento total': `${maskNumbers(lodash.sum(allEngagement).toString())}`,
      'Visualizações': `${maskNumbers(lodash.sum(allViews).toString())}`,
      'Taxa de engajamento': `${Number.isInteger(rateEngagement) ? rateEngagement : rateEngagement.toFixed(2)}%`,
    }]
    //eslint-disable-next-line
  }, [influencers]);

  const individualInfo = useMemo(() => {
    let allIndividual: IIndividualTwitterProps[] = [];

    influencers.filter((item) => item.twitter.isActive)
      .map((influencer) => {
        let allImpressions: number[] = [];
        let allEngagement: number[] = [];
        let allDetailsExpansion: number[] = [];
        let allLikes: number[] = [];
        let allRetweets: number[] = [];
        let allClicksOnHashtag: number[] = [];
        let allClicksOnLink: number[] = [];
        let allViews: number[] = [];
        let allReplies: number[] = [];

        influencer.twitter.metrics.map((metric => {
          allImpressions.push(parseInt(metric.impressions || '0'));
          allEngagement.push(parseInt(metric.engagements || '0'));
          allDetailsExpansion.push(parseInt(metric.expansionDetails || '0'));
          allLikes.push(parseInt(metric.likes || '0'));
          allRetweets.push(parseInt(metric.retweets || '0'));
          allClicksOnHashtag.push(parseInt(metric.clicksOnHashtag || '0'));
          allClicksOnLink.push(parseInt(metric.clicksOnLinks || '0'));
          allViews.push(parseInt(metric.views || '0'));
          allReplies.push(parseInt(metric.replies || '0'));
        }))

        let rateEngagement = validateRangeRateEngagementTwitter(allEngagement, influencer.twitter.followers);

        allIndividual.push({
          'Influenciador': influencer,
          'Total de impressões': `${maskNumbers(lodash.sum(allImpressions).toString())}`,
          'Engajamento total': `${maskNumbers(lodash.sum(allEngagement).toString())}`,
          'Expansão de detalhes': `${maskNumbers(lodash.sum(allDetailsExpansion).toString())}`,
          'Taxa de engajamento': `${Number.isInteger(rateEngagement) ? rateEngagement : rateEngagement.toFixed(2)}%`,
          'Favoritos/\nCurtidas': `${maskNumbers(lodash.sum(allLikes).toString())}`,
          'Retweets': `${maskNumbers(lodash.sum(allRetweets).toString())}`,
          'Cliques em #': `${maskNumbers(lodash.sum(allClicksOnHashtag).toString())}`,
          'Cliques no link': `${maskNumbers(lodash.sum(allClicksOnLink).toString())}`,
          'Visualizações': `${maskNumbers(lodash.sum(allViews).toString())}`,
          'Respostas': `${maskNumbers(lodash.sum(allReplies).toString())}`,
        })

      })

    return allIndividual;

    //eslint-disable-next-line
  }, [influencers])

  const newArrInfluencers = useMemo(() => {
    //novo array alcance
    let newArrImpressions: INewArrProps[] = [];

    //novo array engajamento
    let newArrEngagement: INewArrProps[] = [];

    //novo array engajamento
    let newArrViews: INewArrProps[] = [];

    influencers.filter((item) => item.twitter.isActive)
      .map((influencer) => {
        //alcances de cada influencer
        let allImpressions: number[] = [];

        //todas as interacoes
        let allEngagements: number[] = [];

        let allViews: number[] = [];


        influencer.twitter.metrics.map((metric) => {
          allImpressions.push(parseInt(metric.impressions || '0'));
          allEngagements.push(parseInt(metric.engagements || '0'));
          allViews.push(parseInt(metric.views || '0'))
        })

        newArrImpressions.push({
          name: influencer.name,
          photo: influencer.profilePhoto,
          cluster: influencer.cluster,
          value: lodash.sum(allImpressions)
        })

        let rateEngagement = validateRangeRateEngagementTwitter(allEngagements, influencer.twitter.followers);

        newArrEngagement.push({
          name: influencer.name,
          photo: influencer.profilePhoto,
          cluster: influencer.cluster,
          value: Number.isInteger(rateEngagement) ? rateEngagement : parseFloat(rateEngagement.toFixed(2))
        })

        newArrViews.push({
          name: influencer.name,
          photo: influencer.profilePhoto,
          cluster: influencer.cluster,
          value: lodash.sum(allViews)
        })

      })


    return {
      newArrImpressions: lodash.orderBy(newArrImpressions, ['value'], ['desc']).slice(0, 3),
      newArrEngagement: lodash.orderBy(newArrEngagement, ['value'], ['desc']).slice(0, 3),
      newArrViews: lodash.orderBy(newArrViews, ['value'], ['desc']).slice(0, 3)
    }
  }, [influencers])

  return (
    <Container>
      <TwitterTableGeneralInfo rows={generalInfo} />
      <Title>Panorama geral - Tweets</Title>
      <TwitterPostsByInfluencer data={individualInfo} />
      <Description>
        {twitterQualitative.overviewTweets}
      </Description>
      <Top3Twitter newArrInfluencers={newArrInfluencers} />
    </Container>
  );
};
export default PostTwitter;
