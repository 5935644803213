import React from 'react';
import ButtonPrimary from '../../../global/components/buttons/primary/buttonPrimary.component';
import ButtonSecondary from '../../../global/components/buttons/secondary/buttonSecondary.component';
import Footer from '../../../global/components/footer/footer.components';
import Input from '../../../global/components/input/input.component';
import PrivatePage from '../../../global/templates/private/privatePage.component';
import UploadPhotoComponent from './components/uploadPhoto/uploadPhoto.component';
import { IBrandsRegisterProps } from './brandsRegister.types';
import {
  ButtonsFooterBox,
  Container,
  Divider,
  Panel,
  PanelBody,
  PanelContent,
  PanelTitle,
  Title,
} from './brandsRegister.styles';
import CustomDropdown from '../../../global/components/dropdown/dropdown.component';

const BrandsRegisterLayout: React.FC<IBrandsRegisterProps> = ({ localState, handlers }) => {

  const {
    Niches,
    EmployeesList,
    AnnualBillingList,
    brandName,
    thumb,
    employees,
    niche,
    annualBilling,
    loading,
    buttonDisabled
  } = localState;
  const {
    setBrandName,
    setNiche,
    setEmployees,
    setThumb,
    setAnnualBilling,
    handleBack,
    handleRegister
  } = handlers;

  return (
    <PrivatePage>
      <Container>
        <Title>Cadastrar marca</Title>
        <Panel>
          <PanelTitle>
            Dados principais
          </PanelTitle>
          <PanelContent>
            <PanelBody>
              <Input
                label='Nome da marca'
                value={brandName}
                onChange={setBrandName}
              />
            </PanelBody>
            <Divider />
            <PanelBody>
              <UploadPhotoComponent
                value={thumb?.name}
                onChange={setThumb}
              />
            </PanelBody>
          </PanelContent>
          <PanelContent>
            <PanelBody>
              <CustomDropdown
                label='Segmento de mercado'
                value={niche}
                listItem={Niches}
                onChange={setNiche}
              />
            </PanelBody>
          </PanelContent>
          <PanelContent>
            <PanelBody>
              <CustomDropdown
                label='Funcionarios'
                value={employees}
                listItem={EmployeesList}
                onChange={setEmployees}
              />
            </PanelBody>
            <Divider />
            <PanelBody>
              <CustomDropdown
                label='Faturamento anual'
                value={annualBilling}
                listItem={AnnualBillingList}
                onChange={setAnnualBilling}
              />
            </PanelBody>
          </PanelContent>
        </Panel>
        <ButtonsFooterBox>

          <ButtonSecondary
            label='Cancelar'
            isDisabled={false}
            loading={false}
            onClick={handleBack}
            customStyle={{
              'margin-top': '25px;',
              'width': '335px;',
              'height': '56px;'
            }}
          />

          <ButtonPrimary
            label='Cadastrar'
            isDisabled={buttonDisabled}
            loading={loading.status}
            onClick={handleRegister}
            customStyle={{
              'margin-top': '25px;',
              'width': '335px;',
              'height': '56px;'
            }}
          />
        </ButtonsFooterBox>
      </Container>
      <Footer />
    </PrivatePage>
  )
}

export default BrandsRegisterLayout;