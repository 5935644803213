import { IBrandsProps, IBrandsReducerProps } from './brands.types';
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  brands: [],
  selectedBrand: {} as IBrandsProps
} as IBrandsReducerProps;

export const brandsSlice = createSlice({
  name: 'brands',
  initialState,
  reducers: {
    setBrandsInfo: (state: any, action) => {
      const properties = action.payload;
      const newState = properties;
      const values = Object.keys(properties) as Array<keyof typeof properties>;
      for (const key of values) {
        state[key] = newState[key];
      }
    }
  }
});

export const { setBrandsInfo } = brandsSlice.actions;
export default brandsSlice.reducer;
