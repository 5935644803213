import styled from 'styled-components'

export const Container = styled.div<{ margin?: string }>`
  margin: ${(props) => props.margin};
`

export const Title = styled.p`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  color: #23314b;
`
export const Content = styled.div`
  background: #ffffff;
  border: 1px solid #d8d8e8;
  border-radius: 16px;
  min-height: 100px;
  margin-top: 14px;
`

export const LengendContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-top: 1px solid #d8d8e8;
  padding: 25px 0px;
  gap: 75px;
  margin-top: 32px;
   @media (max-width: 420px) {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding-left: 30px;
  }
`

export const LengendWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
  @media (max-width: 420px) {
    margin-top: 20px;
    margin-right: 0px;
  }
`

export const Lengend = styled.div<{ color: string }>`
  width: 49.12px;
  height: 12px;
  background: ${(props) => props.color};
  border-radius: 16px;
`

export const LengendText = styled.span`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 16px;
  margin-left: 14px;
  color: #000000;
`

export const ChartsContainer = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: repeat(4, 1fr);
  margin-top: 26px;
  gap: 34px;
  padding: 0px 30px;
  @media (max-width: 420px) {
    grid-template-columns: repeat(1, 1fr);
  }
`
