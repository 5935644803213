import React from "react";
import twitterIcon from "../../../../assets/icons/twitter_white.svg";
import Img from "../../../../assets/img/instagram_topo.png";
import PostsTwitter from "./components/panorama/panorama.component";
import ResultDetailsByInfluencer from "./components/resultDetailsByInfluencer/index/index.component";
import {
  Container,
  ContentWrapper,
  Description,
  Icon,
  InstagramTitle,
  TikTokTopContainer,
  TikTokWrapper,
  Title,
  Topic,
  TopicContent,
  WhiteWrapper,
} from "./twitter.styles";
import { ITwitterReportProps } from "./twitter.types";
import EditIcon from "../../../../assets/icons/edit.svg";
import ButtonSecondary from "../../buttons/secondary/buttonSecondary.component";

const TwitterReport: React.FC<ITwitterReportProps> = (props) => {
  const { editable, influencers, twitterQualitative, position } = props;

  return (
    <Container id="#twitter">
      <TopicContent>
        <Topic>{position}. Twitter</Topic>
        {editable && (
          <ButtonSecondary
            label="Editar Informações"
            isDisabled={false}
            loading={false}
            onClick={() => null}
            icon={EditIcon}
            customStyle={{ width: "180px", height: "32px" }}
            customLabelStyle={{
              fontSize: "13px",
              lineHeight: "14px",
              fontWeight: "400",
              fontFamily: "Montserrat",
            }}
          />
        )}
      </TopicContent>
      <WhiteWrapper>
        <TikTokTopContainer
          style={{
            backgroundImage: `url(${Img})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
          }}
        >
          <TikTokWrapper>
            <Icon src={twitterIcon} />
            <InstagramTitle>Performance no Twitter</InstagramTitle>
          </TikTokWrapper>
        </TikTokTopContainer>
        <ContentWrapper>
          <Title>Panorama geral da campanha</Title>
          <Description>{twitterQualitative.overviewCampaign}</Description>

          <PostsTwitter
            influencers={influencers}
            twitterQualitative={twitterQualitative}
          />

          <ResultDetailsByInfluencer
            influencers={influencers}
            twitterQualitative={twitterQualitative}
          />
        </ContentWrapper>
      </WhiteWrapper>
    </Container>
  );
};
export default TwitterReport;
