import React from "react";
import {
  HeaderCell,
  RowCell,
  TableContainer,
  TableHeader,
  TableRow,
} from "./index.styles";

import { IInstagramResultsStoriesByInfluencerProps } from "./index.types";

const InstagramResultsStoriesByInfluencer: React.FC<IInstagramResultsStoriesByInfluencerProps> = ({
  data,
  scroll = false
}) => {

  const headers = !data.length ? [] : Object.keys(data[0]);

  if (!data.length) return null;

  return (
    <TableContainer scroll={scroll}>
      <TableHeader scroll={scroll} collumns={headers.length}>
        {headers.map((item, index) => {
          return (
            <HeaderCell
              key={index}
              width={"100px"}
            >
              {item}
            </HeaderCell>
          );
        })}
      </TableHeader>
      {data.map((item, index) => {
        return (
          <TableRow key={index} scroll={scroll} collumns={headers.length} numberRow={index} lastItem={index + 1 === data.length}>
            <RowCell width={"100px"}>{item["Contratado"]}</RowCell>
            <RowCell width={"100px"}>{item["Entregue"]}</RowCell>
            <RowCell width={"100px"}>{item["Alcance máximo"]}</RowCell>
            <RowCell width={"100px"}>{item["Taxa de alcance"]}</RowCell>
            <RowCell width={"100px"}>{item['Impressões totais']}</RowCell>
            <RowCell width={"100px"}>{item['Cliques no @ da marca']}</RowCell>
            <RowCell width={"100px"}>{item['Cliques no link']}</RowCell>
            <RowCell width={"100px"}>{item["Interações totais"]}</RowCell>
          </TableRow>
        );
      })}
    </TableContainer>
  );
};
export default InstagramResultsStoriesByInfluencer;
