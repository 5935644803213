import React from "react";
import {
  Container,
  ContainerGrid,
  GridItem,
  PersonContainer,
  PersonInfo,
  PersonName,
  PersonPhoto,
  PersonPosition,
  PersonRate,
  Subtitle,
  Title,
  TopicContainer,
  TopicText,
} from "./top3Tiktok.styles";
import { ITop3TiktokProps } from "./top3Tiktok.types";

const Top3TikTok: React.FC<ITop3TiktokProps> = ({ newArrInfluencers }) => {

  return (
    <Container>
      <Title>Top 3 TikTok</Title>
      <Subtitle>
        Os melhores influenciadores em % de views e % de engajamento no Tiktok
      </Subtitle>
      <ContainerGrid>
        {[
          {
            label: "Alcance",
            influencers: newArrInfluencers.newArrReach
          },
          {
            label: "Engajamento",
            influencers: newArrInfluencers.newArrEngagement
          },
          {
            label: "Views",
            influencers: newArrInfluencers.newArrViews
          }
        ].map((item, index) => {
          return (
            <GridItem key={index}>
              <TopicContainer>
                <TopicText>{item.label}</TopicText>
              </TopicContainer>
              {item.influencers.map((influencer, index) => (
                <PersonContainer key={index} border={index + 1 !== item.influencers.length}>
                  <PersonPhoto src={influencer.photo} />
                  <PersonInfo>
                    <PersonName>{influencer.name.toUpperCase()}</PersonName>
                    <PersonPosition>{influencer.cluster}</PersonPosition>
                    <PersonRate>{influencer.value}% de {item.label}</PersonRate>
                  </PersonInfo>
                </PersonContainer>
              ))}
            </GridItem>
          );
        })}
      </ContainerGrid>
    </Container>
  );
};
export default Top3TikTok;
