import React from 'react';
import {
  Title,
  Container,
  PanelContent,
  PanelBody,
  Divider,
  LineDivider,
} from './tiktokMetricsItems.styles';
import InputComponent from '../../../../global/components/input/input.component';
import { ITiktokMetricsProps } from '../../../../store/campaigns/campaigns.types';
import { maskNumbers, maskTime, onlyNumbers } from 'utils/validations';

interface ITiktokMetricsItemsProps {
  index: number;
  item: ITiktokMetricsProps;
  handleSetMetrics: (i: number, p: string, v: React.SetStateAction<string>) => void;
}

const TiktokMetricsItems: React.FC<ITiktokMetricsItemsProps> = ({ index, item, handleSetMetrics }) => {
  const {
    reach,
    likes,
    comments,
    forwards,
    totalReprodution,
    views,
    retention,
    postLink,
    avgWatchedTime,
    watchedFullVideo,
    saved,
  } = item;
  return (
    <Container>
      <Title>Vídeo {index + 1}</Title>

      <PanelContent>
        <PanelBody>
          <InputComponent
            label='Alcance'
            value={maskNumbers(reach)}
            onChange={(reach) => handleSetMetrics(index, 'reach', onlyNumbers(reach.toString()))}
          />
        </PanelBody>
        <Divider />
        <PanelBody>
          <InputComponent
            label='Likes'
            value={maskNumbers(likes)}
            onChange={(likes) => handleSetMetrics(index, 'likes', onlyNumbers(likes.toString()))}
          />
        </PanelBody>

        <Divider />
        <PanelBody>
          <InputComponent
            label='Comentários'
            value={maskNumbers(comments)}
            onChange={(comments) => handleSetMetrics(index, 'comments', onlyNumbers(comments.toString()))}
          />
        </PanelBody>
        <Divider />
        <PanelBody>
          <InputComponent
            label='Encaminhamentos'
            value={maskNumbers(forwards)}
            onChange={(forwards) => handleSetMetrics(index, 'forwards', onlyNumbers(forwards.toString()))}
          />
        </PanelBody>
      </PanelContent>

      <PanelContent>
        <PanelBody>
          <InputComponent
            label='Tempo de reprod. total'
            value={maskTime(totalReprodution)}
            onChange={(totalReprodution) => handleSetMetrics(index, 'totalReprodution', onlyNumbers(totalReprodution.toString()))}
          />
        </PanelBody>
        <Divider />
        <PanelBody>
          <InputComponent
            label='Views'
            value={maskNumbers(views)}
            onChange={(views) => handleSetMetrics(index, 'views', onlyNumbers(views.toString()))}
          />
        </PanelBody>

        <Divider />
        <PanelBody>
          <InputComponent
            label='Retenção'
            value={maskNumbers(retention)}
            onChange={(retention) => handleSetMetrics(index, 'retention', onlyNumbers(retention.toString()))}
          />
        </PanelBody>
        <Divider />
        <PanelBody>
          <InputComponent
            label='Link do post'
            value={postLink}
            onChange={(postLink) => handleSetMetrics(index, 'postLink', postLink.toString())}
          />
        </PanelBody>
      </PanelContent>

      <PanelContent>
        <PanelBody>
          <InputComponent
            label='Tempo médio visto'
            value={avgWatchedTime}
            onChange={(avgWatchedTime) => handleSetMetrics(index, 'avgWatchedTime', avgWatchedTime.toString())}
          />
        </PanelBody>
        <Divider />
        <PanelBody>
          <InputComponent
            label='Assistiu ao vídeo completo (%)'
            value={watchedFullVideo}
            onChange={(watchedFullVideo) => handleSetMetrics(index, 'watchedFullVideo', watchedFullVideo.toString())}
          />
        </PanelBody>

        <Divider />
        <PanelBody>
          <InputComponent
            label='Salvos'
            value={maskNumbers(saved)}
            onChange={(saved) => handleSetMetrics(index, 'saved', onlyNumbers(saved.toString()))}
          />
        </PanelBody>
        <Divider />
        <PanelBody />
      </PanelContent>
      <LineDivider />
    </Container>

  )
}

export default TiktokMetricsItems;