import React from 'react';

import {
  Container,
  ContentHeader,
  ProfilePhoto,
  InfluencerName,
  PanelContent,
  PanelBody,
  Divider
} from './qualityAnalytics.styles';

import InputComponent from '../../../../global/components/input/input.component';
import { IQualitativeMetricsProps } from '../../../../store/campaigns/campaigns.types';
import InputAreaComponent from 'global/components/inputArea/index.component';

interface IQualitativeMetricsComponentProps {
  item: IQualitativeMetricsProps;
  index: number;
  handleSetMetrics: (i: number, p: string, v: React.SetStateAction<string>) => void;
}

const QualityAnalytics: React.FC<IQualitativeMetricsComponentProps> = ({ item, index, handleSetMetrics }) => {

  const {
    contentInsights,
    analyzeQualitative,
    analyzeComments,
    linkPubli
  } = item;

  return (
    <Container>
      <ContentHeader>
        <ProfilePhoto src={item.influencerPhoto} />
        <InfluencerName>
          {item.influencerName}<span>  | {item.influencerCluster}</span>
        </InfluencerName>
      </ContentHeader>

      <PanelContent>
        <PanelBody>
          <InputAreaComponent
            label='Insights de conteúdo'
            value={contentInsights}
            onChange={(contentInsights) => handleSetMetrics(index, 'contentInsights', contentInsights)}
          />
        </PanelBody>
        <Divider />
        <PanelBody>
          <InputAreaComponent
            label='Análise qualitativa'
            value={analyzeQualitative}
            onChange={(analyzeQualitative) => handleSetMetrics(index, 'analyzeQualitative', analyzeQualitative)}
          />
        </PanelBody>
        <Divider />
        <PanelBody>
          <InputAreaComponent
            label='Análise de comentários'
            value={analyzeComments}
            onChange={(analyzeComments) => handleSetMetrics(index, 'analyzeComments', analyzeComments)}
          />
        </PanelBody>
      </PanelContent>

      <PanelContent>
        <PanelBody>
          <InputComponent
            label='Publicação em destaque'
            value={linkPubli}
            onChange={(linkPubli) => handleSetMetrics(index, 'linkPubli', linkPubli)}
          />
        </PanelBody>
      </PanelContent>

    </Container>
  )
}
export default QualityAnalytics;