import React from 'react';
import Modal from 'react-bootstrap/Modal';
import { useDispatch } from 'react-redux';
import { motion } from 'framer-motion';

import './modalStyles.scss';

import ButtonPrimary from '../buttons/primary/buttonPrimary.component';
import ButtonSecondary from '../buttons/secondary/buttonSecondary.component';
import { IModalReducerProps } from '../../../store/modal/modal.types';
import { setProperties } from '../../../store/modal/modal.reducer';
import { useAppSelector } from '../../../store/storeHook';

const CustomModal: React.FC = () => {

  const {
    isVisible,
    title,
    description,
    confirmation,
    labelBtn,
    labelBtnLeft,
    labelBtnRight,
    icon,
    onClick,
    onClickBtnLeft,
    onClickBtnRight
  } = useAppSelector((state) => state.modalReducer);

  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(setProperties({
      isVisible: false
    } as IModalReducerProps));
  }

  return (
    <Modal
      size='lg'
      show={isVisible}
      style={{ backgroundColor: 'transparent' }}
    >

      <div className='content'>

        {icon === 'warning' &&
          <svg width="58px" height="58px" viewBox="-2.4 -2.4 28.80 28.80" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="#f06c02" strokeWidth="0.00024000000000000003">
            <g id="SVGRepo_bgCarrier" strokeWidth="0" />
            <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round" stroke="#CCCCCC" strokeWidth="0.43200000000000005" />
            <g id="SVGRepo_iconCarrier"> <g id="style=stroke"> <g id="error-box"> <path id="vector (Stroke)" fillRule="evenodd" clipRule="evenodd" d="M1.25 8C1.25 4.27208 4.27208 1.25 8 1.25H16C19.7279 1.25 22.75 4.27208 22.75 8V16C22.75 19.7279 19.7279 22.75 16 22.75H8C4.27208 22.75 1.25 19.7279 1.25 16V8ZM8 2.75C5.10051 2.75 2.75 5.10051 2.75 8V16C2.75 18.8995 5.10051 21.25 8 21.25H16C18.8995 21.25 21.25 18.8995 21.25 16V8C21.25 5.10051 18.8995 2.75 16 2.75H8Z" fill="#f06c02" /> <path id="vector (Stroke)_2" fillRule="evenodd" clipRule="evenodd" d="M8.46967 8.46967C8.76257 8.17678 9.23744 8.17678 9.53033 8.46967L15.5303 14.4697C15.8232 14.7626 15.8232 15.2374 15.5303 15.5303C15.2374 15.8232 14.7625 15.8232 14.4696 15.5303L8.46967 9.53033C8.17678 9.23743 8.17678 8.76256 8.46967 8.46967Z" fill="#f06c02" /> <path id="vector (Stroke)_3" fillRule="evenodd" clipRule="evenodd" d="M15.5303 8.46967C15.8232 8.76257 15.8232 9.23744 15.5303 9.53033L9.53033 15.5303C9.23743 15.8232 8.76256 15.8232 8.46967 15.5303C8.17678 15.2374 8.17678 14.7625 8.46967 14.4696L14.4697 8.46967C14.7626 8.17678 15.2374 8.17678 15.5303 8.46967Z" fill="#f06c02" /> </g> </g> </g>
          </svg>
        }
        {icon === 'success' &&
          <svg width="58" height="58" viewBox="0 0 58 58" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M29 53.65C42.6138 53.65 53.65 42.6138 53.65 29C53.65 15.3862 42.6138 4.35 29 4.35C15.3862 4.35 4.35 15.3862 4.35 29C4.35 42.6138 15.3862 53.65 29 53.65ZM29 58C45.0163 58 58 45.0163 58 29C58 12.9837 45.0163 0 29 0C12.9837 0 0 12.9837 0 29C0 45.0163 12.9837 58 29 58Z" fill="#3BC5CB" />
            <path fillRule="evenodd" clipRule="evenodd" d="M41.9813 20.1757C42.8307 21.025 42.8307 22.4022 41.9813 23.2516L25.3758 39.857L16.5879 31.0691C15.7385 30.2197 15.7385 28.8426 16.5879 27.9932C17.4373 27.1438 18.8144 27.1438 19.6638 27.9932L25.3758 33.7052L38.9054 20.1757C39.7548 19.3263 41.1319 19.3263 41.9813 20.1757Z" fill="#3BC5CB" />
          </svg>

        }
        {icon === 'user' &&
          <svg width="58px" height="58px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="#4F2FC4">
            <g id="SVGRepo_bgCarrier" strokeWidth="0" />
            <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round" />
            <g id="SVGRepo_iconCarrier"> <path d="M18 18.86H17.24C16.44 18.86 15.68 19.17 15.12 19.73L13.41 21.42C12.63 22.19 11.36 22.19 10.58 21.42L8.87 19.73C8.31 19.17 7.54 18.86 6.75 18.86H6C4.34 18.86 3 17.53 3 15.89V4.97998C3 3.33998 4.34 2.01001 6 2.01001H18C19.66 2.01001 21 3.33998 21 4.97998V15.89C21 17.52 19.66 18.86 18 18.86Z" stroke="#4F2FC4" strokeWidth="1.5" stroke-miterlimit="10" strokeLinecap="round" strokeLinejoin="round" /> <path d="M11.9999 10.0001C13.2868 10.0001 14.33 8.95687 14.33 7.67004C14.33 6.38322 13.2868 5.34009 11.9999 5.34009C10.7131 5.34009 9.66992 6.38322 9.66992 7.67004C9.66992 8.95687 10.7131 10.0001 11.9999 10.0001Z" stroke="#4F2FC4" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" /> <path d="M16 15.6601C16 13.8601 14.21 12.4001 12 12.4001C9.79 12.4001 8 13.8601 8 15.6601" stroke="#4F2FC4" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" /> </g>
          </svg>
        }

        <h1 className='title'>{title}</h1>
        <h2 className='description'>{description}</h2>

        {confirmation &&
          <div className='row-buttons-footer'>
            <ButtonSecondary
              label={labelBtnLeft || 'Cancelar'}
              isDisabled={false}
              loading={false}
              onClick={onClickBtnLeft || handleClose}
              customStyle={{
                'margin-top': '25px;',
                'width': '278px;',
                'height': '56px;',
                'margin-right': '8px;'
              }}
            />
            <ButtonPrimary
              label={labelBtnRight || 'Confirmar'}
              isDisabled={false}
              loading={false}
              onClick={onClickBtnRight || handleClose}
              customStyle={{
                'margin-top': '25px;',
                'width': '278px;',
                'height': '56px;',
                'margin-left': '8px;'
              }}
            />
          </div>
        }

        {!confirmation &&
          <ButtonPrimary
            label={labelBtn || 'Entendi'}
            isDisabled={false}
            loading={false}
            onClick={onClick || handleClose}
            customStyle={{
              'margin-top': '25px;',
              'width': '335px;',
              'height': '56px;'
            }}
          />
        }
      </div>
    </Modal>
  )
}

export default React.memo(CustomModal);