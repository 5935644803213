import styled from 'styled-components';

export const Container = styled.div`
min-height: 100vh;
padding:25px;
`

export const Title = styled.h1`
font-family: 'Poppins';
font-weight: 600;
font-size: 24px;
color: #23314B;
`

export const ContainerListCampaigns = styled.div`
display:flex;
flex-direction: row;
flex-wrap: wrap;
`