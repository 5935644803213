import { createGlobalStyle } from 'styled-components';
import '../../assets/fonts/Poppins/Poppins-Regular.ttf';
import '../../assets/fonts/Nexa/Nexa-Trial-Regular.ttf';
import '../../assets/fonts/Inter/Inter-Regular.ttf';

export default createGlobalStyle`
* {
 margin:0 
}

html, body, #root {
  height:100%
}

*, button, input {
  border:0;
  outline:0;
}

button {
  cursor:pointer;
}
`