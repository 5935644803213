import styled from 'styled-components';

export const Container = styled.div`
display: grid;
grid-template-columns: 50%;

grid-template-areas: 'LS RS';
height: 100vh;
`

export const LSWrapper = styled.div`
grid-area: LS;
background-color: ${props => props.theme.colors.secondary};
display: flex;
flex-direction: column;
padding-left: 8%;
padding-right: 22%;
padding-top: 5%;
`

export const RSWrapper = styled.div`
grid-area: RS;
background-color: ${props => props.theme.colors.secondary};
display: inline-flex;
flex-direction: column;
`

export const LogoColored = styled.img`
width: 128px;
`

export const Title = styled.h1`
font-family: 'Nexa';
font-weight: 700;
font-size: 40px;
color: #33303E;
margin-top: 60px;
line-height: 116%;
`

export const Subtitle = styled.p`
font-family: 'Poppins';
font-weight: 400;
font-size: 16px;
color: #7A7786;
margin-top: 8;
`

export const FooterLabel = styled.span`
font-family: 'Poppins';
color: #8C8A97;
font-weight: 500;
margin-top: 20px;
`

export const ButtonFAQ = styled.a`
cursor: pointer;
text-decoration: none;
transition: opacity .3s;
margin:23px 0;
display: flex;
position: absolute;
top: 5%;
right: 8%;
justify-content: center;
align-items: center;
`

export const ButtonFAQLabel = styled.span`
font-family: 'Poppins';
color: #fff;
font-weight: 400;

  > span{
    font-family: 'Poppins';
    color: #fff;
    font-weight: 600;
  }
`

export const FAQLogo = styled.img`
margin-right: 10px;
`

export const RSLogo = styled.img`
display: block;
margin: auto;
`

export const RSBackground = styled.div`
display: inline-flex;
justify-content: center;
align-items: center;
`