import {
  Container,
  ImageBg,
  InfluencerContainer,
  InfluencerDetail,
  InfluencerInfo,
  InfluencerLabel,
  PersonContainer,
  PersonInfo,
  PersonName,
  PersonPhoto,
  PersonPosition,
  ResultDetailItem,
  Subtitle,
  SubTopic,
  Title,
  Topic,
} from "./index.styles";
import { IResultDetailsByInfluencerProps, ITiktokResultsByInfluencerPostsProps } from "./index.types";
import lodash from 'lodash';
import KpisComponent from "../../../../../kpis/kpis.component";
import { maskNumbers, maskTime, validateIndividualRangeRateReachTiktok, validateRangeRateEngagementTiktok, validateRangeRateViewsTiktok } from "utils/validations";
import TiktokResultsByInfluencerPosts from "../postsByInfluencer/postsByInfluencer.component";


const ResultDetailsByInfluencer: React.FC<
  IResultDetailsByInfluencerProps
> = ({ influencers, tiktokQualitative }) => {
  const handleFeedData = (influencerName: string) => {
    let newArr: ITiktokResultsByInfluencerPostsProps[] = []

    influencers.filter((item) => item.tiktok.isActive)
      .map((influencer) => {
        if (influencer.name === influencerName) {

          influencer.tiktok.metrics.map((metric) => {

            let allIterations = lodash.sum([
              parseInt(metric.likes || '0'),
              parseInt(metric.comments || '0'),
              parseInt(metric.forwards || '0'),
              parseInt(metric.saved || '0'),
            ])

            let rangeRateReach = validateIndividualRangeRateReachTiktok(metric.reach ? [metric.reach] : [], influencer.tiktok.followers);
            let rangeRateEngagement = validateRangeRateEngagementTiktok([allIterations], metric.reach ? [metric.reach] : []);
            let rangeRateViews = validateRangeRateViewsTiktok(metric.views ? [metric.views] : [], influencer.tiktok.followers);

            newArr.push({
              'Alcance do post': maskNumbers(metric.reach || '0'),
              'Taxa de alcance': `${Number.isInteger(rangeRateReach) ? rangeRateReach : rangeRateReach.toFixed(2)}%`,
              'Likes': maskNumbers(metric.likes),
              'Comentários': maskNumbers(metric.comments || '0'),
              'Encaminham.': maskNumbers(metric.forwards || '0'),
              'Salvos': maskNumbers(metric.saved || '0'),
              'Interações totais': `${maskNumbers(allIterations.toString())}`,
              'Taxa de engajamento': `${Number.isInteger(rangeRateEngagement) ? rangeRateEngagement : rangeRateEngagement.toFixed(2)}%`,
              'Views': maskNumbers(metric.views || '0'),
              'Taxa de views': `${Number.isInteger(rangeRateViews) ? rangeRateViews : rangeRateViews.toFixed(2)}%`,
              'Retenção': `${metric.retention || '0'}%`,
              'Tempo de Repr. Total': maskTime(metric.totalReprodution || '0'),
              'Tempo médio visto': metric.avgWatchedTime || '0',
              'Assistiu ao video completo': `${metric.watchedFullVideo || '0'}%`,
            })
          })
        }
      })

    return newArr;
  }

  const handleFeedDataKpis = (influencerName: string, influencerCluster: string) => {


    let allRangeRateViewsCluster: number[] = [];
    let rangeRateViewsIndividual: number = 0;

    let allRangeRateEngagementsCluster: number[] = [];
    let rangeRateEngagementIndividual: number = 0;


    influencers.filter((item) => item.tiktok.isActive)
      .map((influencer) => {

        let allViewsIndividualCluster: number[] = [];
        let allViewsIndividual: number[] = [];

        let allIterationsIndividualCluster: number[] = [];
        let allIterationsIndividual: number[] = [];

        let allReachsIndividualCluster: number[] = [];
        let allReachsIndividual: number[] = [];

        influencer.tiktok.metrics.map((metric) => {



          if (influencer.cluster === influencerCluster) {
            //todas as views do cluster
            allViewsIndividualCluster.push(parseInt(metric.views || '0'));

            //todas as iteraçoes da campanha
            allIterationsIndividualCluster.push(parseInt(metric.likes || '0'));
            allIterationsIndividualCluster.push(parseInt(metric.comments || '0'));
            allIterationsIndividualCluster.push(parseInt(metric.forwards || '0'));
            allIterationsIndividualCluster.push(parseInt(metric.saved || '0'));

            //todos os alcances do cluster
            allReachsIndividualCluster.push(parseInt(metric.reach || '0'));
          }


          if (influencer.name === influencerName) {
            allViewsIndividual.push(parseInt(metric.views || '0'));

            allIterationsIndividual.push(parseInt(metric.likes || '0'));
            allIterationsIndividual.push(parseInt(metric.comments || '0'));
            allIterationsIndividual.push(parseInt(metric.forwards || '0'));
            allIterationsIndividual.push(parseInt(metric.saved || '0'));

            allReachsIndividual.push(parseInt(metric.reach || '0'));
          }

        })

        if (influencer.cluster === influencerCluster) {
          allRangeRateViewsCluster.push(validateRangeRateViewsTiktok(allViewsIndividualCluster, influencer.tiktok.followers));
          allRangeRateEngagementsCluster.push(validateRangeRateEngagementTiktok(allIterationsIndividualCluster, allReachsIndividualCluster));
        }

        if (influencer.name === influencerName) {
          rangeRateViewsIndividual = validateRangeRateViewsTiktok(allViewsIndividual, influencer.tiktok.followers);
          rangeRateEngagementIndividual = validateRangeRateEngagementTiktok(allIterationsIndividual, allReachsIndividual);
        }

      })

    let avgRangeRateViewsCluster = lodash.sum(allRangeRateViewsCluster) / allRangeRateViewsCluster.length;
    let avgRangeRateEngagementsCluster = lodash.sum(allRangeRateEngagementsCluster) / allRangeRateEngagementsCluster.length;

    return {
      engage: [
        {
          name: 'Média do cluster',
          label: 'Taxa de engajamento',
          value: Number.isInteger(avgRangeRateEngagementsCluster) ? avgRangeRateEngagementsCluster : parseInt(avgRangeRateEngagementsCluster.toFixed(2))
        },
        {
          name: 'Performance influenciador',
          label: 'Taxa de engajamento',
          value: Number.isInteger(rangeRateEngagementIndividual) ? rangeRateEngagementIndividual : parseInt(rangeRateEngagementIndividual.toFixed(2))
        },
      ],
      views: [
        {
          name: 'Média do cluster',
          label: 'Taxa de Views',
          value: Number.isInteger(avgRangeRateViewsCluster) ? avgRangeRateViewsCluster : parseInt(avgRangeRateViewsCluster.toFixed(2))
        },
        {
          name: 'Performance influenciador',
          label: 'Taxa de Views',
          value: Number.isInteger(rangeRateViewsIndividual) ? rangeRateViewsIndividual : parseInt(rangeRateViewsIndividual.toFixed(2))
        },
      ]
    }
  }

  return (
    <Container>
      <Title>Detalhamento de Resultado</Title>
      <Subtitle>Influenciador a influenciador</Subtitle>
      {tiktokQualitative.qualitativeMetrics.map((item, index) => (
        <ResultDetailItem key={index}>
          <InfluencerContainer>
            <ImageBg style={{ backgroundImage: `url(${item.influencerPhoto})` }}>
              <div style={{ width: '261px' }}></div>
            </ImageBg>
            <InfluencerInfo>
              <PersonContainer>
                <PersonPhoto src={item.influencerPhoto} />
                <PersonInfo>
                  <PersonName>{item.influencerName.toUpperCase()}</PersonName>
                  <PersonPosition>{item.influencerCluster}</PersonPosition>
                </PersonInfo>
              </PersonContainer>
              <>
                <InfluencerLabel>Análise Qualitativa</InfluencerLabel>
                <InfluencerDetail>
                  {item.analyzeQualitative}
                </InfluencerDetail>
                <InfluencerLabel>Análise de comentários</InfluencerLabel>
                <InfluencerDetail>
                  {item.analyzeComments}
                </InfluencerDetail>
                <InfluencerLabel>Insights de conteúdo</InfluencerLabel>
                <InfluencerDetail>
                  {item.contentInsights}
                </InfluencerDetail>
              </>
            </InfluencerInfo>
          </InfluencerContainer>
          <Topic>TikTok</Topic>
          <TiktokResultsByInfluencerPosts
            scroll
            data={handleFeedData(item.influencerName)}
          />
          <Topic>KPI's TikTok</Topic>
          <SubTopic>Cluster: {item.influencerCluster}</SubTopic>

          <KpisComponent
            columnOne={handleFeedDataKpis(item.influencerName, item.influencerCluster).engage}
            columnOnePerc={true}
            columnTwo={handleFeedDataKpis(item.influencerName, item.influencerCluster).views}
            columnTwoPerc={true}
          />

        </ResultDetailItem>
      ))}
    </Container>
  );
};

export default ResultDetailsByInfluencer;
