import React from "react";
import {
  Container,
  ContainerGrid,
  GridItem,
  PersonContainer,
  PersonInfo,
  PersonName,
  PersonPhoto,
  PersonPosition,
  PersonRate,
  Subtitle,
  Title,
  TopicContainer,
  TopicText,
} from "./top3Twitter.styles";
import { ITop3TwitterProps } from "./top3Twitter.types";
import { maskNumbers } from "utils/validations";

const Top3Twitter: React.FC<ITop3TwitterProps> = ({ newArrInfluencers }) => {

  return (
    <Container>
      <Title>Top 3 Twitter</Title>
      <Subtitle>
        Os melhores influenciadores em impressões e % de engajamento no Twitter
      </Subtitle>
      <ContainerGrid>
        {[
          {
            label: "Impressões",
            influencers: newArrInfluencers.newArrImpressions
          },
          {
            label: "Engajamento",
            influencers: newArrInfluencers.newArrEngagement
          },
          {
            label: "Views",
            influencers: newArrInfluencers.newArrViews
          },
        ].map((item, index) => {
          return (
            <GridItem key={index}>
              <TopicContainer>
                <TopicText>{item.label}</TopicText>
              </TopicContainer>
              {item.influencers.map((influencer, index) => (
                <PersonContainer key={index} border={index + 1 !== item.influencers.length}>
                  <PersonPhoto src={influencer.photo} />
                  <PersonInfo>
                    <PersonName>{influencer.name.toUpperCase()}</PersonName>
                    <PersonPosition>{influencer.cluster}</PersonPosition>
                    <PersonRate>{item.label !== 'Engajamento' ? maskNumbers(influencer.value.toString()) : influencer.value}{item.label === 'Engajamento' ? '%' : ''} {item.label}</PersonRate>
                  </PersonInfo>
                </PersonContainer>
              ))}
            </GridItem>
          );
        })}
      </ContainerGrid>
    </Container>
  );
};
export default Top3Twitter;
