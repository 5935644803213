import React from "react";
import logoMenu from "../../../../assets/logo/mudah_logo_white.svg";
import {
  Container,
  Header,
  IconBox,
  LogoImg,
  MenuContainer,
  MenuItemLink,
  Tag,
  TagBox,
} from "./sideMenuReport.styles";
import { ISideMenuReportProps } from "./sideMenuReport.types";

const SideMenuReport: React.FC<ISideMenuReportProps> = ({
  sections,
  handleScroll,
  menuActive,
}) => {
  return (
    <Container>
      <Header>
        <LogoImg
          src={logoMenu}
          alt="logo mudah performance analytics"
          width={144}
          height={29}
        />
      </Header>
      <MenuContainer>
        {sections.map((item, index) => {
          return (
            <MenuItemLink
              key={index}
              onClick={() => handleScroll(item.scrollTo)}
              isActive={item.scrollTo === menuActive}
            >
              <IconBox isActive={item.scrollTo === menuActive}>
                <p>{index + 1}</p>
              </IconBox>
              {item.name}
              {item.scrollTo === menuActive && (
                <TagBox>
                  <Tag />
                </TagBox>
              )}
            </MenuItemLink>
          );
        })}
      </MenuContainer>
    </Container>
  );
};
export default SideMenuReport;
