import React, { useEffect, useState } from 'react';
import {
  Panel,
  PanelTitle,
  PanelSubtitle,
} from './campaignRegisterThree.styles';
import ButtonSecondary from '../../../../global/components/buttons/secondary/buttonSecondary.component';
import ModalContent from '../../../../global/components/modalContent/modalContent.components';
import { ICampaignRegisterThreeProps } from './campaignRegisterThree.types';
import CampaignDataInstagram from '../../components/campaignDataInstagram/campaignDataInstagram.component';
import CampaignDataTwitter from '../../components/campaignDataTwitter/campaignDataTwitter.component';
import CampaignDataYoutube from '../../components/campaignDataYoutube/campaignDataYoutube.component';
import CampaignDataTiktok from '../../components/campaignDataTiktok/campaignDataTiktok.component';
import InstagramPreviewMetrics from 'pages/campaings/components/instagramPreviewMetrics/index.component';
import TwitterPreviewMetrics from 'pages/campaings/components/twitterPreviewMetrics/index.component';
import TikTokPreviewMetrics from 'pages/campaings/components/tiktokPreviewMetrics/index.component';
import YoutubePreviewMetrics from 'pages/campaings/components/youtubePreviewMetrics/index.component';

const CampaignRegisterThree: React.FC<ICampaignRegisterThreeProps> = (props) => {

  const {
    influencers,
    instagramQualitative,
    twitterQualitative,
    youtubeQualitative,
    tiktokQualitative,
    handleSetStates,
    handleShowModal,
    enableHandleNext
  } = props;

  const [getData, setGetData] = useState(false);
  const [modal, setModal] = useState({ isVisible: false, type: '' });

  const handleGetInfoBack = (item: any) => {
    handleSetStates(item);

    setTimeout(() => {
      setGetData(false);
    }, 1000);
  }

  const handleShowGraphics = (type: string) => {
    setModal({ isVisible: true, type });
  }

  const handleCloseModal = () => {
    setModal({ isVisible: false, type: '' });
  }

  return (
    <>
      <Panel>
        <PanelTitle>Dados da campanha</PanelTitle>
        <PanelSubtitle>Análise qualitativas, insigths e comentários</PanelSubtitle>

        <CampaignDataInstagram
          influencers={influencers}
          instagramQualitative={instagramQualitative}
          getData={getData}
          handleGetInfoBack={handleGetInfoBack}
          handleShowGraphics={() => handleShowGraphics('instagram')}
        />
        <CampaignDataTwitter
          influencers={influencers}
          twitterQualitative={twitterQualitative}
          getData={getData}
          handleGetInfoBack={handleGetInfoBack}
          handleShowGraphics={() => handleShowGraphics('twitter')}
        />
        <CampaignDataYoutube
          influencers={influencers}
          youtubeQualitative={youtubeQualitative}
          getData={getData}
          handleGetInfoBack={handleGetInfoBack}
          handleShowGraphics={() => handleShowGraphics('youtube')}
        />
        <CampaignDataTiktok
          influencers={influencers}
          tiktokQualitative={tiktokQualitative}
          getData={getData}
          handleGetInfoBack={handleGetInfoBack}
          handleShowGraphics={() => handleShowGraphics('tiktok')}
        />

        <ButtonSecondary
          label='Salvar alterações'
          isDisabled={false}
          loading={false}
          onClick={() => {
            setGetData(true);
            handleShowModal();
            enableHandleNext();
          }}
          customStyle={{
            'margin-top': '25px;',
            'width': '256px;',
            'height': '56px;',
            'align-self': 'flex-end;'
          }}
          customLabelStyle={{
            "font-style": "normal;",
            "font-weight": "400;",
            "font-size": "12px;",
            "line-height": "14px;"
          }}
        />
        <ModalContent isVisible={modal.isVisible}>
          {modal.type === 'instagram' &&
            <InstagramPreviewMetrics
              toggleModalVisible={handleCloseModal}
              influencers={influencers}
              instagramQualitative={instagramQualitative}
            />
          }

          {modal.type === 'twitter' &&
            <TwitterPreviewMetrics
              toggleModalVisible={handleCloseModal}
              influencers={influencers}
              twitterQualitative={twitterQualitative}
            />
          }

          {modal.type === 'tiktok' &&
            <TikTokPreviewMetrics
              toggleModalVisible={handleCloseModal}
              influencers={influencers}
              tiktokQualitative={tiktokQualitative}
            />
          }

          {modal.type === 'youtube' &&
            <YoutubePreviewMetrics
              toggleModalVisible={handleCloseModal}
              influencers={influencers}
              youtubeQualitative={youtubeQualitative}
            />
          }

        </ModalContent>
      </Panel>
    </>
  )
}

export default CampaignRegisterThree;