import styled from 'styled-components';

export const Panel = styled.div`
margin-bottom: 20px;
background-color: ${props => props.theme.colors.white};
border-radius: 10px;
display: flex;
flex-direction: column;
padding:32px;
`
export const PanelTitle = styled.h1`
font-family: 'Poppins';
font-weight: 600;
font-size: 18px;
color: ${props => props.theme.colors.dark};
`

export const PanelSubtitle = styled.h2`
font-family: 'Poppins';
font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 12px;
color: #A9B8CD;
margin-top: 10px;
margin-bottom: 40px;
`

export const InterPanelBorder = styled.div`
display: flex;
border: 1px solid #D8D8E8;
border-radius: 12px;
flex-direction: column;
padding: 42px 32px;
`

export const PanelContent = styled.div`
display: flex;
flex:1;
flex-direction: row;
`
export const PanelBody = styled.div`
display: flex;
flex-direction: column;
justify-content: flex-start;
width: 100%;
`
export const Divider = styled.div`
width: 30px;
`

export const LineDivider = styled.div`
width: 100%;
border: 1px solid #D8D8E8;
margin:30px 0 50px 0;
`

export const Title = styled.h1`
font-family: 'Poppins';
font-weight: 600;
font-size: 18px;
color: ${props => props.theme.colors.dark};
margin-bottom: 40px;
`