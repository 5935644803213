import styled from 'styled-components'

export const Container = styled.div`
  margin-top: 27px;
`

export const Title = styled.h1`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  color: #23314b;
`
export const Subtitle = styled.p`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  margin-top: 3px;
  color: #809fb8;
`

export const ContainerGrid = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: repeat(3, 1fr);
  margin-top: 26px;
  gap: 34px;
  @media (max-width: 420px) {
    grid-template-columns: repeat(1, 1fr);
  }
`

export const GridItem = styled.div`
  background: #ffffff;
  border: 1px solid #d8d8e8;
  border-radius: 16px;
  padding: 39px 26px;
`
export const TopicContainer = styled.div`
  display: flex;
  background: #809fb8;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  position: relative;
  width: 133.31px;
  height: 24px;
  bottom: 52px;
`

export const TopicText = styled.span`
  font-family: 'Poppins';
  font-style: normal;
  text-align: center;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: #ffffff;
`

export const PersonContainer = styled.div<{ border?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: ${(props) => props.border && '1px solid #d8d8e8'};
  padding-bottom: 23px;
  margin-top: 24px;
`

export const PersonPhoto = styled.img`
  width: 86px;
  height: 86px;
  border-radius: 43px;
  object-fit: cover;
`

export const PersonInfo = styled.div`
  margin-left: 20px;
`

export const PersonName = styled.div`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  color: #23314b;
`

export const PersonPosition = styled.div`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 100%;
  margin-top: 4px;
  color: #23314b;
`

export const PersonRate = styled.div`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 100%;
  margin-top: 4px;
  color: #3bc5cb;
`

export const PersonMedia = styled.div`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 100%;
  margin-top: 4px;
  color: #23314b;
`
