import styled from 'styled-components'

export const Container = styled.div`
  display: grid;
  grid-template-columns: 259px auto;
  grid-template-rows: 100px auto;
  grid-template-areas:
    'SM MC'
    'SM MC';
  height: 100vh;
  @media(max-width: 420px) {
    grid-template-columns: 100%;
    grid-template-rows: 100%;
    grid-template-areas:
    'MC'
    'MC';
  }
`

export const Content = styled.div`
  grid-area: MC;
  background-color: #f6f7fb;
  color: ${(props) => props.theme.colors.white};
  overflow-y: scroll;
  scroll-behavior: smooth;
  ::-webkit-scrollbar {
    width: 0px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: ${(props) => props.theme.colors.secondary};
    border-radius: 10px;
  }
  ::-webkit-scrollbar-track {
    background-color: ${(props) => props.theme.colors.tertiary};
  }
`
