import ReactDOM from 'react-dom/client';
import App from './App';
// import './global/styles/globalStyles';
import 'bootstrap/dist/css/bootstrap.css';
import './assets/fonts/Poppins/Poppins-Regular.ttf';
import './assets/fonts/Nexa/Nexa-Trial-Regular.ttf';
import './assets/fonts/Inter/Inter-Regular.ttf';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <App />
);