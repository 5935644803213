import { doc, getDoc, onSnapshot, query, collection, setDoc, updateDoc, deleteDoc, getDocs } from "firebase/firestore";
import db, { firebaseStorage } from "../../db/firebaseConn";
import { ILoadingProps } from "../../utils/globalTypes";
import { IBrandsProps } from "./brands.types";
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";

export const FetchAllBrands = (setBrands: (brands: any) => void, callback: (status: ILoadingProps) => void) => {
  try {
    const q = query(collection(db, 'brands'));
    let brands: any = [];
    onSnapshot(q, (querySnapShot) => {

      querySnapShot.forEach((doc) => {
        brands.push(doc.data());
      });

      // console.log('brands ->', brands);

      setBrands(brands);
      callback({ status: false, type: 'list_brands_success' });
    })
  } catch (error) {
    console.log('Error on trycatch FetchAllBrands ->', error);

  }
}


export const FetchBrandRegister = async (brandData: IBrandsProps, callback: (status: ILoadingProps) => void) => {
  try {

    if (brandData.thumb?.name) {
      const storageRef = ref(firebaseStorage, `brands/${brandData.thumb?.name}`);
      const uploadTask = uploadBytesResumable(storageRef, brandData.thumb);

      uploadTask.on("state_changed",
        () => { },
        (error) => {
          console.log('Error on uploadPhoto');
          callback({ status: false, type: 'brand_registration_failed' });
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then(async (downloadURL) => {

            const newBrand = {
              ...brandData,
              thumb: downloadURL
            }

            await setDoc(doc(db, "brands", newBrand.id), newBrand);

            callback({ status: false, type: 'brand_registered_successfully' });
          });
        }
      );
    } else {
      const newBrand = {
        ...brandData,
        thumb: 'https://firebasestorage.googleapis.com/v0/b/mudah-web.appspot.com/o/brands%2Fdefault_brand_image.jpeg?alt=media&token=b0a80022-3808-4c8e-8854-235460f0fea0'
      }

      await setDoc(doc(db, "brands", newBrand.id), newBrand);
      callback({ status: false, type: 'brand_registered_successfully' });

    }

  } catch (error) {
    callback({ status: false, type: 'brand_registration_failed' });
    console.log('Error on trycatch FetchBrandsRegister ->', error);

  }
}

export const FetchBrandsEdit = async (brandData: IBrandsProps, callback: (status: ILoadingProps) => void) => {
  try {

    if (brandData.thumb?.name) {
      const storageRef = ref(firebaseStorage, `brands/${brandData.thumb?.name}`);
      const uploadTask = uploadBytesResumable(storageRef, brandData.thumb);

      uploadTask.on("state_changed",
        () => { },
        (error) => {
          console.log('Error on uploadPhoto');
          callback({ status: false, type: 'brand_edition_failed' });
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then(async (downloadURL) => {

            const newBrand = {
              ...brandData,
              thumb: downloadURL
            }
            await updateDoc(doc(db, "brands", newBrand.id), newBrand);

            callback({ status: false, type: 'brand_edited_successfully' });
          });
        }
      );
    } else {
      const newBrand = {
        ...brandData
      }

      await updateDoc(doc(db, "brands", newBrand.id), newBrand);
      callback({ status: false, type: 'brand_edited_successfully' });

    }

  } catch (error) {
    callback({ status: false, type: 'brand_edition_failed' });
    console.log('Error on trycatch FetchBrandsEdit ->', error);

  }
}




