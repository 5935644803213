import styled from 'styled-components';

export const Container = styled.div`
display: grid;
grid-template-columns: 50%;
grid-template-areas: 'LS RS';
height: 100vh;

@media(max-width: 420px) {
grid-template-columns: 100%;
grid-template-areas: 'RS' 'LS' ;
}
`

export const LSWrapper = styled.div`
grid-area: LS;
background-color: #263238;
display: inline-flex;
flex-direction: column;
padding-left: 8%;
padding-right: 8%;
padding-top: 5%;
@media(max-width: 420px) {
  padding-right: 8%;
}
`

export const RSWrapper = styled.div`
grid-area: RS;
background-color: ${props => props.theme.colors.secondary};
display: inline-flex;
flex-direction: column;
@media(max-width: 420px) {
 flex:1;
}
`

export const LogoColored = styled.img`
width: 128px;
@media(max-width: 420px) {
display: none;
}
`

export const Title = styled.h1`
font-family: 'Nexa';
font-weight: 700;
font-size: 40px;
color: #fff;
margin-top: 60px;
line-height: 116%;

@media(max-width: 420px) {
  font-size: 30px;
  margin-top: 20px;
}
`

export const Subtitle = styled.p`
font-family: 'Poppins';
font-weight: 400;
font-size: 16px;
color: #fff;
margin-top: 8px;
@media(max-width: 420px) {
  margin-top: 20px;
}
`

export const FooterLabel = styled.span`
font-family: 'Poppins';
color: #8C8A97;
font-weight: 500;
margin-top: 20px;
`

export const ButtonFAQ = styled.a`
cursor: pointer;
text-decoration: none;
transition: opacity .3s;
margin:23px 0;
display: flex;
position: absolute;
top: 5%;
right: 8%;
justify-content: center;
align-items: center;

@media(max-width: 420px) {
display: none;
}
`

export const ButtonFAQLabel = styled.span`
font-family: 'Poppins';
color: #fff;
font-weight: 400;

  > span{
    font-family: 'Poppins';
    color: #fff;
    font-weight: 600;
  }
`

export const FAQLogo = styled.img`
margin-right: 10px;
`

export const RSLogo = styled.img`
@media(max-width: 420px) {
  height: 36px;
  margin:20px;
}
`

export const RSBackground = styled.div`
display: inline-flex;
justify-content: center;
align-items: center;

@media(max-width: 420px) {
  justify-content: flex-start;
  align-items: flex-end;
}
`

export const Overlay = styled.div`
@media(max-width: 420px) {
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: rgba(0,0,0,0.5);
}
`  