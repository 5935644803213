import React, { useState } from 'react';
import {
  Panel,
  PanelTitle,
  PanelSubtitle,
  InterPanelBorder,
  PanelContent,
  PanelBody,
  Divider,
  TextCopied
} from './campaignRegisterFive.styles';
import ButtonSecondary from '../../../../global/components/buttons/secondary/buttonSecondary.component';
import { ICampaignRegisterFiveProps } from './campaignRegisterFive.types';

import downloadReportIcon from '../../../../assets/icons/download_report.svg';
import copyDataReportIcon from '../../../../assets/icons/copy_data_report.svg';

import InputComponent from '../../../../global/components/input/input.component';

const CampaignRegisterFive: React.FC<ICampaignRegisterFiveProps> = (props) => {

  const {
    reportLink,
    reportPassword,
    isComplete,
    handleBackEdit,
    handleGoToDownloadPDF
  } = props;

  const [linkCopied, setLinkCopied] = useState(false);
  const [passwordCopied, setPasswordCopied] = useState(false);

  const handleCopyLink = () => {
    setLinkCopied(true);
    setTimeout(() => {
      setLinkCopied(false);
    }, 2000);
  }

  const handleCopyPassword = () => {
    setPasswordCopied(true);
    setTimeout(() => {
      setPasswordCopied(false);
    }, 2000);
  }


  return (
    <>
      <Panel>
        <PanelTitle>Compartilhar report</PanelTitle>
        <PanelSubtitle>Envio de relatório para o cliente</PanelSubtitle>

        <InterPanelBorder>
          <PanelTitle>{isComplete ? 'Dados de acesso' : 'Tudo pronto!'}</PanelTitle>
          <PanelSubtitle>Copie os dados abaixo e envie para o cliente ter acesso ao relatório da campanha</PanelSubtitle>

          <PanelContent>
            <PanelBody>
              <InputComponent
                label='Link de acesso ao relatório'
                value={reportLink.replace('https://', '').length > 57 ? `${reportLink.replace('https://', '').substring(0, 50)}...` : reportLink.replace('https://', '')}
                onChange={() => { }}
                icon={copyDataReportIcon}
                iconClick={() => {
                  navigator.clipboard.writeText(reportLink);
                  handleCopyLink();
                }}
                customIconStyle={{
                  "top": "40px;",
                  "cursor": "pointer;"
                }}
              />
              {linkCopied &&
                <TextCopied>Link copiado com sucesso</TextCopied>
              }
            </PanelBody>
            <Divider />
            <PanelBody>
              <InputComponent
                label='Senha'
                value={reportPassword}
                onChange={() => { }}
                icon={copyDataReportIcon}
                iconClick={() => {
                  navigator.clipboard.writeText(reportPassword);
                  handleCopyPassword();
                }}
                customIconStyle={{
                  "top": "40px;",
                  "cursor": "pointer;"
                }}
              />
              {passwordCopied &&
                <TextCopied>Senha copiada com sucesso</TextCopied>
              }
            </PanelBody>
          </PanelContent>
        </InterPanelBorder>



        <ButtonSecondary
          label='Baixar em PDF'
          isDisabled={false}
          loading={false}
          onClick={() => {
            if (handleGoToDownloadPDF)
              handleGoToDownloadPDF();
          }}
          customStyle={{
            'margin-top': '25px;',
            'width': '299px;',
            'height': '48px;',
            'align-self': 'flex-start;'
          }}
          customLabelStyle={{
            "font-style": "normal;",
            "font-weight": "600;",
            "font-size": "14px;",
            "line-height": "17px;"
          }}
          icon={downloadReportIcon}
        />

        {handleBackEdit && <ButtonSecondary
          label={"Editar campanha"}
          isDisabled={false}
          loading={false}
          onClick={handleBackEdit}
          customStyle={{
            "margin-top": "25px;",
            width: "150px;",
            height: "40px;",
          }}
        />}

      </Panel>
    </>
  )
}

export default CampaignRegisterFive;