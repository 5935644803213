import React, { useState, useEffect } from 'react';
import SignInLayout from './signIn.layout';
import { useNavigate } from 'react-router-dom';
import { validateEmail } from '../../utils/validations';
import { ILoadingProps } from '../../utils/globalTypes';
import { FetchSignIn } from '../../store/user/user.actions';
import { useAppDispatch } from '../../store/storeHook';
import { setModalMessage } from '../../store/modal/modal.actions';

const SignInController: React.FC = () => {

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [keepSignedIn, setKeepSignedIn] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [loading, setLoading] = useState({ status: false, type: '' })

  const handleHome = () => {
    navigate('/campanhas');
  }

  const toggleLoading = (status: ILoadingProps) => {
    setLoading(status);
  }

  const handleSignIn = () => {
    toggleLoading({ status: true, type: 'fetching' });
    dispatch(FetchSignIn(email, password, toggleLoading));
  }

  useEffect(() => {
    if (loading.type === 'login_successfully')
      handleHome();

    if (loading.type === 'login_failed')
      dispatch(setModalMessage({
        title: 'Ops! :(',
        isVisible: true,
        description: 'E-mail ou senha inválidos.',
        icon: '',
        confirmation: false,
      }))

    if (loading.type === 'user_inactive')
      dispatch(setModalMessage({
        title: 'Ops! :(',
        isVisible: true,
        description: 'Seu usuário está inativo, entre em contato com o gestor para ativa-lo.',
        icon: '',
        confirmation: false,
      }))

    //eslint-disable-next-line
  }, [loading])

  useEffect(() => {
    if (validateEmail(email) && password.length > 0)
      setButtonDisabled(false);
    else setButtonDisabled(true);
  }, [email, password])

  return <SignInLayout
    localState={{
      email,
      password,
      keepSignedIn,
      buttonDisabled,
      loading
    }}
    handlers={{
      setEmail,
      setPassword,
      setKeepSignedIn,
      handleSignIn
    }}
  />
}

export default SignInController;