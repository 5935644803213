import styled from 'styled-components'
import bg from '../../../assets/backgrounds/headerBg.jpg'

export const Container = styled.div<{ themeMode: 'light' | 'dark' }>`
  background-color: ${(props) => props.themeMode === 'light' ? props.theme.colors.secondary : '#37474E'};
  display: flex;
  flex-direction: column;
`

export const InputProfileBox = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
  padding: 10px 29px;
`

export const Input = styled.div<{ themeMode: 'light' | 'dark' }>`
  width: 728px;
  height: 46px;
  background: ${(props) => props.themeMode === 'light' ? '#f6f7fb' : '#3F5057'};
  border-radius: 34px;
  display: flex;
  align-items: center;
  padding-left: 25px;

  > span {
    font-family: 'Poppins';
    font-weight: 400;
    font-size: 16px;
    color: #bdbdbd;
    margin-left: 31px;
  }
`

export const Profile = styled.div`
  display: flex;
  align-items: center;
`

export const IconBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 44.29px;
  height: 44.29px;
  background-color: #f6f7fb;
  border-radius: 30px;
  margin: 0 10px;
`

export const UserBox = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 10px;
`

export const UserName = styled.h5<{ themeMode: 'light' | 'dark' }>`
  font-family: 'Poppins';
  font-weight: 600;
  font-size: 16px;
  color: ${(props) => (props.themeMode === 'light' ? '#23314b' : '#f6f7fb')};
  line-height: 18px;
`

export const ButtonExit = styled.a<{ themeMode: 'light' | 'dark' }>`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: ${(props) => (props.themeMode === 'light' ? '#828282' : '#f6f7fb')};
  text-align: right;
  text-decoration: none;
  cursor: pointer;
  line-height: 5px;
`

export const ProfilePhoto = styled.img`
  width: 48px;
  height: 48px;
  margin-left: 10px;
  border-radius: 30px;
  object-fit: cover;
`

export const Title = styled.span`
  margin-bottom: 20px;
`

export const HeaderBg = styled.div.attrs(() => ({
  style: {
    backgroundImage: `url(${bg})`,
    backgroundRepeat: 'no-repeat',
  },
}))`
  display: flex;
  align-items: flex-end;
  padding-left: 53px;
  /* padding-bottom: 23px; */
  height: 190px;
  width: auto;
  > span {
    font-size: 32px;
    font-family: 'Poppins';
    color: ${(props) => props.theme.colors.white};
    font-weight: 600;
  }
`
